import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { Component, OnInit } from '@angular/core';
import { RegistersService } from 'src/app/core/services/registers.service';
import { phoneNumberStringFormatter } from 'src/app/core/utils/string-formatters';
import { Title } from "@angular/platform-browser";
import { ListTemplate } from 'src/app/shared/models/list-template';

@Component({
  selector: 'app-attendance-list',
  templateUrl: './attendance-list.component.html',
  styleUrls: ['./attendance-list.component.scss']
})
export class AttendanceListComponent implements OnInit {

  searchString: string = "";
  searchSchedule: number[] = [];
  searchDate: string = moment().format("YYYY-MM-DD");
  orderingItem: string = "patient__name";

  listTemplate: ListTemplate = {
    loading: true,
    showSearch: true,
    searchBarPlaceholder: "Buscar por nome do paciente",
    showAdvancedSearch: false,
    newDataText: "+ Atender sem agendamento",
    newDataRouterLink: "register",
    notFoundMessage: "Nenhum atendimento encontrado",
    searchBarDate: true,
    searchBarSchedule: true,
    showInfo: true,
    count: false,
    idFieldName: "id",
    header: [
      { name: "date_time", label: "Data e Hora", widthInPercentage: "20%", width: "150px" },
      { name: "patient__name", label: "Nome", widthInPercentage: "30%", width: "250px" },
      { name: "patient__phone_number", label: "Telefone", widthInPercentage: "25%", width: "200px", displayStringConvertionFunction: phoneNumberStringFormatter },
      { name: "attendance_type", label: "Tipo", widthInPercentage: "25%", width: "200px" }
    ],
    onHeaderItemClick: (value: string) => {
      let invertString = '';
      if (this.orderingItem == value) {
        this.listTemplate.invertedOrdering = !this.listTemplate.invertedOrdering;
        if (this.listTemplate.invertedOrdering)
          invertString = '-';
      }
      this.fetchList(this.searchString, this.searchSchedule,  `${invertString}${value}`, this.searchDate);
      this.orderingItem = value;
      this.listTemplate.selectedOrdering = value;
    },
    onSearchFocusOut: (value: string) => {
      this.searchString = value;
      this.fetchList(value, this.searchSchedule, this.orderingItem, this.searchDate);
    },
    onDateFocusOut: (value: string) => {
      this.searchDate = value;
      this.fetchList(this.searchString, this.searchSchedule, this.orderingItem, this.searchDate);
    },
    onScheduleFocusOut: (value: number[]) => {
      this.searchSchedule = value;
      this.fetchList(this.searchString, this.searchSchedule, this.orderingItem, this.searchDate);
    },
    selectedOrdering: this.orderingItem,
    invertedOrdering: false,
  };

  constructor(private registersService: RegistersService, private titleService: Title) {
    this.titleService.setTitle('ELEVE - Prontuários');
  }

  ngOnInit(): void {
    this.initializeList();
  }

  initializeList() {
    this.fetchList(this.searchString, this.searchSchedule, this.orderingItem, this.searchDate);
  }

  async fetchList(searchString: string, searchSchedules: number[], orderingItem: string, searchDate: string) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;

    try {
      const listResponse = await this.registersService.attendanceListGet(searchString, searchSchedules, orderingItem, searchDate).toPromise();
      this.listTemplate.loading = false;
      this.listTemplate.data = listResponse;
      this.listTemplate.hasNext = listResponse.next != null;
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = response.error['detail'];
    }
  }

  loadMore(page: number) {
    this.listTemplate.loadingNext = true;
    this.registersService.attendanceListGet(this.searchString, this.searchSchedule, this.orderingItem, this.searchDate, page).subscribe((response) => {
      response.results?.forEach(result => {
        this.listTemplate.data?.results?.push(result);
      })
      this.listTemplate.loadingNext = false;
      this.listTemplate.hasNext = response.next != null;
    }), (error: HttpErrorResponse) => {
      this.listTemplate.loadingNext = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = error.error['detail'];
      console.error(error);
    };
  }
}
