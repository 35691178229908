import { ValidatorFn } from "@angular/forms";
import { HealthInsuranceModel } from "src/app/core/models/health-insurance.model";
import { OptionModel } from "./option";
import {HealthInsurancePatientModel} from "../../../core/models/health-insurance-patient";

export const inputType = {
    BASIC: 0,
    SEARCH: 1,
    DATE: 2,
    DROPDOWN: 3,
    CHIPS: 4,
    MULTILINE: 5,
    MULTIDATA: 6,
    IMAGE: 7,
    RADIO: 8,
    CHECKBOX: 9,
    CHECKBOX2: 10,
    SCHEDULE: 11,
    READONLY: 12,
    RADIO2: 13,
    DROPDOWN2: 14,
    ATTACHMENTS: 15,
    NUMBERONLY: 16,
    ZIPCODE: 17,
    DDI: 18,
    LIST: 19,
    HIDDEN: 20,
};

export interface WidthInCols {
    xs?: number,
    sm?: number,
    md?: number,
    lg?: number,
    xl?: number,
}

export interface MaskOptions {
    mask?: string,
    prefix?: string,
}

export interface FormInput {
    name: string,
    label?: string,
    placeholder?: string,
    widthInCols?: WidthInCols,
    type?: number,
    validators?: ValidatorFn[],
    dropdownOptions?: OptionModel[],
    multiDataInputs?: FormInput[],
    value?: any,
    maskOptions?: MaskOptions,
    onFileSelect?: Function,
    onValueChange?: Function,
    canRemoveFirst?: boolean,
    canRemoveAll?: boolean,
    removeVerticalSpacing?: boolean
    healthInsuranceData?: HealthInsuranceModel[],
    healthInsuranceEdit?: boolean,
    capitalize?: boolean,
    showAddItem?: boolean,
    phoneNumberMultidata?: boolean,
    readOnlyMultidata?: boolean,
    readOnlyMultidataInitialValues?: any[];
    hideClear?: boolean;
    disabled?: boolean;
    healthInsurances?: HealthInsurancePatientModel[] | undefined;
}

export interface Dictionary {
    [key: string]: string;
}
