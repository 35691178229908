<div class="row p-0 m-0 header">
  <div class="col-12">
    <div class="page-info">
      <div class="page-info__content">
        <h1 class="page-info__content--title">Agenda</h1>
        <h2 class="page-info__content--subtitle" [formGroup]="formGroup">
          <ng-select class="custom" formControlName="currentSchedule" (change)="selectSchedule($event)"
            [clearable]="false">
            <ng-option value="reminder">Lembrete</ng-option>
            <ng-option *ngFor="let schedule of scheduleList" [value]="schedule.id">{{ schedule.name }}</ng-option>
          </ng-select>
        </h2>
      </div>
    </div>
  </div>
</div>
<div class="row px-2">
  <div class="col-4 mb-5 position-sticky d-none">
    <form [formGroup]="formGroup">
      <div class="menu-card sticky-top">
        <div class="menu-card__calendar-wrapper mb-5">
          <dp-day-calendar [config]="datePickerConfig" (onSelect)="dateChangeClickHandler($event)"
            (onGoToCurrent)="goToCurrentClickHandler()" theme="custom-theme">
          </dp-day-calendar>
        </div>

        <div class="selectors-wrapper">
          <div class="selector-group mb-4">
            <h3 class="mb-3">Tipo de visualização</h3>
            <div class="selector d-flex align-content-center mb-1">
              <input type="radio" id="schedule-view-diary" value="diary" formControlName="scheduleViewType"
                class="mr-2">
              <label for="schedule-view-diary">Diário</label><br>
            </div>

            <div class="selector d-flex align-content-center mb-1">
              <input type="radio" id="schedule-view-weekly" value="weekly" formControlName="scheduleViewType"
                class="mr-2">
              <label for="schedule-view-weekly">Semanal</label><br>
            </div>

            <div class="selector d-flex align-content-center mr-2">
              <input type="radio" id="schedule-view-multiple" value="multiple" formControlName="scheduleViewType"
                class="mr-2">
              <label for="schedule-view-multiple">Múltiplas agendas</label><br>
            </div>
          </div>
        </div>
      </div>

      <div class="menu-card d-flex d-md-none">
        <div class="menu-card__calendar-wrapper">
          <dp-day-calendar [config]="datePickerConfig" (onSelect)="dateChangeClickHandler($event)"
            (onGoToCurrent)="goToCurrentClickHandler()" theme="custom-theme">
          </dp-day-calendar>
        </div>
      </div>
    </form>
  </div>

  <div class="col px-0 d-none d-md-block">
    <div class="content-card">
      <div class="calendar" *ngIf="currentSchedule || scheduleIsReminder else scheduleNotSelectedTemplate">
        <div class="calendar-toolbar" [formGroup]="formGroup">
          <div class="calendar-toolbar__toolbar mb-3">
            <div class="row d-flex align-items-end mt-3" *ngIf="selectedToAction.length > 0">
              <div class="col-2 pl-0 ">
                <button class="btn btn-danger" (click)="remove()">Apagar</button>
              </div>

              <div class="col-2 pl-0 ">
                <button class="btn btn-primary" (click)="merge()"
                  [disabled]="selectedToAction.length < 2 || !isSequencial">Mesclar
                </button>
              </div>

              <div class="col-5 p-0 ">
                <div class="row d-flex align-items-end">
                  <div class="col-5 p-0 ">
                    <label for="firstSplitTime">Primeiro atendimento (min)</label>
                    <input type="number" id="firstSplitTime" [formControl]="firstSplitTime">
                  </div>

                  <div class="col-7 btn-dividir">
                    <button class="btn btn-info" (click)="split()" [disabled]="selectedToAction.length != 1">Dividir
                    </button>
                  </div>
                </div>
              </div>

              <div class="col-3 pr-0">
                <button class="btn btn-lg btn-primary" (click)="open(content)"
                  [disabled]="selectedToAction.length < 1">Tipo de atendimento</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-3 pr-0 buttons-title">
              <div class="wrapper">
                <button class="btn btn-link btn-datepicker mr-4" (click)="openDatePicker()">
                  <span class="icon icon-Icon-ionic-ios-calendar"></span>
                </button>
              </div>

              <button class="btn btn-outline-primary btn-today" (click)="goToCurrentClickHandler()">
                Hoje
              </button>
            </div>

            <div class="col-6 d-flex justify-content-center">
              <button class="btn btn-link-arrow icon-previous" (click)="previous()">
                <span class="icon icon-Icon-ionic-ios-arrow-down"></span>
              </button>

              <h4 class="d-inline-block current-month">{{ currentMonth }}</h4>

              <button class="btn btn-link-arrow icon-next" (click)="next()">
                <span class="icon icon-Icon-ionic-ios-arrow-down"></span>
              </button>
            </div>

            <div class="col-3 align-self-center">
              <div class="row">
                <div class="col d-flex modal-box-support" *ngIf="attendanceTypes">
                  <button class="btn btn-outline-primary btn-attendance-types"
                    (click)="openAttendanceTypeFilter()">Filtrar tipos de atendimento</button>

                  <div class="modal-box-wrapper" *ngIf="attendanceTypeFilterIsOpened"
                    (click)="closeAttendanceTypeFilter()"></div>
                  <div class="modal-box" *ngIf="attendanceTypeFilterIsOpened">
                    <div class="close-button" (click)="closeAttendanceTypeFilter()">
                      <span class="icon icon-Icon ionic-ios-close"></span>
                    </div>

                    <ul class="modal-box__attendance-types">
                      <li (click)="toggleAllAttendanceTypes()">Selecionar todos</li>
                      <ng-container *ngFor="let attendanceType of attendanceTypes">
                        <li *ngIf="attendanceTypeIsAvailable(attendanceType)"
                          (click)="toggleAttendanceType(attendanceType)">
                          <input type="checkbox" [checked]="attendanceTypesIsSelected(attendanceType)">
                          {{attendanceType.name}}
                        </li>
                      </ng-container>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="calendar-header">
          <div class="row">
            <div class="cell cell--title time">&nbsp;</div>
            <div class="cell cell--title" *ngFor="let date of week" [class.active]="isCurrentDate(date)"
              [class.d-none]="!weekly && !isCurrentDate(date)" [class.cell--daily]="!weekly && isCurrentDate(date)"
              (click)="setDateAndDaily(date)">
              <p class="text-center weekday">{{ getWeekday(date) }}</p>
              <p class="text-center">{{ formatDate(date) }}</p>
            </div>
          </div>
        </div>

        <ng-container *ngIf="!scheduleIsEmpty else emptyScheduleTemplate">
          <div class="calendar-body" *ngIf="!loadingWeek else loadingTemplate">
            <div class="row" *ngFor="let hour of timeline; let index = index">
              <div class="cell cell--time">
                <span *ngIf="!scheduleIsReminder">{{ hour }}</span>
              </div>
              <div class="cell" *ngFor="let day of cells[index]" [class.d-none]="!weekly && !isCurrentDate(day)"
                [class.cell--daily]="!weekly && isCurrentDate(day)">
                <event-card (onSelect)="onSelect($event)" (onScheduleUpdated)="reloadWeek($event)" [selectedToAction]="selectedToAction" [isWeekly]="weekly"
                  [date]="day" [source]="_weekResponse" [scheduleId]="currentSchedule?.id"
                  (onContextMenu)="onContextMenu($event)" (onContextMenuInclude)="onContextMenuInclude($event)"
                  (onContextMenuIncludeReminder)="onContextMenuIncludeReminder($event)"
                  (onContextMenuReminder)="onContextMenuReminder($event)"
                  [attendanceTypes]="selectedAttendanceTypes" [isReminder]="scheduleIsReminder">
                </event-card>
              </div>
            </div>
          </div>
        </ng-container>

        <app-context-menu (toggleStatus)="toggleSchedulingStatus($event)" (cut)="cutAttendance($event)"
          (copy)="copyAttendance($event)" (paste)="pasteAttendance($event)"
          (toggleReminderStatus)="toggleReminderStatus($event)" (includeReminder)="onReminderIncluded($event)"
          (includeScheduling)="onSchedulingIncluded($event)" (create)="createReminder($event)">
        </app-context-menu>

      </div>
    </div>
  </div>
</div>

<div class="col px-0 d-md-none">
  <div class="content-card">
    <div class="calendar calendar-mobile" *ngIf="currentSchedule || scheduleIsReminder else scheduleNotSelectedTemplate">
      <div class="calendar-toolbar" [formGroup]="formGroup">
        <div class="row">
          <div class="col-12 pr-0 d-flex justify-content-between">
            <div class="wrapper">
              <button class="btn btn-link btn-datepicker mr-4" (click)="openDatePicker()">
                <span class="icon icon-Icon-ionic-ios-calendar"></span>
              </button>
            </div>

            <h4 class="current-month">{{ currentMonth }}</h4>

            <button class="btn btn-outline-primary btn-today" (click)="goToCurrentClickHandler()">
              Hoje
            </button>

          </div>

          <div class="col-12 align-self-center">
            <div class="row">
              <div class="col d-flex modal-box-support justify-content-center" *ngIf="attendanceTypes">
                <button class="btn btn-outline-primary btn-attendance-types" *ngIf="!scheduleIsEmpty"
                  (click)="openAttendanceTypeFilter()">Filtrar tipos de atendimento</button>

                <div class="modal-box-wrapper" *ngIf="attendanceTypeFilterIsOpened"
                  (click)="closeAttendanceTypeFilter()"></div>
                <div class="modal-box" *ngIf="attendanceTypeFilterIsOpened">
                  <div class="close-button" (click)="closeAttendanceTypeFilter()">
                    <span class="icon icon-Icon ionic-ios-close"></span>
                  </div>

                  <ul class="modal-box__attendance-types">
                    <li (click)="toggleAllAttendanceTypes()">Selecionar todos</li>
                    <ng-container *ngFor="let attendanceType of attendanceTypes">
                      <li *ngIf="attendanceTypeIsAvailable(attendanceType)"
                        (click)="toggleAttendanceType(attendanceType)">
                        <input type="checkbox" [checked]="attendanceTypesIsSelected(attendanceType)">
                        {{attendanceType.name}}
                      </li>
                    </ng-container>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="calendar-header">
        <div class="row">
          <div class="col-12 d-flex justify-content-center" *ngIf="!loadingWeek">
            <button class="btn btn-link icon-previous" (click)="previous()">
              <span class="icon icon-Icon-ionic-ios-arrow-down"></span>
            </button>

            <div class="cell cell--title" *ngFor="let date of week" [class.active]="isCurrentDate(date)"
              [class.d-none]="!weekly && !isCurrentDate(date)" [class.cell--daily]="true">
              <p class="text-center weekday">{{ getWeekday(date) }}</p>
              <p class="text-center">{{ formatDate(date) }}</p>
            </div>

            <button class="btn btn-link icon-next" (click)="next()">
              <span class="icon icon-Icon-ionic-ios-arrow-down"></span>
            </button>
          </div>

        </div>
      </div>

      <ng-container *ngIf="!scheduleIsEmpty else emptyScheduleTemplate">
        <div class="calendar-body" *ngIf="!loadingWeek else loadingTemplate">
          <div class="row" *ngFor="let hour of timeline; let index = index">
            <div class="cell cell--time">
              <span *ngIf="!scheduleIsReminder">{{ hour }}</span>
            </div>
            <div class="cell" *ngFor="let day of cells[index]" [class.d-none]="!weekly && !isCurrentDate(day)"
              [class.cell--daily]="!weekly && isCurrentDate(day)">
              <event-card (onSelect)="onSelect($event)" (onScheduleUpdated)="reloadWeek($event)" [selectedToAction]="selectedToAction" [isWeekly]="weekly"
              [date]="day" [source]="_weekResponse" [scheduleId]="currentSchedule?.id"
              (onContextMenu)="onContextMenu($event)" (onContextMenuInclude)="onContextMenuInclude($event)"
              [attendanceTypes]="selectedAttendanceTypes" [isReminder]="scheduleIsReminder">
              </event-card>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="full-loading text-center mt-4">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>

<ng-template #emptyScheduleTemplate>
  <div class="text-center list-message">
    <p>Esta semana está indisponível nesta agenda</p>
  </div>
</ng-template>

<ng-template #scheduleNotSelectedTemplate>
  <div class="text-center list-message">
    <p>Nenhuma agenda selecionada</p>
  </div>
</ng-template>

<ng-template #content let-modal class="modal modal-content">
  <div class="modal-body" [formGroup]="formGroup">
    <h4>Tipos de atendimento</h4>
    <div class="checkbox-option">
      <label>
        <input type="checkbox" [checked]="isCheckedAll" (change)="onSelectAll($event)" />
        Selecionar Todos
      </label>
    </div>

    <div class="row" *ngFor="let type of attendanceCheckedList">
      <div class="checkbox-option">
        <label [for]="type.id">
          <input type="checkbox" [value]="type.id" [id]="type.id" [checked]="type.checked"
            (change)="onCheckChange(type)" />
          {{ type.name }}
        </label>
      </div>
    </div>

  </div>
  <div class="row justify-content-end buttons">
    <button class="btn btn-link col-4 " (click)="modal.dismiss()">Cancelar</button>
    <button class="btn btn-primary col-4" (click)="updateAllowedAttendanceType()">Salvar</button>
  </div>
</ng-template>

<div class="modal-box-wrapper-foo" *ngIf="datePickerIsOpened()" (click)="closeDatePicker()"></div>
<div class="modal-box-foo" *ngIf="datePickerIsOpened()" [formGroup]="formGroup">
  <div class="close-button" (click)="closeDatePicker()">
    <span class="icon icon-Icon-ionic-ios-close"></span>
  </div>
  <dp-day-calendar [config]="datePickerConfig" theme="custom-theme" formControlName="currentDate" class="mb-5"
    (onSelect)="dateChangeClickHandler($event)">
  </dp-day-calendar>
</div>
