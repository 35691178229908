import { Component, OnInit, Renderer2 } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { RegistersService } from 'src/app/core/services/registers.service';
import * as moment from 'moment';
import { FinancialStatusModel } from 'src/app/core/models/financial-status.model';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { AccountModel } from 'src/app/core/models/account.model';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AttachmentModalComponent } from 'src/app/shared/components/attachment-modal/attachment-modal.component';
import { documentsStringFormatter } from 'src/app/core/utils/string-formatters';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { TicketModel } from 'src/app/core/models/ticket.model';
import { ProtocolModelComponent } from 'src/app/shared/components/protocol-model/protocol-model.component';
import {TicketProcedureModel} from "../../../../core/models/ticket-procedure.model";
import {HealthInsuranceModel} from "../../../../core/models/health-insurance.model";
import {OriginModel} from "../../../../core/models/origin.model";
import {StatusStageModel} from "../../../../core/models/status-stage.model";

@Component({
  selector: 'app-financial-table',
  templateUrl: './financial-table.component.html',
  styleUrls: ['./financial-table.component.scss']
})
export class FinancialTableComponent implements OnInit {
  detailHeader = [
    { title: 'PROCEDIMENTO', tooltip: 'PROCEDIMENTO', width: 'col-3' },
    { title: 'STATUS', tooltip: 'STATUS', width: 'col-1' },
    { title: 'PROTOCOLO', tooltip: 'PROTOCOLO', width: 'col-1' },
    { title: 'DATA PEDIDO', tooltip: 'DATA DO PEDIDO', width: 'col-1' },
    { title: 'VALOR NF', tooltip: 'VALOR DA NOTA FISCAL', width: 'col-1' },
    { title: 'V. REEMBOLSO', tooltip: 'VALOR DO REEMBOLSO', width: 'col-1' },
    { title: 'DT. REEMBOLSO', tooltip: 'DATA DO PEDIDO DE REEMBOLSO', width: 'col-1' },
    { title: 'VALOR COP.', tooltip: 'VALOR DA COPARTICIPAÇÃO', width: 'col-1' },
    { title: 'DATA RECEB.', tooltip: 'DATA DO RECEBIMENTO', width: 'col-1' },
    { title: 'V. RECEBIDO', tooltip: 'VALOR DO RECEBIMENTO', width: 'col-1' },
    { title: 'ANEXOS', tooltip: 'ANEXO', width: '' }
  ];

  historicHeader = [
    { title: 'DATA E HORA', width: 'col-2' },
    { title: 'USUÁRIO', width: 'col-2' },
    { title: 'ANEXOS', width: '' },
    { title: 'OBSERVAÇÕES', width: 'col-12' },
  ];

  active = true;

  searchPatient: string = '';
  searchStatus: string = '';
  searchUser: string = '';
  searchDateSince: string = '';
  searchDateUntil: string = '';

  stageOrder: number[] = [];

  stageList: StatusStageModel[] = [];
  statusList: FinancialStatusModel[] = [];
  usersList: AccountModel[] = [];

  tickets: TicketModel[] = [];
  filteredTickets: TicketModel[] = [];
  currentTicket?: TicketModel;

  hasNext = {
    next: false,
    page: 1,
    loading: false
  };

  loading = false;

  moreFilters = false;

  searchFormGroup = this.fb.group({
    status: [[]],
    user: [[]],
    since: [],
    until: [],
    patient__name: [],
    patient__document_number: [],
    health_insurances: [[]],
    origin: [[]]
  });

  subscription: Subscription | null = null;
  ordering: string = '-date_time';

  healthInsurances: HealthInsuranceModel[] = [];
  origins: OriginModel[] = [];

  loadingOrigins = false;
  loadingStages = false;
  loadingStatus = false;
  loadingUsers = false;
  loadingHealthInsurances = false;
  loadingHealthInsurancePlans = false;
  loadingTickets = false;

  get isLoading() {
    return this.loadingStages || this.loadingStatus || this.loadingUsers || this.loadingHealthInsurances &&
      this.loadingHealthInsurancePlans || this.loadingTickets;
  }

  constructor(private registerService: RegistersService, private fb: FormBuilder, private accountsService: AccountsService, private routerService: Router,
    private modalService: NgbModal, private renderer: Renderer2, private titleService: Title, private activatedRoute: ActivatedRoute,) {
    this.titleService.setTitle('ELEVE - Financeiro');
  }

  ngOnInit(): void {
    this.fetchStatusStages();
    this.fetchStatusList();
    this.fetchUsersList();
    this.fetchTicketList();
    this.fetchHealthInsurances();
    this.fetchPatientOrigins();
  }

  filterTicketListAndOrder() {
    this.saveFilters();
    this.filteredTickets = this.tickets.filter(ticket => {
      let statuses = this.searchFormGroup.get('status')?.value;
      let hasDetailStatus = statuses.some((id: number) => ticket.detail_status?.includes(id));
      if (statuses.length > 0 && !hasDetailStatus) {
        return false;
      }

      let users = this.searchFormGroup.get('user')?.value;
      if (users.length > 0 && !users.includes(ticket.user?.id)) {
        return false;
      }

      let since = this.searchFormGroup.get('since')?.value;
      if (since && ticket.date_time! < since) {
        return false;
      }

      let until = this.searchFormGroup.get('until')?.value;
      if (until && ticket.date_time! > until) {
        return false;
      }

      let patientName = this.searchFormGroup.get('patient__name')?.value;
      if (patientName && !ticket.patient?.name?.toLowerCase().includes(patientName.toLowerCase())) {
        return false;
      }

      let patientDocumentNumber = this.searchFormGroup.get('patient__document_number')?.value;
      if (patientDocumentNumber && !ticket.patient?.document_number?.includes(patientDocumentNumber)) {
        return false;
      }

      let healthInsurances = this.searchFormGroup.get('health_insurances')?.value;
      if (healthInsurances.length > 0 && !healthInsurances.includes(ticket.health_insurance_plan?.health_insurance?.id)) {
        return false;
      }

      let origin = this.searchFormGroup.get('origin')?.value;
      if (origin.length > 0 && !origin.includes(ticket.patient?.ad_origin) && !origin.includes(ticket.patient?.clinic?.display_name)) {
        return false;
      }

      return true;
    });

    this.filteredTickets = this.filteredTickets.sort((a: TicketModel, b: TicketModel) => {
      let reverse: number = this.ordering.includes('-') ? -1 : 1;
      let orderBy = this.ordering.replace('-', '');

      type ObjectKey = keyof typeof a;
      const myVar = orderBy as ObjectKey;

      if (a[myVar]! > b[myVar]!) {
        return reverse;
      } else {
        return -1 * reverse;
      }
    });
  }

  fetchStatusStages() {
    this.loadingStages = true;

    this.registerService.statusStageAll().subscribe(response => {
      this.stageList = response;
      this.loadingStages = false;
    });
  }

  fetchTicketList(active: boolean = true) {
    this.active = active;
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
    this.loadFilters();
    this.loadingTickets = true;
    this.subscription = this.registerService.listAllFinancialTickets(active).pipe(
      map(value => {
        value.map(item => {
          item.origin = item.patient?.ad_origin || item.patient?.clinic?.display_name
          return item;
        });
        return value;
      })
    ).subscribe(response => {
      this.tickets = response;
      this.filteredTickets = response;
      this.hasNext.next = false;
      this.loadingTickets = false;
      this.loadFilters();
    });
  }

  get isAscending() {
    return !this.ordering.includes('-');
  }

  orderedBy(field: string) {
    return this.ordering.includes(field);
  }

  orderBy(field: string) {
    if (this.ordering == field) {
      this.ordering = `-${field}`;
    } else {
      this.ordering = field;
    }
    this.filterTicketListAndOrder();
  }

  loadMore() {
    this.hasNext.loading = true;
    this.hasNext.page++;
    this.registerService.financialTicketGet(this.searchPatient, this.searchStatus, this.searchUser, this.searchDateSince, this.searchDateUntil, this.hasNext.page).subscribe(response => {
      this.hasNext.next = false;
      response.forEach(result => {
        this.tickets.push(result);
      });
      this.hasNext.loading = false;
    })
  }

  fetchPatientOrigins() {
    this.loadingOrigins = true;
    this.registerService.getAllPatientOrigins().subscribe(response => {
      this.origins = response;
      this.loadingOrigins = false;
      this.loadFilters();
    })
  }

  fetchHealthInsurances() {
    this.loadingHealthInsurances = true;
    this.registerService.healthInsuranceGetAll().subscribe(response => {
      this.healthInsurances = response;
      this.loadingHealthInsurances = false;
      this.loadFilters();
    });
  }

  fetchStatusList() {
    this.loadingStatus = true;
    this.registerService.financialStatusList().subscribe(response => {
      this.statusList = response.results!;
      this.loadingStatus = false;
      this.loadFilters();
    });
  }

  fetchUsersList() {
    this.loadingUsers = true;
    this.accountsService.accountGetAll().subscribe(response => {
      this.usersList = response;
      this.loadingUsers = false;
      this.loadFilters();
    });
  }

  getBackgroundColor(ticket: TicketModel, clicked: boolean = false) {
    let status = this.statusList.find(status => status.id == ticket.late_status_id);
    if (!status) {
      status = this.statusList.find(status => status.id == ticket.status?.id);
      if (!status) {
        return '#FFFFFF';
      }
    }
    let date = moment();
    let desired = moment(ticket.updated_at).add(status.desired_time, 'days');
    let deadline = moment(ticket.updated_at).add(status.deadline, 'days');
    if (clicked) {
      return '#000000';
    } else {
      if (date >= deadline) {
        return status.deadline_background_color;
      } else if (date >= desired) {
        return status.desired_time_background_color;
      } else {
        return this.getStatusBackgroundColor(status.id, clicked);
      }
    }
  }

  getFontColor(ticket: TicketModel, clicked: boolean = false) {
    let status = this.statusList.find(status => status.id == ticket.late_status_id);
    if (!status) {
      status = this.statusList.find(status => status.id == ticket.status?.id);
      if (!status) {
        return '#5F5F5F';
      }
    }
    let date = moment();
    let desired = moment(ticket.updated_at).add(status.desired_time, 'days');
    let deadline = moment(ticket.updated_at).add(status.deadline, 'days');
    if (clicked) {
      return '#FFFFFF'
    } else {
      if (date >= deadline) {
        return status.deadline_font_color;
      } else if (date >= desired) {
        return status.desired_time_font_color;
      } else {
        return this.getStatusFontColor(status.id, clicked);
      }
    }
  }

  getStatusBackgroundColor(status_id: any, clicked: boolean = false) {
    let status = this.statusList.find(status => status.id == status_id);
    if (!status)
      return '#ffffff';
    let date = moment().format('YYYY-MM-DD');
    let desired = moment(status.updated_at).add(status.desired_time, 'days').format('YYYY-MM-DD');
    let deadline = moment(status.updated_at).add(status.deadline, 'days').format('YYYY-MM-DD');
    if (clicked) {
      return '#000000'
    } else {
      if (date >= deadline) {
        return status.deadline_background_color;
      } else if (date >= desired) {
        return status.desired_time_background_color;
      } else {
        return status.background_color;
      }
    }
  }

  getStatusFontColor(status_id: any, clicked: boolean = false) {
    let status = this.statusList.find(status => status.id == status_id);
    if (!status)
      return '#5f5f5f';
    let date = moment().format('YYYY-MM-DD');
    let desired = moment(status.updated_at).add(status.desired_time, 'days').format('YYYY-MM-DD');
    let deadline = moment(status.updated_at).add(status.deadline, 'days').format('YYYY-MM-DD');
    if (clicked) {
      return '#FFFFFF'
    } else {
      if (date >= deadline) {
        return status.deadline_font_color;
      } else if (date >= desired) {
        return status.desired_time_font_color;
      } else {
        return status.font_color;
      }
    }
  }

  getStatuses(ticket: TicketModel) {
    let distinctStatusIds = ticket.detail_status?.filter((value, index, array) => array.findIndex(el => el == value) == index) || [];
    let statuses = distinctStatusIds.map(id => this.statusList.find(el => el.id == id));
    statuses = statuses.sort((firstStatus, secondStatus) => {
      if (!firstStatus || !secondStatus) {
        return 1;
      }
      let firstStage = this.stageList.find(_stage => _stage.id == firstStatus.stage_id);
      let secondStage = this.stageList.find(_stage => _stage.id == secondStatus.stage_id);
      if (!firstStage || !secondStage) {
        return 1;
      }
      if (firstStage.order! > secondStage.order!) {
        return 1;
      } else {
        return -1;
      }
    });
    return statuses.map(status => status?.name).join(' | ');
  }

  getDocumentNumber(document_number: string) {
    return documentsStringFormatter(document_number);
  }

  dateHandle(date: string, isDateTime: boolean) {
    if (date == undefined) return '';
    if (isDateTime) return moment(date).format('DD/MM/YYYY HH:mm');
    else return moment(date).format('DD/MM/YYYY');
  }

  saveFilters() {
    let filters = this.searchFormGroup.getRawValue();
    localStorage.setItem('financialFilters', JSON.stringify(filters));
  }

  loadFilters() {
    if (this.statusList.length == 0 || this.tickets.length == 0 || this.usersList.length == 0) {
      return;
    }
    let filters: {[key: string]: string} = {};
    let stringifiedFilters = localStorage.getItem('financialFilters');

    if (stringifiedFilters) {
      filters = JSON.parse(stringifiedFilters);
    }

    this.searchFormGroup.patchValue(filters);
    this.filterTicketListAndOrder();
  }

  recommendationHandle(ticket: TicketModel) {
    if (ticket.patient?.clinic?.display_name) return `${ticket.patient?.clinic?.display_name}`;
    else if (ticket.patient?.ad_origin) return ticket.patient?.ad_origin;
    else return '';
  }

  editHandle(id: number) {
    this.routerService.navigate([`/dashboard/financial/edit/${id}`], {
      queryParams: {
        dateStart: this.searchDateSince,
        dateEnd: this.searchDateUntil,
      }
    });
  }

  showDetail(ticket: TicketModel) {
    return ticket.clicked == true && this.currentTicket && this.currentTicket.id == ticket.id;
  }

  ticketClickHandle(ticket: TicketModel) {
    if (!ticket.clicked) {
      this.tickets.forEach(ticket => ticket.clicked = false);
      ticket.clicked = true;
      this.registerService.financialTicketGetById(ticket.id!).subscribe(response => {
        this.currentTicket = response;
      });
    } else {
      this.tickets.forEach(ticket => ticket.clicked = false);
    }
  }

  openProtocolModal(addNewProtocol: boolean, name: string, id: any, items: any) {
    const modalRef = this.modalService.open(ProtocolModelComponent, { centered: true, size: 'lg', animation: true});
    modalRef.componentInstance.modalItems = { id: id, name: name, items: items};
    modalRef.componentInstance.addNewProtocol = addNewProtocol;
    modalRef.result.then(result => {
      return;
    });
  }

  showAttachmentIcon(detail: TicketProcedureModel) {
    let hasAttachments = false;
    let hasMedicalOrders = false;
    let hasMedicalReports = false;

    if (detail.attachment_list)
      hasAttachments = detail.attachment_list.length > 0;
    if (detail.medical_orders)
      hasMedicalOrders = detail.medical_orders.length > 0;
    if (detail.medical_reports)
      hasMedicalReports = detail.medical_reports.length > 0;

    return hasAttachments || hasMedicalOrders || hasMedicalReports;
  }

  openAttachmentModal(addNewAttachment: boolean, type: String, name: String, id: any, items: any) {
    const modalRef = this.modalService.open(AttachmentModalComponent, { centered: true, size: 'lg', animation: true });
    modalRef.componentInstance.modalItems = { id: id, name: name, items: items };
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.addNewAttachment = addNewAttachment;
    modalRef.result.then(result => {});
  }

  openProcedureAttachmentModal(ticketProcedure: TicketProcedureModel, name: string, id: any, items: any) {
    let medical_reports = ticketProcedure.medical_reports;
    let medical_orders = ticketProcedure.medical_orders;
    const modalRef = this.modalService.open(AttachmentModalComponent, {centered: true, size: 'lg', animation: true});
    modalRef.componentInstance.modalItems = {id, name, items, medical_reports, medical_orders};
    modalRef.componentInstance.type = 'Procedimentos';
    modalRef.componentInstance.addNewAttachment = false;
    modalRef.result.then(result => {});
  }

  exportToCSV() {
    let ticketIds = this.filteredTickets.map(e => e.id).join(',');
    this.registerService.exportFinancialToCSV(ticketIds).subscribe(response => {
      let file = new Blob([response.toString()], {type: 'text/csv;charset=utf-8'});
      let element = document.createElement('a');
      element.target = '_blank';
      element.href = URL.createObjectURL(file);
      element.download = `financeiro.csv`;
      element.click();
    });
  }
}
