import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit {

  @Input() id!: string;
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() value!: string;

  @Output() onFileChange = new EventEmitter<File>();

  imageUrl!: string;
  isDocument: boolean = true;

  constructor() { }

  ngOnInit(): void {
    if (this.value) {
      const image: any = document.getElementById('preview') as HTMLImageElement;
      image.src = this.value;
    }

    document.getElementById("uploader")!.onchange = this.previewImage;
  }

  previewImage = (event: Event) => {
    const file = (<HTMLInputElement>event.target).files![0];
    const image: any = document.getElementById('preview') as HTMLImageElement;
    this.imageUrl = URL.createObjectURL(file);

    if (file.name.match(/.(jpg|jpeg|png|svg)$/i)) {
      this.isDocument = false;
      image.src = this.imageUrl;
    }
    else {
      this.isDocument = true;
      image.src = "";
    }

    this.onFileChange.emit(file);
  }

  reset() {
    const image: any = document.getElementById('preview') as HTMLImageElement;
    image.src = "";
    this.imageUrl = ""
    this.isDocument = true;
  }

}
