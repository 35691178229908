import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { WelcomeMessageModel } from 'src/app/core/models/welcome-message.model';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';

import * as moment from 'moment';

@Component({
  selector: 'app-welcome-message-form',
  templateUrl: './welcome-message-form.component.html',
  styleUrls: ['./welcome-message-form.component.scss']
})
export class WelcomeMessageFormComponent implements OnInit {

  formGroup = this.fb.group({
    flow: [],
    limit_datetime: [],
    date: [],
    time: []
  });

  id!: number;
  loadingPage: boolean = false;
  welcomeMessage!: WelcomeMessageModel;

  isRegister: boolean = true;

  submitting: boolean = false;
  removeButton?: String = "Desativar";
  submitButton?: String = "Salvar";

  button: boolean = false;
  registerSuccess: boolean = false;

  constructor(private registersService: RegistersService, private router: Router, private fb: FormBuilder,
    private activatedRoute: ActivatedRoute, config: NgbModalConfig, private modalService: NgbModal,
    private sessionManager: SessionManagerService, private toast: ToastrService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.registersService.welcomeMessageGet().subscribe(response => {
      this.welcomeMessage = response;
      this.formGroup.patchValue(this.welcomeMessage);
      this.formGroup.get('date')?.setValue(moment(this.welcomeMessage.limit_datetime).format('YYYY-MM-DD'));
      this.formGroup.get('time')?.setValue(moment(this.welcomeMessage.limit_datetime).format('HH:mm'));
      this.formGroup.get('flow')?.disable();
    })
  }

  get canSave() {
    if (this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_chatbottrigger);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_chatbottrigger);
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_chatbottrigger);
  }

  cancelHandler() {
    this.router.navigate(['/dashboard/registers/welcomeMessage'])
  }

  submitHandler() {
    this.submitting = true;
    let welcomeMessageData = this.formGroup.getRawValue();
    this.welcomeMessageEdit(welcomeMessageData);
  }

  removeHandler() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar esta mensagem?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.submitting = true;
        // this.welcomeMessageRemove();
      }
    })
  }

  welcomeMessageEdit(welcomeMessageData: WelcomeMessageModel) {
    let date = this.formGroup.get('date')?.value;
    let time = this.formGroup.get('time')?.value;
    welcomeMessageData.limit_datetime = `${date}T${time}-03:00`;

    this.registersService.welcomeMessageEdit(welcomeMessageData).subscribe(response => {
      this.registerSuccess = true;
      this.submitting = false;
      this.toast.success("ID do fluxo alterado com sucesso.", "Sucesso");
      this.router.navigate(['/dashboard/registers/welcomeMessage']);
    }, error => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    } else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "flow", "limit_datetime", "date", "time"
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
    });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if ((this.formGroup.dirty || this.submitting) && !this.registerSuccess && this.isRegister) {
      const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
      modalRef.componentInstance.text = "As alterações no formulário não foram salvas e serão descartadas, deseja prosseguir?";
      return modalRef.result
    } else
      return true;
  };

}
