import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClinicModel } from '../models/clinic.model';
import { ListModel } from '../models/list.model';
import { ProfessionalModel } from '../models/professional.model';

@Injectable({
  providedIn: 'root'
})
export class ClinicsService {
  constructor(private httpClient: HttpClient) { }

  clinicRegister(clinicData: ClinicModel): Observable<ClinicModel> {
    return this.httpClient.post<ClinicModel>(`${environment.API_URL}/clinics/`, JSON.stringify(clinicData));
  }

  clinicEdit(clinicData: ClinicModel): Observable<ClinicModel> {
    return this.httpClient.put<ClinicModel>(`${environment.API_URL}/clinics/${clinicData.id}/`, JSON.stringify(clinicData));
  }

  clinicRemove(clinicId: number): Observable<ClinicModel> {
    return this.httpClient.delete<ClinicModel>(`${environment.API_URL}/clinics/${clinicId}/`);
  }

  clinicsList(searchString: string = "", orderingParam: string = "", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/clinics/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  clinicGet(clinicId: number): Observable<ClinicModel> {
    return this.httpClient.get<ClinicModel>(`${environment.API_URL}/clinics/${clinicId}/`);
  }

  professionalRegister(professionalData: ProfessionalModel): Observable<ProfessionalModel> {
    return this.httpClient.post<ProfessionalModel>(`${environment.API_URL}/professionals/`, JSON.stringify(professionalData));
  }

  professionalEdit(professionalData: ProfessionalModel): Observable<ProfessionalModel> {
    return this.httpClient.patch<ProfessionalModel>(`${environment.API_URL}/professionals/${professionalData.id}/`, JSON.stringify(professionalData));
  }

  professionalRemove(clinicId: number): Observable<ProfessionalModel> {
    return this.httpClient.delete<ProfessionalModel>(`${environment.API_URL}/professionals/${clinicId}/`);
  }

  professionalsList(searchString: string = "", orderingParam: string = "", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/professionals/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}&page=${page}`);
  }

  professionalGet(professionlId: number): Observable<ProfessionalModel> {
    return this.httpClient.get<ProfessionalModel>(`${environment.API_URL}/professionals/${professionlId}/`);
  }

  uploadProfessionalImage(imageFile: File | undefined, professionalId: number): Observable<object> {
    const formData = new FormData();
    formData.append("profile_image", imageFile ? imageFile : '');

    return this.httpClient.patch<object>(`${environment.API_URL}/professionals/${professionalId}/profile_image/`, formData);
  }

  uploadClinicImage(imageFile: File | undefined, clinicId: number): Observable<object> {
    const formData = new FormData();
    formData.append("profile_image", imageFile ? imageFile : '');

    return this.httpClient.patch<object>(`${environment.API_URL}/clinics/${clinicId}/profile_image/`, formData);
  }

  listAll(): Observable<ClinicModel[]> {
    return this.httpClient.get<ClinicModel[]>(`${environment.API_URL}/clinics/all/?ordering=name`);
  }

  clinicsListAllActive(): Observable<ClinicModel[]> {
    return this.httpClient.get<ClinicModel[]>(`${environment.API_URL}/clinics/all/?&ordering=name&is_active=true`);
  }
}
