<div class="include-modal-wrapper">
  <div class="include-modal" *ngIf="attendanceTypesList else loadingTemplate">
    <div class="include-modal__content">
      <table class="incluirNovo" [formGroup]="formGroup">
        <tr>
          <td>
            Data:
          </td>
          <td>
            <input class="date" type="date" formControlName="date">
            <app-form-error id="date" [formGroup]="formGroup" controlName="date"></app-form-error>
          </td>
        </tr>
        <tr>
          <td>
            Horário:
          </td>
          <td class="d-flex align-items-center">
            <input type="text" mask="00" formControlName="hour" placeholder="00"> &nbsp; :
            &nbsp;<input type="text" mask="00" formControlName="minutes" placeholder="00">
          </td>
        </tr>
        <tr *ngIf="formGroup.get('hour')?.errors || formGroup.get('minutes')?.errors">
          <td></td>
          <td>
            <app-form-error id="hour" [formGroup]="formGroup" controlName="hour"></app-form-error>
            <app-form-error id="minutes" [formGroup]="formGroup" controlName="minutes" *ngIf="!formGroup.get('hour')?.errors"></app-form-error>
          </td>
        </tr>
        <tr>
          <td>
            Duração (minutos):
          </td>
          <td>
            <input type="text" mask="00" formControlName="duration" placeholder="10">
            <app-form-error id="duration" [formGroup]="formGroup" controlName="duration"></app-form-error>
          </td>
        </tr>
        
      </table>
      <div [formGroup]="formGroup" class="incluirNovo">
        <label class="title">Tipo de atendimento</label>
        <ng-select id="allowed_attendance_types" formControlName="allowed_attendance_types"
          [items]="attendanceTypesList" bindLabel="name" bindValue="id" [hideSelected]="true" [multiple]="true"
          class="custom" [clearable]="true" addTagText="Adicionar novo" notFoundText="Não encontrado">

          <ng-template ng-header-tmp>
            <span class="btn btn-link" (click)="onSelectAll()">Selecionar todos</span>
          </ng-template>

          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-label">{{item.name}}</span>
            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="false">×</span>
          </ng-template>
        </ng-select>
        <app-form-error id="allowed_attendance_types" [formGroup]="formGroup" controlName="allowed_attendance_types"></app-form-error>
      </div>

      <div class="error" *ngIf="showError">
        <label class="__label">{{error.detail}}</label>
      </div>
      
      <div class="row justify-content-end">
        <button class="btn btn-secondary col-3 " (click)="activeModal.dismiss()">Cancelar</button>
        <button class="btn btn-primary col-4" (click)="onceSchedule($event)">Adicionar horário</button>
      </div>
    </div>
  </div>
  <ng-template #loadingTemplate>
    <div class="col card full-loading text-center">
      <div class="spinner-border spinner-border-xl" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-template>
</div>
