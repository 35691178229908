<form [formGroup]="formPasswordRecover">
  <fieldset>
    <div class="row card">
      <div class="col">
        <label for="old_password">Senha Antiga</label>
        <input type="password" formControlName="old_password" id="old_password">
        <label for="password">Nova senha</label>
        <input type="password" formControlName="password" id="password">
        <label for="password_confirm">Cofirmar nova senha</label>
        <input type="password" formControlName="password_confirm" id="password_confirm">
      </div>
    </div>
    <div class="row p-0 d-flex justify-content-end">
      <div class="col-12 col-md-4 mt-4">
        <button class="btn btn-link" type="button" (click)="cancelHandler()">
          Cancelar
        </button>
      </div>
      <div class="col-12 col-md-4 mt-4">
        <button class="btn btn-primary" type="submit" (click)="submitHandler()">
          Enviar
          <span *ngIf="submitting" class="spinner-border" role="status" aria-hidden="true"></span>
        </button>
      </div>
    </div>
  </fieldset>
</form>
