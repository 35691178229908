import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExamsTemplateListComponent } from './exams-template-list/exams-template-list.component';
import { ExamsTemplateFormComponent } from './exams-template-form/exams-template-form.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    ExamsTemplateListComponent,
    ExamsTemplateFormComponent
  ],
  imports: [
    CommonModule,
    RenderersModule,
    SharedModule,
    ReactiveFormsModule
  ]
})
export class ExamsTemplateModule { }
