<div *ngIf="!loadingPage else loadingTemplate" [formGroup]="formGroup">
  <div class="row separator">
    <div class="col-12 card">
      <div class="col px-0">
        <h3 class="mb-4">Leads</h3>
      </div>

      <div class="row px-0">
        <div class="col-6 px-0">
          <phone-number-input id="phone_number" label="Whatsapp" [formGroup]="phone_number"></phone-number-input>
        </div>

        <div class="col-3 px-0 pl-2" *ngIf="!isRegister">
          <basic-input id="created_at" label="Data de criação" [formGroup]="formGroup" readonly="true"></basic-input>
        </div>

        <div class="col-3 px-0 pl-2" *ngIf="!isRegister">
          <basic-input id="updated_at" label="Última atualização" [formGroup]="formGroup" readonly="true"></basic-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" formArrayName="variables" *ngIf="!isRegister">
    <div class="col-12 card">
      <div class="row">
        <div class="px-2 col-12 titlebar">
          <h3>Variáveis</h3>
        </div>

        <ng-container *ngFor="let variable of variablesForms.controls; index as i">
          <div class="row p-0 col-12">
            <div class="col-4 p-0 mb-3">
              <basic-input id="key" label="Chave" [formGroup]="getVariablesForm(i)"></basic-input>
            </div>

            <div class="col-8 p-0 pl-4 mb-3">
              <basic-input id="value" label="Valor" [formGroup]="getVariablesForm(i)"></basic-input>
            </div>

          </div>
        </ng-container>

      </div>

      <div class="row">
        <div class="row">
          <div class="col-12">
            <button class="btn btn-link" [disabled]="formGroup.disabled" (click)="addVariables()">Adicionar
              novo</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator">
    <div class="col card">
      <div class="row">
        <div class="px-2 col-12">
          <h3>Tags</h3>
        </div>
        <div class="px-2 col-12">
          <ng-select id="tags" [items]="patientTagsList" bindLabel="name" bindValue="id" [hideSelected]="true"
            [multiple]="true" class="custom" [clearable]="false" addTagText="Adicionar novo"
            notFoundText="Não encontrado" formControlName="tags">

            <ng-template ng-label-tmp let-item="item" let-clear="clear">
              <span class="ng-value-label">{{item.name}}</span>
              <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="false">×</span>
            </ng-template>

            <ng-template ng-option-tmp let-item="item">
              <span class="ng-value-label">{{item.name}}</span>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
  </div>


  <div class="row justify-content-end mt-5 mb-5">
    <div class="col-12 col-md-3 mb-3">
      <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
        Cancelar
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3" *ngIf="button">
      <button (click)="removeHandler()" [disabled]="submittingRemove" class="btn btn-secondary btn-lg" type="button">
        {{removeButton}}
        <span class="spinner-border" *ngIf="submittingRemove"></span>
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3">
      <button (click)="submitHandler()" [disabled]="submitting" class="btn btn-primary btn-lg" type="button">
        {{submitButton}}
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>

</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
