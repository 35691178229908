import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecialtiesListComponent } from './specialties-list/specialties-list.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { SpecialtiesFormComponent } from './specialties-form/specialties-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    SpecialtiesListComponent,
    SpecialtiesFormComponent
  ],
  imports: [
    CommonModule,
    RenderersModule,
    SharedModule,
    ReactiveFormsModule
  ]
})
export class SpecialtiesModule { }
