import { Injectable } from '@angular/core';

import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { AccountModel } from '../models/account.model';
import { Observable } from 'rxjs';
import { GroupModel } from '../models/group.model';
import { ListModel } from '../models/list.model';
import {PermissionModel} from "../models/permission.model";

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(private httpClient: HttpClient) { }

  login(accountData: AccountModel): Observable<AccountModel> {
    return this.httpClient.post<AccountModel>(`${environment.API_URL}/accounts/login/`, JSON.stringify(accountData));
  }

  accountRegister(accountData: AccountModel): Observable<AccountModel> {
    return this.httpClient.post<AccountModel>(`${environment.API_URL}/accounts/`, JSON.stringify(accountData));
  }

  accountRedefinePassword(accountRedefinePassword: any): Observable<any> {
    return this.httpClient.patch<any>(`${environment.API_URL}/accounts/password/`, JSON.stringify(accountRedefinePassword));
  }

  accountEdit(accountData: AccountModel): Observable<AccountModel> {
    return this.httpClient.put<AccountModel>(`${environment.API_URL}/accounts/${accountData.id}/`, JSON.stringify(accountData));
  }

  accountGet(accountId: number): Observable<AccountModel> {
    return this.httpClient.get<AccountModel>(`${environment.API_URL}/accounts/${accountId}/`);
  }

  accountGetAll(isActive: string = 'true'): Observable<AccountModel[]> {
    return this.httpClient.get<AccountModel[]>(`${environment.API_URL}/accounts/all/?is_active=${isActive}`);
  }

  accountRemove(accountId: number): Observable<AccountModel> {
    return this.httpClient.delete<AccountModel>(`${environment.API_URL}/accounts/${accountId}/`);
  }

  accountList(searchString: string, orderingItem: string, isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/accounts/?search=${searchString}&ordering=${orderingItem}&is_active=${isActive}&page=${page}`);
  }

  permissionsList(): Observable<PermissionModel[]> {
    return this.httpClient.get<PermissionModel[]>(`${environment.API_URL}/permissions/user_permissions/`);
  }

  groupRegister(groupData: GroupModel): Observable<GroupModel> {
    return this.httpClient.post<GroupModel>(`${environment.API_URL}/permissions/groups/`, JSON.stringify(groupData));
  }

  groupEdit(groupData: GroupModel): Observable<GroupModel> {
    return this.httpClient.put<GroupModel>(`${environment.API_URL}/permissions/groups/${groupData.id}/`, JSON.stringify(groupData));
  }

  groupGet(groupId: number): Observable<GroupModel> {
    return this.httpClient.get<GroupModel>(`${environment.API_URL}/permissions/groups/${groupId}/`);
  }

  groupsList(searchString: string, orderingItem: string): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/permissions/groups/?search=${searchString}&ordering=${orderingItem}`);
  }

  uploadImage(imageFile: File | undefined, accountId: number): Observable<object> {
    const formData = new FormData();
    formData.append("profile_image", imageFile ? imageFile : '');

    return this.httpClient.patch<object>(`${environment.API_URL}/accounts/${accountId}/profile_image/`, formData);
  }

  passwordRecovery(accountData: AccountModel) {
    return this.httpClient.patch<object>(`${environment.API_URL}/accounts/reset_password/`, accountData);
  }

  passwordSet(accountData: AccountModel) {
    return this.httpClient.patch<object>(`${environment.API_URL}/accounts/password/`, accountData);
  }
}
