import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { LeadsModel } from 'src/app/core/models/leads.model';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';

import * as moment from 'moment';

@Component({
  selector: 'app-leads-form',
  templateUrl: './leads-form.component.html',
  styleUrls: ['./leads-form.component.scss']
})
export class LeadsFormComponent implements OnInit {

  id!: string;
  loadingPage: boolean = true;

  exitingLeadData!: LeadsModel;

  phone_number = this.fb.group({
    country_code: ['+55'],
    phone_number: ['']
  });

  formGroup = this.fb.group({
    id: [],
    phone_number: [],
    variables: this.fb.array([]),
    mongo_id: [],
    tags: [],
    created_at: [],
    updated_at: []
  });

  isRegister: boolean = true;

  patientTagsList!: any[];

  disabledSalvar: boolean = false;
  disabledRemover: boolean = false;

  submitting: boolean = false;
  submittingRemove: boolean = false;
  removeButton?: String = "Desativar";
  submitButton?: String = "Salvar";

  button: boolean = true;
  registerSuccess: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document, private fb: FormBuilder, private registersService: RegistersService, private router: Router, private activatedRoute: ActivatedRoute, config: NgbModalConfig, private modalService: NgbModal, private sessionManager: SessionManagerService, private toast: ToastrService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      if (this.id) {
        this.isRegister = false;
      } else this.loadingPage = false;
    });
    this.initializeForm();
  }

  initializeForm() {
    if (this.id) this.fetchLeadsExistingData();
    this.patientTags();
    if (!this.sessionManager.getUserPermission(PermissionConst.change_lead) || !this.sessionManager.getUserPermission(PermissionConst.add_lead)) {
      this.disabledSalvar = true;
      this.disabledRemover = true;
      this.formGroup.disable();
    }
    if (this.sessionManager.getUserPermission(PermissionConst.remove_lead) && this.sessionManager.getUserPermission(PermissionConst.add_lead)) {
      this.disabledRemover = false;
    }
    if (!this.sessionManager.getUserPermission(PermissionConst.remove_lead)) {
      this.disabledRemover = true;
    }
  }

  patientTags() {
    this.registersService.listAllPatientTags().subscribe(response => this.patientTagsList = response)
  }

  cancelHandler() {
    this.router.navigate(['/dashboard/registers/leads'])
  }

  submitHandler() {
    this.submitting = true;
    let phone_number = this.phone_number.get('country_code')?.value + this.phone_number.get('phone_number')?.value;
    phone_number = phone_number.replace(/\D/g, "");
    this.formGroup.get('phone_number')?.setValue(phone_number);
    let leadsData = this.formGroup.getRawValue() as LeadsModel;
    leadsData.tags = leadsData.tags ?? [];
    if (this.isRegister) {
      this.leadsRegister(leadsData);
    } else this.leadsEdit(leadsData);
  }

  leadsRegister(leadsData: LeadsModel) {
    leadsData.id = undefined;
    leadsData.variables = undefined;
    this.registersService.leadsRegister(leadsData).subscribe(() => {
      this.isRegister = false;
      this.submitting = false;
      this.router.navigate(['dashboard/registers/leads']);
      this.toast.success('Lead criado com sucesso', 'Sucesso');
    }, (errorResponse: HttpErrorResponse) => {
      this.mapErrorResponse(errorResponse);
      this.submitting = false;
    })
  }

  leadsEdit(leadsData: LeadsModel) {
    leadsData.is_active = true;
    if (this.variablesForms.length > 0) {
      for (let variables of leadsData.variables) {
        this.registersService.leadsVariablesRegister(this.id, variables).subscribe(() => { });
      }
    }
    leadsData.mongo_id = undefined;
    this.registersService.leadsEdit(this.id, leadsData).subscribe(() => {
      this.isRegister = false;
      this.submitting = false;
      this.toast.success('Lead alterado com sucesso', 'Sucesso');
      this.router.navigate(['dashboard/registers/leads']);
    }, (errorResponse: HttpErrorResponse) => {
      this.mapErrorResponse(errorResponse);
      this.submitting = false;
    })
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar este lead?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.registersService.leadsRemove(this.id).subscribe(() => {
          this.router.navigate(['dashboard/registers/leads']);
          this.toast.success('Lead desativado com sucesso', 'Sucesso');
        }, (errorResponse: HttpErrorResponse) => {
          this.mapErrorResponse(errorResponse);
        });
      } else {
        this.loadingPage = false;
      }
      this.submittingRemove = false;
    });
  }

  fetchLeadsExistingData() {
    this.registersService.leadsGet(this.id).subscribe(response => {
      this.exitingLeadData = response;
      this.variablesForms.clear();
      this.exitingLeadData.variables?.forEach(() => this.addVariables());
      this.formGroup.patchValue(this.exitingLeadData);
      this.formGroup.get('mongo_id')?.disable();
      this.formGroup.get('created_at')?.setValue(moment(response.created_at).format('DD/MM/YYYY - HH:mm'));
      this.formGroup.get('updated_at')?.setValue(moment(response.updated_at).format('DD/MM/YYYY - HH:mm'));
      this.formGroup.get('created_at')?.disable();
      this.formGroup.get('updated_at')?.disable();
      if (this.exitingLeadData.is_active) {
        this.removeButton = 'Desativar';
        this.submitButton = 'Salvar';
      } else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
        this.variablesForms.disable();
      }
      this.loadingPage = false;
    });
  }

  get variablesForms() {
    return this.formGroup.get('variables') as FormArray;
  }

  getVariablesForm(index: number) {
    return this.variablesForms.controls[index] as FormGroup;
  }

  addVariables() {
    let variableFormGroup = this.fb.group({
      key: [],
      value: []
    });
    this.variablesForms.push(variableFormGroup);
  }

  registerVariable(at: number) {
    let formGroup = this.variablesForms.controls[at] as FormGroup;
    let variable = {
      key: formGroup.get('key'),
      value: formGroup.get('value')
    };
    this.registersService.leadsVariablesRegister(this.id, variable).subscribe(response => {
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro", {
        closeButton: true,
      });
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    } else {
      this.setFormErrors(errorResponse);
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "name", "phone_number", "mongo_id", "tags",
      "variables"
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
    });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if ((this.formGroup.dirty || this.submitting) && !this.registerSuccess && this.isRegister) {
      const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
      modalRef.componentInstance.text = "As alterações no formulário não foram salvas e serão descartadas, deseja prosseguir?";
      return modalRef.result
    }
    else
      return true;
  };

}
