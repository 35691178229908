import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { OximeterService } from 'src/app/core/services/oximeter.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ListTemplate } from 'src/app/shared/models/list-template';

@Component({
  selector: 'app-exams',
  templateUrl: './exams-list.component.html',
  styleUrls: ['./exams-list.component.scss']
})
export class ExamsListComponent implements OnInit {

  searchString: string = "";
  orderingItem: string = "-attendance__date_time";
  statusString: string = "";

  dateSince: string = "";
  dateUntil: string = "";

  listTemplate: ListTemplate = {
    loading: true,
    showSearch: true,
    searchBarPlaceholder: "Buscar por Paciente",
    showAdvancedSearch: false,
    searchBarStatus: true,
    searchBarDateRange: true,
    newDataRouterLink: undefined,
    notFoundMessage: "Nenhum paciente encontrado",
    header: [
      { name: "attendance__date_time", label: "Data da consulta", widthInPercentage: "13%", width: "150px"},
      { name: "oximeter__serial_number", label: "Oxímetro", widthInPercentage: "10%", width: "120px" },
      { name: "patient__name", label: "Paciente", widthInPercentage: "18%", width: "220px" },
      { name: "patient__phone_number", label: "Telefone", widthInPercentage: "18%", width: "200px" },
      { name: "exam_nights", label: "Noites", widthInPercentage: "7%", width: "100px" },
      { name: "status_exam", label: "Status", widthInPercentage: "13%", width: "140px" },
      { name: "sent_at", label: "Data do envio", widthInPercentage: "11%", width: "100px" },
      { name: "user__name", label: "Responsável", widthInPercentage: "10%", width: "100px" },
    ],
    onHeaderItemClick: (value: string) => {
      let invertString = '';
      if (this.orderingItem == value) {
        this.listTemplate.invertedOrdering = !this.listTemplate.invertedOrdering;
        if (this.listTemplate.invertedOrdering)
          invertString = '-';
      }
      this.orderingItem = value;
      this.listTemplate.selectedOrdering = value;
      if (value == "status_exam") {
        value = "status";
      }
      if (value == "patient__name") {
        value = "attendance__patient__name";
      }
      this.fetchList(this.searchString, `${invertString}${value}`, this.statusString, this.listTemplate.is_active, this.dateSince, this.dateUntil);
    },
    onSearchFocusOut: (value: string) => {
      this.searchString = value;
      this.fetchList(value, this.orderingItem, this.statusString, this.listTemplate.is_active, this.dateSince, this.dateUntil);
    },
    onScheduleFocusOut: (value: string) => {
      this.statusString = value;
      this.fetchList(this.searchString, this.orderingItem, this.statusString, this.listTemplate.is_active, this.dateSince, this.dateUntil);
    },
    onDateRangeFocusOut: (value: any) => {
      this.dateSince = value.start;
      this.dateUntil = value.end;
      this.fetchList(this.searchString, this.orderingItem, this.statusString, this.listTemplate.is_active, this.dateSince, this.dateUntil);
    },
    selectedOrdering: this.orderingItem,
    invertedOrdering: false,
    exportUrl: undefined,
  };

  constructor(private oximeterService: OximeterService, private sessionManager: SessionManagerService,
    private titleService: Title) {
    this.titleService.setTitle('ELEVE - Oxímetros');
  }

  ngOnInit(): void {
    this.initializeList();
  }

  initializeList() {
    this.fetchList(this.searchString, this.orderingItem, this.statusString, this.listTemplate.is_active, this.dateSince, this.dateUntil);
  }

  fetchList(searchString: string, orderingItem: string, status: string, isActive: boolean | undefined, dateSince: string, dateUntil: string) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;

    this.oximeterService.polysomnographyList(searchString, orderingItem, status, isActive, dateSince, dateUntil).subscribe(response => {
      this.listTemplate.loading = false;
      this.listTemplate.data = response;
      this.listTemplate.hasNext = response.next != null;
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = response.error['detail'];
    });
  }

  loadMore(page: number) {
    this.listTemplate.loadingNext = true;
    this.oximeterService.polysomnographyList(this.searchString, this.orderingItem, this.statusString, this.listTemplate.is_active, this.dateSince, this.dateUntil, page).subscribe(
      response => {
        this.listTemplate.hasNext = response.next != null;
        response.results!.map(item => this.listTemplate.data!.results!.push(item));
        this.listTemplate.loadingNext = false;
      },
      error => {
        console.error(error);
        this.listTemplate.loadingNext = false;
      }
    );
  }
}
