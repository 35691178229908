import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { SpecialtyModel } from 'src/app/core/models/specialty.model';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-specialties-form',
  templateUrl: './specialties-form.component.html',
  styleUrls: ['./specialties-form.component.scss']
})
export class SpecialtiesFormComponent implements OnInit {

  formGroup = this.fb.group({
    id: [],
    name: []
  });

  id!: number;
  loadingPage: boolean = false;
  submitting: boolean = false;
  submittingRemove: boolean = false;

  isRegister: boolean = true;
  removeButton: String = "Desativar";
  submitButton: String = "Salvar";

  button: boolean = false;

  fetchedSpecialtyData!: SpecialtyModel;

  constructor(private fb: FormBuilder, private registersService: RegistersService, private activatedRoute: ActivatedRoute, private sessionManager: SessionManagerService, private toast: ToastrService, private router: Router, config: NgbModalConfig, private modalService: NgbModal) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      if(this.id) {
        this.isRegister = false;
        this.fetchSpecialtyExistingData();
      }
    })
  }

  get canSave() {
    if(this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_specialty);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_specialty);
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_specialty);
  }

  cancelHandler() {
    this.router.navigate(['/dashboard/registers/specialties'])
  }

  submitHandler() {
    this.submitting = true;
    this.formGroup.markAllAsTouched();

    let specialtiesData = this.formGroup.getRawValue() as SpecialtyModel;
    if(this.isRegister) {
      if(this.formGroup.valid) {
        this.specialtiesRegister(specialtiesData);
      } else this.submitting = false;
    } else {
      this.specialtiesEdit(specialtiesData);
    }
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar esta área de atuação?";
    modalRef.result.then((result: boolean) => {
      if (result == true) {
        this.specialtiesRemove();
      } else this.submittingRemove = false;
    })
  }

  specialtiesRegister(specialtieData: SpecialtyModel) {
    this.registersService.specialtyRegister(specialtieData).subscribe(response => {
      this.toast.success("Área de atuação criada com sucesso", "Sucesso", {
        closeButton: true,
      });
      this.router.navigate(['/dashboard/registers/specialties']);
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  specialtiesEdit(specialtieData: SpecialtyModel) {
    specialtieData.is_active = true;
    this.registersService.specialtyEdit(specialtieData).subscribe(response => {
      this.toast.success("Área de atuação alterada com sucesso", "Sucesso", {
        closeButton: true,
      });
      this.router.navigate(['/dashboard/registers/specialties']);
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  specialtiesRemove() {
    this.registersService.specialtyRemove(this.id).subscribe(response => {
      this.toast.success("Área de atuação removida com sucesso", "Sucesso", {
        closeButton: true,
      });
      this.router.navigate(['/dashboard/registers/specialties']);
    }, (error: HttpErrorResponse) => {
      this.submittingRemove = false;
      this.mapErrorResponse(error);
    })
  }

  fetchSpecialtyExistingData() {
    this.registersService.specialtyGet(this.id).subscribe(response => {
      this.fetchedSpecialtyData = response;
      if(this.fetchedSpecialtyData.is_active) {
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
        this.button = true;
      }
      else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
      }
      this.formGroup.patchValue(this.fetchedSpecialtyData);
      this.loadingPage = false;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    })
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro", {
        closeButton: true,
      });
    }
    else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["name"]) this.formGroup.get("name")?.setErrors({ response: errorResponse.error['name'] });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
      return true;
  }
}
