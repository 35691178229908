<div id="passwordSet">
    <div class="container">
        <div class="row">
            <div class="col logo-bar">
                <img src="/assets/eleve-logo.svg" alt="oral secret">
            </div>
        </div>

        <div class="row">
            <div class="offset-sm-1 offset-md-2 offset-lg-3 col-12 col-sm-10 col-md-8 col-lg-6">
                <div class="card">
                    <form [formGroup]="passwordSetForm" (submit)="onSubmit($event)" class="form">
                        <div class="mb-4">
                            <label for="password" class="form__label">Nova senha</label>
                            <input type="password" formControlName="password" id="password"
                                class="form-control form__input">
                        </div>

                        <div class="row">
                            <div class="offset-md-3 col-12 col-md-6 mb-3">
                                <button class="btn btn-primary btn-block" type="submit" [disabled]="loading">
                                    Enviar
                                    <span *ngIf="loading" class="spinner-border" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
