import { DOCUMENT, KeyValue } from '@angular/common';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { OximeterService } from 'src/app/core/services/oximeter.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';
import * as moment from 'moment';
import { PolysomnographyModel } from 'src/app/core/models/polysomnography.model';
import { last, map, tap } from 'rxjs/operators';
import { PolysomnographyExamModel } from 'src/app/core/models/polysomnography-exam.model';
import { OptionModel } from 'src/app/shared/renderers/models/option';
import { ProfessionalsService } from 'src/app/core/services/professionals.service';
import { ProfessionalModel } from 'src/app/core/models/professional.model';
import { IncludeExamModalComponent } from 'src/app/shared/components/include-exam-modal/include-exam-modal.component';
import { OptionsSelectModel } from 'src/app/shared/models/option';

@Component({
  selector: 'app-exam-item',
  templateUrl: './exam-item.component.html',
  styleUrls: ['./exam-item.component.scss']
})
export class ExamItemComponent implements OnInit {

  id!: number;
  loadingList: boolean = true;

  errorMessage!: string;
  error: boolean = false;

  addressFormGroup: FormGroup = this.fb.group({
    zipcode: [''],
    address: [''],
    number: [''],
    complement: [''],
    neighborhood: [''],
    city: [''],
    state: ['']
  });

  patientFormGroup = this.fb.group({
    id: [''],
    name: [''],
    phone_number: [''],
    birthdate: [''],
    gender: [''],
    address: this.addressFormGroup,
  });

  formGroup = this.fb.group({
    id: [],
    patient: this.patientFormGroup,
    oximeter_id: [],
    status: [],
    sent_at: [],
    attendance__date_time: [''],
    period: [''],
    attendance__professional_id: [],
    exam_nights: ['', Validators.min(1)],
    temporary_file: [],
    notes: []
  });

  isRegister: boolean = true;

  maxDate = '';
  minDate = '';
  maxDays = 1;

  disabledSalvar: boolean = false;

  submitting: boolean = false;
  submittingContinueEditing: boolean = false;
  submitButton?: String = "Salvar";

  attachmentFeedback = '';
  attachmentFileName = '';
  downloadFile: boolean = false;

  fetchedExam!: PolysomnographyModel;

  examsPerformed = true;

  allProfessionals: ProfessionalModel[] = [];
  allProfessionalsList!: OptionsSelectModel[];

  oximeterOptions: OptionsSelectModel[] = [];

  periodOptions = [
    { label: '08:00 às 12:00', value: 'FIRST_PERIOD' },
    { label: '12:00 às 14:00', value: 'SECOND_PERIOD' },
    { label: '14:00 às 17:00', value: 'THIRD_PERIOD' },
    { label: '17:00 às 19:00', value: 'FOURTH_PERIOD' },
  ];

  statusOptions = [
    { value: 'SEND', label: 'Enviar' },
    { value: 'DELIVERED', label: 'Entregue' },
    { value: 'IN_PROGRESS', label: 'Em andamento' },
    { value: 'DONE', label: 'Exames realizados' },
    { value: 'CONCLUDED', label: 'Concluído' },
    { value: 'CANCELED', label: 'Cancelado' }
  ];

  examsPerformedHeader = [
    { name: 'started_at', label: 'Início', width: '140px', widthWithPercentage: '12%' },
    { name: 'ended_at', label: 'Fim', width: '140px', widthWithPercentage: '12%' },
    { name: 'exam_duration', label: 'Duração', width: '100px', widthWithPercentage: '7%' },
    { name: 'gender', label: 'Gênero', width: '80px', widthWithPercentage: '7%' },
    { name: 'birthdate', label: 'Idade', width: '80px', widthWithPercentage: '7%' },
    { name: 'imc', label: 'IMC', width: '30px', widthWithPercentage: '7%' },
    { name: 'conditions', label: 'Condições', width: '100px', widthWithPercentage: '14%' },
    { name: 'snoring', label: 'Ronco', width: '50px', widthWithPercentage: '8%' },
    { name: 'spo2', label: '↓SpO2', width: '30px', widthWithPercentage: '10%' },
    { name: 'ido', label: 'IDO', width: '30px', widthWithPercentage: '8%' },
    { name: 'report', label: 'Relatório', width: '30px', widthWithPercentage: '7%' },
  ];

  examsPerformedData: PolysomnographyExamModel[] = [];

  constructor(@Inject(DOCUMENT) private document: Document, private fb: FormBuilder, private oximeterService: OximeterService,
    private router: Router, private activatedRoute: ActivatedRoute, config: NgbModalConfig, private modalService: NgbModal,
     private toast: ToastrService, private professionalService: ProfessionalsService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = Number(params['id']);
      if (this.id) {
        this.isRegister = false;
      }
    });
    this.initializeForm();
    this.fetchExamsList();
  }

  get loadingPage(): boolean {
    let optionsSelectedExists: boolean = this.allProfessionalsList && this.oximeterOptions ? true : false;
    let examDataExists = this.fetchedExam ? true : false;
    if(this.isRegister) {
      return !optionsSelectedExists;
    } else  {
      return !(optionsSelectedExists && examDataExists);
    }
  }

  initializeForm() {
    this.fetchOximeter();
    if (this.id) this.fetchExamExistingData();
    this.fetchProfessionalsList();
  }

  fetchExamsList() {
    this.oximeterService.polysomnographyExamsList(this.id).subscribe(response => {
      this.examsPerformedData = response;
      this.loadingList = false;
    }, (error: HttpErrorResponse) => {
      this.loadingList = false;
      this.error = true;
      this.errorMessage = error.error['detail'];
    });
  }

  viewExams() {
    this.router.navigate([`dashboard/patients/edit/${this.fetchedExam.patient!.id}/patientPolysomnography`]);
  }

  includeExam(examData?: PolysomnographyExamModel) {
    const modalRef = this.modalService.open(IncludeExamModalComponent, { centered: true, size: 'lg', animation: true });
    modalRef.componentInstance.modalItems = { polysomnographyId: this.id, examData: examData, imc: this.fetchedExam.first_exam__imc };
    modalRef.result.then(result => {
      this.fetchExamsList();
      this.fetchExamExistingData();
    });
  }

  getKeyByValue(object: any, name: string) {
    return object[name];
  }

  getDate(object: any, name: string) {
    return moment(object[name]).format('DD/MM/YYYY HH:mm');
  }

  getAge(object: any) {
    return Math.floor(moment(new Date()).diff(moment(object.patient.birthdate), 'years'));
  }

  getDuration(object: any) {
    let hours = Math.floor(object.exam_duration / 60);
    let minutes = object.exam_duration % 60;
    if (minutes.toString().length == 1) return `${hours}h0${minutes}min`;
    else return `${hours}h${minutes}min`;
  }

  getSpo2(object: any) {
    let minutes = object.spo2;
    let percentage = object.percentage_spo2;
    return `${minutes}min (${percentage}%)`;
  }

  getGender(object: any) {
    if (object.patient.gender == 'FEMALE') {
      return 'gender-female';
    } else return 'gender-male';
  }

  originalOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return 0;
  }

  getConditions(object: any, name: string) {
    let conditions = new Map<string, string>();

    if (object[name]) {
      object[name].forEach((item: string) => {
        if (item == 'ALCOHOLIC_BEVERAGE') {
          conditions.set('icon-Icon-awesome-cocktail', 'Bebida alcoólica');
        } else if (item == 'STUFFY_NOSE') {
          conditions.set('icon-Grupo-409', 'Nariz entupido');
        } else if (item == 'SEDATIVES') {
          conditions.set('icon-pill', 'Sedativos');
        } else if (item == 'CPAP') {
          conditions.set('icon-cpap', 'CPAP');
        } else if (item == 'INTRAORAL_SNORING_DEVICE') {
          conditions.set('icon-intraoral_snoring_device', 'Aparelho de Ronco Intraoral');
        }
      });
    }
    return conditions;
  }

  getBackground(object: any, name: string) {
    let style = {
      'background-color': '#000',
      'border-radius': '4px',
      'padding': '0 4px'
    }
    if (name == 'spo2') {
      if (object.percentage_spo2 == 0) {
        style['background-color'] = '#88f988';
      } else if (object.percentage_spo2 > 0 && object.percentage_spo2 < 10) {
        style['background-color'] = '#f5f988';
      } else {
        style['background-color'] = '#f6a9a8';
      }
    } else {
      if (object[name] >= 0 && object[name] <= 4.9) {
        style['background-color'] = '#88f988';
      } else if (object[name] >= 5 && object[name] <= 14.9) {
        style['background-color'] = '#f5f988';
      } else if (object[name] >= 15 && object[name] <= 29.9) {
        style['background-color'] = '#ffd0a8';
      } else {
        style['background-color'] = '#f6a9a8';
      }
    }
    return style;
  }

  downloadAttachment(object: PolysomnographyExamModel) {
    window.open(object.snoring_file, '_blank');
  }

  cancelHandler() {
    this.router.navigate(['/dashboard/oximeter/exams']);
  }

  submitHandler(continueEditing: boolean) {
    if (continueEditing) {
      this.submittingContinueEditing = true;
    } else {
      this.submitting = true;
    }
    let examData = this.formGroup.getRawValue() as PolysomnographyModel;
    examData.sent_at = moment(examData.sent_at).format();
    this.examEdit(examData, continueEditing);
  }

  examEdit(examData: PolysomnographyModel, continueEditing: boolean) {
    examData.notes = examData.notes ?? '';
    examData.oximeter_id = examData.oximeter_id == this.fetchedExam.oximeter_id ? undefined : examData.oximeter_id;
    this.oximeterService.polysomnographyEdit(examData).subscribe(response => {
      this.toast.success("Exame alterado com sucesso", "Sucesso");
      if (continueEditing) {
        this.fetchExamExistingData();
      } else {
        this.cancelHandler();
      }
      this.submitting = false;
      this.submittingContinueEditing = false;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
      this.submittingContinueEditing = false;
    });
  }

  fetchOximeter() {
    this.oximeterService.oximetersAll().subscribe(response => {
      if (response) {
        this.oximeterOptions = response.map(item => {
          return {
            value: item.id?.toString(),
            label: item.serial_number,
            is_active: !item.is_damaged && item.status == 'OCCUPIED' && item.is_active
          } as OptionsSelectModel;
        });
      };
    });
  }


  fetchExamExistingData() {
    this.oximeterService.polysomnographyGet(this.id).subscribe(response => {
      this.fetchedExam = response;
      this.formGroup.patchValue(response);
      let date_time = moment(this.fetchedExam.attendance__date_time).format('DD/MM/YYYY - HH:mm:ss');
      let sent_at = moment(this.fetchedExam.sent_at).format('YYYY-MM-DD');
      this.maxDate = sent_at;
      this.minDate = moment().format('YYYY-MM-DD');
      let attendanceDate = moment(this.fetchedExam.attendance__date_time).format('YYYY-MM-DD');
      this.maxDays = moment(attendanceDate).diff(moment(this.minDate), 'days');
      console.log(attendanceDate, this.minDate, this.maxDays)
      this.formGroup.get('exam_nights')?.addValidators([Validators.max(this.maxDays)]);
      this.formGroup.get('attendance__date_time')?.setValue(date_time);
      this.formGroup.get('sent_at')?.setValue(sent_at);
      if (this.fetchedExam.filename) {
        let filename: string = this.fetchedExam.filename
        this.attachmentFileName = filename.split('/').pop() ?? '';
        this.downloadFile = true;
      }
      this.formGroup.disable();
      this.formGroup.get('notes')?.enable();
      this.formGroup.get('oximeter_id')?.enable();
      this.formGroup.get('status')?.enable();
      this.formGroup.get('sent_at')?.enable();
      this.formGroup.get('exam_nights')?.enable();
      this.formGroup.get('attendance__professional_id')?.enable();

    });
  }

  fetchProfessionalsList() {
    this.professionalService.listAll().subscribe(response => {
      this.allProfessionals = response;
      this.allProfessionalsList = response.map(item => {
        return {
          label: `${item.name} - CRO ${item.cro}`,
          value: item.id?.toString()
        } as OptionsSelectModel;
      });
    });
  }


  setDatePolysomnography(value: string) {
    let dateAttendance = moment(this.fetchedExam.attendance__date_time).format();
    let days = Number(value);
    if (days <= this.maxDays) {
      let date = moment(dateAttendance).subtract(days, "days");
      this.maxDate = moment(dateAttendance).subtract(days, "days").format('YYYY-MM-DD');
      this.formGroup.get('sent_at')?.setValue(date?.format('YYYY-MM-DD'));
    }
  }

  fnWorkingDaysUntilDate(date: string, days: number) {
    let _aux = [];
    let _curr = moment(date).subtract(1, 'days');

    while (_aux.length < days) {
      let dayOfWeek = moment(_curr).day();
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        _aux.push(moment(_curr));
      }
      _curr = moment(_curr).subtract(1, 'days');
    }

    return _aux;
  }

  downloadFiles(url: string) {
    window.open(url, '_blank');
  }

  addAttachment(event: any) {
    let files: FileList = event.target.files;
    this.attachmentFileName = files[0].name;
    this.downloadFile = false;
    let formData: FormData = new FormData();
    Array.from(files).forEach(file => {
      formData.append('files', file);
    });
    this.oximeterService.uploadTemporaryFile(formData).pipe(
      map((event: any, formData: any) => {
        switch (event['type']) {
          case HttpEventType.UploadProgress:
            let percentage = event.total ? Math.round(100 * event.loaded / event.total) : 0;
            return `Envio de arquivo em ${percentage}%.`;
          case HttpEventType.Response:
            event.body.forEach((attachment: any) => {
              this.formGroup.get('temporary_file')?.setValue(attachment.id);
            });
            return '';
          default:
            return null;
        }
      }),
      tap(message => {
        this.attachmentFeedback = message || '';
      }),
      last()
    ).subscribe(response => {
      /**/
    }, error => {
      console.error(error);
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro", {
        closeButton: true,
      });
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    } else {
      this.setFormErrors(errorResponse);
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      'id', 'patient__name', 'patient__phone_number', 'patient__address', 'oximeter_id',
      'status', 'sent_at', 'attendance__date_time', 'period',
      'exam_nights', 'notes'
    ];

    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
    });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if ((this.formGroup.dirty || this.submitting) && this.isRegister) {
      const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
      modalRef.componentInstance.text = "As alterações no formulário não foram salvas e serão descartadas, deseja prosseguir?";
      return modalRef.result
    }
    else
      return true;
  };

}
