import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { FinancialStatusModel } from 'src/app/core/models/financial-status.model';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { StatusStageModel } from 'src/app/core/models/status-stage.model';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-financial-status-form',
  templateUrl: './financial-status-form.component.html',
  styleUrls: ['./financial-status-form.component.scss']
})
export class FinancialStatusFormComponent implements OnInit {

  id!: number;
  loadingPage: boolean = true;

  backgroundColorValue?: String = "#FFFFFF";
  fontColorValue?: String = "#000000";

  formGroup = this.fb.group({
    id: [''],
    name: ['', Validators.required],
    background_color: [this.backgroundColorValue, Validators.required],
    font_color: [this.fontColorValue, Validators.required],
    stage_id: ['']
  });

  fetchedStatusFinancial!: FinancialStatusModel;
  stageList!: StatusStageModel[];

  isRegister: boolean = true;

  disabledSalvar: boolean = false;
  disabledRemover: boolean = false;

  submitting: boolean = false;
  submittingRemove: boolean = false;
  removeButton?: String = "Desativar";
  submitButton?: String = "Salvar";

  button: boolean = false;

  constructor(private fb: FormBuilder, private registersService: RegistersService, private router: Router, private activatedRoute: ActivatedRoute, config: NgbModalConfig, private modalService: NgbModal, private sessionManager: SessionManagerService, private toast: ToastrService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }


  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      this.id = Number(params['id']);
      if(this.id) {
        this.isRegister = false;
      }
    });
    this.initializeForm();
  }

  initializeForm() {
    this.fetchStage();
    if(this.id) this.fetchStatusExistingData();
    else this.loadingPage = false;
  }

  fetchStage() {
    this.registersService.statusStageAll().subscribe(response => this.stageList = response!);
  }

  get canSave() {
    if (this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_financialstatus);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_financialstatus);
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_financialstatus);
  }

  fetchStatusExistingData() {
    this.registersService.financialStatusGet(this.id).subscribe(response => {
      this.fetchedStatusFinancial = response;
      if (this.fetchedStatusFinancial.is_active) {
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
        this.button = true;
      }
      else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
      }
      this.formGroup.patchValue(this.fetchedStatusFinancial);
      this.loadingPage = false;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    })
  }

  cancelHandler() {
    this.router.navigate(["/dashboard/registers/financialStatus"]);
  }

  submitHandler() {
    this.submitting = true;
    this.formGroup.markAllAsTouched();
    let statusData = this.formGroup.getRawValue() as FinancialStatusModel;
    if (this.isRegister) {
      if(this.formGroup.valid) {
        this.statusRegister(statusData);
      } else this.submitting = false;
    } else  {
      this.statusEdit(statusData);
    }
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar este status de financeiro?";
    modalRef.result.then((result: boolean) => {
      if(result == true) {
        this.statusRemove();
      } else this.submittingRemove = false;
    });
  }

  statusRegister(statusData: FinancialStatusModel) {
    statusData.id = undefined;
    this.registersService.financialStatusRegister(statusData).subscribe(response => {
      this.toast.success("Status de financeiro criado com sucesso", "Sucesso");
      this.cancelHandler();
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  statusEdit(statusData: FinancialStatusModel) {
    statusData.is_active = true;
    console.log(statusData)
    this.registersService.financialStatusEdit(statusData).subscribe(response => {
      this.toast.success("Status de financeiro alterado com sucesso", "Sucesso");
      this.cancelHandler();
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  statusRemove() {
    this.registersService.financialStatusRemove(this.id).subscribe(response => {
      this.toast.success("Status de financeiro removido com sucesso", "Sucesso");
      this.cancelHandler();
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    }
    else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "name", "background_color", "font_color", "stage_id"
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
    });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  }
}
