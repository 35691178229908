<div class="row search m-4" [formGroup]="searchFormGroup">
  <div class="col-3">
    <ng-select id="status" formControlName="status" bindLabel="name" bindValue="id" [items]="statusList"
               [multiple]="true" placeholder="Todos os status" (change)="filterTicketListAndOrder()"></ng-select>
  </div>

  <div class="col-2">
    <ng-select id="users" formControlName="user" bindLabel="name" bindValue="id" [items]="usersList"
               [multiple]="true" placeholder="Todos os usuários" (change)="filterTicketListAndOrder()"></ng-select>
  </div>

  <div class="col-3 py-3 p-md-0 search_date">
    <div class="row p-0 m-0" [formGroup]="searchFormGroup">
      <input type="date" class="col-5" formControlName="since" (change)="filterTicketListAndOrder()" />
      <span class="col-1 d-flex align-self-center justify-content-center p-0">até</span>
      <input type="date" class="col-5" formControlName="until" (change)="filterTicketListAndOrder()" />
    </div>
  </div>

  <div class="link col-3">
    <button class="btn btn-link" *ngIf="!active" (click)="fetchTicketList(true)">Encerrados</button>
    <button class="btn btn-link" *ngIf="active" (click)="fetchTicketList(false)">Ativos</button>

    <button class="btn btn-link" (click)="moreFilters = !moreFilters">Busca avançada</button>
    <button class="btn btn-link" (click)="exportToCSV()">Exportar</button>
  </div>

  <div class="link col-1">
    <a routerLink="register">+ Ticket</a>
  </div>

  <div class="row mt-4 w-100 d-none" [class.d-flex]="moreFilters">
    <div class="col-3 pl-0">
      <app-basic-input label="Nome do paciente" [formGroup]="searchFormGroup" controlName="patient__name"
                       (keyup)="filterTicketListAndOrder()"></app-basic-input>
    </div>

    <div class="col-3 pl-0">
      <app-basic-input label="CPF" [maskOptions]="{mask: '00000000000'}" [formGroup]="searchFormGroup"
                       controlName="patient__document_number" (keyup)="filterTicketListAndOrder()"></app-basic-input>
    </div>

    <div class="col-3 pl-0">
      <label for="healthInsurances" class="field__title pl-3">Convênio</label>
      <ng-select id="healthInsurances" formControlName="health_insurances" [items]="healthInsurances" bindLabel="name"
                 bindValue="id" [multiple]="true" (change)="filterTicketListAndOrder()"></ng-select>
    </div>

    <div class="col-3 pl-0">
      <label for="origin" class="field__title pl-3">Indicação</label>
      <ng-select id="origin" formControlName="origin" [items]="origins" [multiple]="true"
                 (change)="filterTicketListAndOrder()"></ng-select>
    </div>
  </div>
</div>

<div class="card my-2">
  <div *ngIf="isLoading" class="text-center spinner">
    <span class="spinner-border spinner-border-xl"></span>
  </div>

  <div *ngIf="!isLoading && filteredTickets.length == 0" class="text-center spinner">
    <span>Nenhum ticket foi encontrado.</span>
  </div>

  <table class="table table-bordered col-12 m-0" *ngIf="!isLoading && filteredTickets.length > 0">
    <div class="main-scroll">
      <thead class="main-head col-12">
        <tr>
          <th class="col-3 orderable" (click)="orderBy('patient__name')">
            PACIENTE
            <i *ngIf="orderedBy('patient__name')" class="icon-Icon-awesome-caret-down" [class.ascending]="isAscending"></i>
          </th>
          <th class="col-2 orderable" (click)="orderBy('patient__document_number')">
            CPF
            <i *ngIf="orderedBy('patient__document_number')" class="icon-Icon-awesome-caret-down" [class.ascending]="isAscending"></i>
          </th>
          <th class="col-1 orderable" (click)="orderBy('health_insurance_plan__health_insurance__name')">
            CONVÊNIO
            <i *ngIf="orderedBy('health_insurance_plan__health_insurance__name')" class="icon-Icon-awesome-caret-down" [class.ascending]="isAscending"></i>
          </th>
          <th class="col-1 orderable" (click)="orderBy('health_insurance_plan__name')">
            PLANO
            <i *ngIf="orderedBy('health_insurance_plan__name')" class="icon-Icon-awesome-caret-down" [class.ascending]="isAscending"></i>
          </th>
          <th class="col-2 orderable" (click)="orderBy('origin')">
            INDICAÇÃO
            <i *ngIf="orderedBy('origin')" class="icon-Icon-awesome-caret-down" [class.ascending]="isAscending"></i>
          </th>
          <th class="col-1 orderable" title="Data de atendimento" (click)="orderBy('date_time')">
            DT ATEND.
            <i *ngIf="orderedBy('date_time')" class="icon-Icon-awesome-caret-down" [class.ascending]="isAscending"></i>
          </th>
          <th class="col-2 orderable" (click)="orderBy('status__name')">
            STATUS
            <i *ngIf="orderedBy('status__name')" class="icon-Icon-awesome-caret-down" [class.ascending]="isAscending"></i>
          </th>
          <th class="col-1 orderable" (click)="orderBy('user__name')">
            ATENDENTE
            <i *ngIf="orderedBy('user__name')" class="icon-Icon-awesome-caret-down" [class.ascending]="isAscending"></i>
          </th>
          <th>ANEXOS</th>
        </tr>
      </thead>

      <tbody *ngFor="let ticket of filteredTickets; let ticketIndex = index" class="main-body">
        <tr (click)="ticketClickHandle(ticket)"
          [style]="{'background-color': getBackgroundColor(ticket, ticket.clicked), 'color': getFontColor(ticket, ticket.clicked)}">
          <td [title]="ticket.patient?.name">{{ticket.patient?.name}}</td>
          <td [title]="getDocumentNumber(ticket.patient?.document_number ?? '')">
            {{getDocumentNumber(ticket.patient?.document_number ?? '')}}
          </td>
          <td [title]="ticket.health_insurance_plan?.health_insurance?.name">{{ticket.health_insurance_plan?.health_insurance?.name}}</td>
          <td [title]="ticket.health_insurance_plan?.name">{{ticket.health_insurance_plan?.name}}</td>
          <td [title]="recommendationHandle(ticket)">{{recommendationHandle(ticket)}}</td>
          <td>{{dateHandle(ticket.date_time!, false)}}</td>
          <td [title]="getStatuses(ticket)">{{getStatuses(ticket)}}</td>
          <td [title]="ticket.user?.name">{{ticket.user?.name}}</td>
          <td>
            <span *ngIf="ticket.attachment_list?.length != 0"
              (click)="$event.stopImmediatePropagation(); openAttachmentModal(false, 'Ticket', ticket.patient?.name ?? '', ticket.id, ticket.attachment_list)">
              <i-bs name="paperclip" width="2rem" height="2rem"></i-bs>
            </span>
          </td>
        </tr>

        <tr *ngIf="showDetail(ticket)" class="detail-table">
          <td colspan="12" class="p-0">
            <div class="d-flex">
              <div class="text-center detail-cell col-1">DETALHES</div>
              <div class="secondary-scroll w-100">
                <table class="">
                  <thead class="detail-head text-center col-11">
                    <th *ngFor="let header of detailHeader" [title]="header.tooltip" class="{{header.width}}">{{header.title}}
                    </th>
                  </thead>
                  <tbody *ngFor="let detail of currentTicket!.ticket_procedure_list"
                    class="detail-body text-center">
                    <tr (click)="editHandle(ticket.id!)">
                      <td  [title]="detail.procedure__name">{{detail.procedure__name}}</td>
                      <td [title]="detail.status?.status__name"
                        [style]="{'background-color': getStatusBackgroundColor(detail.status), 'color': getStatusFontColor(detail.status)}">
                        {{detail.status?.status__name}}
                      </td>
                      <td>
                        <span *ngIf="detail.protocol_list?.length != 0"
                        (click)="$event.stopImmediatePropagation(); openProtocolModal(false, detail.procedure__name ?? '', detail.procedure_id, detail.protocol_list)">
                          {{detail.current_protocol}}
                        </span>
                      </td>
                      <td>{{dateHandle(detail.refund_request_date!,
                        false)}}</td>
                      <td>{{ detail.invoice_value | currency:'BRL':true:'1.2-2':'pt-br' }}</td>
                      <td>{{ detail.refunded_amount | currency:'BRL':true:'1.2-2':'pt-br' }}</td>
                      <td>{{dateHandle(detail.refund_date!, false)}}</td>
                      <td>{{ detail.co_participation_value | currency:'BRL':true:'1.2-2':'pt-br' }}</td>
                      <td>{{dateHandle(detail.receipt_date!, false)}}</td>
                      <td>{{ detail.transfer_value | currency:'BRL':true:'1.2-2':'pt-br' }}</td>
                      <td><span *ngIf="showAttachmentIcon(detail)"
                          (click)="$event.stopImmediatePropagation();openProcedureAttachmentModal(detail, ticket.patient?.name ?? '', detail.procedure_id, detail.attachment_list)">
                          <i-bs name="paperclip" width="2rem" height="2rem"></i-bs>
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </td>
        </tr>
        <tr *ngIf="showDetail(ticket)" class="detail-table">
          <td colspan="12" class="p-0">
            <div class="d-flex">
              <div class="text-center detail-cell col-1 historic ">
                HISTÓRICO
                <p><a (click)="editHandle(ticket.id!)">+ Novo</a></p>
              </div>
              <div class="third-scroll col-11 p-0 m-0">
                <table class="">
                  <thead class="detail-head text-center historic">
                    <th *ngFor="let header of historicHeader" class="{{header.width}}">{{header.title}}
                    </th>
                  </thead>
                  <tbody *ngFor="let historic of currentTicket!.log_list" class="historic-body text-center">
                    <tr class="col-12">
                      <td>{{dateHandle(historic.created_at!, true)}}</td>
                      <td>{{historic.user__name}}</td>
                      <td><span *ngIf="historic.attachment_list?.length != 0"
                          (click)="$event.stopImmediatePropagation(); openAttachmentModal(false, 'Histórico', ticket.patient?.name ?? '', historic.id, historic.attachment_list)">
                          <i-bs name="paperclip" width="2rem" height="2rem"></i-bs>
                        </span>
                      </td>
                      <td [title]="historic.notes">{{historic.notes}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </td>
        </tr>
      </tbody>

      <div class="col-12 align-items-center pt-2 pb-2" *ngIf="hasNext.next">
        <div *ngIf="hasNext.loading" class="text-center spinner">
          <span class="spinner-border spinner-border-xl"></span>
        </div>

        <button *ngIf="!hasNext.loading" class="text-center orange" (click)="loadMore()" [disabled]="hasNext.loading">
          + Clique para exibir mais
        </button>
      </div>
    </div>
  </table>
</div>
