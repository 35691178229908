import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ContextMenuComponent } from 'ngx-contextmenu';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IncludeSchedulingModalComponent } from '../include-scheduling-modal/include-scheduling-modal.component';
import { CreateReminderModalComponent } from '../create-reminder-modal/create-reminder-modal.component';
import {Router} from "@angular/router";

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuBasicComponent implements OnInit {
  @ViewChild('basicMenu') contextMenu!: ContextMenuComponent;
  @ViewChild('basicMenuInclude') contextMenuInclude!: ContextMenuComponent;
  @ViewChild('reminderInclude') reminderInclude!: ContextMenuComponent;
  @ViewChild('reminder') reminder!: ContextMenuComponent;

  @Input() contextMenuItems!: any;

  @Output() toggleStatus = new EventEmitter();
  @Output() toggleReminderStatus = new EventEmitter();
  @Output() includeScheduling = new EventEmitter();
  @Output() includeReminder = new EventEmitter();
  @Output() cut = new EventEmitter();
  @Output() copy = new EventEmitter();
  @Output() paste = new EventEmitter();
  @Output() create = new EventEmitter();

  item!: any;
  date!: any;
  schedulingStatus!: any;
  reminderStatus: any[] = [];
  duplicateAttendance: boolean = false;

  constructor(private modalService: NgbModal, private router: Router) { }

  ngOnInit(): void {
  }

  initContextMenu() {
    this.item = this.contextMenuItems;
    this.getSchedulingStatus();
    this.getReminderStatus();
  }

  initContextMenuInclude() {

  }

  getSchedulingStatus() {
    this.schedulingStatus = JSON.parse(localStorage.getItem('schedulingStatus')!);
  }

  getReminderStatus() {
    this.reminderStatus = [];
    let status = JSON.parse(localStorage.getItem('reminderStatus')!);
    Object.keys(status).forEach(value => {
      this.reminderStatus.push(status[value]);
    });
  }

  include(event: any) {
    const modalRef = this.modalService.open(IncludeSchedulingModalComponent, { centered: true });
    modalRef.componentInstance.modalItems = this.contextMenuItems;
    modalRef.result.then(result => this.includeScheduling.emit(result));
  }

  includeReminderModal(event: any) {
    const modalRef = this.modalService.open(CreateReminderModalComponent, { centered: true });
    modalRef.componentInstance.modalItems = this.contextMenuItems;
    modalRef.componentInstance.action = 'create';
    modalRef.result.then(result => {
      if(result) {
        this.includeReminder.emit(result);
      }
    });
  }

  toggleSchedulingStatus(event: any, attendanceId: any, id: any) {
    this.toggleStatus.emit({ event, attendanceId, id });
  }

  toggleStatusReminder(event: any, reminderId: any, id: any) {
    this.toggleReminderStatus.emit({ event, reminderId, id });
  }

  cutAttendance(event: any, attendance: any) {
    this.duplicateAttendance = true;
    this.cut.emit({ event, attendance });
  }

  copyAttendance(event: any, attendance: any) {
    this.duplicateAttendance = true;
    this.copy.emit({ event, attendance });
  }

  pasteAttendance(event: any, item: any) {
    this.paste.emit({ event, item });
  }

  createReminder(event: any, item: any) {
    this.create.emit({ event, item });
  }

  goToChart(event: any, item: any) {
    this.router.navigate([`/dashboard/charts/edit/${item.patient.id}`],
      {queryParams: {date: item.date_time}});
  }
}
