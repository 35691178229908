import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss']
})
export class ImageInputComponent implements OnInit {

  @Input() id!: string;
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() value!: string;
  @Input() disabled!: boolean;
  @Input() height: string = '200px';

  @Output() onImageChange = new EventEmitter<any>();

  imageElement!: HTMLImageElement;
  fileInput!: HTMLInputElement;
  imageUrl: string = '';

  constructor(config: NgbModalConfig, private modalService: NgbModal) { }

  ngOnInit(): void {
    if (this.value) {
      this.imageUrl = this.value;
    }
  }

  ngAfterViewInit(): void {
    this.imageElement = document.getElementById('preview') as HTMLImageElement;
    this.imageElement.src = this.imageUrl;
    document.getElementById("uploader")!.onchange = this.previewImage;

    this.fileInput = document.getElementById('uploader') as HTMLInputElement;
  }

  previewImage = (event: Event) => {
    const file = this.fileInput.files![0];
    this.imageUrl = URL.createObjectURL(file);
    this.imageElement.src = this.imageUrl;

    this.onImageChange.emit(file);
  }

  removeButtonClickHandle(event: Event) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja remover esta imagem?";
    modalRef.result.then((result) => {
      if (result) {
        this.imageElement.src = "";
        this.imageUrl = "";
        this.fileInput.value = "";
      }
    });

    this.onImageChange.emit(null);
    event.preventDefault();
  }
}
