import { Component, Input, OnInit } from '@angular/core';
import { PhoneModel } from "../../../core/models/phone.model";
import { FormGroup } from "@angular/forms";
import { RestCountriesService } from "../../../core/services/restcountries.service";

@Component({
  selector: 'phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss']
})
export class PhoneNumberInputComponent implements OnInit {
  @Input() id: any = '';
  @Input() controlName: any = '';
  @Input() label: string = '';
  @Input() formGroup?: FormGroup;
  @Input() showError: boolean = true;

  phoneNumber: PhoneModel = {};
  countryCodes: any[] = [];
  loading = false;

  constructor(private restCountriesService: RestCountriesService) { }

  ngOnInit(): void {
    this.restCountriesService.getPhonesList().subscribe((phonesList: any[]) => {
      phonesList.forEach((phone) => {
        this.countryCodes = [...this.countryCodes, { label: phone.idd.root + (phone.idd.suffixes[0] ? phone.idd.suffixes[0] : phone.idd.suffixes) + " - " + phone.translations.por.common, value: phone.idd.root + (phone.idd.suffixes[0] ? phone.idd.suffixes[0] : phone.idd.suffixes), flag: phone.flags.svg }];
      })
      this.loading = false;
    });
  }

  get mask() {
    if (this.formGroup?.get('country_code')?.value == '+55') {
      if (this.formGroup?.get('phone_number')?.value) {
        if (this.formGroup?.get('phone_number')?.value.length <= 10)
          return '(00) 0000-00000';
        else
          return '(00) 00000-0000';
      }
    }
    return '000000000000000';
  }

  shouldValidate() {
    if (this.formGroup?.get('country_code')?.value != '+55')
      return false;
      if (this.formGroup?.get('country_code')?.value == "+55" && this.formGroup?.get('phone_number')?.value && this.formGroup?.get('phone_number')?.value.length >= 11) {
      return false;
    } else if (this.formGroup?.get('country_code')?.value == "+55" && this.formGroup?.get('phone_number')?.value && this.formGroup?.get('phone_number')?.value.length < 10) {
      return true;
    } else {
      return false;
    }
  }
}
