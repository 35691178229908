import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChatbotConnectionModel } from 'src/app/core/models/chatbot-connection.model';
import { ChatbotService } from 'src/app/core/services/chatbot.service';

@Component({
  selector: 'app-qrcode-modal',
  templateUrl: './qrcode-modal.component.html',
  styleUrls: ['./qrcode-modal.component.scss']
})
export class QrcodeModalComponent implements OnInit {

  modalItems!: ChatbotConnectionModel;
  value!: any;

  constructor(public activeModal: NgbActiveModal, private chatbotService: ChatbotService) { }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    this.chatbotService.qrcodeGet().subscribe(response => {
      this.modalItems = response;
      this.value = response.qrcode;
      if(!response.connected) setTimeout(() => this.initialize(), 1000*10);
      else this.activeModal.close(true);
    });
  }

}
