import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RegistersService } from 'src/app/core/services/registers.service';
import { ListTemplate } from 'src/app/shared/models/list-template';
import { healthInsuranceTypeStringFormatter } from 'src/app/core/utils/string-formatters';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-health-insurances-list',
  templateUrl: './health-insurances-list.component.html',
  styleUrls: ['./health-insurances-list.component.scss']
})
export class HealthInsurancesListComponent implements OnInit {

  searchString: string = "";
  orderingItem: string = "name";

  get canNewData() {
    const permissions = [PermissionConst.add_healthinsurance, PermissionConst.change_healthinsurance, PermissionConst.view_healthinsurance];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  get canExport() {
    const permissions = [PermissionConst.export_healthinsurance, PermissionConst.change_healthinsurance, PermissionConst.view_healthinsurance];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  listTemplate: ListTemplate = {
    loading: true,
    showSearch: true,
    searchBarPlaceholder: "Buscar por Operadora, Plano ou ANS",
    showAdvancedSearch: false,
    newDataText: "+ Novo Convênio",
    newDataRouterLink: this.canNewData ? "register" : undefined,
    notFoundMessage: "Nenhum convênio encontrado",
    idFieldName: "id",
    header: [
      { name: "name", label: "Operadora", widthInPercentage: "50%", width: "350px" },
      { name: "type", label: "Tipo", widthInPercentage: "25%", width: "150px", displayStringConvertionFunction: healthInsuranceTypeStringFormatter },
      { name: "code", label: "ANS", widthInPercentage: "25%", width: "150px" }],
    onHeaderItemClick: (value: string) => {
      let invertString = '';
      if (this.orderingItem == value) {
        this.listTemplate.invertedOrdering = !this.listTemplate.invertedOrdering;
        if (this.listTemplate.invertedOrdering)
          invertString = '-';
      }
      this.fetchList(this.searchString, `${invertString}${value}`, this.listTemplate.is_active);
      this.orderingItem = value;
      this.listTemplate.selectedOrdering = value;
    },
    onSearchFocusOut: (value: string) => {
      this.searchString = value;
      this.fetchList(value, this.orderingItem, this.listTemplate.is_active);
    },
    onClickActive: () => {
      this.listTemplate.is_active = !this.listTemplate.is_active;
      this.fetchList(this.searchString, this.orderingItem, this.listTemplate.is_active);
    },
    selectedOrdering: this.orderingItem,
    invertedOrdering: false,
    exportUrl: this.canExport ? '/health_insurances/export' : undefined,
    exportOptions: {
      id: 'ID',
      name: 'Nome',
      type: 'Tipo',
      code: 'ANS',
      plans: 'Planos',
      is_active: 'Ativo'
    }
  };

  constructor(private registersService: RegistersService, private sessionManager: SessionManagerService,
              private titleService: Title) {
    this.titleService.setTitle('ELEVE - Convênios');
  }

  ngOnInit(): void {
    this.initializeList();
  }

  initializeList() {
    this.fetchList("", "name", this.listTemplate.is_active);
  }

  fetchList(searchString: string, orderingItem: string, isActive: boolean | undefined) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;

    this.registersService.healthInsurancesList(searchString, orderingItem, isActive).subscribe(response => {
      this.listTemplate.loading = false;
      this.listTemplate.data = response;
      this.listTemplate.hasNext = response.next != null;
    }, error => {
      const response = error as HttpErrorResponse;
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = response.error['detail'];
    });
  }

  loadMore(page: number) {
    this.listTemplate.loadingNext = true;
    this.registersService.healthInsurancesList(this.searchString, this.orderingItem, this.listTemplate.is_active, page).subscribe(
      response => {
        this.listTemplate.hasNext = response.next != null;
        response.results!.map(item => this.listTemplate.data!.results!.push(item));
        this.listTemplate.loadingNext = false;
      },
      error => {
        const response = error as HttpErrorResponse;
        this.listTemplate.loading = false;
        this.listTemplate.error = true;
        this.listTemplate.errorMessage = response.error['detail'];
      });
  }
}
