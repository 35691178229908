export const environment = {
  production: true,
  TOMOGRAFIA_COMPUTADORIZADA_DAS_ATMS_EXAM_TEMPLATE_ID: 1,
  TOMOGRAFIA_COMPUTADORIZADA_DE_FARINGE_EXAM_TEMPLATE_ID: 2,
  TOMOGRAFIA_COMPUTADORIZADA_DE_MANDIBULA_EXAM_TEMPLATE_ID: 3,
  TOMOGRAFIA_COMPUTADORIZADA_DE_MAXILA_EXAM_TEMPLATE_ID: 4,
  TOMOGRAFIA_COMPUTADORIZADA_DOS_SEIOS_DA_FACE_EXAM_TEMPLATE_ID: 5,
  REEMBOLSO_URL: 'https://meureembolso-h8j9f.ondigitalocean.app/#/',
  API_URL: 'https://api-bsb.elevediagnostico.com.br/api/v1'
};
