import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { TagModel } from 'src/app/core/models/tag.model';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-patient-tags-form',
  templateUrl: './patient-tags-form.component.html',
  styleUrls: ['./patient-tags-form.component.scss']
})
export class PatientTagsFormComponent implements OnInit {

  formGroup = this.fb.group({
    id: [],
    name: [],
    type: ['PATIENT']
  });

  id!: number;
  loadingPage: boolean = false;
  submitting: boolean = false;
  submittingRemove: boolean = false;

  isRegister: boolean = true;
  removeButton: String = "Desativar";
  submitButton: String = "Salvar";

  button: boolean = false;

  fetchedPatientTagData!: TagModel;

  constructor(private fb: FormBuilder, private registersService: RegistersService, private activatedRoute: ActivatedRoute, private sessionManager: SessionManagerService, private toast: ToastrService, private router: Router, config: NgbModalConfig, private modalService: NgbModal) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      if(this.id) {
        this.isRegister = false;
        this.fetchPatientTagExistingData();
      }
    })
  }

  get canSave() {
    if(this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_tag);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_tag);
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_tag);
  }

  cancelHandler() {
    this.router.navigate(['/dashboard/registers/patientTags'])
  }

  submitHandler() {
    this.submitting = true;
    this.formGroup.markAllAsTouched();

    let patientTagsData = this.formGroup.getRawValue() as TagModel;
    if(this.isRegister) {
      if(this.formGroup.valid) {
        this.patientTagsRegister(patientTagsData);
      } else this.submitting = false;
    } else {
      this.patientTagsEdit(patientTagsData);
    }
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar esta tag?";
    modalRef.result.then((result: boolean) => {
      if (result == true) {
        this.patientTagsRemove();
      } else this.submittingRemove = false;
    })
  }

  patientTagsRegister(patientTagData: TagModel) {
    this.registersService.tagRegister(patientTagData).subscribe(response => {
      this.toast.success("Tag criada com sucesso", "Sucesso", {
        closeButton: true,
      });
      this.router.navigate(['/dashboard/registers/patientTags']);
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  patientTagsEdit(patientTagData: TagModel) {
    patientTagData.is_active = true;
    this.registersService.tagEdit(patientTagData).subscribe(response => {
      this.toast.success("Tag alterada com sucesso", "Sucesso", {
        closeButton: true,
      });
      this.router.navigate(['/dashboard/registers/patientTags']);
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  patientTagsRemove() {
    this.registersService.tagRemove(this.id, "PATIENT").subscribe(response => {
      this.toast.success("Tag removida com sucesso", "Sucesso", {
        closeButton: true,
      });
      this.router.navigate(['/dashboard/registers/patientTags']);
    }, (error: HttpErrorResponse) => {
      this.submittingRemove = false;
      this.mapErrorResponse(error);
    })
  }

  fetchPatientTagExistingData() {
    this.registersService.tagGet(this.id, "PATIENT").subscribe(response => {
      this.fetchedPatientTagData = response;
      if(this.fetchedPatientTagData.is_active) {
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
        this.button = true;
      }
      else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
      }
      this.formGroup.patchValue(this.fetchedPatientTagData);
      this.loadingPage = false;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    })
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro", {
        closeButton: true,
      });
    }
    else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["name"]) this.formGroup.get("name")?.setErrors({ response: errorResponse.error['name'] });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
      return true;
  }

}
