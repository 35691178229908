import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusReminderListComponent } from './status-reminder-list/status-reminder-list.component';
import { StatusReminderFormComponent } from './status-reminder-form/status-reminder-form.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    StatusReminderListComponent,
    StatusReminderFormComponent
  ],
  imports: [
    CommonModule,
    RenderersModule,
    SharedModule,
    ReactiveFormsModule,
  ]
})
export class StatusReminderModule { }
