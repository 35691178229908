<div class="include-modal-wrapper">
  <div class="row p-0 justify-content-between title">
    <h3 class="py-4">Adicionar novo exame</h3>
    <button class="btn btn-link col-1 p-0 m-0" (click)="activeModal.close(false)">
      <i-bs name="x-lg" width="2rem" height="2rem"></i-bs>
    </button>
  </div>
  <div class="include-modal" *ngIf="!loadingPage else loadingTemplate">
    <div class="include-modal__content incluirNovo" [formGroup]="formGroup">
      <div class="row p-0 m-0">
        <div class="col-6 p-0 m-0 px-2">
          <label class="field__title">Início</label>
          <input [ngClass]="formGroup.get('started_at')!.errors ? 'error' : ''" type="datetime-local" (change)="setMinDate($event)" [max]="maxDate" formControlName="started_at" id="started_at">
          <app-form-error id="started_at" [formGroup]="formGroup" controlName="started_at">
          </app-form-error>
        </div>
        <div class="col-6 p-0 m-0 px-2">
          <label class="field__title">Fim</label>
          <input type="datetime-local" [ngClass]="formGroup.get('ended_at')!.errors ? 'error' : ''" [min]="minDate" [max]="maxDate" (change)="validatorDate($event)" formControlName="ended_at" id="ended_at">
          <app-form-error id="ended_at" [formGroup]="formGroup" controlName="ended_at">
          </app-form-error>
        </div>
      </div>

      <div class="row p-0 m-0 my-3">
        <div class="col-6 p-0 m-0 px-2">
          <label class="field__title">Condições</label>
          <div class=" d-flex">
            <label class="checkbox" *ngFor="let condition of conditionList" (click)="toggleCondition(condition.value)"
              [ngClass]="condition.checked ? 'checked' : ''">
              <i class="{{condition.icon}}"></i>
              {{condition.name}}
            </label>
          </div>
        </div>

        <div class="col-6 p-0 m-0 px-2">
          <label class="field__title">Tratamento</label>
          <div class=" d-flex">
            <label class="checkbox" *ngFor="let condition of snoringTreatmentList" (click)="toggleSnoringTreatment(condition.value)"
              [ngClass]="condition.checked ? 'checked' : ''">
              <i class="{{condition.icon}}"></i>
              {{condition.name}}
            </label>
          </div>
        </div>


      </div>

      <div class="row p-0 m-0 my-3">

        <div class="col-3 p-0 m-0 px-2">
          <label class="field__title">IMC</label>
          <input type="number" [ngClass]="formGroup.get('imc')!.errors ? 'error' : ''" formControlName="imc" id="imc">
          <app-form-error id="imc" [formGroup]="formGroup" controlName="imc">
          </app-form-error>
        </div>

        <div class="col-3 p-0 m-0 px-2">
          <label class="field__title">Ronco</label>
          <div class="d-flex align-items-end">
            <input type="number" [ngClass]="formGroup.get('snoring')!.errors ? 'error' : ''" class="col-11" min="0" max="100" formControlName="snoring" id="snoring">
            <label class="col-1 p-0 m-0 ml-1 label">%</label>
          </div>
          <app-form-error id="snoring" [formGroup]="formGroup" controlName="snoring">
          </app-form-error>
        </div>

          <div class="col-3 p-0 m-0 px-2">
            <label class="field__title">↓SpO2</label>
            <div class="d-flex align-items-end">
              <input type="number" [ngClass]="formGroup.get('spo2')!.errors ? 'error' : ''" [max]="maxMin" class="col-10" formControlName="spo2" id="spo2">
              <label class="col-2 p-0 m-0 ml-1 label">min</label>
            </div>
            <app-form-error id="spo2" [formGroup]="formGroup" controlName="spo2">
            </app-form-error>
          </div>
          <div class="col-3 p-0 m-0 px-2">
            <label class="field__title">IDO</label>
            <input type="text" [ngClass]="formGroup.get('ido')!.errors ? 'error' : ''" min="0" mask="0*,0" [dropSpecialCharacters]="false" formControlName="ido" id="ido" >
          <app-form-error id="ido" [formGroup]="formGroup" controlName="ido">
          </app-form-error>
        </div>
      </div>

      <div class="row p-0 m-0 my-3">
        <div class="col-6 p-0 m-0 px-2">
          <label class="field__title">Relatório</label>
          <div class="row p-0 m-0">
            <div class="d-flex align-items-center col-10 p-0 m-0">
              <label class="btn-link-primary">
                <input type="file" (change)="addAttachment($event)" class="d-none" multiple="false"
                *ngIf="!attachmentFeedback">
                <span *ngIf="attachmentFileName == ''">Adicionar arquivo</span>
                <span *ngIf="attachmentFileName != ''">{{attachmentFileName}}</span>
              </label>
              <span *ngIf="attachmentFeedback">Carregando - {{attachmentFeedback}}</span>
            </div>
            <div class="col-2 p-0 m-0" *ngIf="downloadFile">
              <i class="i icon icon-download p-0" (click)="downloadAttachment(item.snoring_file!)"></i>
            </div>
          </div>
          <app-form-error id="temporary_file" [formGroup]="formGroup" controlName="temporary_file">
          </app-form-error>
        </div>
      </div>


      <div class="row mt-4 justify-content-end">

        <div class="col-12 col-md-3 mb-3">
          <button (click)="activeModal.close(false)" class="btn btn-link-primary" type="button">
            Cancelar
          </button>
        </div>

        <div class="col-12 col-md-3 mb-3" *ngIf="!isRegister">
          <button (click)="removeHandler()" [disabled]="submitting" class="btn btn-secondary btn-lg" type="button">
            Desativar
          </button>
        </div>

        <div class="col-12 col-md-3 mb-3">
          <button (click)="submitHandler()" [disabled]="submitting" class="btn btn-primary btn-lg" type="button">
            Salvar
            <span class="spinner-border" *ngIf="submitting"></span>
          </button>
        </div>
      </div>

    </div>
  </div>
  <ng-template #loadingTemplate>
    <div class="col card full-loading text-center">
      <div class="spinner-border spinner-border-xl" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-template>
</div>
