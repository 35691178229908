<div
  [ngClass]="['field', removeVerticalSpacing ? 'remove-vertical-space' : '', labelPosition == 'left' ? 'horizontal' : '']"
  [formGroup]="formGroup">
  <label class="field__title" [for]="id"
         [ngClass]="{'field__title--left': labelPosition == 'left'}">{{label ? label : ""}}</label>
  <input [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="id"
         [dropSpecialCharacters]="dropSpecialCharacters"
         [mask]="maskOptions != undefined && maskOptions.mask != undefined ? maskOptions.mask : ''"
         [ngClass]="[(formGroup.get(id)!.errors && formGroup.get(id)!.touched) || (formGroup.get(id)!.errors && formGroup.get(id)!.errors['response']) ? 'error' : '', capitalize ? 'capitalize' : '']"
         (keypress)="checkKey($event)" (focusout)="focusOutHandle($event)"/>
  <form-error [id]=" id" [formGroup]="formGroup" *ngIf="showError"></form-error>
</div>
