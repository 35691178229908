<ng-container *ngIf="isAvailable() else reminder">
  <ng-container *ngFor="let availableTime of getAvailableTimes(date)">
    <div class="event" [class.event--selected]="isSelectedToAction(availableTime)"
      [class]="getColorClass(availableTime['attendance'])" [class.event--disabled]="!isEnabled(availableTime)"
      (click)="onClick($event, availableTime)" (contextmenu)="contextMenu($event, availableTime) ; false">
      <ng-container *ngIf="availableTime['attendance'] && isWeekly">
        <ng-template #tipContent>
          <div style="font-size: 12px; text-align: start; max-width: 400px;"
            *ngIf="availableTime['attendance']['notes']">
            <p>{{availableTime['attendance']['patient__name']}}</p>
            <b>Observações:</b>
            <p>{{availableTime['attendance']['notes']}}</p>
          </div>
          <div style="font-size: 12px; text-align: start; max-width: 400px;"
            *ngIf="!availableTime['attendance']['notes']">
            <p>{{availableTime['attendance']['patient__name']}}</p>
            <i>Sem observações</i>
          </div>
        </ng-template>
        <div class="event-card" [ngbTooltip]="tipContent" placement="top" [openDelay]="1000" container="body"
          tooltipClass="tooltipClass"
          [style]="{'background-color': getSchedulingStatusBackgroundColor(availableTime['attendance']), 'color': getSchedulingStatusFontColor(availableTime['attendance'])}">
          <p class="event__text-name">{{ availableTime['start_time'] | date: 'HH:mm' }} -
            {{ availableTime['attendance']['patient__display_name'] }}</p>
          <p class="event__text-attendance">
            <span class="event__tag" [style.color]="getAttendanceTypeColor(availableTime['attendance'])"
              [style.background-color]="getAttendanceTypeBackgroundColor(availableTime['attendance'])">
              {{ getAttendanceTypeName(availableTime['attendance']) }}
            </span>
          </p>
        </div>
        <ng-template #tipContent>
          <div class="textTooltip" *ngIf="availableTime['attendance']['notes']">
            <b>Observações:</b>
            <p>{{availableTime['attendance']['notes']}}</p>
          </div>
          <div class="textTooltip" *ngIf="!availableTime['attendance']['notes']">
            <i>Sem observações</i>
          </div>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="availableTime['attendance'] && !isWeekly">
        <div class="event-card"
          [style]="{'background-color': getSchedulingStatusBackgroundColor(availableTime['attendance']), 'color': getSchedulingStatusFontColor(availableTime['attendance'])}">
          <div class="event__text-diary row">
            <span class="col-3 col-md-2 p-0">
              <p class="event__text-name">{{ availableTime['start_time'] | date: 'HH:mm' }} </p>
            </span>
            <span class="col-9 col-md-4 p-0">
              <p class="event__text-name">{{ availableTime['attendance']['patient__name'] }}</p>
            </span>
            <span class="col-6 col-md-3 p-0">
              <span class="event__tag" [style.color]="getAttendanceTypeColor(availableTime['attendance'])"
                [style.background-color]="getAttendanceTypeBackgroundColor(availableTime['attendance'])">
                {{ getAttendanceTypeName(availableTime['attendance']) }}
              </span>
            </span>
            <span class="col-6 col-md-3 p-0"><a
                href="https://wa.me/{{ availableTime['attendance']['patient__phone_number'] }}" target="_blank">
                {{ availableTime['attendance']['patient__phone_number'] }}
                <span class="whatsapp-button icon icon-Icon-awesome-whatsapp "></span>
              </a></span>
          </div>

          <p class="event__text-attendance col-12 p-0">{{ availableTime['attendance']['notes'] }}</p>

        </div>
      </ng-container>

      <ng-container *ngIf="!availableTime['attendance'] && isEnabled(availableTime)">
        {{ availableTime['start_time'] | date: 'HH:mm' }} <span
          class="duration">({{getDuration(availableTime['start_time'], availableTime['end_time'])}})</span>
      </ng-container>
    </div>
  </ng-container>
</ng-container>

<ng-template #reminder>
  <ng-container *ngIf="isAvailableReminder() else unavailable">
    <ng-container *ngFor="let reminder of getReminder(date)">
      <div class="event event--reminder" (contextmenu)="contextMenuReminder($event, reminder) ; false"
           (click)="openReminder($event, reminder)" >
        <ng-container *ngIf="reminder && isWeekly">
          <ng-template #tipContent>
            <div style="font-size: 12px; text-align: start; max-width: 400px;" *ngIf="reminder['notes']">
              <p>{{reminder['patient__display_name']}}</p>
              <b>Observações:</b>
              <p>{{reminder['notes']}}</p>
            </div>
            <div style="font-size: 12px; text-align: start; max-width: 400px;" *ngIf="!reminder['notes']">
              <p>{{reminder['patient__display_name']}}</p>
              <i>Sem observações</i>
            </div>
          </ng-template>
          <div class="event-card" [ngbTooltip]="tipContent" placement="top" [openDelay]="1000" container="body"
            tooltipClass="tooltipClass" [style.color]="getReminderStatusColor(reminder)"
            [style.background-color]="getReminderStatusBackgroundColor(reminder)">
            <p class="event__text-name">{{reminder['date'] | date: 'HH:mm'}} - {{ reminder['patient__display_name'] }}</p>
            <span class="event__tag">
            {{ getReminderStatusName(reminder) }}
          </span>
          </div>
          <ng-template #tipContent>
            <div class="textTooltip" *ngIf="reminder['notes']">
              <b>Observações:</b>
              <p>{{reminder['notes']}}</p>
            </div>
            <div class="textTooltip" *ngIf="!reminder['notes']">
              <i>Sem observações</i>
            </div>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="reminder && !isWeekly">
          <div class="event-card" [style.color]="getReminderStatusColor(reminder)"
          [style.background-color]="getReminderStatusBackgroundColor(reminder)">
            <div class="event__text-diary row">
              <span class="col-5 p-0">
                <p class="event__text-name">{{ reminder['patient__name'] }}</p>
              </span>
              <span class="col-3 col-md-3 p-0">
                <span class="event__tag" >
                  {{ getReminderStatusName(reminder) }}
                </span>
              </span>
              <span class="col-4 p-0">
                <a href="https://wa.me/{{ reminder['patient__phone_number'] }}"
                  target="_blank">
                  {{ reminder['patient__phone_number'] }}
                  <span class="whatsapp-button icon icon-Icon-awesome-whatsapp "></span>
                </a>
              </span>
            </div>

            <p class="event__text-attendance col-12 p-0">{{ reminder['notes'] }}</p>

          </div>
        </ng-container>
      </div>
    </ng-container>

  </ng-container>
</ng-template>

<ng-template #unavailable>
  <div class="event event--disabled" *ngIf="!isReminder" (contextmenu)="contextMenuInclude($event, date) ; false">
    &nbsp;
  </div>
  <div class="event event--disabled" *ngIf="isReminder" (contextmenu)="contextMenuIncludeReminder($event, date) ; false">
    &nbsp;
  </div>
</ng-template>
