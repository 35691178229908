<div id="passwordRecovery">
    <div class="container">
        <div class="row">
            <div class="col logo-bar">
                <img src="/assets/eleve-logo.svg" alt="oral secret">
            </div>
        </div>

        <div class="row">
            <div class="offset-sm-1 offset-md-2 offset-lg-3 col-12 col-sm-10 col-md-8 col-lg-6">
                <div class="card">
                    <form *ngIf="!showConfirm" [formGroup]="passwordRecoveryForm" (submit)="onSubmit($event)"
                        class="form">
                        <div class="mb-4">
                            <label for="email" class="form__label">E-mail</label>
                            <input type="email" formControlName="email" id="email" class="form-control form__input">
                        </div>

                        <div class="row">
                            <div class="offset-md-3 col-12 col-md-6 mb-3">
                                <button class="btn btn-primary btn-block" type="submit" [disabled]="loading">
                                    Enviar
                                    <span *ngIf="loading" class="spinner-border" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 text-center">
                                <button (click)="loginLinkClickHandler()" class="btn btn-link">Fazer login</button>
                            </div>
                        </div>
                    </form>

                    <div class="confirm" *ngIf="showConfirm">
                        <h2 class="mb-5">Recuperação de senha</h2>
                        <h3 class="mb-3">Uma mensagem foi enviada para o seu e-mail com instruções para criar uma nova
                            senha.</h3>
                        <h3 class="obs">Obs: Verifique também sua caixa de spam</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
