<div *ngIf="!loadingPage else loadingTemplate">
  <div class="row mb-5">
    <div class="col card" [formGroup]="formGroup">
      <div class="col p-0">
        <h3 class="mb-4">Encerrar conversa chatbot</h3>
      </div>

      <div class="row p-0">
        <div class="col-5 p-0 pr-3 mb-3">
          <label class="label m-0">Whatsapp</label>
          <phone-number-input [formGroup]="formGroup"></phone-number-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-end mt-5 mb-5">
    <div class="col-12 col-md-3 mb-3">
      <button (click)="submitHandler()" [disabled]="submitting || !canSave || formGroup.disabled"
        class="btn btn-primary btn-lg" type="button">
        Encerrar
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
