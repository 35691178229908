<div class="modal-body">
  <button class="btn btn-link col-1 p-0 m-0 btn-close" (click)="activeModal.close(false)">
    <i-bs name="x-lg" width="2rem" height="2rem"></i-bs>
  </button>

  <form [formGroup]="formGroup">
    <!--<app-basic-input [formGroup]="formGroup" controlName="to" label="Destinatário"></app-basic-input>-->

    <label for="email">Corpo do e-mail</label>
    <editor [init]="config" formControlName="email"></editor>
  </form>
</div>
