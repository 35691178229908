import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemUsersListComponent } from './system-users-list/system-users-list.component';
import { SystemUserRegisterComponent } from './system-user-register/system-user-register.component';
import { SystemUserEditComponent } from './system-user-edit/system-user-edit.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RedefinePasswordComponent } from './redefine-password/redefine-password.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'src/app/shared/shared.module';
import { SystemUsersFormComponent } from './system-users-form/system-users-form.component';

@NgModule({
  declarations: [
    SystemUsersListComponent,
    SystemUserRegisterComponent,
    SystemUserEditComponent,
    RedefinePasswordComponent,
    SystemUsersFormComponent
  ],
  imports: [
    CommonModule,
    RenderersModule,
    ReactiveFormsModule,
    NgSelectModule,
    SharedModule,
  ]
})
export class SystemUsersModule { }
