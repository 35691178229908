import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { OximeterComponent } from './oximeter.component';
import { NavigationModule } from '../navigation/navigation.module';
import { ExamsListComponent } from './exams/exams-list/exams-list.component';
import { OximetersFormComponent } from './oximeters/oximeters-form/oximeters-form.component';
import { OximetersListComponent } from './oximeters/oximeters-list/oximeters-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ExamItemComponent } from './exams/exam-item/exam-item.component';
import { HistoricComponent } from './oximeters/historic/historic.component';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { ContextMenuModule } from 'ngx-contextmenu';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    OximeterComponent,
    ExamsListComponent,
    OximetersFormComponent,
    OximetersListComponent,
    ExamItemComponent,
    HistoricComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgSelectModule,
    NavigationModule,
    SharedModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    ContextMenuModule.forRoot({
      useBootstrap4: true,
    }),
    NgxBootstrapIconsModule.pick(allIcons)
  ],
})
export class OximeterModule { }
