import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NavigationModule } from '../navigation/navigation.module';

import { PatientRegisterComponent } from './patient-register/patient-register.component';
import { PatientsListComponent } from './patients-list/patients-list.component';
import { PatientEditModule } from './patient-edit/patient-edit.module';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    PatientsListComponent,
    PatientRegisterComponent,
  ],
  imports: [
    CommonModule,
    NavigationModule,
    RouterModule,
    RenderersModule,
    NgSelectModule,
    NgbTooltipModule,
    NgxMaskModule,
    ReactiveFormsModule,
    SharedModule,
    NgxBootstrapIconsModule.pick(allIcons),
    NgbModule
  ]
})
export class PatientsModule { }
