import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { OximeterModel } from 'src/app/core/models/oximeter.model';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { OximeterService } from 'src/app/core/services/oximeter.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';

@Component({
  selector: 'app-oximeters-form',
  templateUrl: './oximeters-form.component.html',
  styleUrls: ['./oximeters-form.component.scss']
})
export class OximetersFormComponent implements OnInit {

  id!: number;
  loadingPage: boolean = false;

  formGroup = this.fb.group({
    id: [''],
    serial_number: ['', [Validators.required]],
    model: ['', [Validators.required]],
    is_damaged: [false],
    notes: ['']
  });

  fetchedOximeter!: OximeterModel;

  isRegister: boolean = true;

  disabledSalvar: boolean = false;
  disabledRemover: boolean = false;

  submitting: boolean = false;
  submittingRemove: boolean = false;
  removeButton?: String = "Desativar";
  submitButton?: String = "Salvar";

  button: boolean = false;

  constructor(private fb: FormBuilder, private oximeterService: OximeterService, private router: Router, private activatedRoute: ActivatedRoute, private sessionManager: SessionManagerService, private toast: ToastrService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: any) => {
      this.id = Number(params['id']);
      if (this.id) {
        this.isRegister = false;
      }
    });
    this.initializeForm();
  }

  initializeForm() {
    if (this.id) this.fetchStatusExistingData();
    else this.loadingPage = false;
  }

  get canSave() {
    if (this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_oximeter);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_oximeter);
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_oximeter);
  }

  fetchStatusExistingData() {
    this.oximeterService.oximeterGet(this.id).subscribe((response) => {
      this.fetchedOximeter = response;
      this.formGroup.patchValue(response);
      if(this.fetchedOximeter.history) {
        if(this.fetchedOximeter.history.length > 0) {
          this.button = true;
        }
      }
      this.loadingPage = false;
    });
  }

  historic() {
    this.router.navigate(['/dashboard/oximeter/oximeters/historic', this.id]);
  }

  cancelHandler() {
    this.router.navigate(['/dashboard/oximeter/oximeters']);
  }

  submitHandler() {
    let oximeterData = this.formGroup.getRawValue();
    this.submitting = true;
    if(this.isRegister) {
      if(this.formGroup.valid) {
        this.oximeterRegister(oximeterData);
      }
    } else {
      this.oximeterEdit(oximeterData);
    }
  }

  oximeterRegister(oximeterData: OximeterModel) {
    oximeterData.id = undefined;
    this.oximeterService.oximeterRegister(oximeterData).subscribe(response => {
      this.toast.success("Oxímetro criado com sucesso", "Sucesso");
      this.cancelHandler();
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  oximeterEdit(oximeterData: OximeterModel) {
    oximeterData.notes = oximeterData.notes ?? '';
    this.oximeterService.oximeterEdit(oximeterData).subscribe(response => {
      this.toast.success("Oxímetro alterado com sucesso", "Sucesso");
      this.cancelHandler();
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    }
    else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      'serial_number', 'model', 'is_damaged', 'notes'
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
    });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  }
}
