import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BirdIdAccountModel, BirdIdModel } from '../models/birdid.model';

@Injectable({
  providedIn: 'root'
})
export class BirdidService {

  constructor(private httpClient: HttpClient) { }

  birdidAuth(birdidData: BirdIdModel): Observable<BirdIdAccountModel> {
    return this.httpClient.post<BirdIdAccountModel>(`${environment.API_URL}/birdid/oauth/`, JSON.stringify(birdidData));
  }

  birdidCertificate(birdidData: string): Observable<BirdIdAccountModel> {
    let alias = {alias: birdidData}
    return this.httpClient.post<BirdIdAccountModel>(`${environment.API_URL}/birdid/certificates/`, JSON.stringify(alias));
  }

}
