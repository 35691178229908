import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ChatbotConnectionModel } from '../models/chatbot-connection.model';
import { ManualTriggerModel } from '../models/manual-trigger.model';
import { TriggerModel } from '../models/triggers.model';

@Injectable({
  providedIn: 'root'
})
export class ChatbotService {

  constructor(private httpClient: HttpClient) { }

  qrcodeGet(): Observable<ChatbotConnectionModel> {
    return this.httpClient.get<ChatbotConnectionModel>(`${environment.API_URL}/chatbot/connection/`);
  }

  triggersGetAll(): Observable<TriggerModel[]> {
    return this.httpClient.get<TriggerModel[]>(`${environment.API_URL}/chatbot/triggers/all/?is_active=true`);
  }

  manualTriggersRegister(manualTriggersData: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.API_URL}/chatbot/send_messages/`, JSON.stringify(manualTriggersData));
  }

  manualTrigger(manualTriggersData: ManualTriggerModel): Observable<ManualTriggerModel> {
    return this.httpClient.patch<ManualTriggerModel>(`${environment.API_URL}/chatbot/triggers/${manualTriggersData.trigger}/start_trigger/`, JSON.stringify(manualTriggersData));
  }

  endConversation(endConversation: any): Observable<any> {
    return this.httpClient.patch<any>(`${environment.API_URL}/chatbot/triggers/stop/`, JSON.stringify(endConversation));
  }


}
