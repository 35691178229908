import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { FileModel } from 'src/app/core/models/file.model';
import { RegistersService } from 'src/app/core/services/registers.service';
import { FileInputComponent } from 'src/app/shared/components/file-input/file-input.component';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-patient-edit-attachments',
  templateUrl: './patient-edit-attachments.component.html',
  styleUrls: ['./patient-edit-attachments.component.scss']
})
export class PatientEditAttachmentsComponent implements OnInit {

  attachments: FileModel[] = [];
  id!: number;
  currentFile!: File;
  hasFile: boolean = false;

  attachmentForm = this.fb.group({
    name: '',
  });

  isLoading: boolean = true;

  constructor(@Inject(DOCUMENT) private document: Document, private activatedRoute: ActivatedRoute, private registersService: RegistersService, config: NgbModalConfig,
    private modalService: NgbModal, private fb: FormBuilder, private toast: ToastrService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  @ViewChild(FileInputComponent) fileInput!: FileInputComponent;

  ngOnInit(): void {
    this.activatedRoute.parent?.params.subscribe(params => {
      this.id = params['id'];
      this.registersService.patientAttachmentsList(this.id.toString()).subscribe((response: any) => {
        this.attachments = response.attachments;
        this.isLoading = false;
      });
    });
  }

  onFileChange(file: File) {
    this.currentFile = file;
    this.hasFile = true;
    this.attachmentForm.get("name")?.setValue(this.currentFile.name);
  }

  addButtonHandler(e: Event) {
    if (this.hasFile && this.attachmentForm.valid) {
      this.registersService.patientAttachmentRegister(this.currentFile, this.attachmentForm.get("name")?.value, this.id).subscribe((response: FileModel) => {
        this.attachmentForm.reset();
        this.attachments.push(response);
        this.fileInput.reset();
        this.hasFile = false
      });
    }

    e.preventDefault();
  }

  removeButtonHandler(attachmentIndex: number, attachmentId: number, e: Event) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja remover este anexo?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.registersService.patientAttachmentRemove(this.id, attachmentId).subscribe(() => {
          this.attachments.splice(attachmentIndex, 1);
        }, (errorResponse: HttpErrorResponse) => {
          this.mapErrorResponse(errorResponse);
        });
      }
    });
    e.preventDefault()
  }

  getPlaceholder(): string {
    if (!this.hasFile) return "Enviar arquivo";
    else return "Substituir arquivo"
  }

  downloadFile(url: string, event: Event) {
    window.open(url, '_blank');
    event.preventDefault();
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    }
    this.document.getElementById('main-container')?.scroll({ top: 0 });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  };
}
