<div *ngIf="!loadingPage else loadingTemplate">
  <div class="row mb-5">
    <div class="col card">
      <div class="col px-0">
        <h3 class="mb-4">Status do Financeiro</h3>
      </div>

      <div class="row px-0" [formGroup]="formGroup">
        <div class="col-10 px-0 pr-4">
          <app-basic-input id="name" label="Nome" controlName="name" [formGroup]="formGroup"></app-basic-input>
        </div>

        <div class="col-2 px-0">
          <app-basic-input id="order" label="Ordem" controlName="order" [formGroup]="formGroup"></app-basic-input>
        </div>

        <div class="col-6 px-0 pr-4 mt-3">
          <app-basic-input id="desired_time" type="number" label="Tempo de envio desejado (dias)" controlName="desired_time" [formGroup]="formGroup"></app-basic-input>
        </div>

        <div class="col-3 px-0 pr-2 mt-3">
          <label>Cor do plano de fundo</label>
          <div class="input-color" [ngClass]="formGroup.get('desired_time_background_color')!.errors && formGroup.get('desired_time_background_color')!.touched ? 'error' : ''">
            <input type="color" formControlName="desired_time_background_color" id="desired_time_background_color">
          </div>
          <app-form-error id="desired_time_background_color" [formGroup]="formGroup" controlName="desired_time_background_color">
          </app-form-error>
        </div>
        <div class="col-3 px-0 pl-2 mt-3">
          <label>Cor do texto</label>
          <div class="input-color" [ngClass]="formGroup.get('desired_time_font_color')!.errors && formGroup.get('desired_time_font_color')!.touched ? 'error' : ''">
            <input type="color" formControlName="desired_time_font_color" id="desired_time_font_color">
          </div>
          <app-form-error id="desired_time_font_color" [formGroup]="formGroup" controlName="desired_time_font_color">
          </app-form-error>
        </div>

        <div class="col-6 px-0 pr-4 mt-3">
          <app-basic-input id="deadline" type="number" label="Tempo limite (dias)" controlName="deadline" [formGroup]="formGroup"></app-basic-input>
        </div>
        <div class="col-3 px-0 pr-2 mt-3">
          <label>Cor do plano de fundo</label>
          <div class="input-color" [ngClass]="formGroup.get('deadline_background_color')!.errors && formGroup.get('deadline_background_color')!.touched ? 'error' : ''">
            <input type="color" formControlName="deadline_background_color" id="deadline_background_color">
          </div>
          <app-form-error id="deadline_background_color" [formGroup]="formGroup" controlName="deadline_background_color">
          </app-form-error>
        </div>
        <div class="col-3 px-0 pl-2 mt-3">
          <label>Cor do texto</label>
          <div class="input-color" [ngClass]="formGroup.get('deadline_font_color')!.errors && formGroup.get('deadline_font_color')!.touched ? 'error' : ''">
            <input type="color" formControlName="deadline_font_color" id="deadline_font_color">
          </div>
          <app-form-error id="deadline_font_color" [formGroup]="formGroup" controlName="deadline_font_color">
          </app-form-error>
        </div>

      </div>
    </div>
  </div>

  <div class="row justify-content-end mt-5 mb-5">
    <div class="col-12 col-md-3 mb-3">
      <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
        Cancelar
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3" *ngIf="button">
      <button (click)="removeHandler()" [disabled]="submittingRemove" class="btn btn-secondary btn-lg" type="button">
        {{removeButton}}
        <span class="spinner-border" *ngIf="submittingRemove"></span>
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3">
      <button (click)="submitHandler()" [disabled]="submitting" class="btn btn-primary btn-lg" type="button">
        {{submitButton}}
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
