<div class="row p-0 m-0 header">
  <div class="col-12">
    <app-header title="Agenda" [subTitle]="subTitle"></app-header>
  </div>
</div>

<div *ngIf="!loadingPage else loadingTemplate">
  <div class="row p-0 m-0 content">
    <div class="row col-12 separator" [formGroup]="formGroup">
      <div class="card">
        <div class="row">
          <div class="col-sm-5 pr-sm-4 p-0">
            <app-basic-input id="schedule" [formGroup]="formGroup" controlName="schedule" label="Agenda">
            </app-basic-input>
          </div>
          <div class="col-sm-5 pr-sm-4 p-0">
            <app-basic-input id="date_time" [formGroup]="formGroup" controlName="date_time" label="Horário">
            </app-basic-input>
          </div>
          <div class="col-sm-2 p-0">
            <app-basic-input id="duration" [formGroup]="formGroup" controlName="duration" label="Duração">
            </app-basic-input>
          </div>
          <div class="col-5 mb-4 px-0">
            <h3 class="my-3">Status do agendamento</h3>
            <div *ngFor="let status of schedulingStatus" class="selector d-flex align-content-center mb-1">
              <label [for]="'scheduling_status_'+status.id"><input formControlName="scheduling_status" type="radio"
                  [id]="'scheduling_status_'+status.id" [value]="status.id" class="mr-2">
                {{status.name}}</label>
            </div>
          </div>
          <div class="col-5 mb-4 px-0">
            <h3 class="my-3">Tipo de atendimento</h3>
            <div *ngFor="let type of attendanceType" class="selector d-flex align-content-center mb-1">
              <label [for]="'attendance_type_'+type.id"><input formControlName="attendance_type" type="radio"
                  [id]="'attendance_type_'+type.id" [value]="type.id" class="mr-2">
                {{type.name}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!isRegister" class="buttons">
      <div class="row justify-content-end">
        <div class="col-sm-12 col-md-3 col-lg-2 mt-5">
          <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
            Cancelar
          </button>
        </div>

        <div class="col-sm-12 col-md-3 mt-md-5">
          <button (click)="submitHandler()" class="btn btn-primary btn-lg" type="button">
            Salvar
          </button>
        </div>
      </div>
    </div>

    <div class="row col-12 separator" [formGroup]="formGroup">
      <div class="card">
        <div class="row">

          <div class="col-12 selector d-flex p-0 m-0 mb-1">
            <label class="align-self-center">
              <input class="mr-2" type="checkbox" [checked]="isSendOximeterChecked" (change)="sendOximeterChecked()">
              Enviar oxímetro
            </label>
          </div>

          <div class="col-12 p-0 m-0 mt-2" *ngIf="isSendOximeterChecked">
            <div class="row p-0 m-0" [formGroup]="polysomnographyFormGroup">
              <div class="col-2 p-0 m-0 mb-4">
                <app-basic-input id="exam_nights" [formGroup]="polysomnographyFormGroup" controlName="exam_nights"
                  type="number" label="Noites" (onValueChange)="setDatePolysomnography($event)">
                </app-basic-input>
              </div>

              <div class="col-3 p-0 m-0 px-4">
                <app-basic-input id="sent_at" [formGroup]="polysomnographyFormGroup" controlName="sent_at" type="date"
                  label="Data de envio" [max]="maxDate" [min]="minDate"
                  (onValueChange)="setDatePolysomnography($event)">
                </app-basic-input>
              </div>

              <div class="col-3 p-0 m-0 pr-4">
                <app-select-input [formGroup]="polysomnographyFormGroup" [options]="periodOptions" id="period"
                  label="Período" [clearable]="true"></app-select-input>
              </div>

              <div class="col-4 p-0 m-0">
                <app-select-input [formGroup]="formGroup" [options]="allProfessionalsList" id="professional"
                  label="Profissional" [clearable]="true"></app-select-input>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="row col-12 separator" [formGroup]="patientFormGroup">
      <div class="card">
        <div class="row">
          <h3 class="my-3 p-0 col-12">Paciente</h3>

          <div class="col-sm-8 col-md-7 mb-2 pr-sm-2 p-0">
            <ng-container *ngIf="!existingPatient">
              <app-select-input [formGroup]="patientFormGroup" [options]="patientOptions" id="name" label="Nome"
                [addTag]="true" (onValueChange)="setPatient($event)" notFoundText="Não encontrado"
                addTagText="Clique para adicionar paciente"></app-select-input>
            </ng-container>

            <div class="clearable" *ngIf="existingPatient">
              <app-basic-input [formGroup]="patientFormGroup" controlName="name" label="Nome"></app-basic-input>
              <button class="btn btn-link" (click)="unsetPatient()">Alterar</button>
            </div>
          </div>

          <div class="col-sm-4 col-md-5 mb-4 px-2">
            <app-basic-input id="display_name" [formGroup]="patientFormGroup" controlName="display_name"
              label="Nome de Tratamento">
            </app-basic-input>
          </div>

          <div class="col-sm-3 col-md-2 p-0 mb-4">
            <label class="field__title">Gênero</label>

            <div class="radio-option">
              <label for="gender_male"><input type="radio" name="gender" formControlName="gender" value="MALE"
                  id="gender_male" /> Masculino</label>
            </div>

            <div class="radio-option">
              <label for="gender_female"><input type="radio" name="gender" formControlName="gender" value="FEMALE"
                  id="gender_female" /> Feminino</label>
            </div>
          </div>

          <div class="col-sm-3 col-md-2 px-2 mb-4">
            <app-basic-input id="birthdate" [formGroup]="patientFormGroup" controlName="birthdate"
              label="Data de nascimento" type="date">
            </app-basic-input>
          </div>

          <div class="col-sm-3 px-2 mb-2">
            <app-basic-input id="document_number" controlName="document_number" [formGroup]="patientFormGroup"
              label="CPF" [maskOptions]="{mask: '000.000.000-00'}"></app-basic-input>
          </div>

          <div class="col-sm-3 col-md-5 px-2 mb-4">
            <app-basic-input id="email" [formGroup]="patientFormGroup" controlName="email" label="E-mail">
            </app-basic-input>
          </div>

          <div class="col-12 px-0 mb-2" formArrayName="phone_numbers">
            <div class="row">
              <div class="p-0 col-12 titlebar">
                <h3>Telefone</h3>
              </div>

              <ng-container *ngFor="let phoneNumber of phoneNumberForms.controls; index as i">
                <div class="row p-0 col-12" [class]="{'d-none': !phoneNumberIsActive(i)}">
                  <div class="col-6 p-0 mb-3">
                    <phone-number-input [formGroup]="getPhoneNumberForm(i)"></phone-number-input>
                    <div class="px-2 col-1 mb-0 text-center whatsapp"
                      *ngIf="getPhoneNumberForm(i).get('type') && getPhoneNumberForm(i).get('type')!.value == 'WHATSAPP'">
                      <a class="whatsapp-link" target="_blank"
                        href="https://wa.me/{{getPhoneNumberForm(i).get('country_code')!.value}}{{getPhoneNumberForm(i).get('phone_number')!.value}}">
                        <span class="icon icon-Icon-awesome-whatsapp"></span>
                      </a>
                    </div>
                  </div>


                  <div class="px-2 col-4 mb-3" [formGroup]="getPhoneNumberForm(i)">
                    <label class="field__title"></label>

                    <app-select-input [formGroup]="getPhoneNumberForm(i)" [options]="phoneTypeList" id="type"
                      [clearable]="true" [searchable]="false"></app-select-input>
                  </div>

                  <div class="px-2 col-2">
                    <button (click)="removePhoneNumber(i)" [disabled]="formGroup.disabled"
                      class="remove-button">Remover</button>
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="row">
              <div class="row">
                <div class="col-12">
                  <button class="btn btn-link" [disabled]="formGroup.disabled" (click)="addPhoneNumber()">Adicionar
                    novo</button>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 px-2 mb-2 input-basic" formArrayName="health_insurances">
            <div class="row">
              <div class="col-4 p-0 pr-2 m-0">
                <label class="field__title font-weight-strong">Convênio</label>
              </div>

              <div class="col-3 px-2 m-0">
                <label class="field__title">Plano</label>
              </div>

              <div class="col-3 p-0 pl-2 m-0">
                <label class="field__title">Número do convênio</label>
              </div>
            </div>
            <div class="row">
              <ng-container *ngFor="let healthInsuranceForm of healthInsuranceForms.controls; index as i">
                <div class="row col-12 p-0 mb-3" [formGroupName]="i" [class]="{'d-none': !healthInsuranceIsActive(i)}">
                  <div class="p-0 m-0 col-4 pr-2">
                    <app-select-input [formGroup]="getHealthInsuranceForm(i)" [options]="healthInsurancesListAll"
                      id="health_insurance_id" [clearable]="true" (onValueChange)="changeHealthInsurancePlans($event)"
                      [searchable]="false"></app-select-input>
                  </div>

                  <div class="m-0 col-3 px-2">
                    <app-select-input [formGroup]="getHealthInsuranceForm(i)" [options]="getHealthInsurancePlansFrom(i)"
                      id="health_insurance_plan" [clearable]="true"></app-select-input>
                  </div>

                  <div class="m-0 col-3 px-2">
                    <app-basic-input class="custom" [formGroup]="getHealthInsuranceForm(i)" id="number"
                      controlName="number"></app-basic-input>
                  </div>

                  <div class="col-2 p-0 pl-2">
                    <button (click)="removeHealthInsurance(i)" class="remove-button">Remover</button>
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="row">
              <div class="row">
                <div class="col-12 p-0 m-0">
                  <button class="btn btn-link" (click)="addHealthInsurance()">Adicionar novo</button>
                </div>
              </div>
            </div>
          </div>

          <div class="row col-12 p-0 m-0 mb-4 d-flex justify-content-center">
            <button class="col-12 p-0 m-0 btn viewMore" (click)="viewMore = !viewMore">
              <span *ngIf="!viewMore">Expandir</span>
              <span *ngIf="viewMore">Diminuir</span>
              <i
                [ngClass]="viewMore ? 'icon-Icon-awesome-caret-down ordering-indicator-reverse' : 'icon-Icon-awesome-caret-down ordering-indicator'"></i></button>

          </div>

          <div *ngIf="viewMore">
            <div class="row p-0 m-0">
              <div class="p-0 m-0 pr-2 col-6">
                <app-select-input label="Estado civil" [formGroup]="patientFormGroup" [options]="maritalStatusList"
                  id="marital_status" [searchable]="false"></app-select-input>
              </div>

              <div class="p-0 pl-2 m-0 col-6">
                <app-select-input label="Profissão" [formGroup]="patientFormGroup" [options]="professionsList"
                  id="profession" [searchable]="true" [addTag]="true"></app-select-input>
              </div>
            </div>

            <div class="row p-0 m-0 mb-2" [formGroup]="patientFormGroup">
              <h3 class="my-3 p-0 col-12">Tags</h3>

              <div class="col-12 p-0 m-0">
                <app-select-input label="Estado civil" [formGroup]="patientFormGroup" [options]="patientTagsList"
                  id="marital_status" [multiple]="true" [searchable]="false" [clearable]="false"
                  [addTag]="true"></app-select-input>
              </div>
            </div>

            <div class="row p-0 m-0 mb-2 input-basic" [formGroup]="patientFormGroup">
              <h3 class="my-3 p-0 col-12">Filiação</h3>

              <div class="p-0 pr-2 m-0 col-6">
                <app-basic-input id="mother_name" [formGroup]="patientFormGroup" label="Nome da mãe"
                  controlName="mother_name">
                </app-basic-input>
              </div>

              <div class="p-0 pl-2 m-0 col-6">
                <app-basic-input id="father_name" [formGroup]="patientFormGroup" label="Nome da pai"
                  controlName="father_name">
                </app-basic-input>
              </div>
            </div>

            <div class="row p-0 m-0 mb-2 input-basic" [formGroup]="addressFormGroup">
              <h3 class="my-3 p-0 col-12">Endereço</h3>

              <div class="p-0 pr-2 mb-3 col-md-2 zipcode">
                <app-basic-input id="zipcode" [formGroup]="addressFormGroup" label="CEP" controlName="zipcode"
                  [maskOptions]="{mask: '00.000-000'}" (onValueChange)="handleZipCodeChange()">
                </app-basic-input>
                <span *ngIf="searchZipcode" class="spinner-border"></span>
              </div>
              <div class="py-0 mb-3 px-2 col-md-8">
                <app-basic-input id="address" [formGroup]="addressFormGroup" label="Endereço" controlName="address">
                </app-basic-input>
              </div>
              <div class="p-0 pl-2 mb-3 col-md-2">
                <app-basic-input id="address_number" [formGroup]="addressFormGroup" label="Número" controlName="number">
                </app-basic-input>
              </div>
              <div class="p-0 pr-2 m-0 col-md-3">
                <app-basic-input id="complement" [formGroup]="addressFormGroup" label="Complemento"
                  controlName="complement"></app-basic-input>
              </div>
              <div class="px-2 m-0 col-md-3">
                <app-basic-input id="neighborhood" [formGroup]="addressFormGroup" label="Bairro"
                  controlName="neighborhood">
                </app-basic-input>
              </div>
              <div class="px-2 m-0 col-md-3">
                <app-basic-input id="city" [formGroup]="addressFormGroup" label="Cidade" controlName="city">
                </app-basic-input>
              </div>
              <div class="px-0 pl-2 m-0 col-md-3">
                <app-basic-input id="state" [formGroup]="addressFormGroup" label="Estado" controlName="state">
                </app-basic-input>
              </div>
            </div>

            <div class="row p-0 m-0 mb-2" [formGroup]="patientFormGroup">
              <h3 class="my-3 p-0 col-12">Indicação</h3>
              <div class="p-0 pr-2 col-6 mb-3">
                <app-select-input label="Clínica" [formGroup]="patientFormGroup" [options]="clinicsListOptions"
                  id="clinic" [searchable]="false"
                  (onValueChange)="getProfessionalsFromClinic($event)"></app-select-input>
              </div>

              <div class="p-0 pl-2 col-6 mb-3">
                <app-select-input label="Profissional" [formGroup]="patientFormGroup" [options]="professionalsList"
                  id="professional" [searchable]="false"></app-select-input>
              </div>

              <div class="p-0 col-12">
                <app-textarea-input label="Observações" [rows]="3" id="notes" controlName="notes"
                  [formGroup]="patientFormGroup"></app-textarea-input>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row col-12 separator">
      <div class="card p-0 m-0">
        <ul class="nav nav-tabs mb-3">
          <li class="nav-item">
            <button (click)="adminTabHandler()" class="nav-link"
              [ngClass]="{'active' : adminTabIsActive}">Administrativo</button>
          </li>
          <li class="nav-item">
            <button (click)="medicalOrderTabHandler()" class="nav-link"
              [ngClass]="{'active' : medicalOrderTabIsActive}">Pedidos Médicos</button>
          </li>
        </ul>
        <div class="row card-content">
          <div class="col-12 p-0 m-0 col-md-9">

            <!-- Admin Tab -->
            <div *ngIf="adminTabIsActive">
              <div [formGroup]="formGroup" class="half_width">
                <h3 class="my-3 p-0 col-12">Profissional Solicitante</h3>

                <app-select-input [formGroup]="formGroup" [options]="allProfessionalsList"
                  id="document_requesting_professional" [clearable]="true" [searchable]="false"></app-select-input>
              </div>

              <div class="row align-center selector d-flex p-0 m-0 mb-1">
                <h3 class="my-3 p-0 col-3">Procedimentos</h3>
                <input class="mr-2" (click)="toggleProcedureList()"
                  [checked]="procedureForms.value.length == proceduresList.length" type="checkbox" id="select-all">
                <label class="m-0" for="select-all"> Selecionar todos </label>
              </div>


              <div *ngFor="let procedure of proceduresList" class="row p-0 m-0 mb-2">
                <div class="col-12 selector d-flex p-0 m-0 mb-1">
                  <input class="mr-2" [id]="'procedures_'+procedure.id" (click)="toggleProcedure(procedure)"
                    type="checkbox" [checked]="procedureIsSelected(procedure)">
                  <label class="align-self-center" [for]="'procedures_'+procedure.id">
                    {{procedure.name}} (TUSS {{procedure.code}})
                  </label>
                </div>

                <div class="col-12 p-0 m-0">
                  <ng-container *ngIf="procedureIsSelected(procedure)"
                    [formGroup]="getProcedureFormFromItem(procedure)">
                    <div class="procedure-dates d-flex" formArrayName="dates">
                      <div class="col-12 d-flex p-0 m-0">
                        <div class="row p-0 m-0">
                          <p class="align-self-center d-flex m-0">- Realizado em</p>
                          <div *ngFor="let date of getDatesForm(procedure).controls; index as i">
                            <div class="d-flex justify-content-start p-0 m-0 mb-1">
                              <div class="input-group">
                                <input class="mx-2" type="date" [formControlName]="i">
                                <span>
                                  <button (click)="removeDate(procedure, i)">X</button>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="ml-2 mb-1">
                            <button class="btn btn-primary btn-lg" (click)="addDate(procedure)">+</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>

            <!-- Medical Order -->
            <div *ngIf="medicalOrderTabIsActive">

              <div [formGroup]="documentsForm" class="half_width">
                <h3 class="my-3 p-0 col-12">Profissional Solicitante</h3>
                <app-select-input [formGroup]="documentsForm" [options]="allProfessionalsList" id="professional"
                  [searchable]="false" [clearable]="true" (onValueChange)="getDocumentsByProfessional()"></app-select-input>
              </div>

              <div *ngIf="!documentsForm.get('professional')?.value">
                <h3 class="my-3 p-0">Selecione um profissional</h3>
              </div>

              <div *ngIf="documentsForm.get('professional')?.value && documentList.length && !formLoadingDocument">
                <div class="row align-center selector d-flex p-0 m-0 mb-1" *ngIf="thereIsDocuments">
                  <h3 class="my-3 p-0 col-3">Pedidos Médicos</h3>
                  <input class="mr-2" (click)="toggleDocumentsList()"
                    [checked]="documentsForms.value.length == documentList.length" type="checkbox" id="select-all">
                  <label class="m-0" for="select-all"> Selecionar todos </label>
                </div>
              </div>

              <div *ngIf="!documentList.length && documentsForm.get('professional')?.value && !formLoadingDocument">
                <h3 class="my-3 p-0">Esse profissional não tem nenhum Pedido Médico cadastrado.</h3>
              </div>

              <div class="spinner-border spinner-border-xl mt-4" role="status"
                *ngIf="documentsForm.get('professional')?.value && formLoadingDocument">
              </div>

              <div *ngFor="let document of documentList; index as i" class="row p-0 m-0 mb-2">
                <div class="col-12 selector d-flex p-0 m-0 mb-1" *ngIf="!formLoadingDocument">
                  <input class="mr-2" type="checkbox" [id]="'document_'+document.id" (click)="toggleDocument($event)"
                    [value]="document.id" [checked]="documentIsSelected(document)">
                  <label class="align-self-center" [for]="'document_'+document.id">
                    {{document.name}}
                  </label>
                </div>

                <div class="col-12 p-0 m-0" *ngIf="documentIsSelected(document)"
                  [formGroup]="getDocumentForm(document)">
                  <div class="procedure-dates d-flex">
                    <div class="col-12 d-flex p-0 m-0">
                      <div class="row p-0 m-0">
                        <p class="align-self-center d-flex m-0">- Data do pedido</p>
                        <div class="d-flex justify-content-start p-0 m-0 mb-1">
                          <input class="mx-2" type="date" formControlName="date">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-3 justify-content-end ">
            <div class="row">
              <div class="col-12">
                <h3 class="my-3 p-0 col-12">Emitir documentos</h3>
              </div>
            </div>

            <div class="row" *ngIf="adminTabIsActive">
              <div class="col-12 mb-3">
                <button (click)="checkFormAndEmitDocument('reception')" class="btn btn-outline-primary btn-lg"
                  type="button" [disabled]="emitDocumentsReceptionLoading">
                  Recepção
                  <span class="spinner-border" *ngIf="emitDocumentsReceptionLoading"></span>
                </button>
              </div>
            </div>

            <div class="row" *ngIf="adminTabIsActive">
              <div class="col-12">
                <button (click)="checkFormAndEmitDocument('refund')" class="btn btn-outline-primary btn-lg"
                  type="button" [disabled]="emitDocumentsRefundLoading">
                  Reembolso
                  <span class="spinner-border" *ngIf="emitDocumentsRefundLoading"></span>
                </button>
              </div>
            </div>

            <div class="row" *ngIf="medicalOrderTabIsActive">
              <div class="col-12">
                <button class="btn btn-outline-primary btn-lg" (click)="checkFormAndEmitMedicalOrder()" type="button"
                  [disabled]="emitDocumentsLoading || emitedDocuments || !documentList.length">
                  <span *ngIf="!emitedDocuments"> Pedido Médico</span>
                  <span *ngIf="emitedDocuments">Documento emitido</span>
                  <span class="spinner-border" *ngIf="emitDocumentsLoading"></span>
                </button>
              </div>
            </div>
            <div class="col-12 selector d-flex p-0 m-0 justify-content-center mt-2" *ngIf="medicalOrderTabIsActive">

              <input class="mr-2" id="downloadEnabled" type="checkbox"
                [disabled]="emitDocumentsLoading || emitedDocuments || !documentList.length"
                [(ngModel)]="downloadMedicalOrderDocuments">
              <label for="downloadEnabled">Download imediato</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row col-12 separator" [formGroup]="formGroup">
      <div class="card">
        <div class="row">
          <div class="px-2 col-12">
            <app-textarea-input label="Observações" [rows]="3" id="notes" controlName="notes"
                  [formGroup]="formGroup"></app-textarea-input>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-end mt-5 mb-5 buttons">
      <div class="col-12 col-md-3">
        <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
          Cancelar
        </button>
      </div>

      <div class="col-12 col-md-3" *ngIf="!isRegister">
        <button (click)="removeHandler()" [disabled]="submittingRemove" class="btn btn-secondary btn-lg" type="button">
          {{removeButton}}
          <span class="spinner-border" *ngIf="submittingRemove"></span>
        </button>
      </div>

      <div class="col-12 col-md-3" *ngIf="!isRegister">
        <button (click)="submitHandler()" [disabled]="submitting" class="btn btn-primary btn-lg" type="button">
          {{submitButton}}
          <span class="spinner-border" *ngIf="submitting"></span>
        </button>
      </div>

      <div class="col-12 col-md-3" *ngIf="isRegister">
        <button (click)="submitHandler()" [disabled]="submittingPatient" class="btn btn-primary btn-lg" type="button">
          Salvar e ir para agenda
          <span class="spinner-border" *ngIf="submitting"></span>
        </button>
      </div>

      <div class="col-12 col-md-3" *ngIf="isRegister">
        <button (click)="submitHandler('patient')" [disabled]="submitting" class="btn btn-tertiary btn-lg"
          type="button">
          Salvar e ir para paciente
          <span class="spinner-border" *ngIf="submittingPatient"></span>
        </button>
      </div>
    </div>

    <div *ngIf="!formLoadingPatient" class="loadingPatient">
      <div class="spinner-border spinner-border-xl" role="status">
      </div>
      <span class="text">Carregando Paciente</span>
    </div>

  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
