import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {TagModel} from "../models/tag.model";
import {environment} from "../../../environments/environment";
import {ProfessionModel} from "../models/profession.model";
import {ListModel} from "../models/list.model";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ProfessionsService {
  constructor(private httpClient: HttpClient) { }

  get(professionId: number): Observable<TagModel> {
    return this.httpClient.get<TagModel>(`${environment.API_URL}/professions/${professionId}/`)
  }

  register(professionData: ProfessionModel): Observable<ProfessionModel> {
    return this.httpClient.post<ProfessionModel>(`${environment.API_URL}/professions/`, JSON.stringify(professionData))
  }

  edit(professionData: ProfessionModel): Observable<ProfessionModel> {
    return this.httpClient.patch<ProfessionModel>(`${environment.API_URL}/professions/${professionData.id}/`, JSON.stringify(professionData))
  }

  remove(professionId: number) {
    return this.httpClient.delete(`${environment.API_URL}/professions/${professionId}/`)
  }

  list(searchString: string = "", orderingItem: string = "name", isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/professions/?search=${searchString}&ordering=${orderingItem}&is_active=${isActive}&page=${page}`);
  }

  listAll(): Observable<ProfessionModel[]> {
    return this.httpClient.get<ProfessionModel[]>(`${environment.API_URL}/professions/all/?ordering=name&is_active=true`);
  }
}
