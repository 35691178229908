<div class="protocol-modal-wrapper">
  <div class="row p-0 justify-content-end">
    <button class="btn btn-closed col-1" (click)="activeModal.close(false)">
      <i-bs name="x-lg" width="2rem" height="2rem"></i-bs>
    </button>
  </div>
  <div class="protocol-modal" *ngIf="modalItems else loadingTemplate">
    <div class="col-12 m-0 p-0">
      <span class="h2">Protocolo - {{title}}</span> <br />
      <div *ngIf="modalItems.items?.length > 0 && !addNewProtocol">
        <div class="row p-0 m-0 border-bottom mt-4">
          <div class="col-3 p-0 m-0 border-right">
            <p class="font-weight-bold m-0 ml-2">DATA</p>
          </div>
          <div class="col-9 p-0 m-0 d-flex justify-content-center">
            <p class="font-weight-bold m-0">PROTOCOLO</p>
          </div>
        </div>
        <div *ngFor="let protocol of modalItems.items">
          <div class="row p-0 m-0 border-bottom">
            <div class="col-3 p-0 m-0 border-right">
              <span class="notes">{{getDate(protocol)}}</span>
            </div>
            <div class="col-9 p-0 m-0 d-flex justify-content-center">
              <span class="notes">{{protocol.protocol}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 m-0 p-0 mt-4" *ngIf="addNewProtocol">
      <div class="col-12 p-0 m-0">
        <table class="col-12">
          <thead class="table-head">
            <th class="col-2 p-0 m-0">DATA</th>
            <th class="col-9">PROTOCOLO</th>
            <th class="col-1 p-0 m-0"></th>
          </thead>
          <tbody>
            <tr *ngFor="let controls of protocolForm.controls, let i = index" [formGroup]="getProtocolForm(i)" [ngClass]="getIsActive(i) ? '' : 'd-none'">
                <td class="col-2 p-0 m-0">
                  <input type="date" formControlName="date_time" id="date_time"
                    [ngClass]="getProtocolForm(i).get('date_time')!.errors && getProtocolForm(i).get('date_time')!.touched ? 'errorInput' : ''">
                  <app-form-error [isModalReport]="true" id="date_time" [formGroup]="getProtocolForm(i)"
                    controlName="date_time">
                  </app-form-error>
                </td>
                <td class="col-9">
                  <app-basic-input controlName="protocol" id="protocol" [formGroup]="getProtocolForm(i)">
                  </app-basic-input>
                </td>
                <td class="col-1 p-0 m-0">
                  <button class="btn btn-link" (click)="removeProtocol(i)">
                    <i-bs name="x-lg" width="2rem" height="2rem"></i-bs>
                  </button>
                </td>
            </tr>
          </tbody>
        </table>
        <button class="btn btn-primary col-4 mt-2" (click)="addProtocol()">+ Adicionar
          protocolo</button>
      </div>
      <div class="row mt-4 justify-content-end">
        <button class="btn btn-link btn-lg col-3 " (click)="activeModal.close(false)">Cancelar</button>
        <button class="btn btn-primary col-3" [disabled]="submitting" (click)="submitHandler()">
          Salvar
          <span class="spinner-border" *ngIf="submitting"></span>
        </button>
      </div>
    </div>
  </div>
  <ng-template #loadingTemplate>
    <div class="col card full-loading text-center">
      <div class="spinner-border spinner-border-xl" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-template>
</div>
