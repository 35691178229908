import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AccountModel } from 'src/app/core/models/account.model';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { DefaultModalComponent } from 'src/app/shared/renderers/components/default-modal/default-modal.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loading: boolean = false;
  loginForm!: FormGroup;

  constructor(private accountsService: AccountsService, private sessionManager: SessionManagerService, private router: Router, private toast: ToastrService) { }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      "email": new FormControl(null, [Validators.required, Validators.email]),
      "password": new FormControl(null, [Validators.required, Validators.minLength(3)]),
    });
  }

  onSubmit(e: Event): void {
    this.loading = true;
    this.loginRequest();
    e.preventDefault();
  }

  loginRequest() {
    var accountData = {
      email: this.loginForm.get('email')!.value,
      password: this.loginForm.get('password')!.value
    } as AccountModel;

    this.accountsService.login(accountData).subscribe((response: AccountModel) => {
      this.sessionManager.setSessionData(response);
      this.router.navigate(["/dashboard"]);
      this.loading = false;
    }, (error: HttpErrorResponse) => {
      this.loading = false;
      this.mapErrorResponse(error);
    });
  }

  mapErrorResponse(error: HttpErrorResponse) {
    if(error.error["detail"]) {
      this.toast.error(error.error["detail"], "Erro");
    } else {
      let errNames = [
        "email", "password"
      ];
      errNames.forEach(name => {
        console.log(error.error[name])
        if (error.error[name])
          this.loginForm.get(name)?.setErrors({ response: error.error[name] });
      });
    }
  }


  passwordRecoveryLinkClickHandler() {
    this.router.navigate(['passwordRecovery']);
  }
}
