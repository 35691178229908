import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AccountModel } from 'src/app/core/models/account.model';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { PermissionModel } from 'src/app/core/models/permission.model';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { ProfessionalsService } from 'src/app/core/services/professionals.service';
import { RegistersService } from 'src/app/core/services/registers.service';
import { ScheduleService } from 'src/app/core/services/schedules.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { OptionsSelectModel } from 'src/app/shared/models/option';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';
import { FieldValidator } from 'src/app/shared/utils/field-validator';

@Component({
  selector: 'app-system-users-form',
  templateUrl: './system-users-form.component.html',
  styleUrls: ['./system-users-form.component.scss']
})
export class SystemUsersFormComponent implements OnInit {

  formGroup = this.fb.group({
    id: [],
    name: ['', [Validators.required, Validators.minLength(3)]],
    email: ['', [Validators.required, Validators.email]],
    birthdate: ['', [Validators.required]],
    document_number: ['', [Validators.required, FieldValidator.documentValidator()]],
    user_permissions: this.fb.array([]),
    professional_id: [],
    schedule_permissions: this.fb.array([]),
  });

  passwordRecover: boolean = false;

  imageFile!: File | null;
  changedImage: boolean = false;

  isRegister: boolean = true;

  submitting: boolean = false;
  submittingRemove: boolean = false;
  submitButton?: String = "Salvar";
  removeButton?: String = "Desativar";
  button: boolean = false;

  userPermissions!: PermissionModel[];
  loadingPermissions: boolean = true;
  isSelectAll: boolean = false;
  disabledSelectAll: boolean = false;
  isCurrentUser: boolean = false;
  disabledPermissions: string[] = [];
  fetchingPermissions: boolean = false;

  professionalsList: OptionsSelectModel[] = [];
  scheduleList: OptionsSelectModel[] = [];
  attendanceTypeList: OptionsSelectModel[] = [];

  id!: number;
  existingAccountData: AccountModel = {};
  fetchingAccount: boolean = false;

  constructor(@Inject(DOCUMENT) private document: Document, private fb: FormBuilder,
    private accountsService: AccountsService, private router: Router, private activatedRoute: ActivatedRoute,
    private modalService: NgbModal, private sessionManager: SessionManagerService,
    private toast: ToastrService, private professionalService: ProfessionalsService,
    private scheduleService: ScheduleService, private registerService: RegistersService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params["id"];
      if (this.id) {
        this.isRegister = false;
      }
    });
    this.initializeForm();
  }

  get loadingPage(): boolean {
    let fetchLists = this.scheduleList && this.professionalsList;
    if (this.fetchingPermissions && fetchLists) {
      if (this.isRegister) {
        return true;
      } else {
        if (this.fetchingAccount) return true;
      }
    }
    return false;
  }

  get canSave() {
    if (this.id) {
      if (this.isCurrentUser) {
        return true;
      }
      return this.sessionManager.getUserPermission(PermissionConst.change_account);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.add_account);
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_account);
  }

  initializeForm() {
    this.fetchUserPermissions();
    this.fetchSchedulesList();
    this.fetchProfessionalsList();
    if (this.id) setTimeout(() => this.fetchAccountExistingData(), 1000);
  }

  fetchSchedulesList() {
    this.scheduleService.all().subscribe(response => {
      this.scheduleList = response.map(item => {
        return {
          label: item.name,
          value: item.id?.toString(),
          item: item
        } as OptionsSelectModel;
      });
    });
  }

  fetchProfessionalsList() {
    this.professionalService.listAllAssociatedWithAccount().subscribe(response => {
      this.professionalsList = response.map(item => {
        return {
          label: `${item.name} - CRO ${item.cro}`,
          value: item.id!.toString()
        } as OptionsSelectModel;
      });
    });
  }

  redefinePassword() {
    this.router.navigate([`/dashboard/registers/systemUsers/redefine_password/${this.id}`]);
  }

  get scheduleForms() {
    return this.formGroup.get('schedule_permissions') as FormArray;
  }

  getFormSchedule(index: number) {
    return this.scheduleForms.controls[index] as FormGroup;
  }

  addSchedule() {
    let scheduleFormGroup = this.fb.group({
      id: [],
      schedule: ['', [Validators.required]],
      attendance_types: [this.attendanceTypeList.map(el => el.value), [Validators.required]],
      is_active: [true]
    });
    this.scheduleForms.push(scheduleFormGroup);
  }

  removeSchedule(at: number) {
    this.scheduleForms.removeAt(at);
  }

  scheduleIsActive(index: number) {
    let formGroup = this.scheduleForms.controls[index] as FormGroup;
    return formGroup.get('is_active')?.value;
  }

  changeAttendanceType(formGroupIndex: number) {
    let formGroup = this.scheduleForms.controls[formGroupIndex] as FormGroup;
    let schedule = formGroup.get('schedule')?.value;
    if (schedule) {
      this.registerService.attendancesTypeAll().subscribe((response) => {
        this.attendanceTypeList = response.map(item => {
          return {
            label: item.name,
            value: item.id?.toString()
          } as OptionsSelectModel
        });
      });
    } else this.attendanceTypeList = [];
  }

  onSelectAll(formGroupIndex: number) {
    let formGroup = this.scheduleForms.controls[formGroupIndex] as FormGroup;
    const selected = this.attendanceTypeList.map(item => item.value);
    formGroup.get('attendance_types')?.patchValue(selected);
  }

  onImageSelect(file: any) {
    this.imageFile = file;
    this.changedImage = true;
  }

  cancelHandler() {
    this.router.navigate(['dashboard/registers/systemUsers']);
  }

  submitHandler() {
    this.submitting = true;
    this.formGroup.markAllAsTouched();
    let accountData = this.formGroup.getRawValue() as AccountModel;
    if (this.isRegister) {
      this.systemUserRegister(accountData);
    } else {
      this.systemUserEdit(accountData);
    }
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar este usuário?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.systemUserRemove();
      }
      this.submittingRemove = false;
    })
  }

  systemUserRegister(accountData: AccountModel) {
    let errorResponse = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    };
    this.accountsService.accountRegister(accountData).subscribe(response => {
      if (this.changedImage) {
        this.accountsService.uploadImage(this.imageFile!, response.id!).subscribe(() => { }, errorResponse);
      }
      this.toast.success("Usuário criado com sucesso", "Sucesso");
      this.submitting = false;
      this.cancelHandler();
    }, errorResponse);
  }

  systemUserEdit(accountData: AccountModel) {
    let errorResponse = (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    };
    accountData.is_active = true;
    this.accountsService.accountEdit(accountData).subscribe(response => {
      if (this.changedImage) {
        this.accountsService.uploadImage(this.imageFile!, response.id!).subscribe(() => { }, errorResponse);
      }
      let currentUser = this.sessionManager.accountData.id;
      if (response.id == currentUser) {
        this.sessionManager.setSessionData(response);
      }
      this.toast.success("Usuário alterado com sucesso", "Sucesso");
      this.submitting = false;
      this.cancelHandler();
    });
  }

  systemUserRemove() {
    this.accountsService.accountRemove(this.id).subscribe(response => {
      this.toast.success("Usuário removido com sucesso", "Sucesso");
      this.submittingRemove = false;
      this.cancelHandler();
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submittingRemove = false;
    });
  }

  get permissionsMap() {
    let mapping = {};
    this.userPermissions.map((permission) => {
      if (permission.id)
        Object.assign(mapping, { [permission.id]: permission.codename });
    });
    return mapping;
  }

  groupBy(arr: any[], property: string) {
    return arr.reduce((acc, cur) => {
      acc[cur[property]] = [...acc[cur[property]] || [], cur];
      return acc;
    }, {});
  }

  get permissionModels() {
    return Object.keys(this.groupBy(this.userPermissions, 'model'));
  }

  getPermissions(name: string): [] {
    return this.groupBy(this.userPermissions, 'model')[name];
  }

  onCheckChange(event: any) {
    let userPermissions: FormArray = this.formGroup.get('user_permissions') as FormArray;
    if (event.target.checked) {
      userPermissions.push(this.fb.control(event.target.value));
      this.enableCheckbox(event.target.id);
    } else {
      let controls = userPermissions.controls;
      userPermissions = this.fb.array(controls.filter(control => control.value != event.target.value));
      this.formGroup.setControl('user_permissions', userPermissions);
      this.disableCheckbox(event.target.id);
    }
  }

  isDisabled(permission: PermissionModel) {
    if (this.existingAccountData) {
      if (!(this.existingAccountData.is_active && (this.isCurrentUser || this.sessionManager.getUserPermission(PermissionConst.change_account)))) {
        return true;
      }
    } else if (!this.sessionManager.getUserPermission(PermissionConst.change_account)) {
      return true;
    }
    return this.disabledPermissions.some(codename => codename == permission!.codename);
  }

  isChecked(permission: PermissionModel) {
    let formArray = this.formGroup.get('user_permissions') as FormArray;
    return formArray.value.some((id: number) => id == permission.id);
  }

  disableCheckbox(codename: string) {
    let permissionModel = codename.split('_')[1];
    let userPermissions: FormArray = this.formGroup.get('user_permissions') as FormArray;
    let controls = userPermissions.controls;

    if (codename.includes('view')) {
      this.disabledPermissions.push(`change_${permissionModel}`);
      this.disableCheckbox(`change_${permissionModel}`);
      this.disabledPermissions.push(`export_${permissionModel}`);
      this.disableCheckbox(`export_${permissionModel}`);
    } else if (codename.includes('change')) {
      this.disabledPermissions.push(`add_${permissionModel}`);
      this.disableCheckbox(`add_${permissionModel}`);
    } else if (codename.includes('add')) {
      this.disabledPermissions.push(`remove_${permissionModel}`);
      this.disableCheckbox(`remove_${permissionModel}`);
    }

    this.disabledPermissions.forEach(codename => {
      let index = Object.values(this.permissionsMap).indexOf(codename);
      let id = Object.keys(this.permissionsMap)[index];
      controls = controls.filter(control => control.value != id);
    });
    userPermissions = this.fb.array(controls);
    this.formGroup.setControl('user_permissions', userPermissions);
  }

  enableCheckbox(codename: string) {
    let permissionModel = codename.split('_')[1];

    if (codename.includes('view')) {
      this.disabledPermissions = this.disabledPermissions.filter(_codename => _codename != `change_${permissionModel}`);
      this.disabledPermissions = this.disabledPermissions.filter(_codename => _codename != `export_${permissionModel}`);
    } else if (codename.includes('change')) {
      this.disabledPermissions = this.disabledPermissions.filter(_codename => _codename != `add_${permissionModel}`);
    } else if (codename.includes('add')) {
      this.disabledPermissions = this.disabledPermissions.filter(_codename => _codename != `remove_${permissionModel}`);
    }
  }

  toggleAllPermissions(event: any) {
    if (!this.disabledSelectAll) {
      let userPermissions: FormArray = this.formGroup.get('user_permissions') as FormArray;
      if (event.target.checked) {
        this.userPermissions.forEach((permission) => {
          userPermissions.push(this.fb.control(permission.id));
          this.enableCheckbox(permission.codename ? permission.codename : '');
        });
      } else {
        userPermissions = this.fb.array([]);
        this.formGroup.setControl('user_permissions', userPermissions);
        this.userPermissions.forEach((permission,) => {
          this.disableCheckbox(permission.codename ? permission.codename : '');
        });
      }
    }
  }

  fetchUserPermissions() {
    this.accountsService.permissionsList().subscribe(response => {
      this.userPermissions = response;
      let userPermissions: FormArray = this.formGroup.get('user_permissions') as FormArray;
      this.userPermissions.forEach(permission => {
        userPermissions.push(this.fb.control(permission.id));
        this.enableCheckbox(permission.codename ?? '');
      });
      this.fetchingPermissions = true;
    }, error => this.mapErrorResponse(error as HttpErrorResponse));
  }

  fetchAccountExistingData() {
    this.accountsService.accountGet(this.id).subscribe(response => {
      this.existingAccountData = response;
      this.isCurrentUser = this.id == this.sessionManager.accountData.id;
      this.passwordRecover = this.isCurrentUser;
      this.formGroup.patchValue(this.existingAccountData);
      let permissionControls = this.fb.array([]);
      this.existingAccountData.user_permissions?.forEach(value => {
        let control = this.fb.control(value);
        permissionControls.push(control);
        let codename = PermissionConst[value];
        this.enableCheckbox(codename);
      });
      this.isSelectAll = response.user_permissions?.length == this.userPermissions?.length;
      if (this.existingAccountData.professional) {
        let currentProfessional = {
          label: `${response.professional?.name} - CRO ${response.professional?.cro}`,
          value: response.professional?.id!.toString()
        };
        this.professionalsList.push(currentProfessional);
      }
      this.formGroup.setControl('user_permissions', permissionControls);
      response.schedule_permissions?.forEach(() => this.addSchedule());
      this.formGroup.patchValue(response);
      response.schedule_permissions?.forEach((schedule, index) => {
        let formGroup = this.scheduleForms.controls[index] as FormGroup;
        this.changeAttendanceType(index);
        formGroup.get('attendance_types')?.setValue(schedule.attendance_types.map((item: any) => { return item.toString() }));
      });

      this.formGroup.disable();
      this.scheduleForms.disable();
      this.disabledSelectAll = false;

      if (this.existingAccountData.is_active) {
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
        this.button = true;
        this.formGroup.enable();
        this.scheduleForms.enable();
        this.disabledSelectAll = false;
      } else {
        this.button = false;
        this.submitButton = "Reativar";
        this.disabledSelectAll = true;
      }

      if (this.fetchingPermissions) {
        this.fetchingAccount = true;
      }
    }, error => this.mapErrorResponse(error as HttpErrorResponse));
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    this.document.getElementById('main-container')?.scroll({ top: 0 });
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    } else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "name", "birthdate", "email", "document_number", "professional_id"
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
    });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    // if (this.formGroup && (!this.formGroup.dirty)) {
    //     const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    //     modalRef.componentInstance.text = 'As alterações no formulário não foram salvas e serão descartadas, deseja prosseguir?';
    //     return modalRef.result
    // } else
    return true;
  };

}
