import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { RootNavigationComponent } from './root-nav/root-nav.component';
import { ListNavigationComponent } from './list-nav/list-nav.component';
import { SidebarModule } from 'ng-sidebar';
import { HeaderComponent } from './components/header/header.component';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';

@NgModule({
  declarations: [
    RootNavigationComponent,
    ListNavigationComponent,
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    SidebarModule,
    NgxBootstrapIconsModule.pick(allIcons)
  ],
  exports: [
    ListNavigationComponent,
    HeaderComponent
  ]
})
export class NavigationModule { }
