import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HealthInsurancesListComponent } from './health-insurances-list/health-insurances-list.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { HealthInsurancesFormComponent } from './health-insurances-form/health-insurances-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [
    HealthInsurancesListComponent,
    HealthInsurancesFormComponent
  ],
  imports: [
    CommonModule,
    RenderersModule,
    SharedModule,
    ReactiveFormsModule,
    NgSelectModule
  ]
})
export class HealthInsurancesModule { }
