<div *ngIf="!loadingPage else loadingTemplate">
  <div class="row mb-5" [formGroup]="formGroup">
    <div class="col card">
      <div class="col px-0 pr-4">
        <h3 class="mb-4">Tipo de Atendimento</h3>
      </div>

      <div class="row px-0">
        <div class="col-6 px-0">
          <app-basic-input id="name" label="Nome" controlName="name" [formGroup]="formGroup"></app-basic-input>
        </div>

        <div class="col-3 px-0 pl-2">
          <label class="field__title" for="background_color">Cor do fundo</label>
          <div class="input-color">
            <input type="color" formControlName="background_color" id="background_color">
          </div>
        </div>

        <div class="col-3 px-0 pl-2">
          <label class="field__title" for="font_color">Cor do texto</label>
          <div class="input-color">
            <input type="color" formControlName="font_color" id="font_color">
          </div>
        </div>

        <div class="offset-6 col-3 px-0 pl-2 mt-2">
          <label class="field__title" for="background_opacity">Transparência do fundo</label>
          <input type="range" min="0" max="1" step="0.01" formControlName="background_opacity" id="background_opacity">
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-end mt-5 mb-5">
    <div class="col-12 col-md-3 mb-3">
      <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
        Cancelar
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3" *ngIf="button">
      <button (click)="removeHandler()" [disabled]="submittingRemove" class="btn btn-secondary btn-lg" type="button">
        {{removeButton}}
        <span class="spinner-border" *ngIf="submittingRemove"></span>
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3">
      <button (click)="submitHandler()" [disabled]="submitting" class="btn btn-primary btn-lg" type="button">
        {{submitButton}}
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
