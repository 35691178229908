<context-menu #basicMenu>
  <ng-template contextMenuItem [subMenu]="statusSubMenu" [enabled]="item?.attendance">
    Status de agendamento &nbsp;
  </ng-template>
  <context-menu #statusSubMenu>
    <ng-template contextMenuItem *ngFor="let status of schedulingStatus"
      (execute)="toggleSchedulingStatus($event, item?.attendance.id, status.id)"
      [enabled]="item?.attendance?.scheduling_status != status?.id">
      <div [ngClass]="item?.attendance?.scheduling_status == status?.id ? 'status' : ''">
        {{status.name}}
      </div>
    </ng-template>
  </context-menu>
  <ng-template contextMenuItem [enabled]="item?.attendance" (execute)="cutAttendance($event, item?.attendance)">
    Recortar
  </ng-template>
  <ng-template contextMenuItem [enabled]="item?.attendance || item?.patient_id" (execute)="copyAttendance($event, item?.attendance)">
    Copiar
  </ng-template>
  <ng-template contextMenuItem [enabled]="!item?.attendance && !item?.patient_id && duplicateAttendance"
    (execute)="pasteAttendance($event, item)">
    Colar
  </ng-template>
  <ng-template contextMenuItem [enabled]="item?.attendance" (execute)="createReminder($event, item?.attendance)">
    Criar Lembrete
  </ng-template>
  <ng-template contextMenuItem [enabled]="item?.attendance" (execute)="goToChart($event, item?.attendance)">
    Prontuário
  </ng-template>
</context-menu>

<context-menu #basicMenuInclude>
  <ng-template contextMenuItem  (execute)="include($event)">
    Incluir novo horário &nbsp;
  </ng-template>
</context-menu>

<context-menu #reminderInclude>
  <ng-template contextMenuItem [enabled]="duplicateAttendance"
    (execute)="pasteAttendance($event, item)">
    Colar
  </ng-template>
  <ng-template contextMenuItem  (execute)="createReminder($event, item?.attendance)" >
    Criar Lembrete &nbsp;
  </ng-template>
</context-menu>

<context-menu #reminder>
  <ng-template contextMenuItem [subMenu]="statusReminderSubMenu" [enabled]="item?.patient__name">
    Status de lembrete &nbsp;
  </ng-template>
  <context-menu #statusReminderSubMenu>
    <ng-template contextMenuItem *ngFor="let status of reminderStatus"
    (execute)="toggleStatusReminder($event, item?.id, status.id)"
      [enabled]="item?.status_id != status?.id">
      <div [ngClass]="item?.status_id == status?.id ? 'status' : ''">
        {{status.name}}
      </div>
    </ng-template>
  </context-menu>
  <ng-template contextMenuItem [enabled]="item?.attendance" (execute)="cutAttendance($event, item)">
    Recortar
  </ng-template>
  <ng-template contextMenuItem [enabled]="item?.patient_id" (execute)="copyAttendance($event, item)">
    Copiar
  </ng-template>
  <ng-template contextMenuItem [enabled]="duplicateAttendance"
    (execute)="pasteAttendance($event, item)">
    Colar
  </ng-template>
  <ng-template contextMenuItem (execute)="createReminder($event, item)">
    Criar Lembrete
  </ng-template>
</context-menu>
