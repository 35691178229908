import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { RegistersService } from 'src/app/core/services/registers.service';
import { documentsStringFormatter, isActiveStatusStringFormatter, phoneNumberStringFormatter } from 'src/app/core/utils/string-formatters';
import { ListTemplate } from 'src/app/shared/models/list-template';
import {Title} from "@angular/platform-browser";
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';

@Component({
  selector: 'app-patients-list',
  templateUrl: './patients-list.component.html',
  styleUrls: ['./patients-list.component.scss']
})
export class PatientsListComponent implements OnInit {

  searchString: string = "";
  orderingItem: string = "name";

  get canNewData() {
    const permissions = [PermissionConst.add_patient, PermissionConst.change_patient, PermissionConst.view_patient];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  get canExport() {
    const permissions = [PermissionConst.export_patient, PermissionConst.change_patient, PermissionConst.view_patient];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  listTemplate: ListTemplate = {
    loading: true,
    showSearch: true,
    searchBarPlaceholder: "Buscar por Nome ou CPF",
    showAdvancedSearch: false,
    newDataText: "+ Novo Paciente",
    newDataRouterLink: "register",
    notFoundMessage: "Nenhum paciente encontrado",
    header: [
      { name: "name", label: "Nome", widthInPercentage: "25%", width: "350px" },
      { name: "document_number", label: "CPF", widthInPercentage: "20%", width: "150px", displayStringConvertionFunction: documentsStringFormatter },
      { name: "phone_numbers", label: "Telefone", widthInPercentage: "25%", width: "250px", displayStringConvertionFunction: phoneNumberStringFormatter },
      { name: "health_insurances", label: "Convênio", widthInPercentage: "20%", width: "350px" },
      { name: "is_active", label: "Status", widthInPercentage: "10%", width: "100px", displayStringConvertionFunction: isActiveStatusStringFormatter }
    ],
    onHeaderItemClick: (value: string) => {
      let invertString = '';
      if (this.orderingItem == value) {
        this.listTemplate.invertedOrdering = !this.listTemplate.invertedOrdering;
        if (this.listTemplate.invertedOrdering)
          invertString = '-';
      }
      this.fetchList(this.searchString, `${invertString}${value}`, this.listTemplate.is_active);
      this.orderingItem = value;
      this.listTemplate.selectedOrdering = value;
    },
    onSearchFocusOut: (value: string) => {
      this.searchString = value;
      this.fetchList(value, this.orderingItem, this.listTemplate.is_active);
    },
    onClickActive: () => {
      this.listTemplate.is_active = !this.listTemplate.is_active;
      this.fetchList(this.searchString, this.orderingItem, this.listTemplate.is_active);
    },
    selectedOrdering: this.orderingItem,
    invertedOrdering: false,
    exportUrl: '/patients/export',
    exportOptions: {
      id: 'ID',
      phone_numbers: 'Telefones',
      address: 'Endereço',
      health_insurances: 'Planos de saúde',
      display_name: 'Nome de tratamento',
      name: 'Nome',
      gender: 'Gênero',
      birthdate: 'Data de nascimento',
      email: 'E-mail',
      document_number: 'CPF',
      identity_number: 'RG',
      marital_status: 'Estado civil',
      profession: 'Profissão',
      mother_name: 'Nome da mãe',
      father_name: 'Nome do pai',
      notes: 'Observações',
      tags: 'Tags'
    }
  };

  constructor(private registersService: RegistersService, private sessionManager: SessionManagerService,  private titleService: Title) {
    this.titleService.setTitle('ELEVE - Pacientes');
  }

  ngOnInit(): void {
    this.initializeList();
  }

  initializeList() {
    this.fetchList("", "name", this.listTemplate.is_active);
  }

  fetchList(searchString: string, orderingItem: string, isActive: boolean | undefined, page = 1) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;

    this.registersService.patientsList(searchString, orderingItem, isActive).subscribe(response => {
      this.listTemplate.loading = false;
      this.listTemplate.data = response;
      this.listTemplate.hasNext = response.next != null;
    }, error => {
      const response = error as HttpErrorResponse;
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = response.error['detail'];
    });
  }

  loadMore(page: number) {
    this.listTemplate.loadingNext = true;
    this.registersService.patientsList(this.searchString, this.orderingItem, this.listTemplate.is_active, page).subscribe(
      response => {
        this.listTemplate.hasNext = response.next != null;
        response.results!.map(item => this.listTemplate.data!.results!.push(item));
        this.listTemplate.loadingNext = false;
      },
      error => {
        const response = error as HttpErrorResponse;
        this.listTemplate.loading = false;
        this.listTemplate.error = true;
        this.listTemplate.errorMessage = response.error['detail'];
      });
  }
}
