import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { ReminderStatusModel } from 'src/app/core/models/reminder-status.model';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-status-reminder-form',
  templateUrl: './status-reminder-form.component.html',
  styleUrls: ['./status-reminder-form.component.scss']
})
export class StatusReminderFormComponent implements OnInit {

  id!: number;
  loadingPage: boolean = true;

  formGroup = this.fb.group({
    id: [''],
    name: ['', [Validators.required]],
    background_color: ['', [Validators.required]],
    font_color: ['', [Validators.required]],
    default: [false]
  });

  fetchedStatusReminder!: ReminderStatusModel;

  isRegister: boolean = true;

  disabledSalvar: boolean = false;
  disabledRemover: boolean = false;

  submitting: boolean = false;
  submittingRemove: boolean = false;
  removeButton?: String = "Desativar";
  submitButton?: String = "Salvar";

  button: boolean = false;
  registerSuccess: boolean = false;

  constructor(private fb: FormBuilder, private registersService: RegistersService, private router: Router, private activatedRoute: ActivatedRoute, config: NgbModalConfig, private modalService: NgbModal, private sessionManager: SessionManagerService, private toast: ToastrService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: any) => {
      this.id = Number(params['id']);
      if (this.id) {
        this.isRegister = false;
      }
    });
    this.initializeForm();
  }

  initializeForm() {
    if (this.id) this.fetchStatusExistingData();
    else this.loadingPage = false;
  }

  get canSave() {
    if (this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_scheduling);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_scheduling);
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_scheduling);
  }

  fetchStatusExistingData() {
    this.registersService.statusReminderGet(this.id).subscribe(response => {
      this.fetchedStatusReminder = response;
      if (this.fetchedStatusReminder.is_active) {
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
        this.button = true;
      }
      else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
      }
      this.formGroup.patchValue(this.fetchedStatusReminder);
      this.loadingPage = false;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    })
  }

  cancelHandler() {
    this.router.navigate(['/dashboard/registers/statusReminder']);
  }

  submitHandler() {
    this.submitting = true;
    this.formGroup.markAllAsTouched();
    let statusData = this.formGroup.getRawValue() as ReminderStatusModel;
    if (this.isRegister) {
      if(this.formGroup.valid) {
        this.statusRegister(statusData);
      } else this.submitting = false;
    } else  {
      this.statusEdit(statusData);
    }
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar este status de lembrete?";
    modalRef.result.then((result: boolean) => {
      if(result == true) {
        this.statusRemove();
      } else this.submittingRemove = false;
    });
  }

  statusRegister(statusData: ReminderStatusModel) {
    statusData.id = undefined;
    this.registersService.statusReminderRegister(statusData).subscribe(response => {
      this.toast.success("Status de lembrete criado com sucesso", "Sucesso");
      this.router.navigate(['/dashboard/registers/statusReminder']);
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  statusEdit(statusData: ReminderStatusModel) {
    statusData.is_active = true;
    this.registersService.statusReminderEdit(statusData).subscribe(response => {
      this.toast.success("Status de lembrete alterado com sucesso", "Sucesso");
      this.router.navigate(['/dashboard/registers/statusReminder']);
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  statusRemove() {
    this.registersService.statusReminderRemove(this.id).subscribe(response => {
      this.toast.success("Status de lembrete removido com sucesso", "Sucesso");
      this.router.navigate(['/dashboard/registers/statusReminder']);
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    }
    else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "name", "default", "background_color", "font_color"
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
    });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  }

}
