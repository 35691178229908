import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-selector-modal',
  templateUrl: './selector-modal.component.html',
  styleUrls: ['./selector-modal.component.scss']
})
export class SelectorModalComponent implements OnInit {

  @Input() text!: string;
  @Input() options: any[] = [];

  optionsForm = this.fb.group({
    option: []
  });

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) { }

  ngOnInit(): void {
  }

  okButton() {
    this.activeModal.close(this.optionsForm.get('option')?.value);
  }
}
