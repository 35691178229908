<div [ngClass]="['field', 'remove-vertical-space']" *ngIf="formGroup" [formGroup]="formGroup">
  <label class="field__title" [for]="id">{{label ? label : ""}}</label>

  <div class="input-wrapper"
       [ngClass]="[formGroup.get('country_code')!.errors && formGroup.get('country_code')!.touched ? 'error' : '', formGroup.get('phone_number')!.errors && formGroup.get('phone_number')!.touched ? 'error' : '']">
    <ng-select [items]="countryCodes" bindLabel="label" bindValue="value" formControlName="country_code"
               [ngClass]="['custom']" [clearable]="false">
      <ng-template ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
        <img class="flag" [src]="item.flag">
        {{item.value}}
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <img class="flag" [src]="item.flag">
        {{item.label}}
      </ng-template>
    </ng-select>

    <input [id]="id" formControlName="phone_number"
           [mask]="mask" [validation]="shouldValidate()"
           [ngClass]="[formGroup.get('phone_number')!.errors && formGroup.get('phone_number')!.touched ? 'error' : '', 'number-input']"/>
  </div>
  <app-form-error id="phone_number" [formGroup]="formGroup" *ngIf="showError" controlName="phone_number"></app-form-error>
</div>
