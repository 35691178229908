<div id="login">
  <div class="container">
    <div class="row">
      <div class="col logo-bar">
        <img src="/assets/eleve-logo.svg" alt="oral secret">
      </div>
    </div>

    <div class="row">
      <div class="offset-sm-1 offset-md-2 offset-lg-3 col-12 col-sm-10 col-md-8 col-lg-6">
        <div class="card">
          <form [formGroup]="loginForm" (submit)="onSubmit($event)" class="form">
            <div class="mb-4">
              <app-basic-input id="email" controlName="email" label="E-mail" [formGroup]="loginForm"></app-basic-input>
            </div>

            <div class="mb-5">
              <app-basic-input id="password" controlName="password" type="password" label="Senha"
                [formGroup]="loginForm"></app-basic-input>
            </div>

            <div class="row">
              <div class="offset-md-3 col-12 col-md-6 mb-3">
                <button class="btn btn-primary btn-block" type="submit" [disabled]="loading">
                  Entrar
                  <span *ngIf="loading" class="spinner-border" role="status" aria-hidden="true"></span>
                </button>
              </div>
            </div>

            <div class="row">
              <div class="col-12 text-center">
                <button (click)="passwordRecoveryLinkClickHandler()" class="btn btn-link">Esqueci minha
                  senha</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
