import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ClinicModel} from '../models/clinic.model';
import {ListModel} from '../models/list.model';
import {PatientModel} from '../models/patient.model';
import {Data} from "@angular/router";
import { ProfessionalModel } from '../models/professional.model';

@Injectable({
  providedIn: 'root'
})
export class SchedulesAsProfessionalService {

  constructor(private httpClient: HttpClient) {
  }

  getAvailableTimeAsProfessional(id: number, documentNumber: string, month: number, year: number): Observable<Data> {
    return this.httpClient.get<Data>(`${environment.API_URL}/schedules/as_professional/available_times/?schedule_id=${id}&professional=${documentNumber}&month=${month}&year=${year}`);
  }

  getSchedules(document: any): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/schedules/as_professional/schedules/?professional=${document}`);
  }

  getClinic(document: any): Observable<any> {
    return this.httpClient.get<any>(`${environment.API_URL}/schedules/as_professional/clinics/?professional=${document}`);
  }

  getPatient(document: any, patient: number): Observable<PatientModel> {
    return this.httpClient.get<PatientModel>(`${environment.API_URL}/schedules/as_professional/patient/?professional=${document}&patient=${patient}`);
  }

  createAttendance(document: any, body: any) {
    return this.httpClient.post(`${environment.API_URL}/schedules/as_professional/?professional=${document}`, body);
  }

  getProfessional(document: string | undefined):Observable<ProfessionalModel> {
    return this.httpClient.get(`${environment.API_URL}/schedules/as_professional/professional/?professional=${document}`);
  }
}
