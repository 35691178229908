<div *ngIf="!loadingPage else loadingTemplate">
  <div class="row mb-5">
    <div class="col card" [formGroup]="formGroup">
      <div class="col p-0">
        <h3 class="mb-4">Mensagem de boas vindas chatbot</h3>
      </div>

      <div class="row p-0" [formGroup]="formGroup">
        <div class="col-12 p-0">
          <app-basic-input id="flow" label="ID do Fluxo" [formGroup]="formGroup" controlName="flow"></app-basic-input>
        </div>
      </div>

      <div class="col p-0">
        <h3 class="mt-4">Limite de oferta de horários</h3>
      </div>

      <div class="row p-0" [formGroup]="formGroup">
        <div class="col-2.5 p-0 mt-4 mr-5">
          <app-basic-input id="date" type="date" label="Data" [formGroup]="formGroup"
            controlName="date"></app-basic-input>
        </div>

        <div class="col-1.5 p-0 mt-4">
          <app-basic-input id="time" type="time" label="Hora" [formGroup]="formGroup"
            controlName="time"></app-basic-input>
        </div>

      </div>
      <app-form-error id="limit_datetime" [formGroup]="formGroup" controlName="limit_datetime"></app-form-error>
    </div>
  </div>

  <div class="row justify-content-end mt-5 mb-5">

    <div class="col-12 col-md-3 mb-3">
      <button (click)="submitHandler()" [disabled]="submitting || !canSave || formGroup.disabled"
        class="btn btn-primary btn-lg" type="button">
        {{submitButton}}
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
