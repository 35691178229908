import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {TagModel} from "../models/tag.model";
import {environment} from "../../../environments/environment";
import {ProfessionModel} from "../models/profession.model";
import {ListModel} from "../models/list.model";
import {HttpClient} from "@angular/common/http";
import {ProfessionalModel} from "../models/professional.model";

@Injectable({
  providedIn: 'root'
})
export class ProfessionalsService {
  constructor(private httpClient: HttpClient) { }

  register(professionalData: ProfessionalModel): Observable<ProfessionalModel> {
    return this.httpClient.post<ProfessionalModel>(`${environment.API_URL}/professionals/`, JSON.stringify(professionalData));
  }

  edit(professionalData: ProfessionalModel): Observable<ProfessionalModel> {
    return this.httpClient.patch<ProfessionalModel>(`${environment.API_URL}/professionals/${professionalData.id}/`, JSON.stringify(professionalData));
  }

  remove(clinicId: number): Observable<ProfessionalModel> {
    return this.httpClient.delete<ProfessionalModel>(`${environment.API_URL}/professionals/${clinicId}/`);
  }

  list(searchString: string = "", orderingParam: string = "", isActive: boolean = true): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/professionals/?search=${searchString}&ordering=${orderingParam}&is_active=${isActive}`);
  }

  get(professionlId: number): Observable<ProfessionalModel> {
    return this.httpClient.get<ProfessionalModel>(`${environment.API_URL}/professionals/${professionlId}/`);
  }

  uploadImage(imageFile: File | undefined, professionalId: number): Observable<object> {
    const formData = new FormData();
    formData.append("profile_image", imageFile ? imageFile : '');

    return this.httpClient.patch<object>(`${environment.API_URL}/professionals/${professionalId}/profile_image/`, formData);
  }

  listAll(): Observable<ProfessionalModel[]> {
    return this.httpClient.get<ProfessionalModel[]>(`${environment.API_URL}/professionals/all/?ordering=name&is_active=true`);
  }

  listAllAssociatedWithAccount(): Observable<ProfessionalModel[]> {
    return this.httpClient.get<ProfessionalModel[]>(`${environment.API_URL}/professionals/all/?ordering=name&is_active=true&associated_with_account=false`);
  }

  listAllTagProfessional(): Observable<TagModel[]> {
    return this.httpClient.get<TagModel[]>(`${environment.API_URL}/tags/professional/all/?ordering=name&is_active=true`);
  }
}
