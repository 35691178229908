import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClinicTagsListComponent } from './clinic-tags-list/clinic-tags-list.component';
import { ClinicTagsFormComponent } from './clinic-tags-form/clinic-tags-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ClinicTagsListComponent,
    ClinicTagsFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule
  ]
})
export class ClinicTagsModule { }
