import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountModel } from 'src/app/core/models/account.model';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {
  loading: boolean = false;
  passwordRecoveryForm!: FormGroup;
  showConfirm: boolean = false;

  constructor(private accountsService: AccountsService, private sessionManager: SessionManagerService, private router: Router, private toast: ToastrService) { }

  ngOnInit(): void {
    this.passwordRecoveryForm = new FormGroup({
      "email": new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  onSubmit(e: Event): void {
    this.loading = true;
    this.passwordRecoveryRequest();
    e.preventDefault();
  }

  passwordRecoveryRequest() {
    var accountData = {
      email: this.passwordRecoveryForm.get('email')!.value,
    } as AccountModel;

    this.accountsService.passwordRecovery(accountData).subscribe((response: AccountModel) => {
      this.showConfirm = true;
      this.toast.success("Verifique seu e-mail", "Sucesso", {
        closeButton: true,
      });
    }, (response: HttpErrorResponse) => {
      this.toast.error(response.error["detail"], "Erro", {
        closeButton: true,
      });
    });
  }

  loginLinkClickHandler() {
    this.router.navigate(['login'])
  }
}
