<div class="report-modal-wrapper">
  <div class="report-modal" *ngIf="!laudo else laudoTemplate">
    <div class="report-modal__header">
      <h1>Laudo {{headerExam}}</h1>
    </div>
    <!-- TOMOGRAFIAS ATM -->
    <div *ngIf="exam.exam_id == TOMOGRAFIA_COMPUTADORIZADA_DAS_ATMS_EXAM_TEMPLATE_ID">
      <!-- atm direita -->
      <div class="mt-4 col-12" [formGroup]="tomografiaDasAtmsForm">
        <h3 class="mt-3">ATM DIREITA</h3>
        <div class="ml-3">
          <!-- Espaço Articular -->
          <div class="mt-3">
            <div class="d-flex">
              <h3 class="field__title">Espaço Articular</h3>
              <app-form-error id="atm_direita_espaco_articular" [formGroup]="tomografiaDasAtmsForm"
                              [isModalReport]="true" controlName="atm_direita_espaco_articular">
              </app-form-error>
            </div>
            <div class="selector d-flex align-content-center mb-1">
              <label class="ml-3" for="preservado"><input formControlName="atm_direita_espaco_articular" type="radio"
                  id="preservado" value="Preservado" class="mr-1">
                Preservado</label>
              <label class="ml-3" for="aumentado"><input formControlName="atm_direita_espaco_articular" type="radio" id="aumentado"
                  value="Aumentado" class="mr-1">
                Aumentado</label>
              <label class="ml-3" for="diminuido"><input formControlName="atm_direita_espaco_articular" type="radio" id="diminuido"
                  value="Diminuido" class="mr-1">
                Diminuído</label>
            </div>
          </div>
          <!-- Remodelação -->
          <div class="mt-3">
            <div class="d-flex">
              <h3 class="field__title">Remodelação</h3>
              <app-form-error id="atm_direita_remodelacao" [formGroup]="tomografiaDasAtmsForm" [isModalReport]="true"
                controlName="atm_direita_remodelacao">
              </app-form-error>
            </div>
            <div class="selector column align-content-center mb-1">
              <div class="row ml-3">
                <label class="" for="ausente"><input formControlName="atm_direita_remodelacao" type="radio" id="ausente"
                    value="Ausente" class="mr-1" (click)="remodelacaoValidator('ausente')">
                  Ausente</label>
                <label class="ml-3" for="presente"><input formControlName="atm_direita_remodelacao" type="radio" id="presente"
                    value="Presente" class="mr-1" (click)="remodelacaoValidator('presente')">
                  Presente</label>
              </div>
              <div class="row ml-5 mt-2" *ngIf="remodelacaoPresente">
                <label class="subtitle">Localização da Remodelação</label>
                <label class="ml-3" for="antero_superior"><input formControlName="atm_direita_localizacao_da_remodelacao"
                    type="radio" id="antero_superior" value="Ântero-superior" class="mr-1">
                  Ântero-superior</label>
                <label class="ml-3" for="superior"><input formControlName="atm_direita_localizacao_da_remodelacao" type="radio"
                    id="superior" value="Superior" class="mr-1">
                  Superior</label>
                <label class="ml-3" for="posterior"><input formControlName="atm_direita_localizacao_da_remodelacao" type="radio"
                    id="posterior" value="Posterior" class="mr-1">
                  Posterior</label>
                <app-form-error id="atm_direita_localizacao_da_remodelacao" [formGroup]="tomografiaDasAtmsForm"
                  [isModalReport]="true" controlName="atm_direita_localizacao_da_remodelacao">
                </app-form-error>
              </div>
              <div class="row ml-5 mt-2" *ngIf="remodelacaoPresente">
                <label class="subtitle">Grau da Remodelação</label>
                <label class="ml-3" for="leve"><input formControlName="atm_direita_grau_da_remodelacao" type="radio" id="leve"
                    value="Leve" class="mr-1">
                  Leve</label>
                <label class="ml-3" for="moderada"><input formControlName="atm_direita_grau_da_remodelacao" type="radio"
                    id="moderada" value="Moderada" class="mr-1">
                  Moderada</label>
                <label class="ml-3" for="acentuada"><input formControlName="atm_direita_grau_da_remodelacao" type="radio"
                    id="acentuada" value="Acentuada" class="mr-1">
                  Acentuada</label>
                <app-form-error id="atm_direita_grau_da_remodelacao" [formGroup]="tomografiaDasAtmsForm" [isModalReport]="true"
                  controlName="atm_direita_grau_da_remodelacao">
                </app-form-error>
              </div>
            </div>
          </div>
          <!-- Erosão -->
          <div class="mt-3">
            <div class="d-flex">
              <h3 class="field__title">Erosão</h3>
              <app-form-error id="atm_direita_erosoes" [formGroup]="tomografiaDasAtmsForm" [isModalReport]="true"
                controlName="atm_direita_erosoes">
              </app-form-error>
            </div>
            <div class="selector column align-content-center mb-1">
              <div class="row ml-3">
                <label class="" for="ausente_erosao"><input formControlName="atm_direita_erosoes" type="radio" id="ausente_erosao"
                    value="Ausente" class="mr-1" (click)="erosaoValidator('ausente')">
                  Ausente</label>
                <label class="ml-3" for="presente_erosao"><input formControlName="atm_direita_erosoes" type="radio"
                    id="presente_erosao" value="Presente" class="mr-1" (click)="erosaoValidator('presente')">
                  Presente</label>
              </div>
              <div class="row ml-5 mt-2" *ngIf="erosaoPresente">
                <label class="subtitle">Localização Erosão</label>
                <label class="ml-3" for="antero_superior_erosao"><input formControlName="atm_direita_localizacao_da_erosao"
                    type="radio" id="antero_superior_erosao" value="Ântero-superior" class="mr-1">
                  Ântero-superior</label>
                <label class="ml-3" for="superior_erosao"><input formControlName="atm_direita_localizacao_da_erosao" type="radio"
                    id="superior_erosao" value="Superior" class="mr-1">
                  Superior</label>
                <label class="ml-3" for="posterior_erosao"><input formControlName="atm_direita_localizacao_da_erosao" type="radio"
                    id="posterior_erosao" value="Posterior" class="mr-1">
                  Posterior</label>
                <app-form-error id="atm_direita_localizacao_da_erosao" [formGroup]="tomografiaDasAtmsForm" [isModalReport]="true"
                  controlName="atm_direita_localizacao_da_erosao">
                </app-form-error>
              </div>
              <div class="row ml-5 mt-2" *ngIf="erosaoPresente">
                <label class="subtitle">Grau da Erosão</label>
                <label class="ml-3" for="discreta"><input formControlName="atm_direita_grau_da_erosao" type="radio" id="discreta"
                    value="Discreta" class="mr-1">
                  Discreta</label>
                <label class="ml-3" for="moderada_erosao"><input formControlName="atm_direita_grau_da_erosao" type="radio"
                    id="moderada_erosao" value="Moderada" class="mr-1">
                  Moderada</label>
                <label class="ml-3" for="acentuada_erosao"><input formControlName="atm_direita_grau_da_erosao" type="radio"
                    id="acentuada_erosao" value="Acentuada" class="mr-1">
                  Acentuada</label>
                <app-form-error id="atm_direita_grau_da_erosao" [formGroup]="tomografiaDasAtmsForm" [isModalReport]="true"
                  controlName="atm_direita_grau_da_erosao">
                </app-form-error>
              </div>
            </div>
            <!-- Formações Císticas -->
            <div class="mt-3">
              <div class="d-flex">
                <h3 class="field__title">Formações Císticas</h3>
                <app-form-error id="atm_direita_formacoes_cisticas" [formGroup]="tomografiaDasAtmsForm" [isModalReport]="true"
                  controlName="atm_direita_formacoes_cisticas">
                </app-form-error>
              </div>
              <div class="selector column align-content-center mb-1">
                <div class="row ml-3">
                  <label class="" for="ausente_formacao"><input formControlName="atm_direita_formacoes_cisticas" type="radio"
                      id="ausente_formacao" value="Ausente" class="mr-1" (change)="cisticasValidator('ausente')">
                    Ausente</label>
                  <label class="ml-3" for="presente_formacao"><input formControlName="atm_direita_formacoes_cisticas" type="radio"
                      id="presente_formacao" value="Presente" class="mr-1" (change)="cisticasValidator('presente')">
                    Presente</label>
                </div>
                <div class="row ml-5 mt-2" *ngIf="cisticasPresente">
                  <label class="subtitle">Localização da Formação</label>
                  <label class="ml-3" for="antero_superior_formacao"><input
                      formControlName="atm_direita_localizacao_da_formacao_cistica" type="radio" id="antero_superior_formacao"
                      value="Ântero-superior" class="mr-1">
                    Ântero-superior</label>
                  <label class="ml-3" for="superior_formacao"><input formControlName="atm_direita_localizacao_da_formacao_cistica"
                      type="radio" id="superior_formacao" value="Superior" class="mr-1">
                    Superior</label>
                  <label class="ml-3" for="posterior_formacao"><input formControlName="atm_direita_localizacao_da_formacao_cistica"
                      type="radio" id="posterior_formacao" value="Posterior" class="mr-1">
                    Posterior</label>
                  <app-form-error id="atm_direita_localizacao_da_formacao_cistica" [formGroup]="tomografiaDasAtmsForm"
                    [isModalReport]="true" controlName="atm_direita_localizacao_da_formacao_cistica">
                  </app-form-error>
                </div>
              </div>
              <!-- Fossa Mandibular -->
              <div class="mt-3">
                <div class="d-flex">
                  <h3 class="field__title">Fossa Mandibular</h3>
                  <app-form-error id="atm_direita_fossa_mandibular" [formGroup]="tomografiaDasAtmsForm" [isModalReport]="true"
                    controlName="atm_direita_fossa_mandibular">
                  </app-form-error>
                </div>
                <div class="selector d-flex align-content-center mb-1">
                  <label class="ml-3" for="preservado_fossa"><input formControlName="atm_direita_fossa_mandibular" type="radio"
                      id="preservado_fossa" value="Preservado" class="mr-1">
                    Preservado</label>
                  <label class="ml-3" for="aumentado_fossa"><input formControlName="atm_direita_fossa_mandibular" type="radio"
                      id="aumentado_fossa" value="Aumentado" class="mr-1">
                    Aumentado</label>
                </div>
              </div>

              <!-- Observações adicionais -->
              <div class="mt-3">
                <div class="d-flex">
                  <h3 class="field__title">Observações adicionais</h3>
                </div>
                <div class="d-flex">
                  <textarea rows="3" formControlName="atm_direita_additional"></textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- atm esquerda -->
      <div class="mt-4 col-12" [formGroup]="tomografiaDasAtmsForm">
        <h3 class="mt-3">ATM ESQUERDA</h3>
        <div class="ml-3">
          <div class="mt-3">
            <div class="d-flex">
              <h3 class="field__title">Espaço Articular</h3>
              <app-form-error id="atm_esquerda_espaco_articular" [formGroup]="tomografiaDasAtmsForm" [isModalReport]="true"
                controlName="atm_esquerda_espaco_articular">
              </app-form-error>
            </div>
            <div class="selector d-flex align-content-center mb-1">
              <label class="ml-3" for="esquerda_preservado"><input formControlName="atm_esquerda_espaco_articular" type="radio"
                  id="esquerda_preservado" value="Preservado" class="mr-1">
                Preservado</label>
              <label class="ml-3" for="esquerda_aumentado"><input formControlName="atm_esquerda_espaco_articular" type="radio"
                  id="esquerda_aumentado" value="Aumentado" class="mr-1">
                Aumentado</label>
              <label class="ml-3" for="esquerda_diminuido"><input formControlName="atm_esquerda_espaco_articular" type="radio"
                  id="esquerda_diminuido" value="Diminuido" class="mr-1">
                Diminuído</label>
            </div>
          </div>
          <div class="mt-3">
            <div class="d-flex">
              <h3 class="field__title">Remodelação</h3>
              <app-form-error id="remodelacao" [formGroup]="tomografiaDasAtmsForm" [isModalReport]="true"
                controlName="atm_esquerda_remodelacao">
              </app-form-error>
            </div>
            <div class="selector column align-content-center mb-1">
              <div class="row ml-3">
                <label class="" for="esquerda_ausente"><input formControlName="atm_esquerda_remodelacao"
                    (click)="remodelacaoValidatorEsquerda('ausente')" type="radio" id="esquerda_ausente" value="Ausente"
                    class="mr-1">
                  Ausente</label>
                <label class="ml-3" for="esquerda_presente"><input formControlName="atm_esquerda_remodelacao" type="radio"
                    id="esquerda_presente" value="Presente" class="mr-1"
                    (click)="remodelacaoValidatorEsquerda('presente')">
                  Presente</label>
              </div>
              <div class="row ml-5 mt-2" *ngIf="remodelacaoPresenteEsquerda">
                <label class="subtitle">Localização da Remodelação</label>
                <label class="ml-3" for="atm_esquerda_antero_superior"><input
                    formControlName="atm_esquerda_localizacao_da_remodelacao" type="radio" id="atm_esquerda_antero_superior"
                    value="Ântero-superior" class="mr-1">
                  Ântero-superior</label>
                <label class="ml-3" for="atm_esquerda_superior"><input formControlName="atm_esquerda_localizacao_da_remodelacao"
                    type="radio" id="atm_esquerda_superior" value="Superior" class="mr-1">
                  Superior</label>
                <label class="ml-3" for="atm_esquerda_posterior"><input formControlName="atm_esquerda_localizacao_da_remodelacao"
                    type="radio" id="atm_esquerda_posterior" value="Posterior" class="mr-1">
                  Posterior</label>
                <app-form-error id="localizacao_da_remodelacao" [formGroup]="tomografiaDasAtmsForm"
                  [isModalReport]="true" controlName="atm_esquerda_localizacao_da_remodelacao">
                </app-form-error>
              </div>
              <div class="row ml-5 mt-2" *ngIf="remodelacaoPresenteEsquerda">
                <label class="subtitle">Grau da Remodelação</label>
                <label class="ml-3" for="atm_esquerda_leve"><input formControlName="atm_esquerda_grau_da_remodelacao" type="radio"
                    id="atm_esquerda_leve" value="Leve" class="mr-1">
                  Leve</label>
                <label class="ml-3" for="atm_esquerda_moderada"><input formControlName="atm_esquerda_grau_da_remodelacao"
                    type="radio" id="atm_esquerda_moderada" value="Moderada" class="mr-1">
                  Moderada</label>
                <label class="ml-3" for="atm_esquerda_acentuada"><input formControlName="atm_esquerda_grau_da_remodelacao"
                    type="radio" id="atm_esquerda_acentuada" value="Acentuada" class="mr-1">
                  Acentuada</label>
                <app-form-error id="atm_esquerda_grau_da_remodelacao" [formGroup]="tomografiaDasAtmsForm" [isModalReport]="true"
                  controlName="grau_da_remodelacao">
                </app-form-error>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="d-flex">
              <h3 class="field__title">Erosão</h3>
              <app-form-error id="erosoes" [formGroup]="tomografiaDasAtmsForm" [isModalReport]="true"
                controlName="atm_esquerda_erosoes">
              </app-form-error>
            </div>
            <div class="selector column align-content-center mb-1">
              <div class="row ml-3">
                <label class="" for="atm_esquerda_ausente_erosao"><input formControlName="atm_esquerda_erosoes"
                    (click)="erosaoValidatorEsquerda('ausente')" type="radio" id="atm_esquerda_ausente_erosao"
                    value="Ausente" class="mr-1">
                  Ausente</label>
                <label class="ml-3" for="atm_esquerda_presente_erosao"><input formControlName="atm_esquerda_erosoes"
                    (click)="erosaoValidatorEsquerda('presente')" type="radio" id="atm_esquerda_presente_erosao"
                    value="Presente" class="mr-1">
                  Presente</label>
              </div>
              <div class="row ml-5 mt-2" *ngIf="erosaoPresenteEsquerda">
                <label class="subtitle">Localização Erosão</label>
                <label class="ml-3" for="atm_esquerda_antero_superior_erosao"><input
                    formControlName="atm_esquerda_localizacao_da_erosao" type="radio" id="atm_esquerda_antero_superior_erosao"
                    value="Ântero-superior" class="mr-1">
                  Ântero-superior</label>
                <label class="ml-3" for="atm_esquerda_superior_erosao"><input formControlName="atm_esquerda_localizacao_da_erosao"
                    type="radio" id="atm_esquerda_superior_erosao" value="Superior" class="mr-1">
                  Superior</label>
                <label class="ml-3" for="atm_esquerda_posterior_erosao"><input formControlName="atm_esquerda_localizacao_da_erosao"
                    type="radio" id="atm_esquerda_posterior_erosao" value="Posterior" class="mr-1">
                  Posterior</label>
                <app-form-error id="localizacao_da_erosao" [formGroup]="tomografiaDasAtmsForm"
                  [isModalReport]="true" controlName="atm_esquerda_localizacao_da_erosao">
                </app-form-error>
              </div>
              <div class="row ml-5 mt-2" *ngIf="erosaoPresenteEsquerda">
                <label class="subtitle">Grau da Erosão</label>
                <label class="ml-3" for="atm_esquerda_discreta_erosao"><input formControlName="atm_esquerda_grau_da_erosao"
                    type="radio" id="atm_esquerda_discreta_erosao" value="Discreta" class="mr-1">
                  Discreta</label>
                <label class="ml-3" for="atm_esquerda_moderada_erosao"><input formControlName="atm_esquerda_grau_da_erosao"
                    type="radio" id="atm_esquerda_moderada_erosao" value="Moderada" class="mr-1">
                  Moderada</label>
                <label class="ml-3" for="atm_esquerda_acentuada_erosao"><input formControlName="atm_esquerda_grau_da_erosao"
                    type="radio" id="atm_esquerda_acentuada_erosao" value="Acentuada" class="mr-1">
                  Acentuada</label>
                <app-form-error id="grau_da_erosao" [formGroup]="tomografiaDasAtmsForm" [isModalReport]="true"
                  controlName="atm_esquerda_grau_da_erosao">
                </app-form-error>
              </div>
            </div>
            <div class="mt-3">
              <div class="d-flex">
                <h3 class="field__title">Formações Císticas</h3>
                <app-form-error id="formacoes_cisticas" [formGroup]="tomografiaDasAtmsForm" [isModalReport]="true"
                  controlName="atm_esquerda_formacoes_cisticas">
                </app-form-error>
              </div>
              <div class="selector column align-content-center mb-1">
                <div class="row ml-3">
                  <label class="" for="atm_esquerda_ausente_formacao"><input
                      (click)="cisticasValidatorEsquerda('ausente')" formControlName="atm_esquerda_formacoes_cisticas" type="radio"
                      id="atm_esquerda_ausente_formacao" value="Ausente" class="mr-1">
                    Ausente</label>
                  <label class="ml-3" for="atm_esquerda_presente_formacao"><input
                      (click)="cisticasValidatorEsquerda('presente')" formControlName="atm_esquerda_formacoes_cisticas" type="radio"
                      id="atm_esquerda_presente_formacao" value="Presente" class="mr-1">
                    Presente</label>
                </div>
                <div class="row ml-5 mt-2" *ngIf="cisticasPresenteEsquerda">
                  <label class="subtitle">Localização da Formação</label>
                  <label class="ml-3" for="atm_esquerda_antero_superior_formacao"><input
                      formControlName="atm_esquerda_localizacao_da_formacao_cistica" type="radio"
                      id="atm_esquerda_antero_superior_formacao" value="Ântero-superior" class="mr-1">
                    Ântero-superior</label>
                  <label class="ml-3" for="atm_esquerda_superior_formacao"><input
                      formControlName="atm_esquerda_localizacao_da_formacao_cistica" type="radio" id="atm_esquerda_superior_formacao"
                      value="Superior" class="mr-1">
                    Superior</label>
                  <label class="ml-3" for="atm_esquerda_posterior_formacao"><input
                      formControlName="atm_esquerda_localizacao_da_formacao_cistica" type="radio"
                      id="atm_esquerda_posterior_formacao" value="Posterior" class="mr-1">
                    Posterior</label>
                  <app-form-error id="localizacao_da_formacao_cistica" [formGroup]="tomografiaDasAtmsForm"
                    [isModalReport]="true" controlName="atm_esquerda_localizacao_da_formacao_cistica">
                  </app-form-error>
                </div>
              </div>
              <div class="mt-3">
                <div class="d-flex">
                  <h3 class="field__title">Fossa Mandibular</h3>
                  <app-form-error id="fossa_mandibular" [formGroup]="tomografiaDasAtmsForm" [isModalReport]="true"
                    controlName="fossa_mandibular">
                  </app-form-error>
                </div>
                <div class="selector d-flex align-content-center mb-1">
                  <label class="ml-3" for="atm_esquerda_preservado_fossa"><input formControlName="atm_esquerda_fossa_mandibular"
                      type="radio" id="atm_esquerda_preservado_fossa" value="Preservado" class="mr-1">
                    Preservado</label>
                  <label class="ml-3" for="atm_esquerda_aumentado_fossa"><input formControlName="atm_esquerda_fossa_mandibular"
                      type="radio" id="atm_esquerda_aumentado_fossa" value="Aumentado" class="mr-1">
                    Aumentado</label>
                </div>
              </div>
            </div>

            <!-- Observações adicionais -->
            <div class="mt-3">
              <div class="d-flex">
                <h3 class="field__title">Observações adicionais</h3>
              </div>
              <div class="d-flex">
                <textarea rows="3" formControlName="atm_esquerda_additional"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TOMOGRAFIA FARINGE -->
    <div *ngIf="exam.exam_id == TOMOGRAFIA_COMPUTADORIZADA_DE_FARINGE_EXAM_TEMPLATE_ID">
      <div class="mt-3" [formGroup]="tomografiaFaringeForm">
        <h3 class="mt-3">Avaliação Sagital</h3>
        <div class="ml-3 mt-3">
          <div class="d-flex">
            <h3 class="field__title">Estreitamento Sagital</h3>
            <app-form-error id="grau_do_estreitamento_sagital" [formGroup]="tomografiaFaringeForm"
              [isModalReport]="true" controlName="grau_do_estreitamento_sagital">
            </app-form-error>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row ml-3">
              <label class="ml-3" for="ausente_sagital"><input formControlName="grau_do_estreitamento_sagital"
                  type="radio" id="ausente_sagital" value="Ausente" class="mr-1" (click)="sagitalValidator('ausente')">
                Ausente</label>
              <label class="ml-3" for="leve_sagital"><input formControlName="grau_do_estreitamento_sagital" type="radio"
                  id="leve_sagital" value="Leve" class="mr-1" (click)="sagitalValidator('leve')">
                Leve</label>
              <label class="ml-3" for="moderado_sagital"><input formControlName="grau_do_estreitamento_sagital"
                  type="radio" id="moderado_sagital" value="Moderado" class="mr-1"
                  (click)="sagitalValidator('moderado')">
                Moderado</label>
              <label class="ml-3" for="acentuado_sagital"><input formControlName="grau_do_estreitamento_sagital"
                  type="radio" id="acentuado_sagital" value="Acentuado" class="mr-1"
                  (click)="sagitalValidator('acentuado')">
                Acentuado</label>
            </div>
            <div class="row ml-5 mt-2" *ngIf="sagitalPresente">
              <label class="subtitle">Localização do Estreitamento</label>
              <div *ngFor="let localizacao of localizacao_do_estreitamento_sagital">
                <label class="ml-3" [for]="localizacao.id+'sagital'">
                  <input type="checkbox" (change)="toggleLocalizacaoSagital(localizacao.id)"
                    [checked]="localizacao.checked" [id]="localizacao.id+'sagital'" class="mr-1" />
                  {{ localizacao.label }}
                </label>
              </div>
              <app-form-error id="localizacao_do_estreitamento_sagital" [formGroup]="tomografiaFaringeForm"
                [isModalReport]="true" controlName="localizacao_do_estreitamento_sagital">
              </app-form-error>
            </div>
          </div>
        </div>
        <div class="ml-3 mt-3">
          <div class="d-flex">
            <h3 class="field__title">Espaço Aéreo entre Palato e Língua</h3>
            <app-form-error id="espaco_aereo_entre_palato_e_lingua" [formGroup]="tomografiaFaringeForm"
              [isModalReport]="true" controlName="espaco_aereo_entre_palato_e_lingua">
            </app-form-error>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row ml-3">
              <label class="ml-3" for="ausente_aereo_sagital"><input
                  formControlName="espaco_aereo_entre_palato_e_lingua" type="radio" id="ausente_aereo_sagital"
                  value="Ausente" class="mr-1">
                Ausente</label>
              <label class="ml-3" for="preservado_aereo_sagital"><input
                  formControlName="espaco_aereo_entre_palato_e_lingua" type="radio" id="preservado_aereo_sagital"
                  value="Preservado" class="mr-1">
                Preservado</label>
              <label class="ml-3" for="diminuido_aereo_sagital"><input
                  formControlName="espaco_aereo_entre_palato_e_lingua" type="radio" id="diminuido_aereo_sagital"
                  value="Diminuido" class="mr-1">
                Dimunído</label>
            </div>
          </div>

          <!-- Observações adicionais -->
          <div class="mt-3">
            <div class="d-flex">
              <h3 class="field__title">Observações adicionais</h3>
            </div>
            <div class="d-flex">
              <textarea rows="3" formControlName="observacoes_adicionais_da_avaliacao_sagital"></textarea>
            </div>
          </div>
        </div>
        <h3 class="mt-3">Avaliação coronal</h3>
        <div class="ml-3 mt-3">
          <div class="d-flex">
            <h3 class="field__title">Estreitamento coronal</h3>
            <app-form-error id="espaco_aereo_entre_palato_e_lingua" [formGroup]="tomografiaFaringeForm"
              [isModalReport]="true" controlName="espaco_aereo_entre_palato_e_lingua">
            </app-form-error>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row ml-3">
              <label class="ml-3" for="ausente_coronal"><input (click)="coronalValidator('ausente')"
                  formControlName="grau_do_estreitamento_coronal" type="radio" id="ausente_coronal" value="Ausente"
                  class="mr-1">
                Ausente</label>
              <label class="ml-3" for="leve_coronal"><input (click)="coronalValidator('leve')"
                  formControlName="grau_do_estreitamento_coronal" type="radio" id="leve_coronal" value="Leve"
                  class="mr-1">
                Leve</label>
              <label class="ml-3" for="moderado_coronal"><input (click)="coronalValidator('moderado')"
                  formControlName="grau_do_estreitamento_coronal" type="radio" id="moderado_coronal" value="Moderado"
                  class="mr-1">
                Moderado</label>
              <label class="ml-3" for="acentuado_coronal"><input (click)="coronalValidator('acentuado')"
                  formControlName="grau_do_estreitamento_coronal" type="radio" id="acentuado_coronal" value="Acentuado"
                  class="mr-1">
                Acentuado</label>
            </div>
            <div class="row ml-5 mt-2" *ngIf="coronalPresente">
              <label class="subtitle">Localização do Estreitamento</label>
              <div *ngFor="let localizacao of localizacao_do_estreitamento_coronal">
                <label class="ml-3" [for]="localizacao.id+'coronal'">
                  <input type="checkbox" (change)="toggleLocalizacaoCoronal(localizacao.id)"
                    [checked]="localizacao.checked" [id]="localizacao.id+'coronal'" class="mr-1" />
                  {{ localizacao.label }}
                </label>
              </div>
              <app-form-error id="localizacao_do_estreitamento_coronal" [formGroup]="tomografiaFaringeForm"
                [isModalReport]="true" controlName="localizacao_do_estreitamento_coronal">
              </app-form-error>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Estreitamento em região Amigdaliana</h3>
            <app-form-error id="estreitamento_em_regiao_amigdaliana" [formGroup]="tomografiaFaringeForm"
              [isModalReport]="true" controlName="estreitamento_em_regiao_amigdaliana">
            </app-form-error>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row ml-2">
              <label class="ml-3" for="ausente_amigdaliana"><input formControlName="estreitamento_em_regiao_amigdaliana"
                  type="radio" id="ausente_amigdaliana" value="Ausente" class="mr-1">
                Ausente</label>
              <label class="ml-3" for="leve_amigdaliana_coronal"><input
                  formControlName="estreitamento_em_regiao_amigdaliana" type="radio" id="leve_amigdaliana_coronal"
                  value="Leve" class="mr-1">
                Leve</label>
              <label class="ml-3" for="moderado_amigdaliana_coronal"><input
                  formControlName="estreitamento_em_regiao_amigdaliana" type="radio" id="moderado_amigdaliana_coronal"
                  value="Moderado" class="mr-1">
                Moderado</label>
              <label class="ml-3" for="acentuado_amigdaliana_coronal"><input
                  formControlName="estreitamento_em_regiao_amigdaliana" type="radio" id="acentuado_amigdaliana_coronal"
                  value="Acentuado" class="mr-1">
                Acentuado</label>
            </div>
          </div>

          <div class="mt-3">
            <!-- Observações adicionais -->
            <div class="mt-3">
              <div class="d-flex">
                <h3 class="field__title">Observações adicionais</h3>
              </div>
              <div class="d-flex">
                <textarea rows="3" formControlName="observacoes_adicionais_da_avaliacao_coronal"></textarea>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="d-flex">
              <h3 class="field__title">Menor Secção Axial</h3>
              <app-form-error id="menor_seccao_axial" [formGroup]="tomografiaFaringeForm" [isModalReport]="true"
                controlName="menor_seccao_axial">
              </app-form-error>
            </div>
            <div class="selector column align-content-center mb-1">
              <div class="row align-items-end">
                <div class="col">
                  <input type="text" label="" mask="separator" thousandSeparator="."
                    [dropSpecialCharacters]="false" id="height" formControlName="menor_seccao_axial" />
                </div>
                <label>mm²</label>
              </div>
            </div>
          </div>
          <div class="mt-3">
            <div class="d-flex">
              <h3 class="field__title">Localização da menor Secção Axial</h3>
              <app-form-error id="localizacao_da_menor_seccao_axial" [formGroup]="tomografiaFaringeForm"
                [isModalReport]="true" controlName="localizacao_da_menor_seccao_axial">
              </app-form-error>
            </div>
            <div class="selector column align-content-center mb-1">
              <div class="column ml-3">
                <label class="mt-3" for="nasofaringe_regiao_coronal"><input
                    formControlName="localizacao_da_menor_seccao_axial" type="radio" id="nasofaringe_regiao_coronal"
                    value="Nasofaringe em região retropalatal" class="mr-1">
                  Nasofaringe em região retropalatal</label>
                <label class="mt-3" for="transicao_coronal"><input formControlName="localizacao_da_menor_seccao_axial"
                    type="radio" id="transicao_coronal" value="Transição da nasofaringe para a orofaringe" class="mr-1">
                  Transição da Nasofaringe para a Orofaringe</label>
                <label class="mt-3" for="orofaringe_lingua_coronal"><input
                    formControlName="localizacao_da_menor_seccao_axial" type="radio" id="orofaringe_lingua_coronal"
                    value="Orofaringe na região da base da língua" class="mr-1">
                  Orofaringe na região da base da língua</label>
                <label class="mt-3" for="orofaringe_amigdaliana_coronal"><input
                    formControlName="localizacao_da_menor_seccao_axial" type="radio" id="orofaringe_amigdaliana_coronal"
                    value="Orofaringe em região amigdaliana" class="mr-1">
                  Orofaringe em região amigdaliana</label>
                <label class="mt-3" for="epiglotica_coronal"><input formControlName="localizacao_da_menor_seccao_axial"
                    type="radio" id="epiglotica_coronal" value="Região epiglótica" class="mr-1">
                  Região epiglótica</label>
              </div>
            </div>
          </div>
          <h3 class="mt-3">Volumetria</h3>
          <div class="ml-3 mt-3">
            <div class="d-flex">
              <h3 class="field__title">Volume da Coluna Aérea</h3>
              <app-form-error id="volumetria_da_coluna_aerea" [formGroup]="tomografiaFaringeForm" [isModalReport]="true"
                controlName="volumetria_da_coluna_aerea">
              </app-form-error>
            </div>
            <div class="selector column align-content-center mb-1">
              <div class="row align-items-end">
                <div class="col">
                  <input type="text" label="" mask="separator" thousandSeparator="."
                    [dropSpecialCharacters]="false" id="height" formControlName="volumetria_da_coluna_aerea" />
                </div>
                <label>mm³</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TOMOGRAFIA MANDIBULA -->
    <div *ngIf="exam.exam_id == TOMOGRAFIA_COMPUTADORIZADA_DE_MANDIBULA_EXAM_TEMPLATE_ID">
      <div class="mt-3" [formGroup]="tomografiaMandibulaForm">
        <div class="ml-3 mt-3">
          <div class="d-flex">
            <h3 class="field__title">Dentes presentes</h3>
            <app-form-error id="dentes_presentes" [formGroup]="tomografiaMandibulaForm" [isModalReport]="true"
              controlName="dentes_presentes">
            </app-form-error>
          </div>
          <div class="selector row align-content-center mb-1">
            <div class="column align-items-start mr-2" *ngFor="let dente of dentesMandibula">
              <input (change)="toggleElementosDentarios(dente)" type="checkbox" [checked]="dente.checked"
                [id]="dente.id+'presentes'" class="mt-1 ml-3">
              <label class="ml-3" [for]="dente.id+'presentes'">{{dente.id}}</label>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Tratamento Endodôntico</h3>
            <app-form-error id="tratamento_endodontico" [formGroup]="tomografiaMandibulaForm" [isModalReport]="true"
              controlName="tratamento_endodontico">
            </app-form-error>
          </div>
          <div class="selector row align-content-center mb-1">
            <div class="column align-items-start" *ngFor="let dente of tratamentoEndodonticoMandibula">
              <div *ngIf="dente.flag" class="mr-2">
                <input (change)="toggleTratamentoEndodontico(dente)" type="checkbox" [checked]="dente.checked"
                  [id]="dente.id+'tratamentos_endodonticos'" class="mt-1 ml-3">
                <label class="ml-3" [for]="dente.id+'tratamentos_endodonticos'">{{dente.id}}</label>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <h3 *ngIf="existImplate()" class="field__title">Implantes Osseointegráveis</h3>
            <app-form-error id="implantes_osseointegraveis" [formGroup]="tomografiaMandibulaForm" [isModalReport]="true"
              controlName="implantes_osseointegraveis">
            </app-form-error>
          </div>
          <div class="selector row align-content-center mb-1">
            <div class="column align-items-start" *ngFor="let dente of implantesMandibula">
              <div *ngIf="dente.flag" class="mr-2">
                <input (change)="toggleImplante(dente)" type="checkbox" [checked]="dente.checked"
                  [id]="dente.id+'implante'" class="mt-1 ml-3">
                <label class="ml-3" [for]="dente.id+'implante'">{{dente.id}}</label>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Lesões Periapicais</h3>
            <app-form-error id="lesoes_periapicais" [formGroup]="tomografiaMandibulaForm" [isModalReport]="true"
              controlName="lesoes_periapicais">
            </app-form-error>
          </div>
          <div class="selector row align-content-center mb-1">
            <div class="column align-items-start" *ngFor="let dente of lesoesMandibula">
              <div *ngIf="dente.flag" class="mr-2">
                <input (change)="toggleLesoes(dente)" type="checkbox" [checked]="dente.checked" [id]="dente.id+'lesoes'"
                  class="mt-1 ml-3">
                <label class="ml-3" [for]="dente.id+'lesoes'">{{dente.id}}</label>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Material Restaurador</h3>
            <app-form-error id="material_restaurador" [formGroup]="tomografiaMandibulaForm" [isModalReport]="true"
              controlName="material_restaurador">
            </app-form-error>
          </div>
          <div class="selector row align-content-center mb-1">
            <div class="row ml-3">
              <label class="ml-3" for="ausente_material"><input formControlName="material_restaurador" type="radio"
                  id="ausente_material" value="Ausente" class="mr-1">
                Ausente</label>
              <label class="ml-3" for="diversos_material"><input formControlName="material_restaurador" type="radio"
                  id="diversos_material" value="Diversos" class="mr-1">
                Diversos</label>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Assimetria Processo Alveolar</h3>
            <app-form-error id="assimetria_processo_alveolar" [formGroup]="tomografiaMandibulaForm"
              [isModalReport]="true" controlName="assimetria_processo_alveolar">
            </app-form-error>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row ml-3">
              <label class="ml-3" for="ausente_assimetria"><input formControlName="assimetria_processo_alveolar"
                  type="radio" id="ausente_assimetria" value="Ausente" class="mr-1">
                Ausente</label>
              <label class="ml-3" for="leve_assimetria"><input formControlName="assimetria_processo_alveolar"
                  type="radio" id="leve_assimetria" value="Leve" class="mr-1">
                Leve</label>
              <label class="ml-3" for="moderada_assimetria"><input formControlName="assimetria_processo_alveolar"
                  type="radio" id="moderada_assimetria" value="Moderada" class="mr-1">
                Moderada</label>
              <label class="ml-3" for="acentuada_assimetria"><input formControlName="assimetria_processo_alveolar"
                  type="radio" id="acentuada_assimetria" value="Acentuada" class="mr-1">
                Acentuada</label>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Desproporção Processo Alveolar</h3>
            <app-form-error id="desproporcao_processo_alveolar" [formGroup]="tomografiaMandibulaForm"
              [isModalReport]="true" controlName="desproporcao_processo_alveolar">
            </app-form-error>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row ml-3">
              <label class="ml-3" for="ausente_desproporcao"><input formControlName="desproporcao_processo_alveolar"
                  type="radio" id="ausente_desproporcao" value="Ausente" class="mr-1">
                Ausente</label>
              <label class="ml-3" for="leve_desproporcao"><input formControlName="desproporcao_processo_alveolar"
                  type="radio" id="leve_desproporcao" value="Leve" class="mr-1">
                Leve</label>
              <label class="ml-3" for="moderada_desproporcao"><input formControlName="desproporcao_processo_alveolar"
                  type="radio" id="moderada_desproporcao" value="Moderada" class="mr-1">
                Moderada</label>
              <label class="ml-3" for="acentuada_desproporcao"><input formControlName="desproporcao_processo_alveolar"
                  type="radio" id="acentuada_desproporcao" value="Acentuada" class="mr-1">
                Acentuada</label>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Assimetria Corpo Mandibular</h3>
            <app-form-error id="assimetria_corpo_mandibular" [formGroup]="tomografiaMandibulaForm"
              [isModalReport]="true" controlName="assimetria_corpo_mandibular">
            </app-form-error>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row ml-3">
              <label class="ml-3" for="ausente_mandibular"><input formControlName="assimetria_corpo_mandibular"
                  type="radio" id="ausente_mandibular" value="Ausente" class="mr-1">
                Ausente</label>
              <label class="ml-3" for="leve_mandibular"><input formControlName="assimetria_corpo_mandibular"
                  type="radio" id="leve_mandibular" value="Leve" class="mr-1">
                Leve</label>
              <label class="ml-3" for="moderada_mandibular"><input formControlName="assimetria_corpo_mandibular"
                  type="radio" id="moderada_mandibular" value="Moderada" class="mr-1">
                Moderada</label>
              <label class="ml-3" for="acentuada_mandibular"><input formControlName="assimetria_corpo_mandibular"
                  type="radio" id="acentuada_mandibular" value="Acentuada" class="mr-1">
                Acentuada</label>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Nivelamento Coronal do Plano Oclusal</h3>
            <app-form-error id="nivelamento_coronal_do_plano_oclusal" [formGroup]="tomografiaMandibulaForm"
              [isModalReport]="true" controlName="nivelamento_coronal_do_plano_oclusal">
            </app-form-error>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row ml-3">
              <label class="ml-3" for="nivelamento"><input formControlName="nivelamento_coronal_do_plano_oclusal"
                  type="radio" id="nivelamento" value="Nivelado" class="mr-1">
                Nivelado</label>
              <label class="ml-3" for="nivelamento_leve"><input formControlName="nivelamento_coronal_do_plano_oclusal"
                  type="radio" id="nivelamento_leve" value="Desnivelamento leve" class="mr-1">
                Desnivelamento Leve</label>
              <label class="ml-3" for="nivelamento_moderado"><input
                  formControlName="nivelamento_coronal_do_plano_oclusal" type="radio" id="nivelamento_moderado"
                  value="Desnivelamento moderado" class="mr-1">
                Desnivelamento Moderado</label>
              <label class="ml-3" for="nivelamento_acentuado"><input
                  formControlName="nivelamento_coronal_do_plano_oclusal" type="radio" id="nivelamento_acentuado"
                  value="Desnivelamento acentuado" class="mr-1">
                Desnivelamento Acentuado</label>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Apinhamento Dentário</h3>
            <app-form-error id="apinhamento_dentario" [formGroup]="tomografiaMandibulaForm" [isModalReport]="true"
              controlName="apinhamento_dentario">
            </app-form-error>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row ml-3">
              <label class="ml-3" for="ausente_apinhamento"><input formControlName="apinhamento_dentario" type="radio"
                  id="ausente_apinhamento" value="Ausente" class="mr-1">
                Ausente</label>
              <label class="ml-3" for="leve_apinhamento"><input formControlName="apinhamento_dentario" type="radio"
                  id="leve_apinhamento" value="Leve" class="mr-1">
                Leve</label>
              <label class="ml-3" for="moderada_apinhamento"><input formControlName="apinhamento_dentario" type="radio"
                  id="moderada_apinhamento" value="Moderada" class="mr-1">
                Moderada</label>
              <label class="ml-3" for="acentuada_apinhamento"><input formControlName="apinhamento_dentario" type="radio"
                  id="acentuada_apinhamento" value="Acentuada" class="mr-1">
                Acentuada</label>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Inserção Óssea Dentária</h3>
            <app-form-error id="insercao_ossea_dentaria" [formGroup]="tomografiaMandibulaForm" [isModalReport]="true"
              controlName="insercao_ossea_dentaria">
            </app-form-error>
          </div>
          <div class="selector row align-content-center mb-1">
            <div class="column align-items-start" *ngFor="let dente of insercaoDentariaMandibula">
              <div *ngIf="dente.flag" class="mr-2">
                <input (change)="toggleInsercaoDentariaMandibula(dente)" type="checkbox" [checked]="dente.checked"
                  [id]="dente.id+'insercao'" class="mt-1 ml-3">
                <label class="ml-3" [for]="dente.id+'insercao'">{{dente.id}}</label>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <h3 *ngIf="existInsercaoImplanteMandibula()" class="field__title">Inserção Óssea Implantes</h3>
            <app-form-error id="insercao_ossea_implantes" [formGroup]="tomografiaMandibulaForm" [isModalReport]="true"
              controlName="insercao_ossea_implantes">
            </app-form-error>
          </div>
          <div class="selector row align-content-center mb-1">
            <div class="column align-items-start" *ngFor="let dente of insercaoImplantesMandibula">
              <div *ngIf="dente.flag" class="mr-2">
                <input (change)="toggleInsercaoImplantesMandibula(dente)" type="checkbox" [checked]="dente.checked"
                  [id]="dente.id+'insercao2'" class="mt-1 ml-3">
                <label class="ml-3" [for]="dente.id+'insercao2'">{{dente.id}}</label>
              </div>
            </div>
          </div>
          <h3 *ngIf="existMolar()" class="field__title">Terceiros Molares Inclusos</h3>
          <div class="selector row align-content-center mb-1">
            <div class="column align-items-start" *ngFor="let dente of molaresMandibula">
              <div *ngIf="dente.flag" class="mr-2">
                <input (change)="toggleMolares(dente)" type="checkbox" [checked]="dente.checked"
                  [id]="dente.id+'molares'" class="mt-1 ml-3">
                <label class="ml-3" [for]="dente.id+'molares'">{{dente.id}}</label>
              </div>
            </div>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row align-items-start ml-5" *ngIf="isCheckedMolares(38)">
              <label class="subtitle">Posição do molar 38</label>
              <label class="ml-3" for="posicao_mesioangular"><input formControlName="posicao_38" type="radio"
                  id="posicao_mesioangular" value="Mesioangular" class="mr-1">
                Mesioangular</label>
              <label class="ml-3" for="posição_vertical"><input formControlName="posicao_38" type="radio"
                  id="posição_vertical" value="Vertical" class="mr-1">
                Vertical</label>
              <label class="ml-3" for="posição_distoangular"><input formControlName="posicao_38" type="radio"
                  id="posição_distoangular" value="Distoangular" class="mr-1">
                Distoangular</label>
              <app-form-error id="posicao_38" [formGroup]="tomografiaMandibulaForm" [isModalReport]="true"
                controlName="posicao_38">
              </app-form-error>
            </div>
            <div class="row align-items-start ml-5" *ngIf="isCheckedMolares(48)">
              <label class="subtitle">Posição do molar 48</label>
              <label class="ml-3" for="posicao_mesioangular_48"><input formControlName="posicao_48" type="radio"
                  id="posicao_mesioangular_48" value="Mesioangular" class="mr-1">
                Mesioangular</label>
              <label class="ml-3" for="posição_vertical_48"><input formControlName="posicao_48" type="radio"
                  id="posição_vertical_48" value="Vertical" class="mr-1">
                Vertical</label>
              <label class="ml-3" for="posição_distoangular_48"><input formControlName="posicao_48" type="radio"
                  id="posição_distoangular_48" value="Distoangular" class="mr-1">
                Distoangular</label>
              <app-form-error id="posicao_48" [formGroup]="tomografiaMandibulaForm" [isModalReport]="true"
                controlName="posicao_48">
              </app-form-error>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Distancia Transversal Anterior</h3>
            <app-form-error id="distancia_transversal_anterior" [formGroup]="tomografiaMandibulaForm"
              [isModalReport]="true" controlName="distancia_transversal_anterior">
            </app-form-error>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row align-items-end">
              <div class="col">
                <input id="anterior" type="text" label="" mask="separator" thousandSeparator="."
                  [dropSpecialCharacters]="false" [formGroup]="tomografiaMandibulaForm"
                  formControlName="distancia_transversal_anterior" />
              </div>
              <label>mm</label>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Distancia Transversal Posterior</h3>
            <app-form-error id="distancia_transversal_posterior" [formGroup]="tomografiaMandibulaForm"
              [isModalReport]="true" controlName="distancia_transversal_posterior">
            </app-form-error>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row align-items-end">
              <div class="col">
                <input id="posterior" type="text" label="" mask="separator" thousandSeparator="."
                  [formGroup]="tomografiaMandibulaForm" formControlName="distancia_transversal_posterior" />
              </div>
              <label>mm</label>
            </div>
          </div>

          <!-- Observações adicionais -->
          <div class="mt-3">
            <div class="d-flex">
              <h3 class="field__title">Observações adicionais</h3>
            </div>
            <div class="d-flex">
              <textarea rows="3" formControlName="observacoes_adicionais"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- TOMOGRAFIA MAXILAR -->
    <div *ngIf="exam.exam_id == TOMOGRAFIA_COMPUTADORIZADA_DE_MAXILA_EXAM_TEMPLATE_ID">
      <div class="mt-3" [formGroup]="tomografiaMaxilarForm">
        <div class="ml-3 mt-3">
          <div class="d-flex">
            <h3 class="field__title">Dentes presentes</h3>
            <app-form-error id="dentes_presentes" [formGroup]="tomografiaMaxilarForm" [isModalReport]="true"
              controlName="dentes_presentes">
            </app-form-error>
          </div>
          <div class="selector row align-content-center mb-1">
            <div class="column align-items-start mr-2" *ngFor="let dente of dentesMaxilar">
              <input (change)="toggleElementosDentariosMaxilar(dente)" type="checkbox" [checked]="dente.checked"
                [id]="dente.id+'presentes2'" class="mt-1 ml-3">
              <label class="ml-3" [for]="dente.id+'presentes2'">{{dente.id}}</label>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Tratamento Endodôntico</h3>
            <app-form-error id="tratamento_endodontico" [formGroup]="tomografiaMaxilarForm" [isModalReport]="true"
              controlName="tratamento_endodontico">
            </app-form-error>
          </div>
          <div class="selector row align-content-center mb-1">
            <div class="column align-items-start" *ngFor="let dente of tratamentoEndodonticoMaxilar">
              <div *ngIf="dente.flag" class="mr-2">
                <input (change)="toggleTratamentoEndodonticoMaxilar(dente)" type="checkbox" [checked]="dente.checked"
                  [id]="dente.id+'tratamentos_endodonticos2'" class="mt-1 ml-3">
                <label class="ml-3" [for]="dente.id+'tratamentos_endodonticos2'">{{dente.id}}</label>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <h3 *ngIf="existImplateMaxilar()" class="field__title">Implantes Osseointegráveis</h3>
            <app-form-error id="implantes_osseointegraveis" [formGroup]="tomografiaMaxilarForm" [isModalReport]="true"
              controlName="implantes_osseointegraveis">
            </app-form-error>
          </div>
          <div class="selector row align-content-center mb-1">
            <div class="column align-items-start" *ngFor="let dente of implantesMaxilar">
              <div *ngIf="dente.flag" class="mr-2">
                <input (change)="toggleImplanteMaxilar(dente)" type="checkbox" [checked]="dente.checked"
                  [id]="dente.id+'implante2'" class="mt-1 ml-3">
                <label class="ml-3" [for]="dente.id+'implante2'">{{dente.id}}</label>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Lesões Paeriapicais</h3>
            <app-form-error id="lesoes_periapicais" [formGroup]="tomografiaMaxilarForm" [isModalReport]="true"
              controlName="lesoes_periapicais">
            </app-form-error>
          </div>
          <div class="selector row align-content-center mb-1">
            <div class="column align-items-start" *ngFor="let dente of lesoesMaxilar">
              <div *ngIf="dente.flag" class="mr-2">
                <input (change)="toggleLesoesMaxilar(dente)" type="checkbox" [checked]="dente.checked"
                  [id]="dente.id+'lesoes2'" class="mt-1 ml-3">
                <label class="ml-3" [for]="dente.id+'lesoes2'">{{dente.id}}</label>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Material Restaurador</h3>
            <app-form-error id="material_restaurador" [formGroup]="tomografiaMaxilarForm" [isModalReport]="true"
              controlName="material_restaurador">
            </app-form-error>
          </div>
          <div class="selector row align-content-center mb-1">
            <div class="row ml-3">
              <label class="ml-3" for="ausente_material2"><input formControlName="material_restaurador" type="radio"
                  id="ausente_material2" value="Ausente" class="mr-1">
                Ausente</label>
              <label class="ml-3" for="diversos_material2"><input formControlName="material_restaurador" type="radio"
                  id="diversos_material2" value="Diversos" class="mr-1">
                Diversos</label>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Assimetria Processo Alveolar</h3>
            <app-form-error id="assimetria_processo_alveolar" [formGroup]="tomografiaMaxilarForm" [isModalReport]="true"
              controlName="assimetria_processo_alveolar">
            </app-form-error>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row ml-3">
              <label class="ml-3" for="ausente_assimetria2"><input formControlName="assimetria_processo_alveolar"
                  type="radio" id="ausente_assimetria2" value="Ausente" class="mr-1">
                Ausente</label>
              <label class="ml-3" for="leve_assimetria2"><input formControlName="assimetria_processo_alveolar"
                  type="radio" id="leve_assimetria2" value="Leve" class="mr-1">
                Leve</label>
              <label class="ml-3" for="moderada_assimetria2"><input formControlName="assimetria_processo_alveolar"
                  type="radio" id="moderada_assimetria2" value="Moderada" class="mr-1">
                Moderada</label>
              <label class="ml-3" for="acentuada_assimetria2"><input formControlName="assimetria_processo_alveolar"
                  type="radio" id="acentuada_assimetria2" value="Acentuada" class="mr-1">
                Acentuada</label>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Desproporção Processo Alveolar</h3>
            <app-form-error id="desproporcao_processo_alveolar" [formGroup]="tomografiaMaxilarForm"
              [isModalReport]="true" controlName="desproporcao_processo_alveolar">
            </app-form-error>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row ml-3">
              <label class="ml-3" for="ausente_desproporcao2"><input formControlName="desproporcao_processo_alveolar"
                  type="radio" id="ausente_desproporcao2" value="Ausente" class="mr-1">
                Ausente</label>
              <label class="ml-3" for="leve_desproporcao2"><input formControlName="desproporcao_processo_alveolar"
                  type="radio" id="leve_desproporcao2" value="Leve" class="mr-1">
                Leve</label>
              <label class="ml-3" for="moderada_desproporcao2"><input formControlName="desproporcao_processo_alveolar"
                  type="radio" id="moderada_desproporcao2" value="Moderada" class="mr-1">
                Moderada</label>
              <label class="ml-3" for="acentuada_desproporcao2"><input formControlName="desproporcao_processo_alveolar"
                  type="radio" id="acentuada_desproporcao2" value="Acentuada" class="mr-1">
                Acentuada</label>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Nivelamento Coronal do Plano Oclusal</h3>
            <app-form-error id="nivelamento_coronal_do_plano_oclusal" [formGroup]="tomografiaMaxilarForm"
              [isModalReport]="true" controlName="nivelamento_coronal_do_plano_oclusal">
            </app-form-error>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row ml-3">
              <label class="ml-3" for="nivelamento2"><input formControlName="nivelamento_coronal_do_plano_oclusal"
                  type="radio" id="nivelamento2" value="Nivelado" class="mr-1">
                Nivelado</label>
              <label class="ml-3" for="nivelamento_leve2"><input formControlName="nivelamento_coronal_do_plano_oclusal"
                  type="radio" id="nivelamento_leve2" value="Desnivelamento leve" class="mr-1">
                Desnivelamento Leve</label>
              <label class="ml-3" for="nivelamento_moderado2"><input
                  formControlName="nivelamento_coronal_do_plano_oclusal" type="radio" id="nivelamento_moderado2"
                  value="Desnivelamento moderado" class="mr-1">
                Desnivelamento Moderado</label>
              <label class="ml-3" for="nivelamento_acentuado2"><input
                  formControlName="nivelamento_coronal_do_plano_oclusal" type="radio" id="nivelamento_acentuado2"
                  value="Desnivelamento acentuado" class="mr-1">
                Desnivelamento Acentuado</label>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Apinhamento Dentário</h3>
            <app-form-error id="apinhamento_dentario" [formGroup]="tomografiaMaxilarForm" [isModalReport]="true"
              controlName="apinhamento_dentario">
            </app-form-error>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row ml-3">
              <label class="ml-3" for="ausente_apinhamento2"><input formControlName="apinhamento_dentario" type="radio"
                  id="ausente_apinhamento2" value="Ausente" class="mr-1">
                Ausente</label>
              <label class="ml-3" for="leve_apinhamento2"><input formControlName="apinhamento_dentario" type="radio"
                  id="leve_apinhamento2" value="Leve" class="mr-1">
                Leve</label>
              <label class="ml-3" for="moderada_apinhamento2"><input formControlName="apinhamento_dentario" type="radio"
                  id="moderada_apinhamento2" value="Moderada" class="mr-1">
                Moderada</label>
              <label class="ml-3" for="acentuada_apinhamento2"><input formControlName="apinhamento_dentario"
                  type="radio" id="acentuada_apinhamento2" value="Acentuada" class="mr-1">
                Acentuada</label>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Inserção Óssea Dentária</h3>
            <app-form-error id="insercao_ossea_dentaria" [formGroup]="tomografiaMaxilarForm" [isModalReport]="true"
              controlName="insercao_ossea_dentaria">
            </app-form-error>
          </div>
          <div class="selector row align-content-center mb-1">
            <div class="column align-items-start" *ngFor="let dente of insercaoDentariaMaxilar">
              <div *ngIf="dente.flag" class="mr-2">
                <input (change)="toggleInsercaoDentariaMaxilar(dente)" type="checkbox" [checked]="dente.checked"
                  [id]="dente.id+'insercao2'" class="mt-1 ml-3">
                <label class="ml-3" [for]="dente.id+'insercao2'">{{dente.id}}</label>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <h3 *ngIf="existInsercaoImplanteMaxilar()" class="field__title">Inserção Óssea Implantes</h3>
            <app-form-error id="insercao_ossea_implantes" [formGroup]="tomografiaMaxilarForm" [isModalReport]="true"
              controlName="insercao_ossea_implantes">
            </app-form-error>
          </div>
          <div class="selector row align-content-center mb-1">
            <div class="column align-items-start" *ngFor="let dente of insercaoImplantesMaxilar">
              <div *ngIf="dente.flag" class="mr-2">
                <input (change)="toggleInsercaoImplantesMaxilar(dente)" type="checkbox" [checked]="dente.checked"
                  [id]="dente.id+'insercao2'" class="mt-1 ml-3">
                <label class="ml-3" [for]="dente.id+'insercao2'">{{dente.id}}</label>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <h3 *ngIf="existMolarMaxilar()" class="field__title">Terceiros Molares Inclusos</h3>
            <app-form-error id="insercao_ossea_implantes" [formGroup]="tomografiaMaxilarForm" [isModalReport]="true"
              controlName="insercao_ossea_implantes">
            </app-form-error>
          </div>
          <div class="selector row align-content-center mb-1">
            <div class="column align-items-start" *ngFor="let dente of molaresMaxilar">
              <div *ngIf="dente.flag" class="mr-2">
                <input (change)="toggleMolaresMaxilar(dente)" type="checkbox" [checked]="dente.checked"
                  [id]="dente.id+'insercao3'" class="mt-1 ml-3">
                <label class="ml-3" [for]="dente.id+'insercao3'">{{dente.id}}</label>
              </div>
            </div>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row align-items-start ml-5" *ngIf="isCheckedMolaresMaxilar(18)">
              <label class="subtitle">Posição do molar 18</label>
              <label class="ml-3" for="posicao_mesioangular2"><input formControlName="posicao_18" type="radio"
                  id="posicao_mesioangular2" value="Mesioangular" class="mr-1">
                Mesioangular</label>
              <label class="ml-3" for="posição_vertical2"><input formControlName="posicao_18" type="radio"
                  id="posição_vertical2" value="Vertical" class="mr-1">
                Vertical</label>
              <label class="ml-3" for="posição_distoangular2"><input formControlName="posicao_18" type="radio"
                  id="posição_distoangular2" value="Distoangular" class="mr-1">
                Distoangular</label>
              <app-form-error id="posicao_18" [formGroup]="tomografiaMaxilarForm" [isModalReport]="true"
                controlName="posicao_18">
              </app-form-error>
            </div>
            <div class="row align-items-start ml-5" *ngIf="isCheckedMolaresMaxilar(28)">
              <label class="subtitle">Posição do molar 28</label>
              <label class="ml-3" for="posicao_mesioangular_28"><input formControlName="posicao_28" type="radio"
                  id="posicao_mesioangular_28" value="Mesioangular" class="mr-1">
                Mesioangular</label>
              <label class="ml-3" for="posição_vertical_28"><input formControlName="posicao_28" type="radio"
                  id="posição_vertical_28" value="Vertical" class="mr-1">
                Vertical</label>
              <label class="ml-3" for="posição_distoangular_28"><input formControlName="posicao_28" type="radio"
                  id="posição_distoangular_28" value="Distoangular" class="mr-1">
                Distoangular</label>
              <app-form-error id="posicao_28" [formGroup]="tomografiaMaxilarForm" [isModalReport]="true"
                controlName="posicao_28">
              </app-form-error>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Distancia Transversal Anterior</h3>
            <app-form-error id="distancia_transversal_anterior" [formGroup]="tomografiaMaxilarForm"
              [isModalReport]="true" controlName="distancia_transversal_anterior">
            </app-form-error>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row align-items-end">
              <div class="col">
                <input id="anterior2" type="text" mask="separator" thousandSeparator="."
                  [dropSpecialCharacters]="false" label="" [formGroup]="tomografiaMaxilarForm"
                  formControlName="distancia_transversal_anterior" />
              </div>
              <label>mm</label>
            </div>
          </div>
          <div class="d-flex">
            <h3 class="field__title">Distancia Transversal Posterior</h3>
            <app-form-error id="distancia_transversal_posterior" [formGroup]="tomografiaMaxilarForm"
              [isModalReport]="true" controlName="distancia_transversal_posterior">
            </app-form-error>
          </div>
          <div class="selector column align-content-center mb-1">
            <div class="row align-items-end">
              <div class="col">
                <input id="posterior2" type="text" mask="separator" thousandSeparator="."
                  [dropSpecialCharacters]="false" label="" formControlName="distancia_transversal_posterior" />
              </div>
              <label>mm</label>
            </div>
          </div>

          <!-- Observações adicionais -->
          <div class="mt-3">
            <div class="d-flex">
              <h3 class="field__title">Observações adicionais</h3>
            </div>
            <div class="d-flex">
              <textarea rows="3" formControlName="observacoes_adicionais"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- TOMOGRAFIA SEIOS DA FACE -->
    <div *ngIf="exam.exam_id == TOMOGRAFIA_COMPUTADORIZADA_DOS_SEIOS_DA_FACE_EXAM_TEMPLATE_ID">
      <form-seios-da-face [variables]="exam.variables"></form-seios-da-face>
    </div>

    <div class="row mt-3 justify-content-between">
      <div class="col-3" [formGroup]="formDate">
        <div class="d-flex">
          <h3 class="field__title">Data do exame</h3>
        </div>
        <input type="date" formControlName="date"/>
      </div>
      <div class="col-9 d-flex justify-content-end">
        <button class="btn btn-link col-sm-12 col-md-3 col-lg-3 mt-5 mb-5 mr-1"
          (click)="activeModal.dismiss()">Cancelar</button>
        <button class="btn btn-primary col-sm-12 col-md-3 col-lg-3 mt-5 mb-5 mr-1" (click)="editReport()">Editar
          laudo</button>
        <button class="btn btn-primary col-sm-12 col-md-3 col-lg-3 mt-5 mb-5" (click)="save()">Salvar</button>
      </div>
    </div>
  </div>

  <ng-template #laudoTemplate>
    <div class="report-modal">
      <ng-container *ngFor="let title of reportTitles; let index = index">
        <h4>{{title}}</h4>
        <textarea [(ngModel)]="reportTextArea[index]" rows="6" placeholder="Observações adicionais"></textarea>
      </ng-container>

      <button class="btn btn-primary" (click)="saveReport()">Salvar</button>
    </div>
  </ng-template>
</div>
