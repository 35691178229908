import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BirdidService } from 'src/app/core/services/birdid.service';
import { FormBuilder, Validators } from '@angular/forms';
import { FieldValidator } from '../../renderers/utils/field-validator/field-validator';
import { HttpErrorResponse } from '@angular/common/http';
import { DefaultModalComponent } from '../../renderers/components/default-modal/default-modal.component';
import { BirdIdAccountModel } from 'src/app/core/models/birdid.model';

@Component({
  selector: 'app-birdid-modal',
  templateUrl: './birdid-modal.component.html',
  styleUrls: ['./birdid-modal.component.scss']
})
export class BirdidModalComponent implements OnInit {

  formGroup = this.fb.group({
    username: ['', [Validators.required, FieldValidator.documentValidator()]],
    password: ['', [Validators.required]],
    lifetime: [64800]
  });

  modalItems!: any;
  submitting: boolean = false;
  birdId: BirdIdAccountModel = {};

  lifeTimeOptions = [
    {
      lifetime: 64800,
      label: 'Expirar em 18 Horas'
    },
    {
      lifetime: 900,
      label: 'Expirar em 15 minutos'
    }
  ];

  constructor(public activeModal: NgbActiveModal, private birdidService: BirdidService, private fb: FormBuilder, private modalService: NgbModal) { }

  ngOnInit(): void {
    let items = JSON.parse(this.modalItems) as { [key: string]: string | undefined }
    this.formGroup.get('username')?.setValue(items.document_number);
  }

  birdidAuth() {
    this.formGroup.markAllAsTouched();
    if(this.formGroup.valid) {
      this.submitting = true;
      let birdidData = this.formGroup.getRawValue();
      this.birdidService.birdidAuth(birdidData).subscribe((response: BirdIdAccountModel) => {
        this.submitting = false;
        this.birdId = response
        this.activeModal.close(this.birdId);
      }, (errorResponse: HttpErrorResponse) => {
        this.mapErrorResponse(errorResponse);
        this.submitting = false;
      });
    }
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      const modalRef = this.modalService.open(DefaultModalComponent, { centered: true });
      modalRef.componentInstance.text = errorResponse.error["detail"];
    } else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "usuario", "password", "lifetime"
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
    });
  }

}
