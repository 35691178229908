<div class="card">
  <div class="row">
    <form>
      <div [ngClass]="[ 'float-left', 'order-1', 'col-3']">
        <app-file-input [placeholder]="getPlaceholder()" (onFileChange)="onFileChange($event)">
        </app-file-input>
      </div>

      <div class="col-9 input-wrapper">
        <div [ngClass]="[ 'float-left', 'order-1', 'col-9']">
          <app-basic-input label="Título" id="name" controlName="name" [formGroup]="attachmentForm"></app-basic-input>
        </div>

        <div [ngClass]="[ 'float-left', 'order-1', 'col-3', 'button-wrapper']">
          <button (click)="addButtonHandler($event)" class="btn btn-primary btn-lg">Adicionar</button>
        </div>
      </div>
    </form>
  </div>

  <div class="row attachments">
    <div class="attachments__wrapper col-3" *ngFor="let attachment of attachments; let index = index">
      <img [src]="attachment.file" (error)="attachment.isImage = false" *ngIf="attachment.isImage == undefined">
      <a class="document-preview-wrapper" *ngIf="attachment.isImage == false"
        (click)="downloadFile(attachment.file!, $event)">
        <div class="document-preview-wrapper__document-preview">
          <i class="icon-document"></i>
        </div>
      </a>
      <label>{{attachment.label}}</label>
      <button class="attachments__wrapper--remove" (click)="removeButtonHandler(index, attachment.id!, $event)">
        X
      </button>
    </div>
  </div>
</div>
