import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { phoneNumberStringFormatter } from 'src/app/core/utils/string-formatters';
import { ListTemplate } from 'src/app/shared/models/list-template';

@Component({
  selector: 'app-leads-list',
  templateUrl: './leads-list.component.html',
  styleUrls: ['./leads-list.component.scss']
})
export class LeadsListComponent implements OnInit {

  searchString: string = "";
  orderingItem: string = "name";

  get canNewData() {
    const permissions = [PermissionConst.add_lead, PermissionConst.change_lead, PermissionConst.view_lead];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  get canExport() {
    const permissions = [PermissionConst.export_lead, PermissionConst.change_lead, PermissionConst.view_lead];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  listTemplate: ListTemplate = {
    loading: true,
    showSearch: true,
    searchBarPlaceholder: "Buscar por Whatsapp",
    showAdvancedSearch: true,
    newDataText: "+ Novo Lead",
    newDataRouterLink: this.canNewData ? "register" : undefined,
    notFoundMessage: "Nenhum lead encontrado",
    header: [
      { name: "phone_number", label: "Whatsapp", widthInPercentage: "20%", width: "150px", displayStringConvertionFunction: phoneNumberStringFormatter },
      { name: "name", label: "Nome", widthInPercentage: "25%", width: "250px" },
      { name: "name_tags", label: "Tags", widthInPercentage: "30%", width: "350px" },
      { name: "created_at", label: "Criado em", widthInPercentage: "25%", width: "150px" }
    ],
    onHeaderItemClick: (value: string) => {
      let invertString = '';
      if (this.orderingItem == value) {
        this.listTemplate.invertedOrdering = !this.listTemplate.invertedOrdering;
        if (this.listTemplate.invertedOrdering)
          invertString = '-';
      }
      this.fetchList(this.searchString, `${invertString}${value}`, this.listTemplate.is_active);
      this.orderingItem = value;
      this.listTemplate.selectedOrdering = value;
    },
    onSearchFocusOut: (value: string) => {
      this.searchString = value;
      this.fetchList(value, this.orderingItem, this.listTemplate.is_active);
    },
    onAdvancedSearchFocusOut: (value: string) => {
      if (value) {
        this.listTemplate.loading = true;
        this.listTemplate.data = undefined;
        this.registersService.leadsSearchTags(value, this.searchString).subscribe(response => {
          this.listTemplate.loading = false;
          this.listTemplate.data = response;
        });
      } else this.fetchList(this.searchString, this.orderingItem, this.listTemplate.is_active);
    },
    onClickActive: () => {
      this.listTemplate.is_active = !this.listTemplate.is_active;
      this.fetchList(this.searchString, this.orderingItem, this.listTemplate.is_active);
    },
    idFieldName: 'phone_number',
    selectedOrdering: this.orderingItem,
    invertedOrdering: false,
    exportUrl: (this.sessionManager.getUserPermission(PermissionConst.export_lead) && this.sessionManager.getUserPermission(PermissionConst.view_lead)) ? '/leads/export' : undefined,
    exportOptions: {
      id: 'ID',
      phone_number: 'Whatsapp',
      is_active: 'Ativo',
      tags: 'Tags de Paciente',
      variables: 'Variáveis'
    }
  };

  constructor(private registersService: RegistersService, private sessionManager: SessionManagerService,
    private titleService: Title) {
    this.titleService.setTitle('ELEVE - Leads');
  }

  ngOnInit(): void {
    this.initializeList();
  }

  initializeList() {
    this.fetchList("", "", this.listTemplate.is_active);
  }

  fetchList(searchString: string, orderingItem: string, isActive: boolean | undefined) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;
    this.registersService.leadsList(searchString, orderingItem, isActive).subscribe(response => {
      this.listTemplate.loading = false;
      this.listTemplate.data = response;
      this.listTemplate.hasNext = response.next != null;
    }, error => {
      const response = error as HttpErrorResponse;
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = response.error['detail'];
    });
  }

  loadMore(page: number) {
    this.listTemplate.loadingNext = true;
    this.registersService.leadsList(this.searchString, this.orderingItem, this.listTemplate.is_active, page).subscribe(
      response => {
        this.listTemplate.hasNext = response.next != null;
        response.results!.map(item => this.listTemplate.data!.results!.push(item));
        this.listTemplate.loadingNext = false;
      },
      error => {
        const response = error as HttpErrorResponse;
        this.listTemplate.loading = false;
        this.listTemplate.error = true;
        this.listTemplate.errorMessage = response.error['detail'];
      });
  }
}
