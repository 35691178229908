import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { DocumentsListComponent } from './documents-list/documents-list.component';
import { DocumentsFormComponent } from './documents-form/documents-form.component';
import {NgSelectModule} from "@ng-select/ng-select";
import {SharedModule} from "../../../../shared/shared.module";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
  declarations: [
    DocumentsListComponent,
    DocumentsFormComponent
  ],
    imports: [
        CommonModule,
        RenderersModule,
        NgSelectModule,
        SharedModule,
        ReactiveFormsModule
    ]
})
export class DocumentsModule { }
