<div *ngIf="!loadingPage else loadingTemplate" [formGroup]="formGroup">
  <div class="row separator">
    <div class="col-12 card">

      <div class="row px-0" [formGroup]="patientFormGroup">
        <div class="col-12 col-md-6 px-2">
          <app-basic-input id="name" label="Paciente" controlName="name" [formGroup]="patientFormGroup">
          </app-basic-input>
        </div>
        <div class="col-12 col-md-4 px-2 ">
          <app-basic-input id="phone_number" label="Telefone" controlName="phone_number" [formGroup]="patientFormGroup">
          </app-basic-input>
          <div class="px-2 col-1 mb-0 text-center whatsapp" *ngIf="patientFormGroup.get('phone_number')?.value">
            <a class="whatsapp-link" target="_blank"
              href="https://wa.me/{{patientFormGroup.get('phone_number')?.value}}">
              <span class="icon icon-Icon-awesome-whatsapp"></span>
            </a>
          </div>
        </div>
        <div class="col-12 col-md-2 d-flex justify-content-center">
          <button class="btn btn-link-primary" (click)="viewExams()">Ver exames</button>
        </div>
      </div>

      <div class="row p-0 m-0 mt-3">
        <div class="px-2 col-sm-2 mt-3">
          <app-basic-input id="zipcode" [formGroup]="addressFormGroup" label="CEP" controlName="zipcode">
          </app-basic-input>
        </div>
        <div class="px-2 col-sm-8 mt-3">
          <app-basic-input id="address" [formGroup]="addressFormGroup" label="Endereço" controlName="address">
          </app-basic-input>
        </div>
        <div class="px-2 col-sm-2 mt-3">
          <app-basic-input id="address_number" [formGroup]="addressFormGroup" label="Número" controlName="number">
          </app-basic-input>
        </div>
        <div class="px-2 col-sm-3 mt-3">
          <app-basic-input id="complement" [formGroup]="addressFormGroup" label="Complemento" controlName="complement">
          </app-basic-input>
        </div>
        <div class="px-2 col-sm-3 mt-3">
          <app-basic-input id="neighborhood" [formGroup]="addressFormGroup" label="Bairro" controlName="neighborhood">
          </app-basic-input>
        </div>
        <div class="px-2 col-sm-3 mt-3">
          <app-basic-input id="city" [formGroup]="addressFormGroup" label="Cidade" controlName="city"></app-basic-input>
        </div>
        <div class="px-2 col-sm-3 mt-3">
          <app-basic-input id="state" [formGroup]="addressFormGroup" label="Estado" controlName="state">
          </app-basic-input>
        </div>
      </div>

      <div class="row p-0 m-0 mt-3">
        <div class="col-5  p-0 m-0 px-2">
          <app-select-input id="oximeter_id" label="Oxímetro" [formGroup]="formGroup" [options]="oximeterOptions"
          [hasOptionDisabled]="true" [searchable]="false" [clearable]="true"></app-select-input>
        </div>
        <div class="col-5  p-0 m-0 px-2">
          <app-select-input id="status" label="Status" [formGroup]="formGroup" [options]="statusOptions"
          [searchable]="false" [clearable]="true"></app-select-input>
        </div>
        <div class="col-2 p-0 m-0 px-2">
          <app-basic-input id="sent_at" [formGroup]="formGroup" label="Data de envio"
            controlName="sent_at" [max]="maxDate" [min]="minDate" type="date">
          </app-basic-input>
        </div>
      </div>

      <div class="row p-0 m-0 mt-3">

        <div class="col-6 col-md-2 m-0 px-2">
          <app-basic-input id="attendance__date_time" [formGroup]="formGroup" label="Data da consulta"
            controlName="attendance__date_time">
          </app-basic-input>
        </div>
        <div class="col-6 col-md-2 p-0 m-0 px-2">
          <app-select-input id="period" label="Período" [formGroup]="formGroup" [options]="periodOptions"
          [searchable]="false" [clearable]="true"></app-select-input>

        </div>
        <div class="col-6 col-md-2 p-0 m-0 px-2">
          <app-basic-input id="exam_nights" [formGroup]="formGroup" controlName="exam_nights" label="Noites"
            (onValueChange)="setDatePolysomnography($event)" type="number">
          </app-basic-input>
        </div>

        <div [formGroup]="formGroup" class="col-6 col-md-3 p-0 m-0 px-2">
          <app-select-input id="attendance__professional_id" label="Profissional" [formGroup]="formGroup" [options]="allProfessionalsList"
          [searchable]="false" [clearable]="true"></app-select-input>
        </div>


        <div class="col-6 col-md-3 p-0 m-0 px-2">
          <label class="field__title">Comprovante de envio</label>
          <div class="row p-0 m-0">
            <div class="d-flex align-items-center col-10 p-0 m-0">
              <label class="btn-link-primary">
                <input type="file" (change)="addAttachment($event)" class="d-none" multiple="false"
                  *ngIf="!attachmentFeedback">
                <span *ngIf="attachmentFileName == ''">Adicionar arquivo</span>
                <span *ngIf="attachmentFileName != ''">{{attachmentFileName}}</span>
              </label>
              <span *ngIf="attachmentFeedback">Carregando - {{attachmentFeedback}}</span>
            </div>
            <div class="col-2 p-0 m-0" *ngIf="downloadFile">
              <i class="i icon icon-download p-0" (click)="downloadFiles(fetchedExam.file!)"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="row p-0 m-0 mt-3">
        <div class="col-12 p-0 px-2">
          <app-textarea-input type="text" label="Observações" id="notes" [formGroup]="formGroup" [rows]="3" controlName="notes"></app-textarea-input>
        </div>
      </div>
    </div>
  </div>

  <div class="row separator" *ngIf="examsPerformed">
    <div class="col-12 card">
      <div class="row p-0 mb-3 d-flex justify-content-between">
        <h3 class="p-0 col-4 m-0">Exames realizados</h3>
        <button class="btn btn-link-primary col-2" [disabled]="fetchedExam.remaining_nights != null && fetchedExam.remaining_nights <= 0 "
          (click)="includeExam()">+ Novo Exame</button>
      </div>
      <div class="row px-0">
        <div class="col-12 p-0 m-0">
          <div class="row table__header">

            <div *ngFor="let header of examsPerformedHeader" [style]="{'min-width': header.widthWithPercentage}"
              [ngClass]="header.name != 'started_at' && header.name != 'ended_at'  ? 'd-flex justify-content-center' : ''">
              <label>{{ header.label }}</label>
            </div>

          </div>

          <div class="row table__body" *ngFor="let row of examsPerformedData" (click)="includeExam(row)">
            <div *ngFor="let colum of examsPerformedHeader" [style]="{'min-width': colum.widthWithPercentage}"
              [ngClass]="colum.name != 'started_at' && colum.name != 'ended_at'  ? 'd-flex justify-content-center' : ''">

              <span *ngIf="colum.name == 'started_at' || colum.name == 'ended_at'">
                {{ getDate(row, colum.name) }}
              </span>

              <span *ngIf="colum.name == 'birthdate'">
                {{ getAge(row) }}
              </span>

              <span *ngIf="colum.name == 'exam_duration'">
                {{ getDuration(row) }}
              </span>

              <span *ngIf="colum.name == 'imc'">
                {{ getKeyByValue(row, colum.name) }}
              </span>

              <span *ngIf="colum.name ==  'snoring'">
                {{ getKeyByValue(row, colum.name) }}%
              </span>

              <span *ngIf="colum.name == 'gender'" class="align-items-center">
                <i-bs [name]="getGender(row)" width="2rem" height="2rem"></i-bs>
              </span>

              <span *ngIf="colum.name == 'conditions'" class="d-flex">
                <div *ngFor="let icon of getConditions(row, colum.name) | keyvalue : originalOrder">
                  <ng-template #tipContent><b>{{icon.value}}</b></ng-template>
                  <i class="{{icon.key}}" [ngbTooltip]="tipContent" placement="top" container="body"
                    tooltipClass="tooltipClass"></i>
                </div>
              </span>

              <span *ngIf="colum.name == 'spo2'" [style]="getBackground(row, colum.name)">
                {{ getSpo2(row) }}
              </span>

              <span *ngIf="colum.name == 'ido'" [style]="getBackground(row, colum.name)">
                {{ getKeyByValue(row, colum.name) }}
              </span>

              <span *ngIf="colum.name == 'report'">
                <button *ngIf="row.snoring_filename" class="attachment" (click)="downloadAttachment(row)"><span>
                    <i-bs name="paperclip" width="2rem" height="2rem"></i-bs>
                  </span></button>
              </span>
            </div>
          </div>

          <div *ngIf="loadingList" class="loading">
            <div class="col card full-loading text-center">
              <div class="spinner-border spinner-border-xl" role="status">
                <span class="sr-only">Carregando...</span>
              </div>
            </div>
          </div>


          <div *ngIf="error" class="error">
            {{errorMessage}}
          </div>

          <div *ngIf="loadingList == false && (!examsPerformedData || examsPerformedData!.length == 0) && !this.error"
            class="message">
            Nenhum exame encontrado
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-end mt-5 mb-5">
    <div class="col-12 col-md-3 mb-3">
      <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
        Cancelar
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3">
      <button (click)="submitHandler(true)" [disabled]="submittingContinueEditing" class="btn btn-primary btn-lg" type="button">
        Salvar e continuar editando
        <span class="spinner-border" *ngIf="submittingContinueEditing"></span>
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3">
      <button (click)="submitHandler(false)" [disabled]="submitting" class="btn btn-primary btn-lg" type="button">
        {{submitButton}}
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>

</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
