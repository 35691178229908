import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfessionsListComponent } from './professions-list/professions-list.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { ProfessionsFormComponent } from './professions-form/professions-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    ProfessionsListComponent,
    ProfessionsFormComponent
  ],
  imports: [
    CommonModule,
    RenderersModule,
    SharedModule,
    ReactiveFormsModule
  ]
})
export class ProfessionsModule { }
