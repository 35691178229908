import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DpDatePickerModule } from 'ng2-date-picker';
import { CalendarModule } from 'angular-calendar';


import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PasswordRecoveryComponent } from './password-recovery/password-recovery.component';
import { PasswordSetComponent } from './password-set/password-set.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { ScheduleNotLoggedInComponent } from './schedule-not-logged-in/schedule-not-logged-in.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [
    LoginComponent,
    PasswordRecoveryComponent,
    PasswordSetComponent,
    ScheduleNotLoggedInComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RenderersModule,
    DpDatePickerModule,
    CalendarModule,
    NgSelectModule,
    NgxMaskModule,
    SharedModule
  ]
})
export class PublicModule { }
