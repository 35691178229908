import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ListTemplate } from 'src/app/shared/renderers/models/list-template';

@Component({
  selector: 'app-patient-edit-schedulings-list',
  templateUrl: './patient-edit-schedulings-list.component.html',
  styleUrls: ['./patient-edit-schedulings-list.component.scss']
})
export class PatientEditSchedulingsListComponent implements OnInit {

  searchString: string = "";
  orderingItem: string = "-date_time";
  patientId: number = 0;

  listTemplate: ListTemplate = {
    loading: true,
    showSearch: true,
    searchBarPlaceholder: "Buscar por agenda, status de agendamento e tipo de atendimento",
    showAdvancedSearch: false,
    searchBarSchedule: false,
    searchBarDateRange: false,
    newDataText: undefined,
    newDataRouterLink: undefined,
    notFoundMessage: "Nenhum agendamento encontrada",
    header: [
      { name: "date_time", label: "Data", widthInPercentage: "15%" },
      { name: "schedule__name", label: "Agenda", widthInPercentage: "25%" },
      { name: "attendance_type__name", label: "Tipo", widthInPercentage: "20%" },
      { name: "scheduling_status__name", label: "Status", widthInPercentage: "20%" },
      { name: 'chartLink', label: 'Prontuário', widthInPercentage: '20%' },
    ],
    onHeaderItemClick: (value: string) => {
      let invertString = '';
      if (this.orderingItem == value) {
        this.listTemplate.invertedOrdering = !this.listTemplate.invertedOrdering;
        if (this.listTemplate.invertedOrdering)
          invertString = '-';
      }
      this.fetchList(this.searchString, `${invertString}${value}`, this.listTemplate.is_active);
      this.orderingItem = value;
      this.listTemplate.selectedOrdering = value;
    },
    onSearchFocusOut: (value: string) => {
      this.searchString = value;
      this.fetchList(value, this.orderingItem, this.listTemplate.is_active);
    },
    onScheduleFocusOut: (value: string) => {
      if (value) {
        this.listTemplate.loading = true;
        this.listTemplate.data = undefined;
        this.registersService.patientGetSchedulings(this.patientId, value, this.searchString).subscribe(response => {
          this.listTemplate.loading = false;
          this.listTemplate.data = response;
        });
      } else this.fetchList(this.searchString, this.orderingItem, this.listTemplate.is_active);
    },
    onDateRangeFocusOut: (value: string) => {
      if (value) {
        this.listTemplate.loading = true;
        this.listTemplate.data = undefined;
        this.registersService.patientGetSchedulings(this.patientId, value, this.searchString).subscribe(response => {
          this.listTemplate.loading = false;
          this.listTemplate.data = response;
        });
      } else this.fetchList(this.searchString, this.orderingItem, this.listTemplate.is_active);
    },
    selectedOrdering: this.orderingItem,
    invertedOrdering: false,
    exportUrl: undefined
  };

  constructor(private registersService: RegistersService, private sessionManager: SessionManagerService,
    private titleService: Title, private activatedRoute: ActivatedRoute) {
    this.titleService.setTitle('ELEVE - Agendamentos paciente');
  }

  ngOnInit(): void {
    this.activatedRoute.parent?.params.subscribe(params => {
      this.patientId = Number(params['id']);
    });
    this.initializeList();
  }

  async initializeList() {
    await this.fetchList("", "-date_time", this.listTemplate.is_active);
  }

  async fetchList(searchString: string, orderingItem: string, isActive: boolean | undefined) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;

    try {
      const listResponse = await this.registersService.patientGetSchedulings(this.patientId, searchString, orderingItem, isActive).toPromise();
      this.listTemplate.loading = false;
      this.listTemplate.data = listResponse;
      this.listTemplate.hasNext = listResponse.next != null;
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = response.error['detail'];
    }
  }

  loadMore(page: number) {
    this.listTemplate.loadingNext = true;
    this.registersService.patientGetSchedulings(this.patientId, this.searchString, this.orderingItem, this.listTemplate.is_active, page).subscribe(
      response => {
        this.listTemplate.hasNext = response.next != null;
        response.results!.map(item => this.listTemplate.data!.results!.push(item));
        this.listTemplate.loadingNext = false;
      },
      error => {
        console.error(error);
        this.listTemplate.loadingNext = false;
      }
    );
  }
}
