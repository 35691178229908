import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FinancialTableComponent } from './financial-table/financial-table.component';
import { RouterModule } from '@angular/router';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';
import { NavigationModule } from '../navigation/navigation.module';
import { FinancialFormComponent } from './financial-form/financial-form.component';
import { NgxBootstrapIconsModule, allIcons } from 'ngx-bootstrap-icons';
import { NgxMaskModule } from 'ngx-mask';
import {CurrencyMaskModule} from "ng2-currency-mask";


@NgModule({
  declarations: [
    FinancialTableComponent,
    FinancialFormComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        RenderersModule,
        NavigationModule,
        SharedModule,
        NgSelectModule,
        NgxMaskModule,
        ReactiveFormsModule,
        NgxBootstrapIconsModule.pick(allIcons),
        CurrencyMaskModule
    ]
})
export class FinancialModule { }
