<div class="toast-container">
  <div class="row">
    <div class="col-2 p-0 m-0 d-flex justify-content-start align-items-center">
      <i-bs name="check" width="5rem" height="5rem"></i-bs>
    </div>
    <div class="col-10 p-0">
      <div class="row">
        <div class="col-10">
          <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
            {{ title }}
          </div>
          <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass"
            [innerHTML]="message">
          </div>
          <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite" [class]="options.messageClass"
            [attr.aria-label]="message">
            {{ message }}
          </div>
        </div>
        <div class="col-2">
          <a (click)="remove()" class="toast-close-button">
            <i-bs name="x" width="3rem" height="3rem"></i-bs>
          </a>
        </div>
      </div>
    </div>
    <div class="col-12 d-flex justify-content-center">
      <button (click)="action($event)" class="btn button">Download</button>
    </div>
  </div>
  <div *ngIf="options.progressBar">
    <div class="toast-progress" [style.width]="width + '%'"></div>
  </div>
</div>
