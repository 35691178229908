<form *ngIf="!isLoading" [formGroup]=" formGroup">
  <fieldset [disabled]="formTemplate.disabled">
    <div *ngIf="formTemplate.rendered">
      <div class="row separator" *ngFor="let separator of formTemplate.separators; let separatorIndex = index">
        <div [formGroupName]="separator.groupName!" class="col card">
          <div class="row">
            <div class="px-2 col-12 titlebar">
              <h3>{{separator.title}}</h3>

              <div *ngIf="separator.selectAll!" class="select-all">
                <input type="checkbox" id="selectAll" name="selectAll"
                       (click)="separator.onSelectAllValueChange!($event)">
                <label for="selectAll" class="">Selecionar tudo</label>
              </div>
            </div>

            <div *ngIf="separator.imageInput" class="col-12 col-lg-3">
              <image-input id="imageInput" [value]="separator.imageValue!"
                           (onImageChange)="onImageChange(separatorIndex, $event)"
                           [disabled]="formTemplate.disabled ?? false">
              </image-input>
            </div>

            <div class="row col remove-padding">
              <div *ngFor="let input of separator.inputs; let inputIndex = index"
                   [ngClass]="[ 'px-2', 'input', input.widthInCols?.xs ? 'col-' + input.widthInCols?.xs : 'col-12', input.widthInCols?.sm ? 'col-sm-' + input.widthInCols?.sm : '', input.widthInCols?.md ? 'col-md-' + input.widthInCols?.md : '', input.widthInCols?.lg ? 'col-lg-' + input.widthInCols?.lg : '', input.widthInCols?.xl ? 'col-xl-' + input.widthInCols?.xl : '']">
                <basic-input *ngIf="input.type == 0" [id]="input.name" [label]="input.label!"
                             [placeholder]="input.placeholder!"
                             [formGroup]="findNestedFormGroup(separator.groupName!)"
                             [maskOptions]="input.maskOptions!" [capitalize]="input.capitalize!">
                </basic-input>

                <search-input *ngIf="input.type == 1" [id]="input.name" [label]="input.label!"
                              [placeholder]="input.placeholder!" [optionList]="input.dropdownOptions!"
                              [formGroup]="findNestedFormGroup(separator.groupName!)"
                              (onValueChange)="onDropdownChange(separatorIndex, inputIndex, $event)"
                              [showAddItem]="input.showAddItem!" [hideClear]="input.hideClear!"
                              [readonly]="input.disabled ?? false">
                </search-input>

                <date-input *ngIf=" input.type==2" [id]="input.name" [label]="input.label!"
                            [placeholder]="input.placeholder!"
                            [formGroup]="findNestedFormGroup(separator.groupName!)">
                </date-input>

                <dropdown-input *ngIf="input.type == 3" [id]="input.name" [label]="input.label!"
                                [placeholder]="input.placeholder!" [optionList]="input.dropdownOptions!"
                                [formGroup]="findNestedFormGroup(separator.groupName!)">
                </dropdown-input>

                <chip-input *ngIf="input.type == 4" [id]="input.name" [label]="input.label!"
                            [placeholder]="input.placeholder!"
                            [formGroup]="findNestedFormGroup(separator.groupName!)"
                            [optionList]="input.dropdownOptions!"
                            [disabled]="formDisabled">
                </chip-input>

                <multiline-input *ngIf="input.type == 5" [id]="input.name" [label]="input.label!"
                                 [placeholder]="input.placeholder!"
                                 [formGroup]="findNestedFormGroup(separator.groupName!)">
                </multiline-input>

                <multidata-input *ngIf="input.type == 6" [id]="input.name" [label]="input.label!"
                                 [array]="findMultiDataFormArray(separator.groupName!, input.name)"
                                 [canRemoveFirst]="input.canRemoveFirst!" [inputs]="input.multiDataInputs!"
                                 [formGroup]="findNestedFormGroup(separator.groupName!)"
                                 [healthInsuranceData]="input.healthInsuranceData!"
                                 [healthInsuranceEdit]="input.healthInsuranceEdit!"
                                 [phoneNumber]="input.phoneNumberMultidata!"
                                 [readOnlyMultidata]="input.readOnlyMultidata!"
                                 [readOnlyMultidataInitialValues]="input.readOnlyMultidataInitialValues!"
                                 [showAddItem]="input.showAddItem!"
                                 [canRemoveAll]="input.canRemoveAll!">
                </multidata-input>

                <radio-input *ngIf="input.type == 8" [id]="input.name" [label]="input.label!"
                             [placeholder]="input.placeholder!" [optionList]="input.dropdownOptions!"
                             [formGroup]="findNestedFormGroup(separator.groupName!)" [disabled]="formDisabled">
                </radio-input>

                <checkbox-input *ngIf="input.type == 9" [id]="input.name" [label]="input.label!"
                                [placeholder]="input.placeholder!" [optionList]="input.dropdownOptions!"
                                [formGroup]="findNestedFormGroup(separator.groupName!)"
                                (change)="onCheckChange($event)"
                                [disabled]="formDisabled">
                </checkbox-input>

                <checkbox-input *ngIf="input.type == 10" [columns]="true" [id]="input.name"
                                [label]="input.label!" [placeholder]="input.placeholder!"
                                [optionList]="input.dropdownOptions!"
                                [formGroup]="findNestedFormGroup(separator.groupName!)"
                                (change)="onCheckChange($event)"
                                [disabled]="formDisabled">
                </checkbox-input>

                <schedule *ngIf="input.type == 11" [id]="input.name" [label]="input.label!"
                          [placeholder]="input.placeholder!"
                          [formGroup]="findNestedFormGroup(separator.groupName!)">
                </schedule>

                <readonly *ngIf="input.type == 12" [id]="input.name" [label]="input.label!"
                          [placeholder]="input.placeholder!"
                          [formGroup]="findNestedFormGroup(separator.groupName!)"
                          [maskOptions]="input.maskOptions!">
                </readonly>

                <radio-input *ngIf="input.type == 13" [id]="input.name" [label]="input.label!"
                             [placeholder]="input.placeholder!" [optionList]="input.dropdownOptions!"
                             [formGroup]="findNestedFormGroup(separator.groupName!)" [inline]="true"
                             (onValueChange)="onSubSelectorChange(separatorIndex, inputIndex, $event)"
                             [disabled]="formDisabled">
                </radio-input>

                <dropdown-input *ngIf="input.type == 14" [id]="input.name" [label]="input.label!"
                                [placeholder]="input.placeholder!" [optionList]="input.dropdownOptions!"
                                [formGroup]="findNestedFormGroup(separator.groupName!)"
                                (onValueChange)="onDropdownChange(separatorIndex, inputIndex, $event)">
                </dropdown-input>

                <attachments-input *ngIf="input.type == 15" [attachmetsInput]="input.value">
                </attachments-input>

                <number-only-input *ngIf="input.type == 16" [id]="input.name" [label]="input.label!"
                                   [placeholder]="input.placeholder!"
                                   [formGroup]="findNestedFormGroup(separator.groupName!)">
                </number-only-input>

                <zipcode-input *ngIf="input.type == 17" [id]="input.name" [label]="input.label!"
                               [placeholder]="input.placeholder!"
                               [formGroup]="findNestedFormGroup(separator.groupName!)"
                               (onValueChange)="onZipCodeChange(separatorIndex, inputIndex, $event)">
                </zipcode-input>

                <ddi-input *ngIf="input.type == 18" [id]="input.name" [label]="input.label!"
                           [placeholder]="input.placeholder!"
                           [formGroup]="findNestedFormGroup(separator.groupName!)">
                </ddi-input>

                <ul *ngIf="input.type == 19">
                  <h5>{{ input.label }}</h5>

                  <ul>
                    <li *ngFor="let item of input.healthInsurances">
                      <strong>Operadora</strong>: {{ item.health_insurance__name }} -
                      <strong>Plano</strong>: {{ item.health_insurance_plan__name }} -
                      <strong>Número</strong>: {{ item.number }}
                    </li>
                  </ul>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </fieldset>
</form>

<div *ngIf="isLoading" class="col card full-loading">
  <div class="spinner-border spinner-border-xl" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div>
  <div class="row justify-content-end mt-5 mb-5" *ngIf="formTemplate.cancelButton || formTemplate.submitButton || formTemplate.submitButton2 || formTemplate.removeButton">
    <div class="col-sm-12 col-md-3 col-lg-2 mb-3">
      <button *ngIf=" formTemplate.cancelButton" (click)="formCancelHandler()" [disabled]="formTemplate.loading"
              class="btn btn-link btn-lg" type="button">
        {{formTemplate.cancelButton.label}}
      </button>
    </div>

    <div *ngIf="formTemplate.submitButton2 && formTemplate.submitButton2.label != undefined" class="col-sm-12 col-md-3 mb-3">
      <button (click)="formSubmitHandler2()"
              [disabled]="formTemplate.loading || isLoading || !formGroup!.valid || formTemplate.submitButton2.disabled"
              class="btn btn-tertiary btn-lg" type="button">
        {{formTemplate.submitButton2.label}}
      </button>
    </div>

    <div *ngIf="formTemplate.removeButton && formTemplate.removeButton.label != undefined" class="col-sm-12 col-md-3 col-lg-3 mb-3">
      <button (click)="formRemoveHandler()"
              [disabled]="formTemplate.loading || isLoading || formTemplate.removeButton.disabled"
              class="btn btn-secondary btn-lg" type="button">
        {{formTemplate.removeButton.label}}
      </button>
    </div>

    <div *ngIf="formTemplate.submitButton && formTemplate.submitButton.label != undefined" class="col-sm-12 col-md-3 col-lg-3 mb-3">
      <button (click)="formSubmitHandler()"
              [disabled]="formTemplate.loading || isLoading || formTemplate.submitButton.disabled"
              class="btn btn-primary btn-lg" type="button">
        {{formTemplate.submitButton.label}}

        <span *ngIf="formTemplate.loading" class=" spinner-border" role="status" aria-hidden="true"></span>
      </button>
    </div>
  </div>
</div>
