import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegistersService } from 'src/app/core/services/registers.service';
import { PatientDocument } from 'src/app/core/models/patientDocument.model';
import { ListModel } from 'src/app/core/models/list.model';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';
import { ListTemplate } from 'src/app/shared/renderers/models/list-template';
import { HttpErrorResponse } from '@angular/common/http';
import { list } from 'ngx-bootstrap-icons';

@Component({
  selector: 'app-patient-edit-documents',
  templateUrl: './patient-edit-documents.component.html',
  styleUrls: ['./patient-edit-documents.component.scss']
})
export class PatientEditDocumentsComponent implements OnInit {

  search: string = '';
  ordering: string = '-created_at';
  patientDocuments: PatientDocument[] = [];

  isLoading: boolean = true;
  patientId?: number;

  listTemplate: ListTemplate = {
    loading: true,
    showSearch: false,
    showAdvancedSearch: false,
    showInfo: false,
    notFoundMessage: "Nenhum documento encontrado.",
    header: [
      { name: "created_at", label: "Data e hora", widthInPercentage: "25%" },
      { name: "file", label: "Documento", widthInPercentage: "50%" },
      { name: "professional__name", label: "Profissional", widthInPercentage: "25%" }
    ],
    onHeaderItemClick: (value: string) => {
      let invertString = '';
      if (this.ordering == value) {
        this.listTemplate.invertedOrdering = !this.listTemplate.invertedOrdering;
        if (this.listTemplate.invertedOrdering)
          invertString = '-';
      }
      if (value == 'filename')
        value = 'file';
      this.fetchList(this.search, `${invertString}${value}`, this.patientId!);
      this.ordering = value;
      this.listTemplate.selectedOrdering = value;
    },
    selectedOrdering: this.ordering,
    invertedOrdering: false,
  };

  constructor(private activatedRoute: ActivatedRoute, private registersService: RegistersService, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.activatedRoute.parent?.params.subscribe(params => {
      this.patientId = params['id']
      this.fetchList('', '-created_at', this.patientId!)
    })
  };

  fetchList(search: string, orderingItem: string, patientId: number) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;
    this.registersService.patientDocuments(patientId, search, orderingItem).subscribe((response: any) => {
      this.listTemplate.data = response;
      this.listTemplate.loading = false;
      this.listTemplate.hasNext = response.next != null;
    }, (error: HttpErrorResponse) => {
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = error.error['detail'];
      console.error(error);
    });
  }

  loadMore(page: number) {
    this.listTemplate.loadingNext = true;
    this.registersService.patientDocuments(this.patientId!, this.search, this.ordering, page).subscribe((response: ListModel) => {
      response.results?.forEach(result => this.listTemplate.data?.results?.push(result));
      this.listTemplate.loading = false;
      this.listTemplate.hasNext = response.next != null;
      this.listTemplate.loadingNext = false;
    }, (error: HttpErrorResponse) => {
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = error.error['detail'];
      this.listTemplate.loadingNext = false;
      console.error(error);
    });
  }
}
