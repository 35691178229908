<!-- <div class="row p-0 m-0 button">
  <button class="d-lg-none menu-button" (click)="menuButtonHandler()">
    <i class="icon-Icon-material-edit"></i>
  </button>
</div>

<div class="row p-0 m-0 header">
  <div class="col-12">
    <app-header title="Paciente" [subTitle]="patientName"></app-header>
  </div>
</div>
<div class="row p-0 m-0 content">
  <div class="content-patient">
    <div class="col-lg-2 nav-list" [ngClass]="showMenuSidebar ? '' : 'd-none d-lg-block'">
      <list-nav></list-nav>
    </div>
    <div class="col-12 col-lg-10">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div *ngIf="showMenuSidebar" class="navbar-backdrop d-lg-none" (click)="menuButtonHandler()">
</div> -->


<div class="row p-0 m-0 button">
  <button class="d-lg-none menu-button" (click)="menuButtonHandler()">
      <i class="icon-Icon-material-edit"></i>
  </button>
</div>

<div class="row p-0 m-0 header">
  <div class="col-12">
      <app-header title="Paciente" [subTitle]="patientName"></app-header>
  </div>
</div>
<div class="row p-0 m-0 content">
  <div class="col-xl-2 col-lg-3 nav-list pl-0" [ngClass]="showMenuSidebar ? '' : 'd-none d-lg-block'">
      <list-nav ></list-nav>
  </div>
  <div class="col-12 col-lg-9 col-xl-10 pr-0">
      <router-outlet></router-outlet>
  </div>
</div>

<div *ngIf="showMenuSidebar" class="navbar-backdrop d-lg-none" (click)="menuButtonHandler()">
</div>
