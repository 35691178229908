import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { ChatbotService } from 'src/app/core/services/chatbot.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';

@Component({
  selector: 'app-end-conversation-form',
  templateUrl: './end-conversation-form.component.html',
  styleUrls: ['./end-conversation-form.component.scss']
})
export class EndConversationFormComponent implements OnInit {

  formGroup = this.fb.group({
    country_code: ['+55'],
    phone_number: ['', [Validators.required]]
  });

  loadingPage: boolean = false;

  submitting: boolean = false;

  button: boolean = false;

  constructor(private chatbotService: ChatbotService, private router: Router, private fb: FormBuilder,
    private sessionManager: SessionManagerService, private toast: ToastrService) { }

  ngOnInit(): void {
  }

  get canSave() {
    return this.sessionManager.getUserPermission(PermissionConst.add_chatbotmessage);
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_chatbotmessage);
  }

  submitHandler() {
    this.submitting = true;
    this.formGroup.markAllAsTouched();
    let phoneNumber = this.formGroup.get('country_code')?.value + this.formGroup.get('phone_number')?.value;
    let phoneNumberData = {
      phone_number: phoneNumber.replace(/\D/g, "")
    };
    if(this.formGroup.valid) {
      this.chatbotService.endConversation(phoneNumberData).subscribe(response => {
        this.submitting = false;
        this.formGroup.get('phone_number')?.reset();
        this.toast.success("Conversa encerrada com sucesso", "Sucesso");
      }, (error: HttpErrorResponse) => {
        this.mapErrorResponse(error);
        this.submitting = false;
      });
    } else {
      this.submitting = false;
    }
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    } else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "phone_number"
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
    });
  }

}
