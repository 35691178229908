import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RegistersService } from './core/services/registers.service';
import { DocumentGeneration } from "./core/models/document-generation";
import { ToastButtonComponent } from './shared/components/toast-button/toast-button.component';
import * as Sentry from "@sentry/angular";
import { AccountsService } from './core/services/accounts.service';
import { SessionManagerService } from './core/services/session-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  DOCUMENT_STATUS_SUCCESS = ['SIGNED', 'GENERATED', 'FAILED_ON_SIGNIN', 'FAILED_ON_GENERATING'];

  constructor(private accountService: AccountsService, private registerService: RegistersService, private toast: ToastrService, private sessionManager: SessionManagerService) { }

  get accountId(): number | undefined {
    return this.sessionManager.accountData.id;
  }

  ngOnInit() {
    setInterval(() => {
      if (this.generatingDocuments.length > 0) {
        let uuids: string = this.generatingDocuments.map(document => document.uuid).join(',');
        this.registerService.celeryGet(uuids).subscribe(response => {
          response.forEach(document => this.__resolveDocumentIfNecessary(document));
          let documents = response.filter(document => !this.DOCUMENT_STATUS_SUCCESS.includes(document.status!));
          sessionStorage.setItem('documents', JSON.stringify(documents));
        });
      }
    }, 5000);
    let delay = 60 * 60 * 1000;
    let now = new Date();
    let start = delay - (now.getMinutes() * 60 + now.getSeconds()) * 1000 + now.getMilliseconds();
    setInterval(() => {
      if (this.accountId) {
        this.accountService.accountGet(this.accountId).subscribe(response => {
          this.sessionManager.setSessionData(response);
        }, errorResponse => {
          this.toast.error(errorResponse.error["detail"], "Erro");
        });
      }
    }, start);
  }

  private __resolveDocumentIfNecessary(document: DocumentGeneration) {
    if (document.status! === 'SIGNED') {
      let toastr = this.toast.show(`Documento ${document.name} assinado`, 'Sucesso', {
        toastComponent: ToastButtonComponent,
        timeOut: 10000
      });
      toastr.onAction.subscribe(() => {
        this.downloadDocument(document.message);
      });
    }

    if (document.status! === 'GENERATED') {
      let toastr = this.toast.show(`Documento ${document.name} gerado`, 'Sucesso', {
        toastComponent: ToastButtonComponent,
        timeOut: 10000,
      });
      toastr.onAction.subscribe(() => {
        this.downloadDocument(document.message);
      })
    }

    if (document.status! === 'FAILED_ON_SIGNIN') {
      this.toast.error(`Falha ao assinar documento ${document.name}`, 'Erro');
    }

    if (document.status! === 'FAILED_ON_GENERATING') {
      this.toast.error(`Falha ao gerar documento `, 'Erro');
    }
  }

  get generatingDocuments(): DocumentGeneration[] {
    let storedDocuments = sessionStorage.getItem('documents');
    if (storedDocuments) {
      let documents = JSON.parse(storedDocuments) as DocumentGeneration[];
      return documents;
    }
    return [];
  }

  downloadDocument(url: any) {
    window.open(url, '_blank');
  }
}
