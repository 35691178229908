<div *ngIf="!loadingPage else loadingTemplate">
  <div class="row mb-5">
    <div class="col card">
      <div class="col px-0">
        <h3 class="mb-4">Status do Financeiro</h3>
      </div>

      <div class="row px-0" [formGroup]="formGroup">

        <div class="col-6 px-0 pr-4">
          <app-basic-input id="name" label="Nome" controlName="name" [formGroup]="formGroup"></app-basic-input>
        </div>
        <div class="col-3 px-0 pr-2">
          <label>Cor do plano de fundo</label>
          <div class="input-color" [ngClass]="formGroup.get('background_color')!.errors && formGroup.get('background_color')!.touched ? 'error' : ''">
            <input type="color" formControlName="background_color" id="background_color">
          </div>
          <app-form-error id="background_color" [formGroup]="formGroup" controlName="background_color">
          </app-form-error>
        </div>
        <div class="col-3 px-0 pl-2">
          <label>Cor do texto</label>
          <div class="input-color" [ngClass]="formGroup.get('font_color')!.errors && formGroup.get('font_color')!.touched ? 'error' : ''">
            <input type="color" formControlName="font_color" id="font_color">
          </div>
          <app-form-error id="font_color" [formGroup]="formGroup" controlName="font_color">
          </app-form-error>
        </div>
        <div class="col-6 p-0 m-0 mt-3 pr-4">
          <label>Estágio</label>
          <ng-container>
            <ng-select [formGroup]="formGroup" formControlName="stage_id"
            [ngClass]="formGroup.get('stage_id')!.errors ? 'error' : 'custom'"
              notFoundText="Não encontrado" [addTag]="false">
              <ng-option *ngFor="let item of stageList" [value]="item.id">
                {{item.name}}
              </ng-option>
            </ng-select>
          </ng-container>
          <app-form-error id="stage_id" [formGroup]="formGroup" controlName="stage_id">
          </app-form-error>
        </div>

      </div>
    </div>
  </div>

  <div class="row justify-content-end mt-5 mb-5">
    <div class="col-12 col-md-3 mb-3">
      <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
        Cancelar
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3" *ngIf="button">
      <button (click)="removeHandler()" [disabled]="submittingRemove" class="btn btn-secondary btn-lg" type="button">
        {{removeButton}}
        <span class="spinner-border" *ngIf="submittingRemove"></span>
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3">
      <button (click)="submitHandler()" [disabled]="submitting" class="btn btn-primary btn-lg" type="button">
        {{submitButton}}
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
