<div *ngIf="formGroup && formGroup.get(id)" [formGroup]="formGroup" class="field">
  <label class="field__title" [for]="id" *ngIf="label">{{label ? label : ""}}</label>

  <ng-select *ngIf="hasOptionDisabled" [id]="id" [hideSelected]="hideSelected"
    [multiple]="multiple" [ngClass]="formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : 'custom'" [clearable]="clearable"
    [addTagText]="addTagText" notFoundText="Não encontrado" [formControlName]="id" [addTag]="addNewTag" [searchable]="searchable" (click)="clickHandle($event)">

    <ng-option *ngFor="let option of optionsList" [disabled]="option.is_active" [value]="option.value" >
      {{option.label}}
    </ng-option>
  </ng-select>

  <ng-select *ngIf="!hasOptionDisabled" [id]="id" [items]="optionsList" bindLabel="label" bindValue="value" [hideSelected]="hideSelected"
    [multiple]="multiple" [ngClass]="formGroup.get(id)!.errors && formGroup.get(id)!.touched ? 'error' : 'custom'" [clearable]="clearable"
    [addTagText]="addTagText" notFoundText="Não encontrado" [formControlName]="id" [addTag]="addNewTag" [searchable]="searchable" (click)="clickHandle($event)">

    <ng-template ng-label-tmp let-item="item" let-clear="clear">
      <span class="ng-value-label">{{item.label}}</span>
      <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="false">×</span>
    </ng-template>

    <ng-template ng-option-tmp let-item="item">
      <span class="ng-value-label">{{item.label}}</span>
    </ng-template>
  </ng-select>

  <app-form-error [id]="id" [formGroup]="formGroup" *ngIf="showError" [controlName]="id"></app-form-error>
</div>
