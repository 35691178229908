import { Component, EventEmitter, HostListener, Input, OnInit, Output, TemplateRef, ViewChild, ViewContainerRef } from "@angular/core";
import * as moment from "moment";
import { Router } from "@angular/router";
import { SchedulingStatusModel } from "src/app/core/models/scheduling-status.model";
import { AttendanceTypeModel } from "../../../../core/models/attendance-type.model";
import {
  CreateReminderModalComponent
} from "../../../../shared/components/create-reminder-modal/create-reminder-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ReminderStatusModel } from "src/app/core/models/reminder-status.model";

@Component({
  selector: 'event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements OnInit {
  @Input() scheduleId?: number;
  @Input() date: any;
  @Input() source: any;
  @Input() isWeekly: boolean = true;
  @Input() isReminder: boolean = false;
  @Input() selectedToAction: any[] = [];
  @Input() attendanceTypes: AttendanceTypeModel[] = [];

  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onContextMenu: EventEmitter<any> = new EventEmitter();
  @Output() onContextMenuInclude: EventEmitter<any> = new EventEmitter();
  @Output() onContextMenuReminder: EventEmitter<any> = new EventEmitter();
  @Output() onContextMenuIncludeReminder: EventEmitter<any> = new EventEmitter();
  @Output() onScheduleUpdated: EventEmitter<any> = new EventEmitter();

  schedulingStatusBackgroundColor?: { [key: string]: string | undefined };
  schedulingStatusFontColor?: { [key: string]: string | undefined };
  attendancesType: { [key: number]: AttendanceTypeModel } = {};
  reminderStatus: { [key: number]: ReminderStatusModel } = {};

  schedulingStatus: SchedulingStatusModel[] = [];

  enabledStatus: boolean = true;

  get hour() {
    return moment(this.date).format('HH:mm');
  }

  getDuration(start: any, end: any) {
    let duration = moment(end).diff(moment().startOf('day'), 'minutes') - moment(start).diff(moment().startOf('day'), 'minutes') + ' min';
    return duration;
  }

  constructor(private router: Router, private modalService: NgbModal) {
  }

  ngOnInit() {
  }

  contextMenu($event: MouseEvent, item: any): void {
    if (!this.isEnabled(item))
      return;
    this.onContextMenu.emit({ $event, item });
  }

  contextMenuInclude($event: MouseEvent, item: any): void {
    let scheduleId = this.scheduleId;
    this.onContextMenuInclude.emit({ $event, item, scheduleId });
  }

  contextMenuIncludeReminder($event: MouseEvent, item: any,) {
    this.onContextMenuIncludeReminder.emit({ $event, item});
  }

  contextMenuReminder($event: MouseEvent, item: any,) {
    this.onContextMenuReminder.emit({ $event, item});
  }

  getAttendanceType(attendance: any): AttendanceTypeModel | undefined {
    if (!attendance)
      return;
    if (Object.keys(this.attendancesType).length == 0) {
      let attendancesTypeStringified = localStorage.getItem('attendanceType');
      if (attendancesTypeStringified)
        this.attendancesType = JSON.parse(attendancesTypeStringified) as { [key: number]: AttendanceTypeModel };
    }
    return this.attendancesType![attendance['attendance_type']];
  }

  getAttendanceTypeName(attendance: any) {
    let attendanceType = this.getAttendanceType(attendance);
    if (attendanceType)
      return attendanceType.name;
    return '';
  }

  getAttendanceTypeBackgroundColor(attendance: any) {
    let attendanceType = this.getAttendanceType(attendance);
    if (attendanceType)
      return attendanceType.background_color;
    return '';
  }

  getAttendanceTypeColor(attendance: any) {
    let attendanceType = this.getAttendanceType(attendance);
    if (attendanceType)
      return attendanceType.font_color;
    return '';
  }

  getReminderStatus(reminder: any): ReminderStatusModel | undefined {
    if (!reminder)
      return;
    if (Object.keys(this.reminderStatus).length == 0) {
      let reminderStatusStringified = localStorage.getItem('reminderStatus');
      if (reminderStatusStringified)
        this.reminderStatus = JSON.parse(reminderStatusStringified) as { [key: number]: ReminderStatusModel };
    }
    return this.reminderStatus![reminder['status_id']];
  }

  getReminderStatusName(reminder: any) {
    let ReminderStatus = this.getReminderStatus(reminder);
    if (ReminderStatus)
      return ReminderStatus.name;
    return '';
  }

  getReminderStatusBackgroundColor(reminder: any) {
    let ReminderStatus = this.getReminderStatus(reminder);
    if (ReminderStatus)
      return ReminderStatus.background_color;
    return '';
  }

  getReminderStatusColor(reminder: any) {
    let ReminderStatus = this.getReminderStatus(reminder);
    if (ReminderStatus)
      return ReminderStatus.font_color;
    return '';
  }

  isEnabled(availableTime: any) {
    if (availableTime['allowed_attendance_types']) {
      let attendanceTypeIds = this.attendanceTypes.map(item => item.id);
      let allowedAttendanceTypes: any[] = availableTime['allowed_attendance_types'];
      return allowedAttendanceTypes.some(item => attendanceTypeIds.indexOf(item['id']) !== -1);
    } else return '';
  }

  isAvailable() {
    if (!this.isReminder) {
      let date = moment(this.date);
      let events = this.getAvailableTimes(date);
      return events.length > 0;
    } else {
      return false;
    }
  }

  isAvailableReminder() {
    if(this.isReminder) {
      let date = moment(this.date);
      let events = this.getReminder(date);
      return events.length > 0;
    } else {
      return false;
    }
  }

  getAvailableTimes(date: any) {
    date = moment(date);
    if (!this.isReminder) {
      return this.source[date.format('YYYY-MM-DD')].filter((item: any) => {
        let eventDate = moment(item['start_time']);
        return date.hours() == eventDate.hours();
      });
    } else {
      this.getReminder(date);
    }
  }

  getReminder(date: any) {
    date = moment(date);
    return this.source[date.format('YYYY-MM-DD')];
  }

  getColorClass(attendance: any): string {
    if (!attendance)
      return 'event--empty';
    return `event--has-attendance`;
  }

  getEnabledStatus(attendance: any, status: any) {
    if (!attendance)
      return true;
    if (attendance.scheduling_status == status)
      return false;
    return true;
  }

  getSchedulingStatusBackgroundColor(attendance: any) {
    if (!attendance)
      return '#ffffff';
    if (!this.schedulingStatusBackgroundColor) {
      let schedulingStatusStringified = localStorage.getItem('schedulingStatusBackgroundColor');
      if (schedulingStatusStringified)
        this.schedulingStatusBackgroundColor = JSON.parse(schedulingStatusStringified) as { [key: string]: string | undefined };
    }
    return this.schedulingStatusBackgroundColor![attendance['scheduling_status']] || '#ffffff';
  }

  getSchedulingStatusFontColor(attendance: any) {
    if (!attendance)
      return '#000000';
    if (!this.schedulingStatusFontColor) {
      let schedulingStatusStringified = localStorage.getItem('schedulingStatusFontColor');
      if (schedulingStatusStringified)
        this.schedulingStatusFontColor = JSON.parse(schedulingStatusStringified) as { [key: string]: string | undefined };
    }
    return this.schedulingStatusFontColor![attendance['scheduling_status']] || '#000000';
  }

  goToEvent(availableTime: any) {
    if (!availableTime['attendance']) {
      let formattedDate = moment(availableTime['start_time']).toISOString(true);
      this.router.navigate(['/dashboard/schedules/new-event'], {
        queryParams: {
          scheduleId: this.scheduleId!,
          date: formattedDate,
          isWeekly: this.isWeekly,
          attendanceTimeId: availableTime['id']
        }
      });
    } else {
      let isWeekly = this.isWeekly;
      let attendanceId = availableTime['attendance']['id'];
      let attendanceTimeId = availableTime['id'];
      this.router.navigate([`/dashboard/schedules/${this.scheduleId}/edit-event/${attendanceId}`], { queryParams: { isWeekly, attendanceTimeId } });
    }
  }

  onClick(ev: any, availableTime: any) {
    let targetTag = ev.target.nodeName;
    if (targetTag == 'A') {
      if (targetTag == 'SPAN')
        return
      return
    }
    if (ev.ctrlKey || ev.metaKey) {
      this.onSelect.emit(availableTime);
    } else {
      if (!this.isEnabled(availableTime))
        return;
      this.goToEvent(availableTime);
    }
  }

  openReminder($event: any, reminder: any) {
    const modalRef = this.modalService.open(CreateReminderModalComponent, { centered: true });
    modalRef.componentInstance.action = 'edit';
    modalRef.componentInstance.modalItems = reminder;
    modalRef.result.then((result: any) => {
      if (!result) {
        return
      } else this.onScheduleUpdated.emit(result);
    });
  }

  isSelectedToAction(availableTime: any) {
    return this.selectedToAction.some(obj => obj['id'] == availableTime['id']);
  }
}
