import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { SharedModule } from 'src/app/shared/shared.module';

import { TriggersListComponent } from './triggers-list/triggers-list.component';
import { TriggersFormComponent } from './triggers-form/triggers-form.component';

@NgModule({
  declarations: [
    TriggersListComponent,
    TriggersFormComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RenderersModule,
    SharedModule,
  ]
})
export class TriggersModule { }
