<div class="row p-0 m-0 header">
  <div class="col-12">
    <app-header title="Financeiro" subTitle="{{subTitle}}"></app-header>
  </div>
</div>
<div class="content">
  <div class="card" *ngIf="!loadingPage else loadingTemplate" [formGroup]="formGroup">
    <div class="case-datas mt-2">
      <h3>Dados do caso</h3>
      <div class="forms">
        <div class="upper row m-0 p-0">
          <div class="forms__container col-2 m-0 p-0">
            <label class="pl-3" for="date">Data do atendimento</label>
            <input id="date" type="date" formControlName="date_time"
              [ngClass]="formGroup.get('date_time')!.errors && formGroup.get('date_time')!.touched ? 'errorInput' : ''">
            <app-form-error id="date" [formGroup]="formGroup" controlName="date_time">
            </app-form-error>
          </div>
          <div class="col-3 m-0 pr-0">
            <label class="pl-3">Responsável pelo atendimento</label>
            <ng-select formControlName="user_id" notFoundText="Não encontrado" [disabled]="true" [searchable]="false"
              [items]="userList" bindLabel="name" bindValue="id" id="user_id"
              [ngClass]="formGroup.get('user_id')!.errors && formGroup.get('user_id')!.touched ? 'error' : 'custom'">
              <ng-template ng-label-tmp let-item="item">
                {{ item.name }} <span *ngIf="!item.is_active">(Desativado)</span>
              </ng-template>

              <ng-template ng-option-tmp let-item="item">
                {{ item.name }} <span *ngIf="!item.is_active">(Desativado)</span>
              </ng-template>
            </ng-select>
            <app-form-error id="user_id" [formGroup]="formGroup" controlName="user_id">
            </app-form-error>
          </div>

          <div class="col-5 p-0">
            <div class="row">
              <div class="col-5 pr-0">
                <label for="login" class="pl-3">Login</label>
                <input type="text" id="login" formControlName="login" *ngIf="showLoginAndPassword else inputBlockedTemplate">
              </div>

              <div class="col-5">
                <label for="password" class="pl-3">Senha</label>
                <input type="text" id="password" formControlName="password" *ngIf="showLoginAndPassword else inputBlockedTemplate">
              </div>

              <ng-template #inputBlockedTemplate>
                <input type="password" disabled>
              </ng-template>

              <div class="col-2 p-0 pt-5">
                <button class="btn btn-link" (click)="showLoginAndPassword = !showLoginAndPassword">
                  <span *ngIf="showLoginAndPassword">Ocultar</span>
                  <span *ngIf="!showLoginAndPassword">Mostrar</span>
                </button>
              </div>
            </div>
          </div>

          <div class="col-2 text-center">
            <label>Anexos ticket</label>
            <button class="attachment" (click)="openAttachmentModal(true, 'ticket')"><span>
                <i-bs name="paperclip" width="3rem" height="3rem"></i-bs>

              </span></button>
          </div>
        </div>
        <div class="lower row m-0 p-0 mt-4">
          <div class="col-4 m-0 pl-0">
            <label class="pl-3">Paciente</label>
            <ng-select [formGroup]="formGroup" formControlName="patient_id" (change)="getHealthInsurance($event)"
              [items]="patientList" bindValue="id" bindLabel="name"
              [ngClass]="formGroup.get('patient_id')!.errors && formGroup.get('patient_id')!.touched ? 'error' : 'custom'"
              notFoundText="Não encontrado">
            </ng-select>
            <app-form-error id="patient_id" [formGroup]="formGroup" controlName="patient_id">
            </app-form-error>
          </div>

          <div class="col-4 m-0 pl-0">
            <label class="pl-3">Convênio - Plano</label>
            <ng-select formControlName="health_insurance_plan_id" id="health_insurance_plan_id"
              [items]="healthInsuranceList" bindLabel="health_insurance_plan__name" bindValue="health_insurance_plan__id"
              notFoundText="Não encontrado" [searchable]="true"
              [ngClass]="formGroup.get('health_insurance_plan_id')!.errors && formGroup.get('health_insurance_plan_id')!.touched ? 'error' : 'custom'">
              <ng-template ng-label-tmp let-item="item">
                {{ item.health_insurance__name }} - {{ item.health_insurance_plan__name }}
              </ng-template>

              <ng-template ng-option-tmp let-item="item">
                {{ item.health_insurance__name }} - {{ item.health_insurance_plan__name }}
              </ng-template>
            </ng-select>
            <app-form-error id="health_insurance_plan_id" [formGroup]="formGroup"
              controlName="health_insurance_plan_id">
            </app-form-error>
          </div>

          <div class="col-4 pl-0">
            <label for="dependent" class="pl-3">Dependente</label>
            <input id="dependent" formControlName="dependent">
          </div>
        </div>

        <div class="row" *ngIf="selectedPatient">
          <div class="col pl-3">
            <p><strong>CPF:</strong> {{ selectedPatientDocumentNumber }} | <strong>Data de nascimento:</strong> {{ selectedPatient.birthdate | date:'dd/MM/YYYY' }} {{ selectedPatientAge }} | <strong>Nome da mãe:</strong> {{ selectedPatient.mother_name }} | <strong>E-mail:</strong> {{ selectedPatient.email }} </p>
          </div>
        </div>
      </div>
    </div>
    <div class="procedures mt-5">
      <h3>Procedimentos</h3>
      <div class="col-12 p-0 m-0 table-body" formArrayName="ticket_procedure">
        <table class="">
          <thead class="table-bordered col-12 m-0 p-0">
            <th *ngFor="let header of detailHeader" class="{{header.width}}">{{header.title}}</th>
          </thead>
          <tbody>
            <tr *ngFor="let controls of proceduresForm.controls, let i = index" [formGroup]="getProcedureFormGroup(i)">
              <td class="col-2 p-0 m-0">
                <ng-select [items]="proceduresList" bindValue="id" bindLabel="name" formControlName="procedure_id"
                  id="procedure_id" notFoundText="Não encontrado"
                  [ngClass]="getProcedureFormGroup(i).get('procedure_id')!.errors && getProcedureFormGroup(i).get('procedure_id')!.touched ? 'error' : 'custom'">
                </ng-select>
                <app-form-error [isModalReport]="true" id="procedure_id" [formGroup]="getProcedureFormGroup(i)"
                  controlName="procedure_id">
                </app-form-error>
              </td>
              <td class="col-1 p-0 m-0">
                <ng-select [items]="statusList" bindValue="id" bindLabel="name" formControlName="status_id"
                  id="status_id" notFoundText="Não encontrado"
                  [ngClass]="getProcedureFormGroup(i).get('status_id')!.errors && getProcedureFormGroup(i).get('status_id')!.touched ? 'error' : 'custom'">
                </ng-select>
                <app-form-error [isModalReport]="true" id="status_id" [formGroup]="getProcedureFormGroup(i)"
                  controlName="status_id">
                </app-form-error>
              </td>
              <td class="col-1 p-0 m-0">
                <button class="protocol" (click)="openProtocolModal(true, i)"><span>
                    {{getProtocolName(i)}}
                  </span></button>
              </td>
              <td class="col-1 p-0 m-0">
                <input type="date" formControlName="refund_request_date" id="refund_request_date"
                  [ngClass]="getProcedureFormGroup(i).get('refund_request_date')!.errors && getProcedureFormGroup(i).get('refund_request_date')!.touched ? 'errorInput' : ''">
                <app-form-error [isModalReport]="true" id="refund_request_date" [formGroup]="getProcedureFormGroup(i)"
                  controlName="refund_request_date">
                </app-form-error>
              </td>
              <td class="col-1 p-0 m-0">
                <input type="text" formControlName="invoice_value" id="invoice_value" currencyMask [options]="{prefix: '', thousands: '.', decimal: ','}"
                  [ngClass]="getProcedureFormGroup(i).get('invoice_value')!.errors && getProcedureFormGroup(i).get('invoice_value')!.touched ? 'errorInput' : ''">
                <app-form-error [isModalReport]="true" id="invoice_value" [formGroup]="getProcedureFormGroup(i)"
                  controlName="invoice_value">
                </app-form-error>
              </td>
              <td class="col-1 p-0 m-0">
                <input type="text" formControlName="invoice_number" id="invoice_number"
                  [ngClass]="getProcedureFormGroup(i).get('invoice_number')!.errors && getProcedureFormGroup(i).get('invoice_number')!.touched ? 'errorInput' : ''">
                <app-form-error [isModalReport]="true" id="invoice_number" [formGroup]="getProcedureFormGroup(i)"
                  controlName="invoice_number">
                </app-form-error>
              </td>
              <td class="col-1 p-0 m-0">
                <input type="text" formControlName="refunded_amount" id="refunded_amount" currencyMask [options]="{prefix: '', thousands: '.', decimal: ','}"
                  [ngClass]="getProcedureFormGroup(i).get('refunded_amount')!.errors && getProcedureFormGroup(i).get('refunded_amount')!.touched ? 'errorInput' : ''">
                <app-form-error [isModalReport]="true" id="refunded_amount" [formGroup]="getProcedureFormGroup(i)"
                  controlName="refunded_amount">
                </app-form-error>
              </td>
              <td class="col-1 p-0 m-0">
                <input type="date" formControlName="refund_date" id="refund_date"
                  [ngClass]="getProcedureFormGroup(i).get('refund_date')!.errors && getProcedureFormGroup(i).get('refund_date')!.touched ? 'errorInput' : ''">
                <app-form-error [isModalReport]="true" id="refund_date" [formGroup]="getProcedureFormGroup(i)"
                  controlName="refund_date">
                </app-form-error>
              </td>
              <td class="col-1 p-0 m-0">
                <input type="text" formControlName="co_participation_value" id="co_participation_value" currencyMask
                       [options]="{prefix: '', thousands: '.', decimal: ','}"
                  [ngClass]="getProcedureFormGroup(i).get('co_participation_value')!.errors && getProcedureFormGroup(i).get('co_participation_value')!.touched ? 'errorInput' : ''">
                <app-form-error [isModalReport]="true" id="co_participation_value"
                  [formGroup]="getProcedureFormGroup(i)" controlName="co_participation_value">
                </app-form-error>
              </td>
              <td class="col-1 p-0 m-0">
                <input type="date" formControlName="receipt_date" id="receipt_date"
                  [ngClass]="getProcedureFormGroup(i).get('receipt_date')!.errors && getProcedureFormGroup(i).get('receipt_date')!.touched ? 'errorInput' : ''">
                <app-form-error [isModalReport]="true" id="receipt_date" [formGroup]="getProcedureFormGroup(i)"
                  controlName="receipt_date">
                </app-form-error>
              </td>
              <td class="col-1 p-0 m-0">
                <input type="text" formControlName="transfer_value" id="transfer_value" currencyMask [options]="{prefix: '', thousands: '.', decimal: ','}"
                  [ngClass]="getProcedureFormGroup(i).get('transfer_value')!.errors && controls.get('transfer_value')!.touched ? 'errorInput' : ''">
                <app-form-error [isModalReport]="true" id="transfer_value" [formGroup]="getProcedureFormGroup(i)"
                  controlName="transfer_value">
                </app-form-error>
              </td>
              <td class="col-1 p-0 m-0 text-center">
                <button class="attachment" (click)="openAttachmentModal(true, 'procedure', i)"><span>
                    <i-bs name="paperclip" width="2rem" height="2rem"></i-bs>
                  </span></button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pt-4">
          <button class="btn btn-primary p-2" [disabled]="!canAddProcedure" (click)="addProcedure()">+ Adicionar
            procedimento</button>
        </div>
      </div>
      <div class="row m-0 p-0 mt-4" [formGroup]="getLogFormGroup()">
        <div class="col-8 m-0 p-0">
          <label for="note">Observações</label>
          <textarea type="text" id="notes" rows="4" formControlName="notes"
            [ngClass]="getLogFormGroup().get('notes')!.errors && getLogFormGroup().get('notes')!.touched ? 'errorInput' : ''"></textarea>
          <app-form-error id="notes" [formGroup]="getLogFormGroup()" controlName="notes">
          </app-form-error>
        </div>
        <div class="col-2 text-center m-0 p-0">
          <label>Anexos log</label>
          <button class="attachment" (click)="openAttachmentModal(true, 'log')"><span>
              <i-bs name="paperclip" width="3rem" height="3rem"></i-bs>
            </span></button>
        </div>

        <div class="col-2">
          <button class="btn btn-primary" (click)="openMailingModal()">Corpo do e-mail</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-end mt-5 mb-5">
    <div class="col-12 col-md-3 mb-3 d-flex justify-content-end">
      <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
        Cancelar
      </button>
    </div>
    <div class="col-12 col-md-3 mb-3 d-flex justify-content-end">
      <button (click)="submitHandler()" [disabled]="submitting || !canSave" class="btn btn-primary btn-lg"
        type="button">
        Salvar
        <span class="spinner-border submitting" *ngIf="submitting"></span>
      </button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
