<div class="background"></div>
<div id="body-container">
  <div id="nav-container" class="container-fluid">
    <nav class="navbar px-0 navbar-expand-lg d-flex justify-content-between">
      <button class="navbar-toggler menu-button" (click)="rootMenuButtonHandler()">
        <i class="icon-Icon-ionic-ios-menu"></i>
      </button>
      <div class="logo-wrapper">
        <img src="/assets/eleve-logo.svg" alt="">
      </div>
      <div *ngIf="isRegistersScreen" class="navbar-toggler menu-button">
      </div>
      <div class="navbar-collapse" [ngClass]="showRootMenuSidebar ? 'collapseShow' : 'collapse' "
        (click)="showRootMenuSidebar ? rootMenuButtonHandler() : null" id="navbarHeader">
        <ul class="navbar-nav">
          <li class="nav-item" *ngFor="let l of linkListHeader">
            <a *ngIf="l.name != 'Financeiro'" routerLink="{{l.routerLink}}" routerLinkActive="{{l.routerLinkActive}}">
              <i class="{{l.icon}}"></i>
              <span>{{l.name}}</span>
            </a>
            <a *ngIf="l.name == 'Financeiro'" (click)="financialLink()" target="_blank">
              <i class="{{l.icon}}"></i>
              <span>{{l.name}}</span>
            </a>
          </li>
          <li class="nav-item" *ngIf="!showRootMenuSidebar">
            <a (click)="whatsappConnect()">
              <i class="whatsapp-button icon icon-Icon-awesome-whatsapp"></i>
              <div *ngIf="connected" class="refresh-spinner" [ngClass]="!loading ? '' : 'spinner'">
                <i-bs name="arrow-repeat" width="1.5rem" height="1.5rem"></i-bs>
              </div>
              <div *ngIf="!connected" class="refresh-spinner-offline">
                <i-bs name="wifi-off" width="1.5rem" height="1.5rem"></i-bs>
              </div>
            </a>
          </li>
          <li class="nav-item" *ngIf="showRootMenuSidebar">
            <a src="#">
              <i [ngClass]="connected ? 'whatsapp-connected' : 'whatsapp-no-connected'"></i>
              <span>Status do Chatbot</span>
            </a>
          </li>
          <div class="line"></div>
          <li class="dropdown" *ngIf="!showRootMenuSidebar">
            <button id="dropdownButton" (click)="avatarClickHandle()" class="dropdown__avatar">
              <img class="profile-image" [src]="getProfileImage()">
            </button>
          </li>
          <div *ngIf="showDropdownMenu || showRootMenuSidebar"
            [ngClass]="showRootMenuSidebar ? 'dropdown__menu-mobile' : 'dropdown__menu'">
            <button class="row" (click)="goToUserScreen()">
              <i class="icon-Icon-metro-profile col-2 p-0"></i>
              <span class="col p-0">Meu perfil</span>
            </button>
            <div class="nav-item signature row" (click)="birdId()" [ngClass]="showRootMenuSidebar ? 'signature_mobile' : ''">
              <div class="col-2 p-0">
                <label class="switch" >
                  <input type="checkbox" [checked]="signature">
                  <span class="slider round" [ngClass]="signature ? 'slider-checked' : ''"></span>
                </label>
              </div>
              <span class="col p-0">Assinatura Digital</span>
            </div>
            <button class="row" (click)="logoutButtonHandler()">
              <i class="icon-Icon-material-exit-to-app col-2 p-0"></i>
              <span class="col p-0">Sair</span>
            </button>
          </div>
        </ul>
      </div>
      <div *ngIf="showRootMenuSidebar" class="navbar-backdrop" (click)="rootMenuButtonHandler()">
      </div>
    </nav>
  </div>
  <div id="main-container" class="container-fluid">
    <router-outlet></router-outlet>
  </div>

</div>
