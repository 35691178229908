import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: ['./textarea-input.component.scss']
})
export class TextareaInputComponent implements OnInit {

  @Input() id!: string;
  @Input() formGroup!: FormGroup | any;
  @Input() placeholder!: string;
  @Input() label!: string;
  @Input() labelPosition: string = 'top';
  @Input() controlName: string = '';
  @Input() disabled: boolean = false;
  @Input() removeVerticalSpacing: boolean = false;
  @Input() capitalize: boolean = false;
  @Input() type: string = 'text';
  @Input() showError: boolean = true;
  @Input() rows: number = 3;

  constructor() { }

  ngOnInit(): void {
  }

}
