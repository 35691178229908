<div class="background"></div>
<div id="body-container">
  <div id="nav-container" class="container">
    <nav class="navbar px-0 d-flex justify-content-center">

      <div class="logo-wrapper">
        <img src="/assets/eleve-logo.svg" alt="">
      </div>
    </nav>
  </div>

  <div id="main-container">
    <div *ngIf="loadingPage" class="container">

      <div class="row p-0 m-0 content content-card">
        <div class="row p-0 m-0 col-12">
          <div class="col-12 p-0 col-lg-3">
            <form [formGroup]="formGroup">
              <div class="select">
                <label class="field__title" for="schedule">Agenda</label>
                <ng-select class="custom" formControlName="schedule" id="schedule" [clearable]="false">
                  <ng-option *ngFor="let schedule of scheduleList" [value]="schedule.id">{{ schedule.name }}</ng-option>
                </ng-select>
              </div>

              <div class="menu-card__calendar-wrapper" *ngIf="!loadingMonth else loadingTemplate">
                <dp-day-calendar [config]="datePickerConfig" (onSelect)="dateChangeClickHandler($event)"
                  (onRightNav)="loadMonth($event.to)" (onLeftNav)="loadMonth($event.to)"
                  (onGoToCurrent)="goToCurrentClickHandler()" [displayDate]="viewDate" theme="custom-theme">
                </dp-day-calendar>
              </div>
            </form>
          </div>

          <div class="col-12 col-lg-9" [formGroup]="formGroup">
            <div class="row">
              <div class="col-12 mb-4 col-lg-6 pr-lg-4 p-0">
                <label class="field__title" for="clinic">Clínica</label>
                <ng-select class="custom" id="clinic" formControlName="clinic" [clearable]="false">
                  <ng-option *ngFor="let clinic of clinicOptions" [value]="clinic.name">{{clinic.label}}</ng-option>
                </ng-select>
              </div>
              <div class="col-12 mb-4 col-lg-6 field">
                <label class="field__title" for="date_time">Horário</label>
                <ng-select class="custom" formControlName="date_time" id="date_time"
                  notFoundText="Não existe horário para esta data" [loading]="loadingTime" (change)="setDuration()">
                  <ng-option *ngFor="let option of dateTimeOptions" [value]="option.name">
                    {{option.label}}
                  </ng-option>
                </ng-select>
              </div>

            </div>

            <div class="row">
              <div class="col-12 mb-4 col-lg-5 pr-lg-4 p-0" [formGroup]="patientFormGroup">
                <label class="field__title" for="document_number">CPF do Paciente</label>
                <input type="text" mask="000.000.000-00" id="document_number" formControlName="document_number"
                  (focusout)="fetchPatient($event)" />
                <app-form-error id="document_number" [formGroup]="patientFormGroup" controlName="document_number">
                </app-form-error>
              </div>

              <div class="col-12 mb-4 col-lg-7 p-0" [formGroup]="patientFormGroup">
                <label class="field__title" for="name">Nome do Paciente</label>
                <input type="text" formControlName="name" id="name" />
              </div>
            </div>

            <div class="row" [formGroup]="patientFormGroup">
              <div class="col-12 p-0 mb-2" formArrayName="phone_numbers">
                <label class="field__title p-0 m-0 font-weight-strong" for="schedule">Whatsapp</label>
                <ng-container *ngFor="let phoneNumber of phoneNumberForms.controls; index as i">
                  <div class="row p-0 m-0 col-12" [class]="{'d-none': !phoneNumberIsActive(i)}">
                    <div class="col-12 p-0 pr-2 mb-0">
                      <phone-number-input [formGroup]="getPhoneNumberForm(i)"></phone-number-input>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="row" [formGroup]="formGroup">
              <div class="col-12 p-0">
                <label class="field__title" for="notes">Observações</label>
                <textarea type="text" formControlName="notes" id="notes" rows="3"></textarea>
              </div>
            </div>
          </div>


          <div *ngIf="!formLoadingPatient" class="loadingPatient">
            <div class="spinner-border spinner-border-xl" role="status">
            </div>
            <span class="text">Carregando Paciente</span>
          </div>
        </div>
      </div>

      <div class="row justify-content-end mt-5 buttons">
        <div class="col-12 col-lg-4 mb-3">
          <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
            Cancelar
          </button>
        </div>

        <div class="col-12 col-lg-4 mb-3">
          <button (click)="submitHandler()" class="btn btn-primary btn-lg" type="button">
            Salvar
            <span *ngIf="submitting" class=" spinner-border" role="status" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="!loadingPage" class="row full-loading">
      <div class="spinner-border spinner-border-xl" role="status">
        <span class="sr-only">Carregando...</span>
      </div>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="spinner-border spinner-border-xl" role="status">
    <span class="sr-only">Carregando...</span>
  </div>
</ng-template>
