<div class="error-modal-wrapper">
    <div class="error-modal">
        <div class="error-modal__header">
            <h1>{{title}}</h1>
        </div>

        <div class="error-modal__content">
            <p>{{text}}</p>

            <div class="row justify-content-end">
                <button class="btn btn-secondary col-sm-12 col-md-3 col-lg-3 mt-5 mb-5"
                    (click)="activeModal.close(false)">Ok</button>
            </div>
        </div>
    </div>
</div>
