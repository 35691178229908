import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { OximeterService } from 'src/app/core/services/oximeter.service';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { last, map, tap } from 'rxjs/operators';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { PolysomnographyExamModel } from 'src/app/core/models/polysomnography-exam.model';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { ConfirmationModalComponent } from '../../renderers/components/confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-include-exam-modal',
  templateUrl: './include-exam-modal.component.html',
  styleUrls: ['./include-exam-modal.component.scss']
})
export class IncludeExamModalComponent implements OnInit {

  @Input() modalItems: any;

  item!: any;
  polysomnographyId!: number;

  isRegister: boolean = true;
  loadingPage: boolean = true;
  submitting: boolean = false;

  formGroup = this.fb.group({
    id: [''],
    started_at: [''],
    ended_at: [''],
    imc: [''],
    ido: ['', [Validators.min(0)]],
    spo2: ['', [Validators.min(0)]],
    snoring: ['', [Validators.min(0), Validators.max(100)]],
    snoring_treatment: [''],
    conditions: [],
    temporary_file: [],
  });

  conditionList = [
    { name: 'Bebida alcoólica', value: 'ALCOHOLIC_BEVERAGE', icon: 'icon-Icon-awesome-cocktail', checked: false },
    { name: 'Nariz entupido', value: 'STUFFY_NOSE', icon: 'icon-Grupo-409', checked: false },
    { name: 'Sedativos', value: 'SEDATIVES', icon: 'icon-pill', checked: false },
  ];

  snoringTreatmentList = [
    { name: 'Nenhum', value: 'none', icon: 'icon-Icon-ionic-ios-close', checked: true },
    { name: 'CPAP', value: 'CPAP', icon: 'icon-cpap', checked: false },
    { name: 'Ap Ronco Intraoral', value: 'INTRAORAL_SNORING_DEVICE', icon: 'icon-intraoral_snoring_device', checked: false },
  ];

  attachmentFeedback = '';
  attachmentFileName = '';
  downloadFile: boolean = false;

  minDate: any;
  maxDate: any;
  maxMin: any;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private oximeterService: OximeterService,
    private toast: ToastrService, config: NgbModalConfig, private modalService: NgbModal,) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.item = this.modalItems.examData;
    this.polysomnographyId = this.modalItems.polysomnographyId;
    let today = moment().format('YYYY-MM-DDTHH:mm');
    this.maxDate = today;
    this.formGroup.get('ended_at')?.disable();
    if (!this.item) {
      this.loadingPage = false;
    } else {
      this.setExam();
      this.isRegister = false;
    }
    if (this.modalItems.imc) {
      this.formGroup.get('imc')?.setValue(this.modalItems.imc);
      this.formGroup.get('imc')?.disable();
    }
  }

  setExam() {
    this.item.started_at = moment(this.item.started_at).format('YYYY-MM-DDTHH:mm');
    this.item.ended_at = moment(this.item.ended_at).format('YYYY-MM-DDTHH:mm');
    this.minDate = this.item.started_at;
    let ido = this.item.ido.toString().replace('.', ',');
    this.formGroup.patchValue(this.item);
    this.formGroup.get('ido')?.setValue(ido);
    let duration = moment.duration(moment(this.item.ended_at).diff(this.minDate));
    this.maxMin = duration.asMinutes();
    this.formGroup.get('ended_at')?.enable();
    this.loadingPage = false;
    this.item.conditions.map((item: string) => {
      if (item == 'CPAP' || item == 'INTRAORAL_SNORING_DEVICE') {
        this.toggleSnoringTreatment(item);
      } else {
        this.toggleCondition(item);
      }
    });
    if (this.item.snoring_filename) {
      let filename: string = this.item.snoring_filename;
      this.attachmentFileName = filename.split('/').pop() ?? '';
      this.downloadFile = true;
    }
  }

  toggleCondition(condition: any) {
    this.conditionList.map(item => {
      if (item.value == condition) {
        item.checked = !item.checked;
      }
    });
  }

  toggleSnoringTreatment(condition: any) {
    this.snoringTreatmentList.map(item => {
      if (item.value == condition) {
        item.checked = true;
      } else item.checked = false;
    });
  }

  downloadAttachment(url: string) {
    window.open(url, '_blank');
  }

  setMinDate(ev: any) {
    this.formGroup.get('ended_at')?.reset();
    this.minDate = moment(this.formGroup.get('started_at')?.value).format('YYYY-MM-DDTHH:mm');
    this.formGroup.get('ended_at')?.enable();
  }

  validatorDate(ev: any) {
    let dateStart = moment(this.formGroup.get('started_at')?.value);
    let dateEnd = moment(this.formGroup.get('ended_at')?.value);

    if (dateEnd < dateStart) {
      this.formGroup.get('ended_at')?.setErrors({ response: 'Este horário não pode ser menor que o início' });
    }
    let duration = moment.duration(dateEnd.diff(dateStart));
    this.maxMin = duration.asMinutes();
  }

  addAttachment(event: any) {
    let files: FileList = event.target.files;
    this.attachmentFileName = files[0].name;
    this.downloadFile = false;
    let formData: FormData = new FormData();
    Array.from(files).forEach(file => {
      formData.append('files', file);
    });
    this.oximeterService.uploadTemporaryFile(formData).pipe(
      map((event: any, formData: any) => {
        switch (event['type']) {
          case HttpEventType.UploadProgress:
            let percentage = event.total ? Math.round(100 * event.loaded / event.total) : 0;
            return `Envio de arquivo em ${percentage}%.`;
          case HttpEventType.Response:
            event.body.forEach((attachment: any) => {
              this.formGroup.get('temporary_file')?.setValue(attachment.id);
            });
            return '';
          default:
            return null;
        }
      }),
      tap(message => {
        this.attachmentFeedback = message || '';
      }),
      last()
    ).subscribe(response => {
      /**/
    }, error => {
      console.error(error);
    });
  }

  removeHandler() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja excluir este exame?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.oximeterService.examDelete(this.polysomnographyId, this.item.id).subscribe(() => {
          this.submitting = true;
          this.activeModal.close();
        }, (errorResponse: HttpErrorResponse) => {
          this.mapErrorResponse(errorResponse);
        })
      }
    });
  }

  submitHandler() {
    this.submitting = true;
    let examData = this.formGroup.getRawValue() as PolysomnographyExamModel;
    examData.conditions = this.conditionList.filter(item => {
      return item.checked == true;
    }).map(item => item.value);
    examData.snoring_treatment = this.snoringTreatmentList.filter(item => {
      return item.checked == true;
    }).map(item => item.value).toString();
    if (examData.snoring_treatment == "none") {
      examData.snoring_treatment = undefined;
    };
    examData.ido = examData.ido?.replace(',', '.');
    if(examData.temporary_file || this.attachmentFileName != '') {
      if (this.isRegister) {
        this.registerExam(examData);
      } else {
        this.editExam(examData);
      }
    } else {
      this.submitting = false;
      this.formGroup.get('temporary_file')?.setErrors({ response: 'É obrigatório colocar anexo' });
    }
  }

  registerExam(examData: PolysomnographyExamModel) {
    examData.id = undefined;
    this.oximeterService.examRegister(this.polysomnographyId, examData).subscribe(response => {
      console.log(response);
      this.submitting = false;
      this.activeModal.close(response);

    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  editExam(examData: PolysomnographyExamModel) {
    this.oximeterService.examEdit(this.polysomnographyId, examData).subscribe(response => {
      this.submitting = false;
      this.activeModal.close(response);
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    } else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      'started_at', 'ended_at', 'imc', 'ido', 'spo2', 'snoring', 'conditions', 'temporary_file'
    ];

    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
    });
  }

}
