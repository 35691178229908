<div class="include-modal-wrapper">
  <div class="row p-0 justify-content-between title">
    <h3 class="py-4">{{action}} Lembrete</h3>
    <button class="btn btn-link col-1 p-0 m-0" (click)="activeModal.close(false)">
      <i-bs name="x-lg" width="2rem" height="2rem"></i-bs>
    </button>
  </div>
  <div class="include-modal" *ngIf="!loadingPage else loadingTemplate">
    <div class="create-reminder row p-0" [formGroup]="formGroup">
      <div class="col-12 px-0 mb-2">
        <ng-container>
          <label class="create-reminder__title" for="patient_id">Paciente</label>
          <ng-select [ngClass]="formGroup.get('patient_id')!.errors ? 'error' : 'custom'" formControlName="patient_id" [addTag]="true" notFoundText="Não encontrado">
            <ng-option *ngFor="let option of patientOptions" [value]="option.name">{{option.label}}
            </ng-option>
          </ng-select>
        </ng-container>
        <app-form-error id="patient_id" [formGroup]="formGroup" controlName="patient_id"></app-form-error>
      </div>

      <div class="col-8 p-0 pr-3 mb-2">
        <div class="row">
          <div class="col-7 p-0">
            <label for="date" class="create-reminder__title">Data</label>
            <input type="date" formControlName="date" id="date" [ngClass]="formGroup.get('date')!.errors ? 'error' : 'custom'"/>
          </div>
          <div class="col-5 p-0 pl-3">
            <label for="time" class="create-reminder__title">Hora</label>
            <input type="time" formControlName="time" id="time" [ngClass]="formGroup.get('date')!.errors ? 'error' : 'custom'"/>
          </div>
        </div>
        <app-form-error id="date" [formGroup]="formGroup" controlName="date"></app-form-error>
      </div>

      <div class="col-4 px-0 mb-2">
        <label class="create-reminder__title">Status</label>
        <span class="chip-label" [style.color]="getReminderStatusColor()"
        [style.background-color]="getReminderStatusBackgroundColor()">
          {{ reminderStatusName }}
        </span>
      </div>

      <div class="col-12 px-0 mb-2">
        <app-basic-input id="chatbot_message" label="Mensagem Chatbot" [formGroup]="formGroup"
          controlName="chatbot_message">
        </app-basic-input>
      </div>

      <div class="col-12 px-0">
        <app-basic-input id="notes" label="Observação" [formGroup]="formGroup"
          controlName="notes">
        </app-basic-input>
      </div>
    </div>

    <div class="row mt-4 justify-content-end">
      <button class="btn btn-outline-primary col-3 " (click)="activeModal.close(false)">Cancelar</button>
      <button class="btn btn-secondary col-4 " (click)="shootMessage()">Disparar mensagem</button>
      <button class="btn btn-primary col-3" [disabled]="submitting" (click)="submitHandler()">
        {{action}} lembrete
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>
  <ng-template #loadingTemplate>
    <div class="col full-loading text-center">
      <div class="spinner-border spinner-border-xl" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-template>
</div>
