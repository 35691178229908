import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AttendanceTypeModel } from 'src/app/core/models/attendance-type.model';
import { PhoneModel } from 'src/app/core/models/phone.model';
import { ProfessionalModel } from 'src/app/core/models/professional.model';
import { Schedule } from 'src/app/core/models/schedule.model';
import { ClinicsService } from 'src/app/core/services/clinics.service';
import { ProfessionalsService } from 'src/app/core/services/professionals.service';
import { RegistersService } from 'src/app/core/services/registers.service';
import { ScheduleService } from 'src/app/core/services/schedules.service';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';
import { FieldValidator } from 'src/app/shared/renderers/utils/field-validator/field-validator';

@Component({
  selector: 'app-professionals-form',
  templateUrl: './professionals-form.component.html',
  styleUrls: ['./professionals-form.component.scss']
})
export class ProfessionalsFormComponent implements OnInit {

  formGroup: FormGroup = this.fb.group({
    id: [],
    name: [null, [Validators.required]],
    display_name: [],
    gender: [],
    birthdate: [null, [Validators.required]],
    email: ['', [Validators.email]],
    document_number: ['', [FieldValidator.documentValidator()]],
    cro: [],
    clinics: ['', [Validators.required]],
    tags: [],
    schedules: this.fb.array([]),
    phone_numbers: this.fb.array([]),
    specialties: ['', [Validators.required]]
  });

  constructor(@Inject(DOCUMENT) private document: Document, private activatedRoute: ActivatedRoute, private fb: FormBuilder, private router: Router, config: NgbModalConfig,
    private modalService: NgbModal, private professionalsService: ProfessionalsService, private clinicsService: ClinicsService,
    private registersService: RegistersService, private scheduleService: ScheduleService, private toast: ToastrService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  imageFile!: File | null;
  changedImage: boolean = false;

  loadingPage: boolean = true;
  isRegister: boolean = true;

  saveButtonDisabled: boolean = false;
  removeButtonDisabled: boolean = false;
  submitting: boolean = false;
  submittingRemove: boolean = false;
  removeButton?: String = "Desativar";
  submitButton?: String = "Salvar";
  button: boolean = true;

  phonesList!: PhoneModel[];
  clinicsList!: any[];
  professionalTagsList!: any[];
  specialtiesList!: any[];
  scheduleList!: any[];
  attendanceTypeList: AttendanceTypeModel[] = [];

  id!: number;
  existingProfessionalData!: ProfessionalModel;

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params["id"];
      if (this.id) {
        this.isRegister = false;
      }
    });
    this.initializeForm();
  }

  initializeForm() {
    if (this.id) this.fetchProfessionalsExistingData();
    else this.loadingPage = false;
    this.fetchProfessionalTags();
    this.fetchClinicsList();
    this.fetchSpecialties();
    this.fetchSchedules();
    this.addSchedule();
    this.addPhoneNumber();
  }

  onImageSelect(file: any) {
    this.imageFile = file;
    this.changedImage = true
  }

  fetchProfessionalTags() {
    this.professionalsService.listAllTagProfessional().subscribe(response => this.professionalTagsList = response);
  }

  fetchClinicsList() {
    this.clinicsService.clinicsListAllActive().subscribe(response => this.clinicsList = response);
  }

  fetchSpecialties() {
    this.registersService.listAllSpecialties().subscribe(response => this.specialtiesList = response);
  }

  fetchSchedules() {
    this.scheduleService.all().subscribe((response) => {
      this.scheduleList = response as Schedule[];
    });
  }

  fetchProfessionalsExistingData() {
    this.professionalsService.get(this.id).subscribe((response) => {
      this.existingProfessionalData = response;
      this.scheduleForms.clear();
      this.phoneNumberForms.clear();
      response.schedules?.forEach(() => this.addSchedule());
      response.phone_numbers!.forEach(() => this.addPhoneNumber());
      this.formGroup.patchValue(this.existingProfessionalData);
      response.schedules?.forEach((schedule, index) => {
        let formGroup = this.scheduleForms.controls[index] as FormGroup;
        this.changeAttendanceType(index);
        formGroup.get('attendance_types')?.setValue(schedule.attendance_types);
      });
      this.loadingPage = false;
      if (this.existingProfessionalData.is_active) {
        this.removeButton = 'Desativar';
        this.submitButton = 'Salvar';
      } else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
        this.phoneNumberForms.disable();
        this.scheduleForms.disable();
      }
    })
  }

  cancelHandler() {
    this.router.navigate(['dashboard/registers/professionals']);
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar este profissional?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.professionalsService.remove(this.id).subscribe(() => {
      this.toast.success('Profissional desativado com sucesso', "Sucesso");
      this.router.navigate(['dashboard/registers/professionals/']);
        }, (errorResponse: HttpErrorResponse) => {
          this.mapErrorResponse(errorResponse);
        })
      } else {
        this.loadingPage = false;
      }
      this.submittingRemove = false;
    })
  }

  submitHandler() {
    this.formGroup.markAllAsTouched();
    this.submitting = true;
    (this.phoneNumberForms?.value as PhoneModel[]).forEach((phone, index) => {
      if (phone.id == null) this.phoneNumberForms.at(index)?.get('id')?.setValue(undefined);
    });
    (this.scheduleForms?.value).forEach((schedule: any, index: any) => {
      if (schedule.id == null) this.scheduleForms.at(index)?.get('id')?.setValue(undefined);
    });
    if (this.formGroup.get('tags')?.value == null) {
      this.formGroup.get('tags')?.setValue([]);
    }
    if (this.formGroup.get('specialties')?.value == null) {
      this.formGroup.get('specialties')?.setValue([]);
    }
    let professionalData = this.formGroup.getRawValue() as ProfessionalModel;
    professionalData.id = professionalData.id ?? undefined;
    if (this.isRegister) {
      if (this.formGroup.valid) {
        this.professionalRegister(professionalData);
      } else {
        this.document.getElementById('main-container')?.scroll({ top: 0 });
        this.submitting = false;
      }
    } else this.professionalEdit(professionalData);
  }

  professionalRegister(professionalData: ProfessionalModel) {
    this.professionalsService.register(professionalData).subscribe((res) => {
      if (this.changedImage) {
        this.professionalsService.uploadImage(this.imageFile!, res.id!).subscribe((response) => {
        }, (errorResponse: HttpErrorResponse) => {
          this.mapErrorResponse(errorResponse);
          this.submitting = false;
        });
      }
      this.isRegister = false;
      this.submitting = false;
      this.toast.success('Profissional criado com sucesso', "Sucesso");
      this.router.navigate(['dashboard/registers/professionals']);
    }, (errorResponse: HttpErrorResponse) => {
      this.mapErrorResponse(errorResponse);
      this.submitting = false;
    });
  }

  professionalEdit(professionalData: ProfessionalModel) {
    professionalData.is_active = true;
    this.professionalsService.edit(professionalData).subscribe(() => {
      if (this.changedImage) {
        this.professionalsService.uploadImage(this.imageFile!, professionalData.id!).subscribe((res) => {
        }, (errorResponse: HttpErrorResponse) => {
          this.mapErrorResponse(errorResponse);
          this.submitting = false;
        });
      }
      this.submitting = false;
      this.toast.success('Profissional alterado com sucesso', "Sucesso");
      this.router.navigate(['dashboard/registers/professionals']);
    }, (errorResponse: HttpErrorResponse) => {
      this.mapErrorResponse(errorResponse);
      this.submitting = false;
    });
  }

  get scheduleForms() {
    return this.formGroup.get('schedules') as FormArray;
  }

  getFormSchedule(index: number) {
    return this.scheduleForms.controls[index] as FormGroup;
  }

  addSchedule() {
    let scheduleFormGroup = this.fb.group({
      id: [],
      schedule: ['', [Validators.required]],
      attendance_types: ['', [Validators.required]],
      is_active: [true]
    });
    this.scheduleForms.push(scheduleFormGroup);
  }

  removeSchedule(at: number) {
    this.scheduleForms.removeAt(at);
  }

  scheduleIsActive(index: number) {
    let formGroup = this.scheduleForms.controls[index] as FormGroup;
    return formGroup.get('is_active')?.value;
  }

  changeAttendanceType(formGroupIndex: number) {
    let formGroup = this.scheduleForms.controls[formGroupIndex] as FormGroup;
    let schedule = formGroup.get('schedule')?.value;
    if (schedule) {
      this.registersService.attendancesTypeAll().subscribe((response) => this.attendanceTypeList = response);
    } else this.attendanceTypeList = [];
  }

  onSelectAll(formGroupIndex: number) {
    let formGroup = this.scheduleForms.controls[formGroupIndex] as FormGroup;
    const selected = this.attendanceTypeList.map(item => item.id);
    formGroup.get('attendance_types')?.patchValue(selected);
  }

  onClearAll(formGroupIndex: number) {
    let formGroup = this.scheduleForms.controls[formGroupIndex] as FormGroup;
    formGroup.get('attendance_types')?.patchValue([]);
  }

  get phoneNumberForms() {
    return this.formGroup.get('phone_numbers') as FormArray;
  }

  getPhoneNumberForm(index: number) {
    return this.phoneNumberForms.controls[index] as FormGroup;
  }

  addPhoneNumber() {
    let phoneNumberFormGroup = this.fb.group({
      id: [],
      country_code: ['+55', [Validators.required]],
      phone_number: ['', [Validators.required]],
      type: ['WHATSAPP', [Validators.required]],
      is_active: [true]
    });
    this.phoneNumberForms.push(phoneNumberFormGroup);
  }

  removePhoneNumber(at: number) {
    let formGroup = this.phoneNumberForms.controls[at] as FormGroup;
    if (formGroup.get('id')?.value) {
      formGroup.patchValue({ is_active: false });
    } else {
      this.phoneNumberForms.removeAt(at);
    }
  }

  phoneNumberIsActive(index: number) {
    let formGroup = this.phoneNumberForms.controls[index] as FormGroup;
    return formGroup.get('is_active')?.value;
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    } else {
      this.setFormErrors(errorResponse);
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "name", "display_name", "gender", "birthdate",
      "email", "document_number", "cro", "clinics",
      "tags", "schedules", "phone_numbers", "specialties"
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
    });
    if (errorResponse.error.phone_numbers) {
      let phone_numbers: [] = errorResponse.error.phone_numbers;
      phone_numbers.map((res, index) => {
        let formGroupPhone = this.phoneNumberForms.controls[index] as FormGroup;
        if (res["phone_number"])
          formGroupPhone.get('phone_number')?.setErrors({ response: res["phone_number"] });
        else {
          if (res["country_code"])
            formGroupPhone.get('phone_number')?.setErrors({ response: res["country_code"] });
          else {
            if (res["type"])
              formGroupPhone.get('phone_number')?.setErrors({ response: res["type"] });
          }
        }
      });
    }

    if (errorResponse.error.schedules) {
      let schedulesError: [] = errorResponse.error.schedules;
      schedulesError.map((res, index) => {
        let formGroupSchedule = this.scheduleForms.controls[index] as FormGroup;
        if (res["schedule"])
          formGroupSchedule.get('schedule')?.setErrors({ response: res["schedule"] });
        if (res["attendance_types"])
          formGroupSchedule.get('attendance_types')?.setErrors({ response: res["attendance_types"] });
      })
    }
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  };
}
