<div class="error-modal-wrapper">
  <div class="error-modal">
    <div class="error-modal__header">
      <h1>Selecione os campos para exportar</h1>
    </div>

    <div class="error-modal__content">
      <p *ngIf="showError" class="text-danger">Pelo menos um campo deve estar selecionado</p>

      <div class="checkbox" *ngFor="let field of keys" (click)="toggleField(field)">
        <input type="checkbox" [id]="field" [checked]="hasField(field)">
        <label [for]="field">{{ fields[field] }}</label>
      </div>

      <div class="row justify-content-end">
        <button class="btn btn-link col-sm-12 col-md-3 col-lg-3 mt-5 mb-5"
          (click)="activeModal.close(false)">Cancelar</button>
        &nbsp;
        <button class="btn btn-primary col-sm-12 col-md-3 col-lg-3 mt-5 mb-5" (click)="export()">Exportar</button>
      </div>
    </div>
  </div>
</div>
