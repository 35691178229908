<div class="list-wrapper">
  <div class="card">
    <div class="row p-0 m-0" *ngIf="listTemplate.showSearch">
      <div class="col col-md p-0 m-0 py-3 py-md-0 search__searchbar">
        <input type="text" #searchbar [placeholder]="listTemplate.searchBarPlaceholder ?? 'Busca'" />
      </div>
      <div class="col-2 p-0 m-0 search__advanced" *ngIf="listTemplate.showAdvancedSearch">
        <button class="btn btn-advanced btn-lg" (click)="searchAdvanced()">
          Busca avançada
          <span class="icon-Icon-ionic-ios-arrow-down " [ngClass]="searchAdvancedBool ? 'up' : 'null'"></span>
        </button>
      </div>

      <div class="col-12 advanced" *ngIf="searchAdvancedBool">
        <div class="labels">
          <h4 class="p-0 m-0 d-flex align-items-center">Busca por Tag:</h4>
          <div *ngFor="let item of patientTagsList" class="label">
            <span class="chip-label" [class.active]="isTagsSelected(item.id)"
              (click)="toggleTags(item.id)">{{item.name}}</span>
          </div>
        </div>
      </div>

      <div class="col-6 px-0 py-3 py-md-0 px-md-2" [ngClass]="listTemplate.searchBarPlaceholder ? 'col-md-4' : ''"
        *ngIf="listTemplate.searchBarSchedule">
        <div class="d-flex modal-box-support" *ngIf="schedulesList">
          <button class="btn btn-schedule" (click)="openScheduleFilter()">
            {{ labelSchedule}}
          </button>

          <div class="modal-box-wrapper" *ngIf="scheduleFilterIsOpened" (click)="closeScheduleFilter()"></div>
          <div class="modal-box" *ngIf="scheduleFilterIsOpened">
            <div class="close-button mb-2" (click)="closeScheduleFilter()">
              <span class="icon-Icon-ionic-ios-close"></span>
            </div>

            <ul class="modal-box__schedule">
              <li (click)="toggleAllSchedule()">Selecionar todos</li>
              <ng-container *ngFor="let schedule of schedulesList">
                <li (click)="toggleSchedule(schedule)">
                  <input type="checkbox" [checked]="scheduleIsSelected(schedule)">
                  {{schedule.name}}
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-6 px-0 py-3 py-md-0 pl-2 px-md-2" [ngClass]="listTemplate.searchBarPlaceholder ? 'col-md-3' : ''"
        *ngIf="listTemplate.searchBarStatus">
        <div class="d-flex modal-box-support" *ngIf="statusList">
          <button class="btn btn-search" (click)="openStatusFilter()">
            {{ labelStatus }}
          </button>

          <div class="modal-box-wrapper" *ngIf="statusFilterIsOpened" (click)="closeStatusFilter()"></div>
          <div class="modal-box" *ngIf="statusFilterIsOpened">
            <div class="close-button" (click)="closeStatusFilter()">
              <span class="icon icon-Icon ionic-ios-close"></span>
            </div>

            <ul class="modal-box__search">
              <li class="mb-1" (click)="toggleAllStatus()">Selecionar todos</li>
              <ng-container *ngFor="let status of statusList">
                <li (click)="toggleStatus(status)">
                  <input type="checkbox" [checked]="statusIsSelected(status)">
                  {{status.name}}
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-3 px-0 py-3 p-md-0 search_date" *ngIf="listTemplate.searchBarDate">
        <input type="date" #date (change)="getSearchDate()" />
      </div>

      <div class="col-12 p-0 py-3 p-md-0 search_date" [ngClass]="listTemplate.searchBarPlaceholder ? 'col-md-3' : ''"
        *ngIf="listTemplate.searchBarDateRange">
        <div class="row p-0 m-0">
          <input type="date" class="col-5" #dateStart (change)="getSearchDateRange()" />
          <span class="col-2 d-flex align-self-center justify-content-center p-0">até</span>
          <input type="date" class="col-5" #dateEnd (change)="getSearchDateRange()" />
        </div>
      </div>
    </div>


  </div>

  <div class="row p-0 m-0 info d-flex"
    [ngClass]="listTemplate.newDataText || listTemplate.onClickActive ? 'justify-content-between' : 'justify-content-center'"
    *ngIf="listTemplate.showInfo">
    <div class="info__new" *ngIf="listTemplate.newDataText"
      [ngClass]="listTemplate.count || listTemplate.onClickActive ? '' : 'col-12'">
      <a [routerLink]="listTemplate.newDataRouterLink"
        [ngClass]="listTemplate.newDataRouterLink == undefined || listTemplate.error ? 'info__disabled' : 'null'">{{listTemplate.newDataText}}</a>
    </div>

    <div class="info__data" *ngIf="!listTemplate.loading && !listTemplate.error && listTemplate.count">
      Total cadastrado {{listTemplate.data ? listTemplate.data.total_count : ""}} -
      Mostrando {{ listTemplate.data!.results!.length }} de {{ listTemplate.data!.count! }}
    </div>

    <div class="info__export" *ngIf="listTemplate.exportUrl" [ngClass]="listTemplate.error ? 'info__disabled' : 'null'">
      <span (click)="exportCSV()">Exportar CSV</span>
    </div>

    <div class="info__is_active mr-1" *ngIf="listTemplate.onClickActive" [ngClass]="listTemplate.error ? 'info__disabled' : 'null'">
      <a (click)="onClickActive()">{{ isActiveText }}</a>
    </div>
  </div>

  <div class="card">
    <div class="data-list-wrapper" [ngClass]="isPatientDocuments ? 'data-list-patient-documents' : ''">
      <div class="list__header" *ngIf="listTemplate.header" [ngClass]="mediaQuery ? '' : 'fit-content'">
        <div *ngFor="let item of listTemplate.header; let columnIndex = index" class="title"
          [style]="{'min-width': getWidth(columnIndex)}">
          <span
            *ngIf="item['name'] != 'chartLink' && item['name'] != 'count' && item['name'] != 'case_sensitive' && item['name'] != 'contains' && item['name'] != 'phone_numbers' && item['name'] != 'patient_phone_number'">
            <button (click)="onHeaderClick(item.name)">
              {{item['label']}}
              <i *ngIf="item.name == listTemplate.selectedOrdering && !listTemplate.invertedOrdering"
                class="icon-Icon-awesome-caret-down ordering-indicator"></i>

              <i *ngIf="item.name == listTemplate.selectedOrdering && listTemplate.invertedOrdering"
                class="icon-Icon-awesome-caret-down ordering-indicator-reverse"></i>
            </button>
          </span>
          <span *ngIf="item['name'] == 'phone_numbers' || item['name'] == 'patient_phone_number'" class="title">
            {{item['label']}}
          </span>
          <span *ngIf="item['name'] == 'count' || item['name'] == 'case_sensitive' || item['name'] == 'contains'"
            class="title_center">
            <button (click)="onHeaderClick(item.name)">
              {{item['label']}}
              <i *ngIf="item.name == listTemplate.selectedOrdering && !listTemplate.invertedOrdering"
                class="icon-Icon-awesome-caret-down ordering-indicator"></i>

              <i *ngIf="item.name == listTemplate.selectedOrdering && listTemplate.invertedOrdering"
                class="icon-Icon-awesome-caret-down ordering-indicator-reverse"></i>
            </button>
          </span>
        </div>
      </div>
      <div class="table" [ngClass]="mediaQuery ? '' : 'fit-content'">
        <div class="table__body" *ngIf="listTemplate.data && listTemplate.header">
          <div class="table__data" *ngFor="let row of listTemplate.data!.results; let rowIndex = index">
            <div *ngFor="let item of listTemplate.header; let columnIndex = index"
              [style]="{'min-width': getWidth(columnIndex)}" class="table__data-column">

              <a [ngClass]="item['name'] == 'exam_type' ? 'd-none' : ''" *ngIf="item['name'] != 'reemit'"
                (click)="rowClickHandle($event, listTemplate.idFieldName == undefined ? row['id'] :  row[listTemplate.idFieldName!])">
                <span
                  *ngIf="item['name'] != 'id' && item['name'] != 'name_tags' && item['name'] != 'health_insurance_id' && item['name'] != 'phone_numbers' && item['name'] != 'patient__phone_number' && item['name'] != 'phone_ddi' && item['name'] != 'health_insurances' && item['name'] != 'clinic_name' && item['name'] != 'case_sensitive' && item['name'] != 'contains' && item['name'] != 'count' && item['name'] != 'created_at' && item['name'] != 'file' && item['name'] != 'date_time' && item['name'] != 'exam_type' && item['name'] != 'status_oximeter' && item['name'] != 'current_patient__attendance__date_time' && item['name'] != 'attendance__date_time' && item['name'] != 'sent_at' && item['name'] != 'current_patient__sent_at' && item['name'] != 'status_exam' && item['name'] != 'exam_nights'"
                  title="{{getKeyByValue(columnIndex, row, item['name'])}}">
                  {{getKeyByValue(columnIndex, row, item["name"])}}
                </span>

                <span *ngIf="item['name'] == 'file'" class="d-flex"
                  title="{{getKeyByValue(columnIndex, row, 'filename')}}">
                  {{getKeyByValue(columnIndex, row, 'filename')}}
                </span>

                <span *ngIf="item['name'] == 'phone_numbers'" class="d-flex">
                  <span>{{getFirstPhoneNumber(columnIndex, row, item["name"])}}</span>
                  <button *ngIf="isPhoneNumberWhatsapp(row, item['name'])!"
                    (click)="whatsappButtonClickHandle(getFirstPhoneNumber(columnIndex, row, item['name'], false)!)"
                    class="whatsapp-button icon icon-Icon-awesome-whatsapp "></button>
                </span>

                <span *ngIf="item['name'] == 'patient__phone_number'" class="d-flex">
                  {{ getPhoneNumberPatientFormatter(row) }}
                  <button (click)="whatsappButtonClickHandle(getPhoneNumberPatient(columnIndex, row, item['name'])!)"
                    class="whatsapp-button icon icon-Icon-awesome-whatsapp "></button>
                </span>

                <span *ngIf="item['name'] == 'health_insurances'" class="label-wrapper label-tags">
                  {{getHealthInsurancesList(rowIndex)}}
                </span>

                <span *ngIf="item['name'] == 'clinic_name'" class="label-wrapper">
                  {{getClinicName(rowIndex)}}
                </span>

                <span *ngIf="item['name'] == 'name_tags'" class="label-wrapper label-tags">
                  {{getLeadsTags(rowIndex)}}
                </span>

                <span *ngIf="item['name'] == 'case_sensitive'" class="label-wrapper table__data-column_center">
                  {{getCaseSensitive(rowIndex)}}
                </span>

                <span *ngIf="item['name'] == 'exam_nights'" class="label-wrapper">
                  {{getExamNights(rowIndex)}}
                </span>

                <span *ngIf="item['name'] == 'contains'"
                  class="label-wrapper table__data-column_center">{{getContains(rowIndex)}}</span>

                <span *ngIf="item['name'] == 'count'"
                  class="label-wrapper p-0 table__data-column_center">{{getKeyByValue(columnIndex, row,
                  item["name"])}}</span>

                <span
                  *ngIf="item['name'] == 'created_at' || item['name'] == 'date_time' || item['name'] == 'current_patient__attendance__date_time' || item['name'] == 'current_patient__sent_at' || item['name'] == 'attendance__date_time'"
                  class="label-wrapper">{{getDate(columnIndex, row, item['name'])}}</span>

                <span *ngIf="item['name'] == 'sent_at'" class="label-wrapper">{{getDateSentAt(columnIndex, row,
                  item['name'])}}</span>

                <span *ngIf="item['name'] == 'status_oximeter'" class="label-wrapper">{{getStatusOximeter(row)}}</span>

                <span *ngIf="item['name'] == 'status_exam'" class="label-wrapper">{{getStatusExam(row)}}</span>

              </a>
              <button *ngIf="item['name'] == 'exam_type'" (click)="signHandle($event, rowIndex)" [disabled]="signing"
                class="btn btn-link">
                {{isSignable(columnIndex, row, 'type')}}
              </button>

              <span *ngIf="item['name'] == 'reemit' && !canReemitDocument(columnIndex, row, 'type')">-</span>

              <button *ngIf="item['name'] == 'reemit'" (click)="reemitDocument($event, rowIndex)" class="btn btn-link">
                <span *ngIf="canReemitDocument(columnIndex, row, 'type')">Emitir</span>
              </button>

              <button *ngIf="item['name'] == 'chartLink'" class="btn btn-link" (click)="redirectToChart(row)">
                Prontuário
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="hasNext && !listTemplate.loading && listTemplate.data && listTemplate.header" (click)="nextPage()">
          <div class="loading-next">
            <ng-container *ngIf="!listTemplate.loadingNext">
              Carregar mais...
            </ng-container>
            <ng-container *ngIf="listTemplate.loadingNext">
              Carregando
            </ng-container>
          </div>
        </div>

        <div *ngIf="listTemplate.loading" class="loading">
          <div class="spinner-border spinner-border-xl" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>

        <div *ngIf="listTemplate.error" class="error">
          {{listTemplate.errorMessage}}
        </div>

        <div
          *ngIf="listTemplate.loading == false && (!listTemplate.data || listTemplate.data.results!.length == 0) && !this.listTemplate.error"
          class="message">
          {{this.listTemplate.notFoundMessage}}
        </div>
      </div>
    </div>
  </div>
</div>
