import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Observable } from "rxjs";
import { AccountsService } from "src/app/core/services/accounts.service";
import { DefaultModalComponent } from "src/app/shared/renderers/components/default-modal/default-modal.component";

@Component({
  selector: 'redefine-password',
  templateUrl: './redefine-password.component.html',
  styleUrls: ['./redefine-password.component.scss']
})
export class RedefinePasswordComponent implements OnInit {


  formPasswordRecover: FormGroup = this.fb.group({
    old_password: ['', [Validators.required, Validators.minLength(6)]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    password_confirm: ['', [Validators.required, Validators.minLength(6)]]
  })

  id!: number;
  submitting: boolean = false;
  registerSuccess: boolean = false;

  constructor(private fb: FormBuilder, private router: Router, private activatedRoute: ActivatedRoute, private modalService: NgbModal, private accountsService: AccountsService) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
    })
  }

  submitHandler() {
    const passwordData = this.getFormData();
    let password = this.formPasswordRecover.get('password')?.value;
    let passwordConfirm = this.formPasswordRecover.get('password_confirm')?.value;
    if(password == passwordConfirm) {
      this.redefinePassword(passwordData);
      return
    }
    else {
      const modalRef = this.modalService.open(DefaultModalComponent, { centered: true });
      modalRef.componentInstance.text = "As senhas não coincidem ";
      this.formPasswordRecover.reset();
      return modalRef.result;
    }
  }

  cancelHandler() {
    this.router.navigate([`/dashboard/registers/systemUsers/edit/${this.id}`]);
  }

  async redefinePassword(passwordData: any) {
    this.submitting = true;
    try {
      await this.accountsService.accountRedefinePassword(passwordData).toPromise();
      this.cancelHandler();
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    }
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      const modalRef = this.modalService.open(DefaultModalComponent, { centered: true });
      modalRef.componentInstance.text = errorResponse.error["detail"];
      modalRef.result.then((modalResult) => {
        if (errorResponse.status == 404) this.cancelHandler();
      })
    }
    else {
      this.setFormErrors(errorResponse);
    }
  }

  getFormData() {
    return {
      old_password: this.formPasswordRecover.get("old_password")?.value,
      password: this.formPasswordRecover.get("password")?.value,
    };
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["old_password"]) this.formPasswordRecover.get("old_password")?.setErrors({ response: errorResponse.error['old_password'] });
    if (errorResponse.error["password"]) this.formPasswordRecover.get("password")?.setErrors({ response: errorResponse.error['password'] });
    if (errorResponse.error["password"]) this.formPasswordRecover.get("password_confirm")?.setErrors({ response: errorResponse.error['password'] });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    // if ((this.formPasswordRecover.dirty || this.submitting) && !this.registerSuccess) {
    //   const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    //   modalRef.componentInstance.text = "As alterações no formulário não foram salvas e serão descartadas, deseja prosseguir?";
    //   return modalRef.result
    // }
    // else
      return true;
  }
}
