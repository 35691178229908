import {HttpErrorResponse} from "@angular/common/http";
import {Component, OnInit} from "@angular/core";
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Data, Router} from "@angular/router";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import {IDay} from "ng2-date-picker";
import { ToastrService } from "ngx-toastr";
import {ClinicModel} from "src/app/core/models/clinic.model";
import {PatientModel} from "src/app/core/models/patient.model";
import {PhoneModel} from "src/app/core/models/phone.model";
import {ProfessionalModel} from "src/app/core/models/professional.model";
import {Schedule} from "src/app/core/models/schedule.model";
import {SchedulesAsProfessionalService} from "src/app/core/services/schedules-as-professional.service";
import {DefaultModalComponent} from "src/app/shared/renderers/components/default-modal/default-modal.component";
import {OptionModel} from "src/app/shared/renderers/models/option";
import {FieldValidator} from "src/app/shared/renderers/utils/field-validator/field-validator";

@Component({
  selector: 'app-schedule-not-logged-in',
  templateUrl: './schedule-not-logged-in.component.html',
  styleUrls: ['./schedule-not-logged-in.component.scss']
})
export class ScheduleNotLoggedInComponent implements OnInit {

  patientFormGroup: FormGroup = this.fb.group({
    name: new FormControl('', [Validators.required]),
    display_name: [],
    document_number: ['', [FieldValidator.documentValidator()]],
    phone_numbers: this.fb.array([]),
    clinic: [null, [Validators.required]],
    professional: [null, [Validators.required]]
  })

  formGroup: FormGroup = this.fb.group({
    schedule: [null, [Validators.required]],
    clinic: [null, [Validators.required]],
    duration: [''],
    date_time: [null, [Validators.required]],
    notes: [''],
    attendance_type: ['1'],
    scheduling_status: ['1'],
    patient: this.patientFormGroup
  })

  loading: boolean = false;
  submitting: boolean = false;
  loadingTime: boolean = false;
  loadingPage: boolean = false;
  documentNumber?: string;

  schedules!: Schedule[];
  idSchedule!: number;
  isFetchSchedules: boolean = false;
  scheduleList: Schedule[] = [];

  clinics!: ClinicModel[];
  clinicOptions!: OptionModel[];

  professional!: ProfessionalModel;
  patient!: PatientModel;
  existingPatient!: PatientModel;
  phonesList!: PhoneModel[];

  existingDateTime!: Data;
  dateTimeOptions!: OptionModel[];

  formLoadingPatient: boolean = true;

  weekDayInitials: string[] = [
    'D', 'S', 'T', 'Q', 'Q', 'S', 'S'
  ];

  optionList: any = [];
  loadingDDI: boolean = true;

  loadingMonth = true;
  availableDates: { [date: string]: [{ key: any }] } = {};

  constructor(private scheduleAsProfessionalService: SchedulesAsProfessionalService, private activatedRoute: ActivatedRoute, private modalService: NgbModal, private fb: FormBuilder, private toast: ToastrService) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.documentNumber = params['document'];
      if (this.documentNumber) {
        this.initiatizeForm();
      }
    });
  }

  initiatizeForm() {
    this.fetchProfessional();
    this.fetchSchedules();
    this.fetchClinics();
    this.addPhoneNumber();
  }

  datePickerConfig = {
    locale: 'pt-br',
    weekDayFormatter: ((weekdayNumber: number) => this.weekDayInitials[weekdayNumber].charAt(0)),
    monthFormatter: ((moment: moment.Moment) => {
      let month = moment.format("MMMM");
      month = month.charAt(0).toUpperCase() + month.slice(1);
      return month + ' de ' + moment.year()
    }),
    enableMonthSelector: false,
    showGoToCurrent: true,
    unSelectOnClick: false,
    isDayDisabledCallback: (date: moment.Moment) => {
      let formattedDate = date.format('YYYY-MM-DD');
      return !Object.keys(this.availableDates).includes(formattedDate);
    }
  };

  viewDate = moment();

  dateChangeClickHandler(date: IDay) {
    this.formGroup.get('date_time')?.reset();
    let formattedDate = date.date.format('YYYY-MM-DD');
    this.existingDateTime = this.availableDates[formattedDate];
    this.dateTimeOptions = this.availableDates[formattedDate].map((item: { [key: string]: any }) => {
      let date = moment(item['datetime']);
      return {
        name: date.format(),
        label: date.format('DD/MM/YYYY - HH:mm:ss')
      }
    });
  }

  goToCurrentClickHandler() {
    this.viewDate = moment();
  }

  loadMonth(date: moment.Moment) {
    this.loadingMonth = true;
    this.scheduleAsProfessionalService.getAvailableTimeAsProfessional(
      this.idSchedule, this.documentNumber!, date.month() + 1, date.year()).subscribe(response => {
      this.availableDates = response;
      this.viewDate = date;
      this.loadingMonth = false;
    });
  }

  async setDuration() {
    let date = moment(this.formGroup.get('date_time')?.value);
    let selected = this.existingDateTime.find((d: any) => moment(d.datetime).isSame(date));
    this.formGroup.patchValue({duration: selected.duration});
  }

  fetchProfessional() {
    this.scheduleAsProfessionalService.getProfessional(this.documentNumber).subscribe((response) => {
      this.professional = response;
    }, error => {
      const modalRef = this.modalService.open(DefaultModalComponent, {centered: true});
      modalRef.componentInstance.text = `Profissional com CPF ${this.documentNumber} não encontrado.`;
      modalRef.result.then((modalResult) => {
      });
    });
  }

  fetchSchedules() {
    this.scheduleAsProfessionalService.getSchedules(this.documentNumber).subscribe((response) => {
      this.scheduleList = response.results as Schedule[];
      if (this.scheduleList.length > 0) {
        this.formGroup.get('schedule')?.setValue(this.scheduleList[0].id);
        if (this.scheduleList[0].id != undefined) this.idSchedule = this.scheduleList[0].id;
        this.loadMonth(moment());
      }
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.loadingPage = false;
    });
  }

  fetchClinics() {
    this.scheduleAsProfessionalService.getClinic(this.documentNumber).subscribe(response => {
      this.clinics = response;
      this.clinicOptions = this.clinics.map(({id, display_name}) => ({
        name: id!.toString(),
        label: display_name
      }) as OptionModel);
      this.formGroup.get('clinic')?.setValue(this.clinicOptions[0].name);
      this.loadingPage = true;
    }, error => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.loadingPage = false;
    });
  }

  //#region Patient

  fetchPatient(e: any) {
    this.formLoadingPatient = false;
    let value = this.patientFormGroup.get('document_number')?.value;
    this.scheduleAsProfessionalService.getPatient(this.documentNumber, value).subscribe((response: any) => {
      this.patient = response;
      this.phoneNumberForms.clear();
      let phone = this.patient.phone_numbers!.find(phone => phone.type == 'WHATSAPP');
      this.patient.phone_numbers = [phone!]
      this.addPhoneNumber();
      this.patientFormGroup.patchValue(this.patient);
      this.formLoadingPatient = true;
    }, (error: HttpErrorResponse) => {
      this.setFormErrors(error);
      this.formLoadingPatient = true;
    });
  }

  //#endregion Patient

  isFormLoading(): boolean {
    return false;
  }

  submitHandler() {
    this.submitting = true;
    let display_name = this.patientFormGroup.get('name')?.value.split(' ');
    this.patientFormGroup.get('display_name')?.setValue(display_name[0]);
    this.patientFormGroup.get('professional')?.setValue(this.professional.id);
    this.patientFormGroup.get('clinic')?.setValue(this.formGroup.get('clinic')?.value);
    let scheduleFormData = this.formGroup.getRawValue();
    scheduleFormData.attendance_type = '1';
    scheduleFormData.scheduling_status = '1';
    scheduleFormData.schedule = this.idSchedule;
    this.scheduleAsProfessionalService.createAttendance(this.documentNumber, scheduleFormData).subscribe(
      response => {
        const modalRef = this.modalService.open(DefaultModalComponent, {centered: true});
        modalRef.componentInstance.title = 'Sucesso';
        modalRef.componentInstance.text = 'Agendamento concluído';
        modalRef.result.then(() => {
          this.cancelHandler();
          this.submitting = false;
        });
      }, errorResponse => {
        const modalRef = this.modalService.open(DefaultModalComponent, {centered: true});
        modalRef.componentInstance.text = errorResponse.error["detail"];
        modalRef.result.then((modalResult) => {
        });
        this.submitting = false;
      }
    );
  }

  cancelHandler() {
    this.fetchSchedules();
    this.formGroup.get('duration')?.reset();
    this.formGroup.get('date_time')?.reset();
    this.formGroup.get('notes')?.reset();
    this.dateTimeOptions = [];
    this.patientFormGroup.reset();
    this.addPhoneNumber();
  }

  get phoneNumberForms() {
    return this.patientFormGroup.get('phone_numbers') as FormArray;
  }

  getPhoneNumberForm(index: number) {
    return this.phoneNumberForms.controls[index] as FormGroup;
  }

  addPhoneNumber() {
    let phoneNumberFormGroup = this.fb.group({
      id: [],
      country_code: ['+55'],
      phone_number: ['', [Validators.required]],
      type: new FormControl('WHATSAPP'),
      is_active: [true]
    });

    this.phoneNumberForms.push(phoneNumberFormGroup);
  }

  phoneNumberIsActive(index: number) {
    let formGroup = this.phoneNumberForms.controls[index] as FormGroup;
    return formGroup.get('is_active')?.value;
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    if (!errorResponse.error) return;
    if (errorResponse.error["document_number"]) this.formGroup.get("document_number")?.setErrors({response: errorResponse.error['document_number']});
    if (errorResponse.error["name"]) this.formGroup.get("name")?.setErrors({response: errorResponse.error['name']});
    if (errorResponse.error["notes"]) this.formGroup.get("notes")?.setErrors({response: errorResponse.error['notes']});

    if (errorResponse.error["date_time"]) this.formGroup.get('date_time')?.setErrors({response: errorResponse.error['date_time']});
    if (errorResponse.error["duration"]) this.formGroup.get('duration')?.setErrors({response: errorResponse.error['duration']});
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error && errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro", {
        closeButton: true,
      });
    } else {
      this.setFormErrors(errorResponse);
    }
  }
}
