<div class="mt-3" [formGroup]="formGroup">
  <!-- Nariz -->
  <h3 class="mt-3">Nariz</h3>
  <div class="ml-3 mt-3">
    <!--Cavidade nasal-->
    <div class="d-flex">
      <h3 class="field__title">Cavidade Nasal</h3>
      <app-form-error id="nariz_cavidade_nasal" [formGroup]="formGroup" [isModalReport]="true"
        controlName="nariz_cavidade_nasal">
      </app-form-error>
    </div>
    <div class="selector column align-content-center mb-1">
      <div class="row ml-3">
        <label class="ml-3" for="preservada">
          <input formControlName="nariz_cavidade_nasal" type="radio" id="preservada" value="Preservada" class="mr-1" />
          Preservada
        </label>
        <label class="ml-3" for="manipulacao_cirurgica">
          <input formControlName="nariz_cavidade_nasal" type="radio" id="manipulacao_cirurgica"
            value="Manipulação cirúrgica" class="mr-1" />
          Manipulação Cirurgica
        </label>
      </div>
    </div>
  </div>
  <!--Corneto-->
  <h3 class="mt-3">Cornetos nasais</h3>
  <div class="ml-3 mt-3">
    <div class="d-flex">
      <h3 class="field__title">Inferior Direito</h3>
      <app-form-error id="nariz_corneto_nasal_inferior_direito" [formGroup]="formGroup" [isModalReport]="true"
        controlName="nariz_corneto_nasal_inferior_direito">
      </app-form-error>
    </div>
    <div class="selector column align-content-center mb-1">
      <div class="row ml-3">
        <label class="ml-3" for="inferior_direito_sem_hipertrofia">
          <input formControlName="nariz_corneto_nasal_inferior_direito" type="radio"
            id="inferior_direito_sem_hipertrofia" value="Sem hipertrofia" class="mr-1" />
          Sem hipertrofia
        </label>
        <label class="ml-3" for="inferior_direito_leve">
          <input formControlName="nariz_corneto_nasal_inferior_direito" type="radio" id="inferior_direito_leve"
            value="Leve" class="mr-1" />
          Leve
        </label>
        <label class="ml-3" for="inferior_direito_moderada">
          <input formControlName="nariz_corneto_nasal_inferior_direito" type="radio" id="inferior_direito_moderada"
            value="Moderada" class="mr-1" />
          Moderada
        </label>
        <label class="ml-3" for="inferior_direito_acentuada">
          <input formControlName="nariz_corneto_nasal_inferior_direito" type="radio" id="inferior_direito_acentuada"
            value="Acentuada" class="mr-1" />
          Acentuada
        </label>
        <label class="ml-3" for="inferior_direito_turbinectomia_previa">
          <input formControlName="nariz_corneto_nasal_inferior_direito" type="radio"
            id="inferior_direito_turbinectomia_previa" value="Turbinectomia prévia" class="mr-1" />
          Turbinectomia prévia
        </label>
        <label class="ml-3" for="inferior_direito_turbinectomia_previa_remanescente">
          <input formControlName="nariz_corneto_nasal_inferior_direito" type="radio"
            id="inferior_direito_turbinectomia_previa_remanescente" value="Turbinectomia prévia com Remanescente"
            class="mr-1" />
          Turbinectomia prévia com Remanescente
        </label>
      </div>
    </div>
    <div class="d-flex">
      <h3 class="field__title">Inferior Esquerdo</h3>
      <app-form-error id="nariz_corneto_nasal_inferior_esquerdo" [formGroup]="formGroup" [isModalReport]="true"
        controlName="nariz_corneto_nasal_inferior_esquerdo">
      </app-form-error>
    </div>
    <div class="selector column align-content-center mb-1">
      <div class="row ml-3">
        <label class="ml-3" for="inferior_esquerdo_sem_hipertrofia">
          <input formControlName="nariz_corneto_nasal_inferior_esquerdo" type="radio"
            id="inferior_esquerdo_sem_hipertrofia" value="Sem hipertrofia" class="mr-1" />
          Sem hipertrofia
        </label>
        <label class="ml-3" for="inferior_esquerdo_leve">
          <input formControlName="nariz_corneto_nasal_inferior_esquerdo" type="radio" id="inferior_esquerdo_leve"
            value="Leve" class="mr-1" />
          Leve
        </label>
        <label class="ml-3" for="inferior_esquerdo_moderada">
          <input formControlName="nariz_corneto_nasal_inferior_esquerdo" type="radio" id="inferior_esquerdo_moderada"
            value="Moderada" class="mr-1" />
          Moderada
        </label>
        <label class="ml-3" for="inferior_esquerdo_acentuada">
          <input formControlName="nariz_corneto_nasal_inferior_esquerdo" type="radio" id="inferior_esquerdo_acentuada"
            value="Acentuada" class="mr-1" />
          Acentuada
        </label>
        <label class="ml-3" for="inferior_esquerdo_turbinectomia_previa">
          <input formControlName="nariz_corneto_nasal_inferior_esquerdo" type="radio"
            id="inferior_esquerdo_turbinectomia_previa" value="Turbinectomia prévia" class="mr-1" />
          Turbinectomia prévia
        </label>
        <label class="ml-3" for="inferior_esquerdo_turbinectomia_previa_remanescente">
          <input formControlName="nariz_corneto_nasal_inferior_esquerdo" type="radio"
            id="inferior_esquerdo_turbinectomia_previa_remanescente" value="Turbinectomia prévia com Remanescente"
            class="mr-1" />
          Turbinectomia prévia com Remanescente
        </label>
      </div>
    </div>
    <div class="d-flex">
      <h3 class="field__title">Médio Direito Hipertrofia</h3>
      <app-form-error id="nariz_corneto_nasal_medio_direito_hipertrofia" [formGroup]="formGroup" [isModalReport]="true"
        controlName="nariz_corneto_nasal_medio_direito_hipertrofia">
      </app-form-error>
    </div>
    <div class="selector column align-content-center mb-1">
      <div class="row ml-3">
        <label class="ml-3" for="direito_hipertrofia_sem_hipertrofia">
          <input formControlName="nariz_corneto_nasal_medio_direito_hipertrofia" type="radio"
            id="direito_hipertrofia_sem_hipertrofia" value="Sem hipertrofia" class="mr-1" />
          Sem hipertrofia
        </label>
        <label class="ml-3" for="direito_hipertrofia_leve">
          <input formControlName="nariz_corneto_nasal_medio_direito_hipertrofia" type="radio"
            id="direito_hipertrofia_leve" value="Leve" class="mr-1" />
          Leve
        </label>
        <label class="ml-3" for="direito_hipertrofia_moderada">
          <input formControlName="nariz_corneto_nasal_medio_direito_hipertrofia" type="radio"
            id="direito_hipertrofia_moderada" value="Moderada" class="mr-1" />
          Moderada
        </label>
        <label class="ml-3" for="direito_hipertrofia_acentuada">
          <input formControlName="nariz_corneto_nasal_medio_direito_hipertrofia" type="radio"
            id="direito_hipertrofia_acentuada" value="Acentuada" class="mr-1" />
          Acentuada
        </label>
      </div>
    </div>
    <div class="d-flex">
      <h3 class="field__title">Médio Direito Formação Bolhosa</h3>
      <app-form-error id="nariz_corneto_nasal_medio_direito_formacao_bolhosa" [formGroup]="formGroup"
        [isModalReport]="true" controlName="nariz_corneto_nasal_medio_direito_formacao_bolhosa">
      </app-form-error>
    </div>
    <div class="selector column align-content-center mb-1">
      <div class="row ml-3">
        <label class="ml-3" for="sem_formacao_bolhosa_direito">
          <input formControlName="nariz_corneto_nasal_medio_direito_formacao_bolhosa" type="radio"
            id="sem_formacao_bolhosa_direito" value="Sem formação bolhosa" class="mr-1" />
          Sem formação bolhosa
        </label>
        <label class="ml-3" for="com_formacao_bolhosa_direito">
          <input formControlName="nariz_corneto_nasal_medio_direito_formacao_bolhosa" type="radio"
            id="com_formacao_bolhosa_direito" value="Com formação bolhosa" class="mr-1" />
          Com formação bolhosa
        </label>
        <label class="ml-3" for="com_extensa_formacao_bolhosa_direito">
          <input formControlName="nariz_corneto_nasal_medio_direito_formacao_bolhosa" type="radio"
            id="com_extensa_formacao_bolhosa_direito" value="Com extensa formação bolhosa" class="mr-1" />
          Com extensa formação bolhosa
        </label>
      </div>
    </div>

    <div class="d-flex">
      <h3 class="field__title">Médio Esquerdo Hipertrofia</h3>
      <app-form-error id="nariz_corneto_nasal_medio_esquerdo_hipertrofia" [formGroup]="formGroup" [isModalReport]="true"
        controlName="nariz_corneto_nasal_medio_esquerdo_hipertrofia">
      </app-form-error>
    </div>
    <div class="selector column align-content-center mb-1">
      <div class="row ml-3">
        <label class="ml-3" for="sem_hipertrofia_esquerdo">
          <input formControlName="nariz_corneto_nasal_medio_esquerdo_hipertrofia" type="radio"
            id="sem_hipertrofia_esquerdo" value="Sem hipertrofia" class="mr-1" />
          Sem hipertrofia
        </label>
        <label class="ml-3" for="leve_esquerdo">
          <input formControlName="nariz_corneto_nasal_medio_esquerdo_hipertrofia" type="radio" id="leve_esquerdo"
            value="Leve" class="mr-1" />
          Leve
        </label>
        <label class="ml-3" for="moderada_esquerdo">
          <input formControlName="nariz_corneto_nasal_medio_esquerdo_hipertrofia" type="radio" id="moderada_esquerdo"
            value="Moderada" class="mr-1" />
          Moderada
        </label>
        <label class="ml-3" for="acentuada_esquerdo">
          <input formControlName="nariz_corneto_nasal_medio_esquerdo_hipertrofia" type="radio" id="acentuada_esquerdo"
            value="Acentuada" class="mr-1" />
          Acentuada
        </label>
      </div>
    </div>
    <div class="d-flex">
      <h3 class="field__title">Médio Esquerdo Formação Bolhosa</h3>
      <app-form-error id="nariz_corneto_nasal_medio_esquerdo_formacao_bolhosa" [formGroup]="formGroup"
        [isModalReport]="true" controlName="nariz_corneto_nasal_medio_esquerdo_formacao_bolhosa">
      </app-form-error>
    </div>
    <div class="selector column align-content-center mb-1">
      <div class="row ml-3">
        <label class="ml-3" for="sem_formacao_bolhosa_esquerdo">
          <input formControlName="nariz_corneto_nasal_medio_esquerdo_formacao_bolhosa" type="radio"
            id="sem_formacao_bolhosa_esquerdo" value="Sem formação bolhosa" class="mr-1" />
          Sem formação bolhosa
        </label>
        <label class="ml-3" for="com_formacao_bolhosa_esquerdo">
          <input formControlName="nariz_corneto_nasal_medio_esquerdo_formacao_bolhosa" type="radio"
            id="com_formacao_bolhosa_esquerdo" value="Com formação bolhosa" class="mr-1" />
          Com formação bolhosa
        </label>
        <label class="ml-3" for="com_extensa_formacao_bolhosa_esquerdo">
          <input formControlName="nariz_corneto_nasal_medio_esquerdo_formacao_bolhosa" type="radio"
            id="com_extensa_formacao_bolhosa_esquerdo" value="Com extensa formação bolhosa" class="mr-1" />
          Com extensa formação bolhosa
        </label>
      </div>
    </div>
    <div class="d-flex">
      <h3 class="field__title">Septo Nasal Desvio</h3>
      <app-form-error id="nariz_septo_nasal_desvio" [formGroup]="formGroup" [isModalReport]="true"
        controlName="nariz_septo_nasal_desvio">
      </app-form-error>
    </div>
    <div class="selector column align-content-center mb-1">
      <div class="row ml-3">
        <label class="ml-3" for="sem_desvio">
          <input formControlName="nariz_septo_nasal_desvio" type="radio" id="sem_desvio" value="Sem desvio" class="mr-1"
            (change)="desvioValidator('sem')" />
          Sem desvio
        </label>
        <label class="ml-3" for="desvio_a_esquerda">
          <input formControlName="nariz_septo_nasal_desvio" type="radio" id="desvio_a_esquerda"
            value="Desvio à esquerda" class="mr-1" (change)="desvioValidator('Desvio à esquerda')" />
          Desvio à esquerda
        </label>
        <label class="ml-3" for="desvio_a_direita">
          <input formControlName="nariz_septo_nasal_desvio" type="radio" id="desvio_a_direita" value="Desvio à direita"
            class="mr-1" (change)="desvioValidator('Desvio à direita')" />
          Desvio à direita
        </label>
      </div>
      <div class="row ml-5 mt-2" *ngIf="desvioPresente">
        <label class="subtitle">Grau do Desvio</label>
        <label class="ml-3" for="leve_desvio"><input formControlName="nariz_septo_nasal_desvio_grau" type="radio"
            id="leve_desvio" value="Leve" class="mr-1">
          Leve</label>
        <label class="ml-3" for="moderado_desvio"><input formControlName="nariz_septo_nasal_desvio_grau" type="radio"
            id="moderado_desvio" value="Moderado" class="mr-1">
          Moderado</label>
        <label class="ml-3" for="acentuado_desvio"><input formControlName="nariz_septo_nasal_desvio_grau" type="radio"
            id="acentuado_desvio" value="Acentuado" class="mr-1">
          Acentuado</label>
        <app-form-error id="nariz_septo_nasal_desvio_grau" [formGroup]="formGroup" [isModalReport]="true"
          controlName="nariz_septo_nasal_desvio_grau">
        </app-form-error>
      </div>
    </div>

    <div class="d-flex">
      <h3 class="field__title">Septo Nasal Esporão</h3>
      <app-form-error id="nariz_septo_nasal_esporao" [formGroup]="formGroup" [isModalReport]="true"
        controlName="nariz_septo_nasal_esporao">
      </app-form-error>
    </div>
    <div class="selector column align-content-center mb-1">
      <div class="row ml-3">
        <label class="ml-3" for="ausencia_esporao">
          <input formControlName="nariz_septo_nasal_esporao" type="radio" id="ausencia_esporao"
            value="Ausência de esporão" class="mr-1" />
          Ausência de esporão
        </label>
        <label class="ml-3" for="presenca_esporao">
          <input formControlName="nariz_septo_nasal_esporao" type="radio" id="presenca_esporao"
            value="Presença de esporão" class="mr-1" />
          Presença de esporão
        </label>
      </div>
    </div>
    <div class="d-flex">
      <h3 class="field__title">Rinofaringe</h3>
      <app-form-error id="nariz_rinofaringe" [formGroup]="formGroup" [isModalReport]="true"
        controlName="nariz_rinofaringe">
      </app-form-error>
    </div>
    <div class="selector column align-content-center mb-1">
      <div class="row ml-3">
        <label class="ml-3" for="normal_rinofaringe">
          <input formControlName="nariz_rinofaringe" type="radio" id="normal_rinofaringe" value="Normal" class="mr-1" />
          Normal
        </label>
        <label class="ml-3" for="obstruida_rinofaringe">
          <input formControlName="nariz_rinofaringe" type="radio" id="obstruida_rinofaringe" value="Obstruída"
            class="mr-1" />
          Obstruída
        </label>
      </div>
    </div>
    <!-- Observações adicionais -->
    <div class="mt-3">
      <div class="d-flex">
        <h3 class="field___title">Observações adicionais</h3>
      </div>
      <div class="d-flex">
        <textarea rows="3" formControlName="nariz_observacoes_adicionais"></textarea>
      </div>
    </div>
  </div>

  <!-- Seios da face -->
  <h3 class="mt-3">Seios da face</h3>
  <div class="ml-3 mt-3">
    <div class="d-flex">
      <h3 class="field__title">Coleções</h3>
      <app-form-error id="seios_da_face_colecoes" [formGroup]="formGroup" [isModalReport]="true"
        controlName="seios_da_face_colecoes">
      </app-form-error>
    </div>
    <div class="selector column align-content-center mb-1">
      <div class="row ml-3">
        <label class="ml-3" *ngFor="let seio of colecoesList" [for]="seio.name">
          <input type="checkbox" [id]="seio.name" value="seio.id" [checked]="seio.checked"
            (change)="toggleColecoes(seio)" class="mr-1" />
          {{seio.id}}
        </label>
      </div>
    </div>
    <div class="d-flex">
      <h3 class="field__title">Membranas Sinuais</h3>
      <app-form-error id="seios_da_face_membranas_sinuais_espessamento" [formGroup]="formGroup" [isModalReport]="true"
        controlName="seios_da_face_membranas_sinuais_espessamento">
      </app-form-error>
    </div>
    <div class="selector column align-content-center mb-1">
      <div class="row ml-3">
        <label class="ml-3" for="sem_espessamento">
          <input formControlName="seios_da_face_membranas_sinuais_espessamento" type="radio" id="sem_espessamento"
            value="Sem espessamento" class="mr-1" (click)="espessamentoValidator('Sem')" />
          Sem espessamento
        </label>
        <label class="ml-3" for="com_espessamento">
          <input formControlName="seios_da_face_membranas_sinuais_espessamento" type="radio" id="com_espessamento"
            value="Com espessamento" class="mr-1" (click)="espessamentoValidator('Com espessamento')" />
          Com espessamento
        </label>
      </div>
      <div class="row ml-5 mt-2" *ngIf="espessamentoPresente">
        <label class="subtitle">Espessamento</label>
        <label class="ml-3" *ngFor="let seio of seiosMembranas" [for]="seio.name">
          <input type="checkbox" [id]="seio.name" value="seio.id" class="mr-1" [checked]="seio.checked" (change)="toggleMembranas(seio)" />
          {{seio.id}}
        </label>
        <app-form-error id="seios_da_face_membranas_sinuais_seios" [formGroup]="formGroup" [isModalReport]="true"
          controlName="seios_da_face_membranas_sinuais_seios">
        </app-form-error>
      </div>
    </div>
    <div class="d-flex">
      <h3 class="field__title">Formações Císticas ou Polipoides</h3>
      <app-form-error id="seios_da_face_formacoes_cisticas_e_polipoides" [formGroup]="formGroup" [isModalReport]="true"
        controlName="seios_da_face_formacoes_cisticas_e_polipoides">
      </app-form-error>
    </div>
    <div class="selector column align-content-center mb-1">
      <div class="row ml-3">
        <label class="ml-3" *ngFor="let formacoes of formacoes_cisticas_e_polipoides" [for]="formacoes.name" [ngClass]="formacoes.disabled ? 'd-none': ''">
          <input type="checkbox" [id]="formacoes.name" value="formacoes.id" class="mr-1" [checked]="formacoes.checked"
            [disabled]="formacoes.disabled" (change)="toggleFormacoes(formacoes.id)" />
          {{formacoes.id}}
        </label>
      </div>
      <div class="row ml-5 mt-2" *ngIf="formacoesCisticasPresente">
        <label class="subtitle">Formações Císticas</label>
        <div class="selector column align-content-center mb-1">
          <div class="row ml-3">
            <label class="ml-3" *ngFor="let seio of cisticasList" [for]="seio.name">
              <input type="checkbox" [id]="seio.name" value="seio.id" (change)="toggleCisticas(seio)"
                [checked]="seio.checked" class="mr-1" />
              {{seio.id}}
            </label>
          </div>
        </div>
        <app-form-error id="seios_da_face_presenca_de_formacoes_cisticas" [formGroup]="formGroup" [isModalReport]="true"
          controlName="seios_da_face_presenca_de_formacoes_cisticas">
        </app-form-error>
      </div>
      <div class="row ml-5 mt-2" *ngIf="formacoesPolipoidesPresente">
        <label class="subtitle">Formações Polipoides</label>
        <label class="ml-3" *ngFor="let seio of polipoidesList" [for]="seio.name">
          <input type="checkbox" [id]="seio.name" value="seio.id" (change)="togglePolipoides(seio)"
            [checked]="seio.checked" class="mr-1" />
          {{seio.id}}
        </label>
        <app-form-error id="seios_da_face_presenca_de_formacoes_polipoides" [formGroup]="formGroup"
          [isModalReport]="true" controlName="seios_da_face_presenca_de_formacoes_polipoides">
        </app-form-error>
      </div>
    </div>
    <div class="d-flex">
      <h3 class="field__title">Unidades Óstio Meatais</h3>
      <app-form-error id="seios_da_face_unidades_ostio_meatais_drenagem" [formGroup]="formGroup" [isModalReport]="true"
        controlName="seios_da_face_unidades_ostio_meatais_drenagem">
      </app-form-error>
    </div>
    <div class="selector column align-content-center mb-1">
      <div class="row ml-3">
        <label class="ml-3" for="drenagem_livre_ostio">
          <input formControlName="seios_da_face_unidades_ostio_meatais_drenagem" type="radio" id="drenagem_livre_ostio"
            value="Drenagem livre" (change)="drenagemObstruidaValidator('Drenagem livre')" class="mr-1" />
          Drenagem livre
        </label>
        <label class="ml-3" for="drenagem_obstruida_ostio">
          <input formControlName="seios_da_face_unidades_ostio_meatais_drenagem" type="radio"
            id="drenagem_obstruida_ostio" value="Drenagem sinusal obstruída"
            (change)="drenagemObstruidaValidator('Drenagem sinusal obstruída')" class="mr-1" />
          Drenagem sinusal obstruída
        </label>
      </div>
      <div class="row ml-5 mt-2" *ngIf="drenagemObstruidaPresente">
        <label class="subtitle">Drenagem sinusal obstruída</label>
        <label class="ml-3" *ngFor="let seio of drenagemList" [for]="seio.name">
          <input type="checkbox" [id]="seio.name" value="seio.id" (change)="toggleDrenagem(seio)"
            [checked]="seio.checked" class="mr-1" />
          {{seio.id}}
        </label>
        <app-form-error id="seios_da_face_unidades_ostio_meatais_drenagem_seios" [formGroup]="formGroup"
          [isModalReport]="true" controlName="seios_da_face_unidades_ostio_meatais_drenagem_seios">
        </app-form-error>
      </div>
    </div>
  </div>
  <!-- Observações adicionais -->
  <div class="mt-3">
    <div class="d-flex">
      <h3 class="field___title">Observações adicionais</h3>
    </div>
    <div class="d-flex">
      <textarea rows="3" formControlName="seios_da_face_observacoes_adicionais"></textarea>
    </div>
  </div>
</div>
