import { ChangeDetectorRef, Component, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'form-seios-da-face',
  templateUrl: './seios-da-face.component.html',
  styleUrls: ['./seios-da-face.component.scss']
})
export class SeiosDaFaceComponent implements OnInit {

  @Input() variables: any;

  formGroup: FormGroup = this.fb.group({
    nariz_cavidade_nasal: ['Preservada', [Validators.required]],
    nariz_corneto_nasal_inferior_direito: ['Sem hipertrofia', [Validators.required]],
    nariz_corneto_nasal_inferior_esquerdo: ['Sem hipertrofia', [Validators.required]],
    nariz_corneto_nasal_medio_direito_hipertrofia: ['Sem hipertrofia', [Validators.required]],
    nariz_corneto_nasal_medio_direito_formacao_bolhosa: ['Sem formação bolhosa', [Validators.required]],
    nariz_corneto_nasal_medio_esquerdo_hipertrofia: ['Sem hipertrofia', [Validators.required]],
    nariz_corneto_nasal_medio_esquerdo_formacao_bolhosa: ['Sem formação bolhosa', [Validators.required]],
    nariz_septo_nasal_desvio: ['Sem desvio', [Validators.required]],
    nariz_septo_nasal_desvio_grau: [''],
    nariz_septo_nasal_esporao: ['Ausência de esporão', [Validators.required]],
    nariz_rinofaringe: ['Normal', [Validators.required]],
    nariz_observacoes_adicionais: [''],
    seios_da_face_colecoes: [''],
    seios_da_face_membranas_sinuais_espessamento: ['Sem espessamento', [Validators.required]],
    seios_da_face_membranas_sinuais_seios: [''],
    seios_da_face_formacoes_cisticas_e_polipoides: ['Sem formações císticas ou polipoides', [Validators.required]],
    seios_da_face_presenca_de_formacoes_cisticas: [''],
    seios_da_face_presenca_de_formacoes_polipoides: [''],
    seios_da_face_unidades_ostio_meatais_drenagem: ['Drenagem livre', [Validators.required]],
    seios_da_face_unidades_ostio_meatais_drenagem_seios: [''],
    seios_da_face_observacoes_adicionais: [''],
    date: ['']
  });

  desvioPresente: boolean = false;
  espessamentoPresente: boolean = false;
  formacoesCisticasPresente: boolean = false;
  formacoesPolipoidesPresente: boolean = false;
  drenagemObstruidaPresente: boolean = false;

  formacoes_cisticas_e_polipoides = [
    { name: 'formacoes_cisticas', id: 'Presença de formações Císticas', checked: false, disabled: false },
    { name: 'formacoes_polipodes', id: 'Presença de formações Polipoides', checked: false, disabled: false },
    { name: 'sem_formacoes', id: 'Sem formações císticas ou polipoides', checked: false, disabled: true }
  ];

  getSeiosFaceList(value: string) {
    return [
      { name: `seio_frontal${value}`, id: 'Seio frontal', checked: false },
      { name: `seio_etmoidal${value}`, id: 'Seio etmoidal', checked: false },
      { name: `seio_maxilar_esquerdo${value}`, id: 'Seio maxilar esquerdo', checked: false },
      { name: `seio_maxilar_direito${value}`, id: 'Seio maxilar direito', checked: false },
    ];
  }

  colecoesList = this.getSeiosFaceList('_colecoes');
  cisticasList = this.getSeiosFaceList('_cisticas');
  polipoidesList = this.getSeiosFaceList('_polipoides');
  drenagemList = this.getSeiosFaceList('_drenagem');

  seiosMembranas = [
    { name: `seio_frontal`, id: 'Seio frontal', checked: false },
    { name: `seio_etmoidal`, id: 'Seio etmoidal', checked: false },
    { name: `seio_maxilar_esquerdo`, id: 'Seio maxilar esquerdo', checked: false },
    { name: `seio_maxilar_direito`, id: 'Seio maxilar direito', checked: false },
  ];

  constructor(private fb: FormBuilder, private readonly changeDetectorRef: ChangeDetectorRef) { }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    if (this.variables) {
      this.existVariables();
    }
  }

  existVariables() {
    this.formGroup.patchValue(this.variables);
    if (this.variables.nariz_septo_nasal_desvio_grau) {
      this.desvioValidator(this.variables.nariz_septo_nasal_desvio)
    }
    if (this.variables.seios_da_face_colecoes) {
      let colecoes = this.buildArray(this.variables.seios_da_face_colecoes);
      this.colecoesList.forEach(item => {
        colecoes.find((value: string) => {
          if (item.id == value) {
            this.toggleColecoes(item);
          }
        });
      });
    }
    if (this.variables.seios_da_face_membranas_sinuais_espessamento) {
      this.espessamentoValidator(this.variables.seios_da_face_membranas_sinuais_espessamento);
      let membranas = this.buildArray(this.variables.seios_da_face_membranas_sinuais_seios);
      this.seiosMembranas.forEach(item => {
        membranas.find((value: string) => {
          if (item.id == value) {
            this.toggleMembranas(item);
          }
        });
      });
    }
    if (this.variables.seios_da_face_formacoes_cisticas_e_polipoides) {
      let formacoes = this.buildArray(this.variables.seios_da_face_formacoes_cisticas_e_polipoides);
      this.formacoes_cisticas_e_polipoides.forEach(item => {
        formacoes.find((value: string) => {
          if (item.id == value) {
            this.toggleFormacoes(item.id);
          }
        })
      })
    }
    if (this.variables.seios_da_face_presenca_de_formacoes_cisticas) {
      let cisticas = this.buildArray(this.variables.seios_da_face_presenca_de_formacoes_cisticas);
      this.cisticasList.forEach(item => {
        cisticas.find((value: string) => {
          if (item.id == value) {
            this.toggleCisticas(item);
          }
        })
      })
    }
    if (this.variables.seios_da_face_presenca_de_formacoes_polipoides) {
      let polipoides = this.buildArray(this.variables.seios_da_face_presenca_de_formacoes_polipoides);
      this.polipoidesList.forEach(item => {
        polipoides.find((value: string) => {
          if (item.id == value) {
            this.togglePolipoides(item);
          }
        })
      })
    }
    this.drenagemObstruidaValidator(this.variables.seios_da_face_unidades_ostio_meatais_drenagem);
    if (this.variables.seios_da_face_unidades_ostio_meatais_drenagem_seios) {
      let drenagem = this.buildArray(this.variables.seios_da_face_unidades_ostio_meatais_drenagem_seios);
      this.drenagemList.forEach(item => {
        drenagem.find((value: string) => {
          if (item.id == value) {
            this.toggleDrenagem(item);
          }
        })
      })
    }

  }

  desvioValidator(value: string) {
    if (value == 'Desvio à esquerda' || value == 'Desvio à direita') {
      this.desvioPresente = true;
      this.formGroup.get('nariz_septo_nasal_desvio_grau')?.addValidators([Validators.required]);
    } else {
      this.desvioPresente = false;
      this.formGroup.get('nariz_septo_nasal_desvio_grau')?.clearValidators();
      this.formGroup.get('nariz_septo_nasal_desvio_grau')?.setValue('');
    }
  }

  espessamentoValidator(value: string) {
    if (value == 'Com espessamento') {
      this.espessamentoPresente = true;
      this.formGroup.get('seios_da_face_membranas_sinuais_seios')?.addValidators([Validators.required]);
    } else {
      this.espessamentoPresente = false;
      this.formGroup.get('seios_da_face_membranas_sinuais_seios')?.clearValidators();
      this.formGroup.get('seios_da_face_membranas_sinuais_seios')?.setValue('');
    }
  }

  formacoesValidator() {
    this.formacoesCisticasPresente = this.formacoes_cisticas_e_polipoides.find(item => item.name == 'formacoes_cisticas')?.checked ?? false;
    this.formacoesPolipoidesPresente = this.formacoes_cisticas_e_polipoides.find(item => item.name == 'formacoes_polipodes')?.checked ?? false;

    if (this.formacoesCisticasPresente) {
      this.formGroup.get('seios_da_face_presenca_de_formacoes_cisticas')?.addValidators([Validators.required]);
    } else {
      this.formGroup.get('seios_da_face_presenca_de_formacoes_cisticas')?.clearValidators();
      this.formGroup.get('seios_da_face_presenca_de_formacoes_cisticas')?.setValue('');
    }

    if (this.formacoesPolipoidesPresente) {
      this.formGroup.get('seios_da_face_presenca_de_formacoes_polipoides')?.addValidators([Validators.required]);
    } else {
      this.formGroup.get('seios_da_face_presenca_de_formacoes_polipoides')?.clearValidators();
      this.formGroup.get('seios_da_face_presenca_de_formacoes_polipoides')?.setValue('');
    }
  }

  drenagemObstruidaValidator(value: string) {
    if (value == 'Drenagem sinusal obstruída') {
      this.drenagemObstruidaPresente = true;
      this.formGroup.get('seios_da_face_unidades_ostio_meatais_drenagem_seios')?.addValidators([Validators.required]);
    } else {
      this.drenagemObstruidaPresente = false;
      this.formGroup.get('seios_da_face_unidades_ostio_meatais_drenagem_seios')?.clearValidators();
      this.formGroup.get('seios_da_face_unidades_ostio_meatais_drenagem_seios')?.setValue('');
    }
  }

  toggleFormacoes(value?: string) {
    let formacao = this.formacoes_cisticas_e_polipoides.find(item => item.id == value);
    let formacoes = '';
    formacao!.checked = !formacao!.checked;

    let cisticas = this.formacoes_cisticas_e_polipoides.find(item => item.name == 'formacoes_cisticas')?.checked;
    let polipoides = this.formacoes_cisticas_e_polipoides.find(item => item.name == 'formacoes_polipodes')?.checked;
    let sem_formacoes = this.formacoes_cisticas_e_polipoides.find(item => item.name == 'sem_formacoes');
    if (!cisticas && !polipoides) {
      sem_formacoes!.checked = true;
    } else {
      sem_formacoes!.checked = false;
    }
    this.formacoesValidator();
    formacoes = this.buildString(this.formacoes_cisticas_e_polipoides);
    this.formGroup.get('seios_da_face_formacoes_cisticas_e_polipoides')?.setValue(formacoes);
  }

  toggleColecoes(value: any) {
    let index = this.colecoesList.findIndex(item => item.id == value.id);
    this.colecoesList[index].checked = !this.colecoesList[index].checked;
    let colecoes = this.buildString(this.colecoesList);
    this.formGroup.get('seios_da_face_colecoes')?.setValue(colecoes);
  }

  toggleDrenagem(value: any) {
    let drenagem = this.drenagemList.find(item => item.id == value.id);
    drenagem!.checked = !drenagem!.checked;
    let drenagens = this.buildString(this.drenagemList);
    this.formGroup.get('seios_da_face_unidades_ostio_meatais_drenagem_seios')?.setValue(drenagens);
  }

  togglePolipoides(value: any) {
    let polipoide = this.polipoidesList.find(item => item.id == value.id);
    polipoide!.checked = !polipoide!.checked;
    let polipoides = this.buildString(this.polipoidesList);
    this.formGroup.get('seios_da_face_presenca_de_formacoes_polipoides')?.setValue(polipoides);
  }

  toggleCisticas(value: any) {
    let cistica = this.cisticasList.find(item => item.id == value.id);
    cistica!.checked = !cistica!.checked;
    let cisticas = this.buildString(this.cisticasList);
    this.formGroup.get('seios_da_face_presenca_de_formacoes_cisticas')?.setValue(cisticas);
  }

  toggleMembranas(value: any) {
    let membrana = this.seiosMembranas.find(item => item.id == value.id);
    membrana!.checked = !membrana!.checked;
    let membranas = this.buildString(this.seiosMembranas);
    this.formGroup.get('seios_da_face_membranas_sinuais_seios')?.setValue(membranas);
  }

  buildString(stringArray: any[]) {
    stringArray = stringArray.filter(item => {
      return item.checked == true;
    });
    stringArray = stringArray.map(item => item.id);
    if (stringArray.length == 0) return '';
    if (stringArray.length == 1) {
      let stringResponseDefault = stringArray.toString();
      return stringResponseDefault;
    }
    let popped = stringArray.pop();
    let stringResponse = stringArray.join(', ');
    stringResponse += ' e ' + popped!;
    return stringResponse;
  }

  buildArray(value: any) {
    let strArray = value.split(', ');
    let arrayResponse = value.split(', ')[strArray.length - 1].split(' e ');
    strArray.pop();
    arrayResponse.push(...strArray)
    return arrayResponse;
  }

  setErrors() {
    let errNamesSeios = [
      'nariz_cavidade_nasal', 'nariz_corneto_nasal_inferior_direito', 'nariz_corneto_nasal_inferior_esquerdo',
      'nariz_corneto_nasal_medio_direito_hipertrofia', 'nariz_corneto_nasal_medio_direito_formacao_bolhosa',
      'nariz_corneto_nasal_medio_esquerdo_hipertrofia', 'nariz_corneto_nasal_medio_esquerdo_formacao_bolhosa',
      'nariz_septo_nasal_desvio', 'nariz_septo_nasal_desvio_grau', 'nariz_septo_nasal_esporao', 'nariz_rinofaringe',
      'nariz_observacoes_adicionais', 'seios_da_face_colecoes', 'seios_da_face_membranas_sinuais_espessamento',
      'seios_da_face_membranas_sinuais_seios', 'seios_da_face_formacoes_cisticas_e_polipoides',
      'seios_da_face_presenca_de_formacoes_cisticas', 'seios_da_face_presenca_de_formacoes_polipoides',
      'seios_da_face_unidades_ostio_meatais_drenagem', 'seios_da_face_unidades_ostio_meatais_drenagem_seios',
      'seios_da_face_observacoes_adicionais'
    ];
    errNamesSeios.forEach(name => {
      if (this.formGroup.get(name)?.errors)
        this.formGroup.get(name)?.setErrors({ response: '* Este campo é obrigatório', modal: true });
    });
  }
}
