import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FinancialSettingsComponent } from './financial-settings.component';



@NgModule({
  declarations: [
    FinancialSettingsComponent
  ],
  imports: [
    CommonModule,
    RenderersModule,
    SharedModule,
    ReactiveFormsModule,
    NgSelectModule
  ]
})
export class FinancialSettingsModule { }
