import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegistersService } from 'src/app/core/services/registers.service';
import { FormBuilder } from '@angular/forms';
import { FileModel } from 'src/app/core/models/file.model';
import { FileInputComponent } from '../file-input/file-input.component';

@Component({
  selector: 'attachments-input',
  templateUrl: './attachments-input.component.html',
  styleUrls: ['./attachments-input.component.scss']
})
export class AttachmentsInputComponent implements OnInit {

  @Input() attachmetsInput: FileModel[] = [];
  attachments: FileModel[] = [];
  id!: number;
  currentFile!: File;
  hasFile: boolean = false;

  attachmentForm = this.formBuilder.group({
    name: '',
  });

  constructor(private registersService: RegistersService, private activatedRoute: ActivatedRoute, private formBuilder: FormBuilder,) { }

  @ViewChild(FileInputComponent) fileInput!: FileInputComponent;

  ngOnInit(): void {
    this.activatedRoute.parent?.params.subscribe(params => {
      this.id = params['id'];

      if (this.attachmetsInput) {
        this.attachments = this.attachmetsInput;
      }
    });
  }

  onFileChange(file: File) {
    this.currentFile = file;
    this.hasFile = true;
    this.attachmentForm.get("name")?.setValue(this.currentFile.name);
  }

  addButtonHandler(e: Event) {
    if (this.hasFile && this.attachmentForm.valid) {
      this.registersService.patientAttachmentRegister(this.currentFile, this.attachmentForm.get("name")?.value, this.id).subscribe((response: FileModel) => {
        this.attachmentForm.reset();
        this.attachments.push(response);
        this.fileInput.reset();
        this.hasFile = false
      });
    }

    e.preventDefault();
  }

  removeButtonHandler(attachmentIndex: number, attachmentId: number, e: Event) {
    const confirmResult = confirm("Deseja remover este anexo?");

    if (confirmResult) {
      this.registersService.patientAttachmentRemove(this.id, attachmentId).subscribe(() => {
        this.attachments.splice(attachmentIndex, 1);
      });
    }
    e.preventDefault()
  }

  getPlaceholder(): string {
    if (!this.hasFile) return "Enviar arquivo";
    else return "Substituir arquivo"
  }

  downloadFile(url: string, event: Event) {
    window.open(url, '_blank');
    event.preventDefault();
  }
}
