
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountModel } from 'src/app/core/models/account.model';
import { FinancialStatusModel } from 'src/app/core/models/financial-status.model';
import { PatientModel } from 'src/app/core/models/patient.model';
import { ProcedureModel } from 'src/app/core/models/procedure.model';
import { TicketModel } from 'src/app/core/models/ticket.model';
import { AccountsService } from 'src/app/core/services/accounts.service';
import { RegistersService } from 'src/app/core/services/registers.service';
import * as moment from 'moment';
import { HealthInsurancePatientModel } from 'src/app/core/models/health-insurance-patient';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AttachmentModalComponent } from 'src/app/shared/components/attachment-modal/attachment-modal.component';
import { AttachmentPostModel } from 'src/app/core/models/attachment-post.model';
import { TicketPostModel } from 'src/app/core/models/ticket-post.model';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { ProtocolModelComponent } from 'src/app/shared/components/protocol-model/protocol-model.component';
import {documentsStringFormatter} from "../../../../core/utils/string-formatters";
import {MailingModalComponent} from "../../../../shared/components/mailing-modal/mailing-modal.component";

@Component({
  selector: 'app-financial-form',
  templateUrl: './financial-form.component.html',
  styleUrls: ['./financial-form.component.scss']
})
export class FinancialFormComponent implements OnInit {

  detailHeader = [
    { title: 'PROCEDIMENTO', width: 'col-3' },
    { title: 'STATUS', width: 'col-2' },
    { title: 'PROTOCOLO', width: 'col-1' },
    { title: 'DATA DO PEDIDO REEMBOLSO', width: 'col-1' },
    { title: 'VALOR NOTA FISCAL', width: 'col-1' },
    { title: 'NÚMERO NOTA FISCAL', width: 'col-1' },
    { title: 'VALOR REEMBOLSO', width: 'col-1' },
    { title: 'DATA REEMBOLSO', width: 'col-1' },
    { title: 'VALOR COPARTICIPAÇÃO', width: 'col-1' },
    { title: 'DATA RECEBIMENTO', width: 'col-1' },
    { title: 'VALOR RECEBIDO', width: 'col-1' },
    { title: 'ANEXOS', width: 'col' },
  ];

  formGroup = this.fb.group({
    date_time: [null, Validators.required],
    user_id: ['', Validators.required],
    patient_id: ['', Validators.required],
    health_insurance_plan_id: [''],
    attachment: this.fb.array([]),
    ticket_procedure: this.fb.array([]),
    login: [''],
    password: [''],
    dependent: [''],
    log: this.fb.group({
      notes: [null, Validators.required],
      protocol: [''],
      attachment: this.fb.array([])
    })
  });

  showLoginAndPassword = false;

  subTitle = 'Novo Ticket';
  protocolName = 'Novo protocolo';
  id!: number;

  loadingPage: boolean = true;
  isRegister: boolean = true;
  submitting: boolean = false;

  queryParams: any;

  fetchedTicket: TicketModel = {};
  userList: AccountModel[] = [];
  patientList: PatientModel[] = [];
  healthInsuranceList: HealthInsurancePatientModel[] = [];
  proceduresList: ProcedureModel[] = [];
  statusList: FinancialStatusModel[] = [];

  constructor(private activatedRoute: ActivatedRoute, private registerService: RegistersService, private accountsService: AccountsService,
    private fb: FormBuilder, private modalService: NgbModal, private toast: ToastrService, private router: Router, private sessionManager: SessionManagerService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params["id"];
      if (this.id) {
        this.isRegister = false;
        this.subTitle = "Carregando...";
      }
    });
    this.activatedRoute.queryParams.subscribe(params => {
      this.queryParams = {
        dateStart: params['dateStart'],
        dateEnd: params['dateEnd']
      }
    });
    this.initializeForm();
  }

  get selectedPatient() {
    let patient = this.patientList.find(patient => patient.id == this.fetchedTicket.patient_id);
    return patient;
  }

  get selectedPatientDocumentNumber() {
    if (this.selectedPatient!.document_number) {
      return documentsStringFormatter(this.selectedPatient!.document_number!);
    }
    return '';
  }

  get selectedPatientAge() {
    if (this.selectedPatient) {
      let birthdate = moment(this.selectedPatient.birthdate);
      let today = moment();

      let days = today.date() - birthdate.date();
      let months = (today.month() + 1) - (birthdate.month() + 1);
      let years = today.year() - birthdate.year();

      if (today.date() < birthdate.date()) {
        days += 30;
        months -= 1;
      }

      if (today.month() + 1 < birthdate.month() + 1) {
        months += 12;
        years -= 1;
      }

      return `(${years} anos ${months} meses e ${days} dias)`;
    }
    return '';
  }

  initializeForm() {
    this.fetchPatients();
    this.fetchUsers();
    this.fetchProcedures();
    this.fetchStatus();
    this.addProcedure();
    if (this.id) this.fetchTicketExistingData();
    else this.loadingPage = false;
  }

  get canAddProcedure() {
    if (this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_procedure_to_an_existent_ticket);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.add_ticket);
    }
  }

  get canSave() {
    if (this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_ticket);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_ticket);
    }
  }

  fetchUsers() {
    this.accountsService.accountGetAll('').subscribe(response => {
      this.userList = response;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    });
  }

  fetchPatients() {
    this.registerService.patientGetAll().subscribe(response => {
      this.patientList = response;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    });
  }

  fetchProcedures() {
    this.registerService.procedureGetAll().subscribe(response => {
      this.proceduresList = response;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    });
  }

  fetchStatus() {
    this.registerService.financialStatusGetAll(true).subscribe(response => {
      this.statusList = response;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    });
  }

  fetchTicketExistingData() {
    this.registerService.financialTicketGetById(this.id).subscribe((response) => {
      this.fetchedTicket = response;
      this.subTitle = this.fetchedTicket.patient?.name ?? '';
      this.proceduresForm.clear();
      response.ticket_procedure_list?.forEach((procedure, index) => {
        this.addProcedure();
        procedure.medical_orders?.forEach(value => this.createMedicalOrder(index));
        procedure.medical_reports?.forEach(value => this.createMedicalReport(index));
        this.getProcedureFormGroup(index).patchValue(procedure);
        this.getProcedureFormGroup(index).get('status_id')?.setValue(procedure.status?.status_id);
        if (procedure.refund_request_date) this.getProcedureFormGroup(index).get('refund_request_date')?.setValue(moment(procedure.refund_request_date).format('YYYY-MM-DD'));
        if (procedure.refund_date) this.getProcedureFormGroup(index).get('refund_date')?.setValue(moment(procedure.refund_date).format('YYYY-MM-DD'));
        if (procedure.receipt_date) this.getProcedureFormGroup(index).get('receipt_date')?.setValue(moment(procedure.receipt_date).format('YYYY-MM-DD'));
      });
      this.getHealthInsurance(this.fetchedTicket.patient);
      this.formGroup.patchValue(this.fetchedTicket);
      this.formGroup.get('date_time')!.setValue(moment(this.fetchedTicket.date_time).format('YYYY-MM-DD'));
      this.loadingPage = false;
      console.log(this.formGroup.getRawValue());
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    });
  }

  getProtocolName(index: number) {
    let procedureProtocolForm = this.getProcedureFormGroup(index).get('protocol') as FormArray;
    if(procedureProtocolForm.length > 0) {
      let procedures = procedureProtocolForm.value;
      procedures.sort((a: any, b: any) => {
        if (a.date_time > b.date_time) {
          return -1;
        }
        if (a.date_time < b.date_time) {
          return 1;
        }
        return 0;
      });
      return procedureProtocolForm.value[0].protocol;
    } else {
      if (this.fetchedTicket.ticket_procedure_list) {
        let name = this.fetchedTicket.ticket_procedure_list[index]?.current_protocol ?? "Novo protocolo";
        return name != "" ? name : "Novo protocolo";
      } else {
        return "Novo protocolo";
      }
    }
  }

  moneyDisplayHandle(value: any) {
    if (value) {
      let array = value.toString().split(".");
      if (array.length == 1) {
        return value + ",00";
      } else {
        if(array[1].length == 1) {
          return value.toString().replace(".", ",") + "0";
        } else {
          return value.toString().replace(".", ",");
        }
      }
    } else {
      return null;
    }
  }

  getHealthInsurance(ev: any) {
    this.formGroup.get('health_insurance_plan_id')?.reset();
    if (ev.health_insurances.length) {
      this.healthInsuranceList = ev.health_insurances;
    } else {
      this.healthInsuranceList = [];
    }
  }

  get proceduresForm() {
    return this.formGroup.get('ticket_procedure') as FormArray;
  }

  getProcedureFormGroup(index: number) {
    return this.proceduresForm.controls[index] as FormGroup;
  }

  addProcedure() {
    let procedureFormGroup = this.fb.group({
      id: [],
      procedure_id: ['', Validators.required],
      status_id: ['', Validators.required],
      refund_request_date: [null],
      invoice_value: [0],
      invoice_number: [],
      refunded_amount: [0],
      refund_date: [null],
      receipt_date: [null],
      co_participation_value: [0],
      transfer_value: [0],
      attachment: this.fb.array([]),
      protocol: this.fb.array([]),
      medical_orders: this.fb.array([]),
      medical_order_ids: this.fb.array([]),
      medical_reports: this.fb.array([]),
      medical_report_ids: this.fb.array([])
    });
    this.proceduresForm.push(procedureFormGroup);
  }

  removeProcedure(at: number) {
    this.proceduresForm.removeAt(at);
  }

  createMedicalOrder(index: number) {
    let formArray = this.getProcedureFormGroup(index).get('medical_order_ids') as FormArray;
    formArray.push(this.fb.control([]));
  }

  createMedicalReport(index: number) {
    let formArray = this.getProcedureFormGroup(index).get('medical_report_ids') as FormArray;
    formArray.push(this.fb.control([]));
  }

  getLogFormGroup() {
    return this.formGroup.get('log') as FormGroup;
  }

  createAttachmentForm() {
    return this.fb.group({
      model_name: [''],
      filename: [''],
      selected_attachment_id: [''],
    });
  }

  get logAttachmentForm() {
    return this.getLogFormGroup().get('attachment') as FormArray;
  }

  createLogAttachment() {
    return this.fb.group({
      temporary_file_id: [''],
      filename: [''],
    });
  }

  get ticketAttachmentForm() {
    return this.formGroup.get('attachment') as FormArray;
  }

  createTicketAttachment() {
    return this.fb.group({
      temporary_file_id: [''],
      filename: [''],
    });
  }

  createProtocol() {
    return this.fb.group({
      id: [''],
      date_time: [''],
      protocol: [''],
      is_active: ['']
    });
  }

  cancelHandler() {
    this.router.navigate(['/dashboard/financial'], { queryParams: this.queryParams });
  }

  submitHandler() {
    this.submitting = true;
    this.formGroup.markAllAsTouched();
    let ticketData: TicketPostModel = this.formGroup.getRawValue();
    if (ticketData.date_time)
      ticketData.date_time = moment(ticketData.date_time).format();
    ticketData.ticket_procedure.forEach((procedure) => {
      if (procedure.refund_date) {
        procedure.refund_date = moment(procedure.refund_date).format();
      } else {
        procedure.refund_date = undefined;
      }
      if (procedure.refund_request_date) {
        procedure.refund_request_date = moment(procedure.refund_request_date).format();
      } else {
        procedure.refund_request_date = undefined;
      }
      if (procedure.receipt_date) {
        procedure.receipt_date = moment(procedure.receipt_date).format();
      } else {
        procedure.receipt_date = undefined;
      }
      if (procedure.attachment) {
        procedure.attachment.forEach(attachment => {
          attachment.filename = undefined;
        });
      }
    });
    if (ticketData.attachment) {
      ticketData.attachment.forEach(attachment => {
        attachment.filename = undefined;
      });
    }
    if (ticketData.log.attachment) {
      ticketData.log.attachment.forEach(attachment => {
        attachment.filename = undefined;
      })
    }
    if (this.isRegister) {
      this.ticketRegister(ticketData);
    } else this.ticketEdit(ticketData);
  }

  ticketRegister(ticketData: TicketPostModel) {
    this.registerService.financialTicketRegister(ticketData).subscribe(response => {
      this.toast.success('Ticket criado com sucesso', 'Sucesso');
      this.cancelHandler();
    }, (error: HttpErrorResponse) => {
      this.submitting = false;
      this.mapErrorResponse(error);
    });
  }

  ticketEdit(ticketData: TicketPostModel) {
    ticketData.id = this.id;
    this.registerService.financialTicketEdit(ticketData).subscribe(response => {
      this.toast.success('Ticket alterado com sucesso', 'Sucesso');
      this.cancelHandler();
    }, (error: HttpErrorResponse) => {
      this.submitting = false;
      this.mapErrorResponse(error);
    });
  }

  openMailingModal() {
    const modalRef = this.modalService.open(MailingModalComponent, { centered: true, size: 'lg', animation: true });
    modalRef.componentInstance.ticket = this.fetchedTicket;
    modalRef.result.then(result => {});
  }

  openProtocolModal(addNewProtocol: boolean, index: number) {
    let procedureProtocolForm = this.getProcedureFormGroup(index).get('protocol') as FormArray;
    let id = this.getProcedureFormGroup(index).get('procedure_id')?.value;
    let name = this.proceduresList.find(value => value.id == id)?.name;
    let items: any = '';
    if (procedureProtocolForm.value != '') {
      items = procedureProtocolForm.value;
    } else {
      if (this.fetchedTicket) {
        if (this.fetchedTicket.ticket_procedure_list) {
          let procedures = this.fetchedTicket.ticket_procedure_list;
          if (procedures[index]?.protocol_list) {
            items = procedures[index].protocol_list;
          }
        }
      }
    }
    const modalRef = this.modalService.open(ProtocolModelComponent, { centered: true, size: 'lg', animation: true });
    modalRef.componentInstance.modalItems = { name: name, items: items };
    modalRef.componentInstance.addNewProtocol = addNewProtocol;
    modalRef.result.then(result => {
      if (result.protocol) {
        procedureProtocolForm.clear();
        result.protocol.forEach((protocol: any) => {
          let protocolForm = this.createProtocol();
          protocolForm.patchValue(protocol);
          procedureProtocolForm.push(protocolForm);
        });
      }
    });
  }

  openAttachmentModal(addNewAttachment: boolean, attachmentOwner: string, index: number = 0) {
    let name: string = this.patientList.find((patient) => patient.id == this.formGroup.get('patient_id')?.value)?.name ?? '';
    let patientId: number = this.formGroup.get('patient_id')?.value;
    let items: any = '';
    let type: string = '';
    let id: any = index ?? '';
    let attachments: any = '';
    let medical_orders: any[] = [];
    let medical_reports: any[] = [];
    if (this.fetchedTicket.ticket_procedure_list && index < this.fetchedTicket.ticket_procedure_list!.length) {
      medical_orders = this.fetchedTicket.ticket_procedure_list![index].medical_orders!;
      medical_reports = this.fetchedTicket.ticket_procedure_list![index].medical_reports!;
    }
    let procedureAttachmentForm = this.getProcedureFormGroup(index).get('attachment') as FormArray;

    if (attachmentOwner == 'procedure') {
      type = "Procedimentos";
      if (this.fetchedTicket) {
        if (this.fetchedTicket.ticket_procedure_list) {
          let procedures = this.fetchedTicket.ticket_procedure_list;
          if (procedures[index]?.attachment_list) {
            items = procedures[index].attachment_list;
          }
        }
      }
      if (procedureAttachmentForm.length > 0) {
        attachments = procedureAttachmentForm.value;
      }
    } else if (attachmentOwner == 'log') {
      type = "Histórico";
      if (this.fetchedTicket) {
        if (this.fetchedTicket.log_list) {
          let logs = this.fetchedTicket.log_list;
          items = logs[index].attachment_list;
        }
      }
      if (this.logAttachmentForm.length > 0) {
        attachments = this.logAttachmentForm.value;
      }
    } else {
      type = "Ticket";
      if (this.fetchedTicket) {
        if (this.fetchedTicket.attachment_list) {
          items = this.fetchedTicket.attachment_list;
        }
      }
      if (this.ticketAttachmentForm.length > 0) {
        attachments = this.ticketAttachmentForm.value;
      }
    }

    const modalRef = this.modalService.open(AttachmentModalComponent, { centered: true, size: 'lg', animation: true });
    modalRef.componentInstance.modalItems = { id, name, patientId, items, attachments, medical_orders, medical_reports };
    modalRef.componentInstance.type = type;
    modalRef.componentInstance.addNewAttachment = addNewAttachment;
    modalRef.result.then(result => {
      let procedureFormGroup = this.getProcedureFormGroup(index);

      let medicalOrderIdsFormArray = procedureFormGroup!.get('medical_order_ids') as FormArray;
      medicalOrderIdsFormArray.clear();
      result.medicalOrderIds.forEach((item:any) => medicalOrderIdsFormArray.push(this.fb.control([])));

      let medicalReportIds = procedureFormGroup!.get('medical_report_ids') as FormArray;
      medicalReportIds.clear();
      result.medicalReportIds.forEach((item:any) => medicalReportIds.push(this.fb.control([])));

      procedureFormGroup.patchValue({'medical_order_ids': result.medicalOrderIds,
        'medical_report_ids': result.medicalReportIds});

      if (result.attachmentsController) {
        let responseAttachments: AttachmentPostModel[] = result.attachmentsController;
        if (responseAttachments.length > 0) {
          if (attachmentOwner == 'procedure') {
            procedureAttachmentForm.clear();
            responseAttachments.forEach(attachment => {
              let attachmentForm = this.createAttachmentForm();
              attachmentForm.patchValue(attachment);
              procedureAttachmentForm.push(attachmentForm);
            });
          } else if (attachmentOwner == 'log') {
            this.logAttachmentForm.clear();
            responseAttachments.forEach(attachment => {
              let attachmentForm = this.createLogAttachment();
              attachmentForm.patchValue(attachment);
              this.logAttachmentForm.push(attachmentForm);
            });
          } else {
            this.ticketAttachmentForm.clear();
            responseAttachments.forEach(attachment => {
              let attachmentForm = this.createTicketAttachment();
              attachmentForm.patchValue(attachment);
              this.ticketAttachmentForm.push(attachmentForm);
            });
          }
        }
      }
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    } else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "date_time", "user_id", "patient_id", "health_insurance_plan_id",
      "attachment", "ticket_procedure", "log"
    ];
    let logErrNames = [
      "protocol", "notes"
    ];
    let procedureErrNames = [
      "procedure_id", "status_id", "refund_request_date", "invoice_value",
      "refunded_amount", "refund_date", "co_participation_value", "transfer_value", "protocol"
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
    });
    if (errorResponse.error.log) {
      logErrNames.forEach(name => {
        if (errorResponse.error[name])
          this.getLogFormGroup().get(name)?.setErrors({ response: errorResponse.error[name] });
      });
    }
    if (errorResponse.error.ticket_procedure) {
      let procedures: [] = errorResponse.error.ticket_procedure;
      Object.keys(procedures).map((index) => {
        let formProcedure = this.proceduresForm.controls[Number(index)] as FormGroup;
        let errorResponseProcedure = procedures[Number(index)];
        procedureErrNames.forEach(name => {
          if (errorResponseProcedure[name])
            formProcedure.get(name)?.setErrors({ response: errorResponseProcedure[name] });
        });
      })
    }
  }

  canDeactivate() {
    return true;
  }
}
