import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { OximeterService } from 'src/app/core/services/oximeter.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ListTemplate } from 'src/app/shared/models/list-template';
@Component({
  selector: 'app-oximeters-list',
  templateUrl: './oximeters-list.component.html',
  styleUrls: ['./oximeters-list.component.scss']
})
export class OximetersListComponent implements OnInit {

  searchString: string = "";
  orderingItem: string = "";
  statusString: string = "";

  listTemplate: ListTemplate = {
    loading: true,
    showSearch: true,
    searchBarPlaceholder: "Número de série",
    showAdvancedSearch: false,
    searchBarStatus: true,
    newDataText: "+ Novo Oxímetro",
    newDataRouterLink: (this.sessionManager.getUserPermission(PermissionConst.add_oximeter) && this.sessionManager.getUserPermission(PermissionConst.change_oximeter) && this.sessionManager.getUserPermission(PermissionConst.view_oximeter)) ? "register" : undefined,
    notFoundMessage: "Nenhum paciente encontrado",
    header: [
      { name: "serial_number", label: "Número de série", widthInPercentage: "15%", width: "125px" },
      { name: "model", label: "Modelo", widthInPercentage: "15%", width: "120px" },
      { name: "status_oximeter", label: "Status", widthInPercentage: "10%", width: "140px" },
      { name: "current_patient__patient__name", label: "Paciente", widthInPercentage: "20%", width: "220px" },
      { name: "current_patient__sent_at", label: "Enviado em", widthInPercentage: "20%", width: "120px" },
      { name: "current_patient__attendance__date_time", label: "Devolução", widthInPercentage: "20%", width: "120px" }
    ],
    onHeaderItemClick: (value: string) => {
      let invertString = '';
      if(value == 'status_oximeter') {
        value = 'status';
      }
      if (this.orderingItem == value) {
        this.listTemplate.invertedOrdering = !this.listTemplate.invertedOrdering;
        if (this.listTemplate.invertedOrdering)
          invertString = '-';
      }
      this.fetchList(this.searchString, `${invertString}${value}`, this.statusString,  this.listTemplate.is_active);
      this.orderingItem = value;
      this.listTemplate.selectedOrdering = value;
    },
    onSearchFocusOut: (value: string) => {
      this.searchString = value;
      this.fetchList(value, this.orderingItem, this.statusString,  this.listTemplate.is_active);
    },
    onScheduleFocusOut: (value: string) => {
      this.statusString = value;
      this.fetchList(this.searchString, this.orderingItem, this.statusString, this.listTemplate.is_active);
    },
    selectedOrdering: this.orderingItem,
    invertedOrdering: false,
  };

  constructor(private oximeterService: OximeterService, private sessionManager: SessionManagerService,
    private titleService: Title) {
    this.titleService.setTitle('ELEVE - Oxímetros');
  }

  ngOnInit(): void {
    this.initializeList();
  }

  initializeList() {
    this.fetchList(this.searchString, this.orderingItem, this.statusString, this.listTemplate.is_active);
  }

  fetchList(searchString: string, orderingItem: string, status: string, isActive: boolean | undefined) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;
    this.oximeterService.oximetersList(searchString, orderingItem, status, isActive).subscribe(response => {
      this.listTemplate.loading = false;
      this.listTemplate.data = response;
      this.listTemplate.hasNext = response.next != null;
    }, (error: HttpErrorResponse) => {
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = error.error['detail'];
    });
  }

  loadMore(page: number) {
    this.listTemplate.loadingNext = true;
    this.oximeterService.oximetersList(this.searchString, this.orderingItem, this.statusString,  this.listTemplate.is_active, page).subscribe(
      response => {
        this.listTemplate.hasNext = response.next != null;
        response.results!.map(item => this.listTemplate.data!.results!.push(item));
        this.listTemplate.loadingNext = false;
      },
      error => {
        this.listTemplate.loadingNext = false;
      }
    );
  }
}
