import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ListTemplate } from 'src/app/shared/models/list-template';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-patient-tags-list',
  templateUrl: './patient-tags-list.component.html',
  styleUrls: ['./patient-tags-list.component.scss']
})
export class PatientTagsListComponent implements OnInit {

  searchString: string = "";
  orderingItem: string = "name";

  get canNewData() {
    const permissions = [PermissionConst.add_tag, PermissionConst.change_tag, PermissionConst.view_tag];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  get canExport() {
    const permissions = [PermissionConst.export_tag, PermissionConst.change_tag, PermissionConst.view_tag];
    return permissions.every((p) => this.sessionManager.getUserPermission(p));
  }

  listTemplate: ListTemplate = {
    loading: true,
    showSearch: true,
    searchBarPlaceholder: "Buscar por Tag",
    showAdvancedSearch: false,
    newDataText: "+ Nova Tag",
    newDataRouterLink: this.canNewData ? "register" : undefined,
    notFoundMessage: "Nenhuma Tag encontrada",
    header: [{ name: "name", label: "Tag", widthInPercentage: "100%", width: "600px" }],
    onHeaderItemClick: (value: string) => {
      let invertString = '';
      if (this.orderingItem == value) {
        this.listTemplate.invertedOrdering = !this.listTemplate.invertedOrdering;
        if (this.listTemplate.invertedOrdering)
          invertString = '-';
      }
      this.fetchList(this.searchString, `${invertString}${value}`, this.listTemplate.is_active);
      this.orderingItem = value;
      this.listTemplate.selectedOrdering = value;
    },
    onSearchFocusOut: (value: string) => {
      this.searchString = value;
      this.fetchList(value, this.orderingItem, this.listTemplate.is_active);
    },
    onClickActive: () => {
      this.listTemplate.is_active = !this.listTemplate.is_active;
      this.fetchList(this.searchString, this.orderingItem, this.listTemplate.is_active);
    },
    selectedOrdering: this.orderingItem,
    invertedOrdering: false,
    exportUrl: this.canExport ? '/tags/patient/export' : undefined,
    exportOptions: {
      id: 'ID',
      name: 'Nome'
    }
  };

  constructor(private registersService: RegistersService, private sessionManager: SessionManagerService,
    private titleService: Title) {
    this.titleService.setTitle('ELEVE - Tags de pacientes');
  }

  ngOnInit(): void {
    this.initializeList();
  }

  initializeList() {
    this.fetchList("", "name", this.listTemplate.is_active);
  }

  fetchList(searchString: string, orderingItem: string, isActive: boolean | undefined) {
    this.listTemplate.loading = true;
    this.listTemplate.data = undefined;

    this.registersService.tagsList("PATIENT", searchString, orderingItem, isActive).subscribe(response => {
      this.listTemplate.loading = false;
      this.listTemplate.data = response;
      this.listTemplate.hasNext = response.next != null;
    }, error => {
      const response = error as HttpErrorResponse;
      this.listTemplate.loading = false;
      this.listTemplate.error = true;
      this.listTemplate.errorMessage = response.error['detail'];
    });
  }

  loadMore(page: number) {
    this.listTemplate.loadingNext = true;
    this.registersService.tagsList("PATIENT", this.searchString, this.orderingItem, this.listTemplate.is_active, page).subscribe(
      response => {
        this.listTemplate.hasNext = response.next != null;
        response.results!.map(item => this.listTemplate.data!.results!.push(item));
        this.listTemplate.loadingNext = false;
      },
      error => {
        const response = error as HttpErrorResponse;
        this.listTemplate.loading = false;
        this.listTemplate.error = true;
        this.listTemplate.errorMessage = response.error['detail'];
      });
  }
}
