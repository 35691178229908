import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { DocumentModel } from 'src/app/core/models/document.model';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { ProfessionalsService } from 'src/app/core/services/professionals.service';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { OptionsSelectModel } from 'src/app/shared/models/option';
import {
  ConfirmationModalComponent
} from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';
import { DefaultModalComponent } from 'src/app/shared/renderers/components/default-modal/default-modal.component';
import { ProfessionalsModule } from '../../professionals/professionals.module';

@Component({
  selector: 'app-documents-form',
  templateUrl: './documents-form.component.html',
  styleUrls: ['./documents-form.component.scss']
})
export class DocumentsFormComponent implements OnInit {
  formGroup = this.fb.group({
    id: [],
    name: [],
    drive_id: [],
    professional: [],
    type: []
  });

  typeOptions = [
    { value: 'RECEPTION', label: 'Recepção' },
    { value: 'REFUND', label: 'Reembolso' },
    { value: 'MEDICAL', label: 'Pedido médico' },
    { value: 'OTHERS', label: 'Outros' }
  ];

  id!: number;
  loadingPage: boolean = false;
  document: DocumentModel = {};
  isMedical: boolean = false;

  professionalList: OptionsSelectModel[] = [];

  isRegister: boolean = true;

  submitting: boolean = false;
  submittingRemove: boolean = false;
  removeButton: String = "Desativar";
  submitButton: String = "Salvar";

  button: boolean = false;
  registerSuccess: boolean = false;

  constructor(private registersService: RegistersService, private professionalService: ProfessionalsService, private router: Router, private fb: FormBuilder,
    private activatedRoute: ActivatedRoute, config: NgbModalConfig, private modalService: NgbModal,
    private sessionManager: SessionManagerService, private toast: ToastrService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      if (this.id) {
        this.isRegister = false;
        this.fetchDocument();
      }
    });
    this.fetchProfessional();
  }

  get canSave() {
    if (this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_document);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_document);
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_document);
  }

  cancelHandler() {
    this.router.navigate(['/dashboard/registers/documents'])
  }

  submitHandler() {
    this.submitting = true;
    this.formGroup.markAllAsTouched();
    let documentData = this.formGroup.getRawValue() as DocumentModel;
    if (this.isRegister) {
      if (this.formGroup.valid) {
        this.documentRegister(documentData);
      } else this.submitting = false;

    } else {
      this.documentEdit(documentData);
    }
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar este documento?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.documentRemove();
      }
      this.submittingRemove = false;
    })
  }

  documentRegister(documentData: DocumentModel) {
    this.registersService.documentRegister(documentData).subscribe(response => {
      this.registerSuccess = true;
      this.toast.success("Doumento criado com sucesso", "Sucesso");
      this.router.navigate(['/dashboard/registers/documents']);
    }, error => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    });
  }

  documentEdit(documentData: DocumentModel) {
    documentData.is_active = true;
    this.registersService.documentEdit(documentData).subscribe(document => {
      this.registerSuccess = true;
      this.toast.success("Doumento alterado com sucesso", "Sucesso");
      this.router.navigate(['/dashboard/registers/documents']);
    }, error => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    });
  }

  documentRemove() {
    this.registersService.documentRemove(this.id).subscribe(response => {
      this.submittingRemove = false;
      this.toast.success("Doumento removido com sucesso", "Sucesso");
      this.router.navigate(['/dashboard/registers/documents']);
    }, (error) => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submittingRemove = false;
    });
  }

  getType() {
    let type = this.formGroup.get('type')?.value;
    if (type == 'MEDICAL') {
      this.formGroup.get('professional')?.addValidators([Validators.required])
      this.isMedical = true;
    } else {
      this.formGroup.get('professional')?.clearValidators();
      this.formGroup.get('professional')?.reset();
      this.isMedical = false;
    }
  }

  fetchProfessional() {
    this.professionalService.listAll().subscribe(response => {
      this.professionalList = response.map(item => {
        return {
          label: item.name,
          value: item.id?.toString()
        } as OptionsSelectModel;
      });
    });
  }

  fetchDocument() {
    this.loadingPage = true;
    this.registersService.documentGet(this.id).subscribe(document => {
      this.document = document;
      if (this.document.type == 'MEDICAL') {
        this.formGroup.get('professional')?.addValidators([Validators.required])
        this.isMedical = true;
      } else {
        this.formGroup.get('professional')?.clearValidators();
      }
      this.formGroup.patchValue(this.document);
      this.loadingPage = false;
      if (this.document.is_active) {
        this.button = true;
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
      } else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
      }
    }, error => {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro", {
        closeButton: true,
      });
    } else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "name", "type", "drive_id", "professional"
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
    });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if ((this.formGroup.dirty || this.submitting) && !this.registerSuccess && this.isRegister) {
      const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
      modalRef.componentInstance.text = "As alterações no formulário não foram salvas e serão descartadas, deseja prosseguir?";
      return modalRef.result
    } else
      return true;
  };
}
