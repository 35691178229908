import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchedulesComponent } from './schedules.component';
import { NavigationModule } from '../navigation/navigation.module';
import { DpDatePickerModule } from 'ng2-date-picker'
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CalendarModule } from 'angular-calendar';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { EventCardComponent } from "./event-card/event-card.component";
import { NgSelectModule } from '@ng-select/ng-select';
import { SchedulingFormComponent } from './scheduling-form/scheduling-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxMaskModule } from 'ngx-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContextMenuModule } from 'ngx-contextmenu';
import { OverlayModule } from '@angular/cdk/overlay';

@NgModule({
  declarations: [
    SchedulesComponent,
    EventCardComponent,
    SchedulingFormComponent
  ],
    imports: [
        CommonModule,
        DpDatePickerModule,
        ReactiveFormsModule,
        NavigationModule,
        CalendarModule,
        RenderersModule,
        NgSelectModule,
        SharedModule,
        NgxMaskModule,
        NgbModule,
        ContextMenuModule.forRoot({
            useBootstrap4: true,
        }),
        OverlayModule,
        FormsModule
    ]
})
export class SchedulesModule {
}
