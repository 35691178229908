import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ScheduleService } from 'src/app/core/services/schedules.service';

@Component({
  selector: 'app-oximeter',
  templateUrl: './oximeter.component.html',
  styleUrls: ['./oximeter.component.scss']
})
export class OximeterComponent implements OnInit {

  titleMap: string[][] = [
    ["exams", "Exames"],
    ["oximeters", "Oxímetros"]
  ];

  headerTitle: string = "Oximetro";
  headerSubTitle: string = "";
  showMenuSidebar: boolean = false;

  constructor(private router: Router) {
    if(!this.router.url.split('/')[3]) this.headerSubTitle = this.titleMap[0][1];
    else this.headerSubTitle = (this.titleMap.filter((route) => route[0] == router.url.split("/")[3])[0][1]);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.split("/")[2] == 'oximeter') {
          this.headerTitle = "Oxímetro";
          if (this.router.url.split("/")[3] != undefined) {
            this.headerSubTitle = (this.titleMap.filter((route) => route[0] == this.router.url.split("/")[3])[0][1]);
          } else {
            this.headerSubTitle = this.titleMap[0][1];
          }
        }
        if (router.url.split("/")[4] == "register") {
          this.headerSubTitle = "Novo Oxímetro";
        }
      }
    })
  }

  menuButtonHandler() {
    this.showMenuSidebar = !this.showMenuSidebar;
  }

  ngOnInit(): void {

  }

}
