import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { RegistersService } from "../../../core/services/registers.service";
import { SeiosDaFaceComponent } from '../report-forms/seios-da-face/seios-da-face.component';
import * as moment from 'moment';

@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss']
})
export class ReportModalComponent implements OnInit {
  TOMOGRAFIA_COMPUTADORIZADA_DAS_ATMS_EXAM_TEMPLATE_ID = environment.TOMOGRAFIA_COMPUTADORIZADA_DAS_ATMS_EXAM_TEMPLATE_ID;
  TOMOGRAFIA_COMPUTADORIZADA_DE_FARINGE_EXAM_TEMPLATE_ID = environment.TOMOGRAFIA_COMPUTADORIZADA_DE_FARINGE_EXAM_TEMPLATE_ID;
  TOMOGRAFIA_COMPUTADORIZADA_DE_MANDIBULA_EXAM_TEMPLATE_ID = environment.TOMOGRAFIA_COMPUTADORIZADA_DE_MANDIBULA_EXAM_TEMPLATE_ID;
  TOMOGRAFIA_COMPUTADORIZADA_DE_MAXILA_EXAM_TEMPLATE_ID = environment.TOMOGRAFIA_COMPUTADORIZADA_DE_MAXILA_EXAM_TEMPLATE_ID;
  TOMOGRAFIA_COMPUTADORIZADA_DOS_SEIOS_DA_FACE_EXAM_TEMPLATE_ID = environment.TOMOGRAFIA_COMPUTADORIZADA_DOS_SEIOS_DA_FACE_EXAM_TEMPLATE_ID;

  @Input() exam!: any;
  @Input() date!: string;

  @ViewChild(SeiosDaFaceComponent) tomografiaSeiosDaFace!: SeiosDaFaceComponent;

  headerExam: string = '';
  laudo: string = '';
  reportTitles: string[] = [];
  reportTextArea: string[] = [''];
  formSeiosDaFace: any;

  localizacao_do_estreitamento_sagital = [
    { id: 'nasofaringe', label: 'Nasofaringe', checked: false },
    { id: 'orofaringe', label: 'Orofaringe', checked: false },
    { id: 'laringofaringe', label: 'Laringofaringe', checked: false },
  ];
  localizacao_do_estreitamento_coronal = [
    { id: 'nasofaringe', label: 'Nasofaringe', checked: false },
    { id: 'orofaringe', label: 'Orofaringe', checked: false },
    { id: 'laringofaringe', label: 'Laringofaringe', checked: false },
  ];

  remodelacaoPresente: boolean = false;
  erosaoPresente: boolean = false;
  cisticasPresente: boolean = false;

  remodelacaoPresenteEsquerda: boolean = false;
  erosaoPresenteEsquerda: boolean = false;
  cisticasPresenteEsquerda: boolean = false;

  sagitalPresente: boolean = false;
  coronalPresente: boolean = false;

  formDate: FormGroup = this.fb.group({
    date: ['']
  });

  tomografiaDasAtmsForm: FormGroup = this.fb.group({
    atm_direita_espaco_articular: ['Preservado', [Validators.required]],
    atm_direita_remodelacao: ['Ausente', [Validators.required]],
    atm_direita_grau_da_remodelacao: [''],
    atm_direita_localizacao_da_remodelacao: [''],
    atm_direita_formacoes_cisticas: ['Ausente', [Validators.required]],
    atm_direita_localizacao_da_formacao_cistica: [''],
    atm_direita_fossa_mandibular: ['Preservado', [Validators.required]],
    atm_direita_erosoes: ['Ausente', [Validators.required]],
    atm_direita_grau_da_erosao: [''],
    atm_direita_localizacao_da_erosao: [''],
    atm_direita_additional: [''],
    atm_esquerda_espaco_articular: ['Preservado', [Validators.required]],
    atm_esquerda_remodelacao: ['Ausente', [Validators.required]],
    atm_esquerda_grau_da_remodelacao: [''],
    atm_esquerda_localizacao_da_remodelacao: [''],
    atm_esquerda_formacoes_cisticas: ['Ausente', [Validators.required]],
    atm_esquerda_localizacao_da_formacao_cistica: [''],
    atm_esquerda_fossa_mandibular: ['Preservado', [Validators.required]],
    atm_esquerda_erosoes: ['Ausente', [Validators.required]],
    atm_esquerda_grau_da_erosao: [''],
    atm_esquerda_localizacao_da_erosao: [''],
    atm_esquerda_additional: [''],
    date: ['']
  });

  tomografiaFaringeForm: FormGroup = this.fb.group({
    grau_do_estreitamento_sagital: ['Ausente', [Validators.required]],
    localizacao_do_estreitamento_sagital: [''],
    espaco_aereo_entre_palato_e_lingua: ['Ausente', [Validators.required]],
    grau_do_estreitamento_coronal: ['Ausente', [Validators.required]],
    localizacao_do_estreitamento_coronal: [''],
    estreitamento_em_regiao_amigdaliana: ['Ausente', [Validators.required]],
    volumetria_da_coluna_aerea: ['', [Validators.required]],
    menor_seccao_axial: ['', [Validators.required]],
    localizacao_da_menor_seccao_axial: ['Nasofaringe em região retropalatal', [Validators.required]],
    observacoes_adicionais_da_avaliacao_sagital: [''],
    observacoes_adicionais_da_avaliacao_coronal: [''],
    date: ['']
  });

  tomografiaMandibulaForm: FormGroup = this.fb.group({
    dentes_presentes: [''],
    tratamento_endodontico: [''],
    assimetria_processo_alveolar: ['Ausente', [Validators.required]],
    desproporcao_processo_alveolar: ['Ausente', [Validators.required]],
    assimetria_corpo_mandibular: ['Ausente', [Validators.required]],
    distancia_transversal_anterior: ['', [Validators.required]],
    nivelamento_coronal_do_plano_oclusal: ['Nivelado', [Validators.required]],
    implantes_osseointegraveis: [''],
    insercao_ossea_dentaria: [''],
    apinhamento_dentario: ['Ausente', [Validators.required]],
    insercao_ossea_implantes: [''],
    distancia_transversal_posterior: ['', [Validators.required]],
    lesoes_periapicais: [''],
    terceiros_molares_inclusos: [''],
    posicao_38: [''],
    posicao_48: [''],
    material_restaurador: ['Ausente', [Validators.required]],
    observacoes_adicionais: [''],
    date: ['']
  });

  tomografiaMaxilarForm: FormGroup = this.fb.group({
    dentes_presentes: [''],
    tratamento_endodontico: [''],
    assimetria_processo_alveolar: ['Ausente', [Validators.required]],
    desproporcao_processo_alveolar: ['Ausente', [Validators.required]],
    distancia_transversal_anterior: ['', [Validators.required]],
    nivelamento_coronal_do_plano_oclusal: ['Nivelado', [Validators.required]],
    implantes_osseointegraveis: [''],
    insercao_ossea_dentaria: [''],
    apinhamento_dentario: ['Ausente', [Validators.required]],
    insercao_ossea_implantes: [''],
    distancia_transversal_posterior: ['', [Validators.required]],
    lesoes_periapicais: [''],
    terceiros_molares_inclusos: [''],
    posicao_18: [''],
    posicao_28: [''],
    material_restaurador: ['Ausente', [Validators.required]],
    observacoes_adicionais: [''],
    date: ['']
  });

  dentesMandibula = [
    { id: 48, checked: true }, { id: 47, checked: true },
    { id: 46, checked: true }, { id: 45, checked: true },
    { id: 44, checked: true }, { id: 43, checked: true },
    { id: 42, checked: true }, { id: 41, checked: true },
    { id: 31, checked: true }, { id: 32, checked: true },
    { id: 33, checked: true }, { id: 34, checked: true },
    { id: 35, checked: true }, { id: 36, checked: true },
    { id: 37, checked: true }, { id: 38, checked: true }
  ];

  dentesMaxilar = [
    { id: 18, checked: true }, { id: 17, checked: true },
    { id: 16, checked: true }, { id: 15, checked: true },
    { id: 14, checked: true }, { id: 13, checked: true },
    { id: 12, checked: true }, { id: 11, checked: true },
    { id: 21, checked: true }, { id: 22, checked: true },
    { id: 23, checked: true }, { id: 24, checked: true },
    { id: 25, checked: true }, { id: 26, checked: true },
    { id: 27, checked: true }, { id: 28, checked: true }
  ];

  getMandibulaList() {
    return this.dentesMandibula.map((item) => {
      return {
        id: item.id,
        checked: false,
        flag: item.checked
      }
    });
  }

  getMaxilarList() {
    return this.dentesMaxilar.map((item) => {
      return {
        id: item.id,
        checked: false,
        flag: item.checked
      }
    });
  }

  tratamentoEndodonticoMandibula = this.getMandibulaList();
  tratamentoEndodonticoMaxilar = this.getMaxilarList();

  implantesMandibula = this.getMandibulaList();
  implantesMaxilar = this.getMaxilarList();

  lesoesMandibula = this.getMandibulaList();
  lesoesMaxilar = this.getMaxilarList();

  insercaoDentariaMandibula = this.getMandibulaList();
  insercaoDentariaMaxilar = this.getMaxilarList();

  insercaoImplantesMandibula = this.getMandibulaList();
  insercaoImplantesMaxilar = this.getMaxilarList();

  insercaoMandibula = this.getMandibulaList();
  insercaoMaxilar = this.getMaxilarList();

  molaresMandibula = [
    { id: 48, flag: true, checked: false }, { id: 38, flag: true, checked: false }
  ];
  molaresMaxilar = [
    { id: 18, flag: true, checked: false }, { id: 28, flag: true, checked: false }
  ];

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private toast: ToastrService,
    private registerService: RegistersService) {
  }

  ngOnInit(): void {
    this.formDate.get('date')?.setValue(moment(this.date).format('YYYY-MM-DD'));
    if (this.exam.success && this.exam.variables) {
      this.formDate.get('date')?.setValue(this.exam.variables.date);
    }
    if (this.exam.exam_id == this.TOMOGRAFIA_COMPUTADORIZADA_DE_FARINGE_EXAM_TEMPLATE_ID) {
      this.headerExam = 'Tomografia Computadorizada de Faringe';
      if (this.exam.success && this.exam.variables) {
        this.existVariablesFaringe();
      }
    } else if (this.exam.exam_id == this.TOMOGRAFIA_COMPUTADORIZADA_DE_MANDIBULA_EXAM_TEMPLATE_ID) {
      this.headerExam = 'Tomografia Computadorizada da Mandíbula';
      if (this.exam.success && this.exam.variables) {
        this.existVariablesMandibula();
      }
    } else if (this.exam.exam_id == this.TOMOGRAFIA_COMPUTADORIZADA_DE_MAXILA_EXAM_TEMPLATE_ID) {
      this.headerExam = 'Tomografia Computadorizada de Maxila';
      if (this.exam.success && this.exam.variables) {
        this.existVariablesMaxila();
      }
    } else if (this.exam.exam_id == this.TOMOGRAFIA_COMPUTADORIZADA_DAS_ATMS_EXAM_TEMPLATE_ID) {
      this.headerExam = 'Tomografia Computadorizada das ATMs';
      if (this.exam.success && this.exam.variables) {
        this.existVariablesATMs();
      }
    } else if (this.exam.exam_id == this.TOMOGRAFIA_COMPUTADORIZADA_DOS_SEIOS_DA_FACE_EXAM_TEMPLATE_ID) {
      this.headerExam = 'Tomografia Computadorizada dos Seios da Face';
    }
  }

  existVariablesFaringe() {
    let form = this.tomografiaFaringeForm;
    form.patchValue(this.exam.variables);
    if (form.get('localizacao_do_estreitamento_coronal')?.value) {
      this.coronalValidator(form.get('localizacao_do_estreitamento_coronal')?.value);
      let localizacao = this.buildArray(form.get('localizacao_do_estreitamento_coronal')?.value)
      localizacao.forEach((local: string) => this.toggleLocalizacaoCoronal(local));
    }
    if (form.get('localizacao_do_estreitamento_sagital')?.value) {
      this.sagitalValidator(form.get('localizacao_do_estreitamento_sagital')?.value);
      let localizacao = this.buildArray(form.get('localizacao_do_estreitamento_sagital')?.value)
      localizacao.forEach((local: string) => this.toggleLocalizacaoSagital(local));
    }
  }

  existVariablesATMs() {
    this.tomografiaDasAtmsForm.patchValue(this.exam.variables);
    this.remodelacaoValidator(this.tomografiaDasAtmsForm.get('atm_direita_remodelacao')?.value);
    this.erosaoValidator(this.tomografiaDasAtmsForm.get('atm_direita_erosoes')?.value);
    this.cisticasValidator(this.tomografiaDasAtmsForm.get('atm_direita_formacoes_cisticas')?.value);
    this.remodelacaoValidatorEsquerda(this.tomografiaDasAtmsForm.get('atm_esquerda_remodelacao')?.value);
    this.erosaoValidatorEsquerda(this.tomografiaDasAtmsForm.get('atm_esquerda_erosoes')?.value);
    this.cisticasValidatorEsquerda(this.tomografiaDasAtmsForm.get('atm_esquerda_formacoes_cisticas')?.value);
  }

  existVariablesMandibula() {
    let form = this.tomografiaMandibulaForm;
    form.patchValue(this.exam.variables);
    this.dentesMandibula.forEach(item => {
      this.toggleElementosDentarios(item);
      this.buildArray(form.get('dentes_presentes')?.value).find((value: number) => {
        if (item.id == value) {
          this.toggleElementosDentarios(item);
          return
        }
      });
    });
    this.tratamentoEndodonticoMandibula.forEach(item => {
      this.buildArray(form.get('tratamento_endodontico')?.value).find((value: number) => {
        if (item.id == value) {
          this.toggleTratamentoEndodontico(item);
          return
        }
      })
    });
    this.implantesMandibula.forEach(item => {
      this.buildArray(form.get('implantes_osseointegraveis')?.value).find((value: number) => {
        if (item.id == value) {
          this.toggleImplante(item);
          return
        }
      })
    });
    this.lesoesMandibula.forEach(item => {
      this.buildArray(form.get('lesoes_periapicais')?.value).find((value: number) => {
        if (item.id == value) {
          this.toggleLesoes(item);
          return
        }
      })
    });
    this.insercaoDentariaMandibula.forEach(item => {
      this.buildArray(form.get('insercao_ossea_dentaria')?.value).find((value: number) => {
        if (item.id == value) {
          this.toggleInsercaoDentariaMandibula(item);
          return
        }
      })
    });
    this.insercaoImplantesMandibula.forEach(item => {
      this.buildArray(form.get('insercao_ossea_implantes')?.value).find((value: number) => {
        if (item.id == value) {
          this.toggleInsercaoImplantesMandibula(item);
          return
        }
      })
    });
    this.molaresMandibula.forEach(item => {
      this.buildArray(form.get('terceiros_molares_inclusos')?.value).find((value: number) => {
        if (item.id == value) {
          this.toggleMolares(item);
          this.isCheckedMolares(value);
          form.get(`posicao_${value}`)?.setValue(this.exam.variables['posicao_' + value]);
        }
      })
    });
  }

  existVariablesMaxila() {
    let form = this.tomografiaMaxilarForm;
    form.patchValue(this.exam.variables);
    this.dentesMaxilar.forEach(item => {
      this.toggleElementosDentariosMaxilar(item);
      this.buildArray(form.get('dentes_presentes')?.value).find((value: number) => {
        if (item.id == value) {
          this.toggleElementosDentariosMaxilar(item);
          return
        }
      });
    });
    this.tratamentoEndodonticoMaxilar.forEach(item => {
      this.buildArray(form.get('tratamento_endodontico')?.value).find((value: number) => {
        if (item.id == value) {
          this.toggleTratamentoEndodonticoMaxilar(item);
          return
        }
      })
    });
    this.implantesMaxilar.forEach(item => {
      this.buildArray(form.get('implantes_osseointegraveis')?.value).find((value: number) => {
        if (item.id == value) {
          this.toggleImplanteMaxilar(item);
          return
        }
      })
    });
    this.lesoesMaxilar.forEach(item => {
      this.buildArray(form.get('lesoes_periapicais')?.value).find((value: number) => {
        if (item.id == value) {
          this.toggleLesoesMaxilar(item);
          return
        }
      })
    });
    this.insercaoDentariaMaxilar.forEach(item => {
      this.buildArray(form.get('insercao_ossea_dentaria')?.value).find((value: number) => {
        if (item.id == value) {
          this.toggleInsercaoDentariaMaxilar(item);
          return
        }
      })
    });
    this.insercaoImplantesMaxilar.forEach(item => {
      this.buildArray(form.get('insercao_ossea_implantes')?.value).find((value: number) => {
        if (item.id == value) {
          this.toggleInsercaoImplantesMaxilar(item);
          return
        }
      })
    });
    this.molaresMaxilar.forEach(item => {
      this.buildArray(form.get('terceiros_molares_inclusos')?.value).find((value: number) => {
        if (item.id == value) {
          this.toggleMolaresMaxilar(item);
          this.isCheckedMolaresMaxilar(value);
          form.get(`posicao_${value}`)?.setValue(this.exam.variables['posicao_' + value]);
        }
      })
    });
  }

  remodelacaoValidator(value: string) {
    if (value == 'presente' || value == 'Presente') {
      this.remodelacaoPresente = true;
      this.tomografiaDasAtmsForm.get('atm_direita_grau_da_remodelacao')?.addValidators([Validators.required]);
      this.tomografiaDasAtmsForm.get('atm_direita_localizacao_da_remodelacao')?.addValidators([Validators.required]);
    } else {
      this.remodelacaoPresente = false;
      this.tomografiaDasAtmsForm.get('atm_direita_grau_da_remodelacao')?.clearValidators();
      this.tomografiaDasAtmsForm.get('atm_direita_localizacao_da_remodelacao')?.clearValidators();
      this.tomografiaDasAtmsForm.get('atm_direita_grau_da_remodelacao')?.setValue("");
      this.tomografiaDasAtmsForm.get('atm_direita_localizacao_da_remodelacao')?.setValue("");
    }
  }

  erosaoValidator(value: string) {
    if (value == 'presente' || value == 'Presente') {
      this.erosaoPresente = true;
      this.tomografiaDasAtmsForm.get('atm_direita_grau_da_erosao')?.addValidators([Validators.required]);
      this.tomografiaDasAtmsForm.get('atm_direita_localizacao_da_erosao')?.addValidators([Validators.required]);
    } else {
      this.erosaoPresente = false;
      this.tomografiaDasAtmsForm.get('atm_direita_grau_da_erosao')?.clearValidators();
      this.tomografiaDasAtmsForm.get('atm_direita_localizacao_da_erosao')?.clearValidators();
      this.tomografiaDasAtmsForm.get('atm_direita_grau_da_erosao')?.setValue("");
      this.tomografiaDasAtmsForm.get('atm_direita_localizacao_da_erosao')?.setValue("");
    }
  }

  cisticasValidator(value: string) {
    if (value == 'presente' || value == 'Presente') {
      this.cisticasPresente = true;
      this.tomografiaDasAtmsForm.get('atm_direita_localizacao_da_formacao_cistica')?.addValidators([Validators.required]);
    } else {
      this.cisticasPresente = false;
      this.tomografiaDasAtmsForm.get('atm_direita_localizacao_da_formacao_cistica')?.clearValidators();
      this.tomografiaDasAtmsForm.get('atm_direita_localizacao_da_formacao_cistica')?.setValue("");
    }
  }

  remodelacaoValidatorEsquerda(value: string) {
    if (value == 'presente' || value == 'Presente') {
      this.remodelacaoPresenteEsquerda = true;
      this.tomografiaDasAtmsForm.get('atm_esquerda_grau_da_remodelacao')?.addValidators([Validators.required]);
      this.tomografiaDasAtmsForm.get('atm_esquerda_localizacao_da_remodelacao')?.addValidators([Validators.required]);
    } else {
      this.remodelacaoPresenteEsquerda = false;
      this.tomografiaDasAtmsForm.get('atm_esquerda_grau_da_remodelacao')?.clearValidators();
      this.tomografiaDasAtmsForm.get('atm_esquerda_localizacao_da_remodelacao')?.clearValidators();
      this.tomografiaDasAtmsForm.get('atm_esquerda_grau_da_remodelacao')?.setValue("");
      this.tomografiaDasAtmsForm.get('atm_esquerda_localizacao_da_remodelacao')?.setValue("");
    }
  }

  erosaoValidatorEsquerda(value: string) {
    if (value == 'presente' || value == 'Presente') {
      this.erosaoPresenteEsquerda = true;
      this.tomografiaDasAtmsForm.get('atm_esquerda_grau_da_erosao')?.addValidators([Validators.required]);
      this.tomografiaDasAtmsForm.get('atm_esquerda_localizacao_da_erosao')?.addValidators([Validators.required]);
    } else {
      this.erosaoPresenteEsquerda = false;
      this.tomografiaDasAtmsForm.get('atm_esquerda_grau_da_erosao')?.clearValidators();
      this.tomografiaDasAtmsForm.get('atm_esquerda_localizacao_da_erosao')?.clearValidators();
      this.tomografiaDasAtmsForm.get('atm_esquerda_grau_da_erosao')?.setValue("");
      this.tomografiaDasAtmsForm.get('atm_esquerda_localizacao_da_erosao')?.setValue("");
    }
  }

  cisticasValidatorEsquerda(value: string) {
    if (value == 'presente' || value == 'Presente') {
      this.cisticasPresenteEsquerda = true;
      this.tomografiaDasAtmsForm.get('atm_esquerda_localizacao_da_formacao_cistica')?.addValidators([Validators.required]);
    } else {
      this.cisticasPresenteEsquerda = false;
      this.tomografiaDasAtmsForm.get('atm_esquerda_localizacao_da_formacao_cistica')?.clearValidators();
      this.tomografiaDasAtmsForm.get('atm_esquerda_localizacao_da_formacao_cistica')?.setValue("");
    }
  }

  sagitalValidator(value: string) {
    if (value == 'ausente' || value == 'Ausente') {
      this.sagitalPresente = false;
      this.tomografiaFaringeForm.get('localizacao_do_estreitamento_sagital')?.clearValidators();
      this.tomografiaFaringeForm.get('localizacao_do_estreitamento_sagital')?.setValue("");
      this.localizacao_do_estreitamento_sagital.forEach(localizacao => localizacao.checked = false);
    } else {
      this.sagitalPresente = true;
      this.tomografiaFaringeForm.get('localizacao_do_estreitamento_sagital')?.addValidators([Validators.required]);
    }
  }

  coronalValidator(value: string) {
    if (value == 'ausente' || value == 'Ausente') {
      this.coronalPresente = false;
      this.tomografiaFaringeForm.get('localizacao_do_estreitamento_coronal')?.clearValidators();
      this.tomografiaFaringeForm.get('localizacao_do_estreitamento_coronal')?.setValue("");
      this.localizacao_do_estreitamento_coronal.forEach(localizacao => localizacao.checked = false);
    } else {
      this.coronalPresente = true;
      this.tomografiaFaringeForm.get('localizacao_do_estreitamento_coronal')?.addValidators([Validators.required]);
    }
  }

  toggleLocalizacaoSagital(value: string) {
    let index = this.localizacao_do_estreitamento_sagital.findIndex(item => item.id == value);
    if (this.localizacao_do_estreitamento_sagital[index].checked) {
      this.localizacao_do_estreitamento_sagital[index].checked = false;
    } else {
      this.localizacao_do_estreitamento_sagital[index].checked = true;
    }
    let localizacao = this.buildString(this.localizacao_do_estreitamento_sagital);
    this.tomografiaFaringeForm.get('localizacao_do_estreitamento_sagital')?.setValue(localizacao);
  }

  toggleLocalizacaoCoronal(value: string) {
    let index = this.localizacao_do_estreitamento_coronal.findIndex(item => item.id == value);
    if (this.localizacao_do_estreitamento_coronal[index].checked) {
      this.localizacao_do_estreitamento_coronal[index].checked = false;
    } else {
      this.localizacao_do_estreitamento_coronal[index].checked = true;
    }
    let localizacao = this.buildString(this.localizacao_do_estreitamento_coronal);
    this.tomografiaFaringeForm.get('localizacao_do_estreitamento_coronal')?.setValue(localizacao);
  }

  toggleElementosDentarios(value: any) {
    let index = this.dentesMandibula.findIndex(item => item.id == value.id);
    let indexModalares = this.molaresMandibula.findIndex(item => item.id == value.id);
    if (value.checked) {
      this.dentesMandibula[index].checked = false;
      this.lesoesMandibula[index].flag = false;
      this.insercaoDentariaMandibula[index].flag = false;
      this.tratamentoEndodonticoMandibula[index].flag = false;
      this.lesoesMandibula[index].checked = false;
      this.insercaoDentariaMandibula[index].checked = false;
      this.tratamentoEndodonticoMandibula[index].checked = false;
      this.implantesMandibula[index].flag = true;
      this.insercaoImplantesMandibula[index].flag = true;
      this.implantesMandibula[index].checked = false;
      this.insercaoImplantesMandibula[index].checked = false;
      if (value.id == 38 || value.id == 48) {
        this.molaresMandibula[indexModalares].flag = false;
        this.molaresMandibula[indexModalares].checked = false;
        this.isCheckedMolares(this.molaresMandibula[indexModalares].id);
      }
    } else {
      this.dentesMandibula[index].checked = true;
      this.lesoesMandibula[index].flag = true;
      this.insercaoDentariaMandibula[index].flag = true;
      this.tratamentoEndodonticoMandibula[index].flag = true;
      this.implantesMandibula[index].flag = false;
      this.insercaoImplantesMandibula[index].flag = false;
      if (value.id == 38 || value.id == 48) {
        this.molaresMandibula[indexModalares].flag = true;
      }
    }
  }

  toggleElementosDentariosMaxilar(value: any) {
    let index = this.dentesMaxilar.findIndex(item => item.id == value.id);
    let indexModalares = this.molaresMaxilar.findIndex(item => item.id == value.id);
    if (value.checked) {
      this.dentesMaxilar[index].checked = false;
      this.lesoesMaxilar[index].flag = false;
      this.insercaoDentariaMaxilar[index].flag = false;
      this.tratamentoEndodonticoMaxilar[index].flag = false;
      this.lesoesMaxilar[index].checked = false;
      this.insercaoDentariaMaxilar[index].checked = false;
      this.tratamentoEndodonticoMaxilar[index].checked = false;
      this.implantesMaxilar[index].flag = true;
      this.insercaoImplantesMaxilar[index].flag = true;
      this.implantesMaxilar[index].checked = false;
      this.insercaoImplantesMaxilar[index].checked = false;
      if (value.id == 18 || value.id == 28) {
        this.molaresMaxilar[indexModalares].flag = false;
        this.molaresMaxilar[indexModalares].checked = false;
        this.isCheckedMolaresMaxilar(this.molaresMaxilar[indexModalares].id);
      }
    } else {
      this.dentesMaxilar[index].checked = true;
      this.lesoesMaxilar[index].flag = true;
      this.insercaoDentariaMaxilar[index].flag = true;
      this.tratamentoEndodonticoMaxilar[index].flag = true;
      this.implantesMaxilar[index].flag = false;
      this.insercaoImplantesMaxilar[index].flag = false;
      if (value.id == 18 || value.id == 28) {
        this.molaresMaxilar[indexModalares].flag = true;
      }
    }
  }

  toggleTratamentoEndodontico(value: any) {
    let index = this.tratamentoEndodonticoMandibula.findIndex(item => item.id == value.id);
    if (value.checked) {
      this.tratamentoEndodonticoMandibula[index].checked = false;
    } else {
      this.tratamentoEndodonticoMandibula[index].checked = true;
    }
  }

  toggleTratamentoEndodonticoMaxilar(value: any) {
    let index = this.tratamentoEndodonticoMaxilar.findIndex(item => item.id == value.id);
    if (value.checked) {
      this.tratamentoEndodonticoMaxilar[index].checked = false;
    } else {
      this.tratamentoEndodonticoMaxilar[index].checked = true;
    }
  }

  toggleImplante(value: any) {
    let index = this.implantesMandibula.findIndex(item => item.id == value.id);
    if (value.checked) {
      this.implantesMandibula[index].checked = false;
      this.insercaoImplantesMandibula[index].flag = false;
    } else {
      this.implantesMandibula[index].checked = true;
      this.insercaoImplantesMandibula[index].flag = true;
    }
  }

  toggleImplanteMaxilar(value: any) {
    let index = this.implantesMaxilar.findIndex(item => item.id == value.id);
    if (value.checked) {
      this.implantesMaxilar[index].checked = false;
      this.insercaoImplantesMaxilar[index].flag = false;
    } else {
      this.implantesMaxilar[index].checked = true;
      this.insercaoImplantesMaxilar[index].flag = true;
    }
  }

  toggleLesoes(value: any) {
    let index = this.lesoesMandibula.findIndex(item => item.id == value.id);
    this.lesoesMandibula[index].checked = !this.lesoesMandibula[index].checked;
  }

  toggleLesoesMaxilar(value: any) {
    let index = this.lesoesMaxilar.findIndex(item => item.id == value.id);
    this.lesoesMaxilar[index].checked = !this.lesoesMaxilar[index].checked;
  }

  existImplate(): boolean {
    this.implantesMandibula.map(item => {
      this.dentesMandibula.filter(value => {
        if (value.id == item.id) {
          item.flag = !value.checked;
        }
      });
    });
    return this.implantesMandibula.some(item => item.flag);
  }

  existImplateMaxilar(): boolean {
    this.implantesMaxilar.map(item => {
      this.dentesMaxilar.filter(value => {
        if (value.id == item.id) {
          item.flag = !value.checked;
        }
      });
    });
    return this.implantesMaxilar.some(item => item.flag);
  }

  existInsercaoImplanteMaxilar() {
    this.insercaoImplantesMaxilar.map(item => {
      this.implantesMaxilar.filter(value => {
        if (value.id == item.id) {
          item.flag = value.checked;
        }
      });
    });
    return this.insercaoImplantesMaxilar.some(item => item.flag);
  }

  existInsercaoImplanteMandibula() {
    this.insercaoImplantesMandibula.map(item => {
      this.implantesMandibula.filter(value => {
        if (value.id == item.id) {
          item.flag = value.checked;
        }
      });
    });
    return this.insercaoImplantesMandibula.some(item => item.flag);
  }

  existMolar(): boolean {
    return this.molaresMandibula.some(item => item.flag);
  }

  existMolarMaxilar() {
    return this.molaresMaxilar.some(item => item.flag);
  }

  toggleInsercaoMaxilar(value: any) {
    let index = this.insercaoMaxilar.findIndex(item => item.id == value.id);
    this.insercaoMaxilar[index].checked = !this.insercaoMaxilar[index].checked;
  }

  isCheckedMolares(value: any) {
    let checked = this.molaresMandibula.find(item => item.id == value)?.checked;
    if (checked) {
      this.tomografiaMandibulaForm.get(`posicao_${value}`)?.addValidators([Validators.required]);
    } else {
      this.tomografiaMandibulaForm.get(`posicao_${value}`)?.clearValidators();
      this.tomografiaMandibulaForm.get(`posicao_${value}`)?.setValue("");
    }
    return checked;
  }

  isCheckedMolaresMaxilar(value: any) {
    let checked = this.molaresMaxilar.find(item => item.id == value)?.checked;
    if (checked) {
      this.tomografiaMaxilarForm.get(`posicao_${value}`)?.addValidators([Validators.required]);
    } else {
      this.tomografiaMaxilarForm.get(`posicao_${value}`)?.clearValidators();
      this.tomografiaMaxilarForm.get(`posicao_${value}`)?.setValue("");
    }
    return checked
  }

  toggleMolares(value: any) {
    let index = this.molaresMandibula.findIndex(item => item.id == value.id);
    this.molaresMandibula[index].checked = !this.molaresMandibula[index].checked;
  }

  toggleMolaresMaxilar(value: any) {
    let index = this.molaresMaxilar.findIndex(item => item.id == value.id);
    this.molaresMaxilar[index].checked = !this.molaresMaxilar[index].checked;
  }

  toggleInsercaoDentariaMaxilar(value: any) {
    let index = this.insercaoDentariaMaxilar.findIndex(item => item.id == value.id);
    this.insercaoDentariaMaxilar[index].checked = !this.insercaoDentariaMaxilar[index].checked;
  }

  toggleInsercaoDentariaMandibula(value: any) {
    let index = this.insercaoDentariaMandibula.findIndex(item => item.id == value.id);
    this.insercaoDentariaMandibula[index].checked = !this.insercaoDentariaMandibula[index].checked;
  }

  toggleInsercaoImplantesMaxilar(value: any) {
    let index = this.insercaoImplantesMaxilar.findIndex(item => item.id == value.id);
    this.insercaoImplantesMaxilar[index].checked = !this.insercaoImplantesMaxilar[index].checked;
  }

  toggleInsercaoImplantesMandibula(value: any) {
    let index = this.insercaoImplantesMandibula.findIndex(item => item.id == value.id);
    this.insercaoImplantesMandibula[index].checked = !this.insercaoImplantesMandibula[index].checked;
  }

  save(edit = false): { [k: string]: any } {
    let lesoes = this.buildString(this.lesoesMandibula) ?? '';
    let implantes = this.buildString(this.implantesMandibula) ?? '';
    let tratamentoEndodontico = this.buildString(this.tratamentoEndodonticoMandibula) ?? '';
    let elementosDentarios = this.buildString(this.dentesMandibula) ?? '';
    let molares = this.buildString(this.molaresMandibula) ?? '';
    let insercaoDentaria = this.buildString(this.insercaoDentariaMandibula) ?? '';
    let insercaoImplante = this.buildString(this.insercaoImplantesMandibula) ?? '';

    this.tomografiaMandibulaForm.get('dentes_presentes')?.setValue(elementosDentarios);
    this.tomografiaMandibulaForm.get('tratamento_endodontico')?.setValue(tratamentoEndodontico);
    this.tomografiaMandibulaForm.get('implantes_osseointegraveis')?.setValue(implantes);
    this.tomografiaMandibulaForm.get('insercao_ossea_dentaria')?.setValue(insercaoDentaria);
    this.tomografiaMandibulaForm.get('insercao_ossea_implantes')?.setValue(insercaoImplante);
    this.tomografiaMandibulaForm.get('lesoes_periapicais')?.setValue(lesoes);
    this.tomografiaMandibulaForm.get('terceiros_molares_inclusos')?.setValue(molares);

    let lesoesMaxilar = this.buildString(this.lesoesMaxilar) ?? '';
    let implantesMaxilar = this.buildString(this.implantesMaxilar) ?? '';
    let tratamentoEndodonticoMaxilar = this.buildString(this.tratamentoEndodonticoMaxilar) ?? '';
    let elementosDentariosMaxilar = this.buildString(this.dentesMaxilar) ?? '';
    let molaresMaxilar = this.buildString(this.molaresMaxilar) ?? '';
    let insercaoDentariaMaxilar = this.buildString(this.insercaoDentariaMaxilar) ?? '';
    let insercaoImplantesMaxilar = this.buildString(this.insercaoImplantesMaxilar) ?? '';

    this.tomografiaMaxilarForm.get('dentes_presentes')?.setValue(elementosDentariosMaxilar);
    this.tomografiaMaxilarForm.get('tratamento_endodontico')?.setValue(tratamentoEndodonticoMaxilar);
    this.tomografiaMaxilarForm.get('implantes_osseointegraveis')?.setValue(implantesMaxilar);
    this.tomografiaMaxilarForm.get('lesoes_periapicais')?.setValue(lesoesMaxilar);
    this.tomografiaMaxilarForm.get('terceiros_molares_inclusos')?.setValue(molaresMaxilar);
    this.tomografiaMaxilarForm.get('insercao_ossea_dentaria')?.setValue(insercaoDentariaMaxilar);
    this.tomografiaMaxilarForm.get('insercao_ossea_implantes')?.setValue(insercaoImplantesMaxilar);

    let volumetria = this.tomografiaFaringeForm.get('volumetria_da_coluna_aerea')?.value;
    let seccaoAxial = this.tomografiaFaringeForm.get('menor_seccao_axial')?.value;
    let distanciaTransversalAnteriorMandibula = this.tomografiaMandibulaForm.get('distancia_transversal_anterior')?.value;
    let distanciaTransversalPosteriorMandibula = this.tomografiaMandibulaForm.get('distancia_transversal_posterior')?.value
    let distanciaTransversalAnteriorMaxilar = this.tomografiaMaxilarForm.get('distancia_transversal_anterior')?.value
    let distanciaTransversalPosteriorMaxilar = this.tomografiaMaxilarForm.get('distancia_transversal_posterior')?.value

    this.tomografiaFaringeForm.get('volumetria_da_coluna_aerea')?.setValue(volumetria.toString());
    this.tomografiaFaringeForm.get('menor_seccao_axial')?.setValue(seccaoAxial.toString());
    this.tomografiaMandibulaForm.get('distancia_transversal_anterior')?.setValue(distanciaTransversalAnteriorMandibula.toString());
    this.tomografiaMandibulaForm.get('distancia_transversal_posterior')?.setValue(distanciaTransversalPosteriorMandibula.toString());
    this.tomografiaMaxilarForm.get('distancia_transversal_anterior')?.setValue(distanciaTransversalAnteriorMaxilar.toString());
    this.tomografiaMaxilarForm.get('distancia_transversal_posterior')?.setValue(distanciaTransversalPosteriorMaxilar.toString());

    let formGroup: any = {
      tomografiaAtmForm: this.tomografiaDasAtmsForm.getRawValue(),
      tomografiaFaringeForm: this.tomografiaFaringeForm.getRawValue(),
      tomografiaMaxilarForm: this.tomografiaMaxilarForm.getRawValue(),
      tomografiaMandibulaForm: this.tomografiaMandibulaForm.getRawValue(),
      tomografiaSeiosDaFace: this.tomografiaSeiosDaFace != undefined ? this.tomografiaSeiosDaFace.formGroup.value : this.formSeiosDaFace
    };

    let date = this.formDate.get('date')?.value;

    let response = {};

    if (this.exam.exam_id == this.TOMOGRAFIA_COMPUTADORIZADA_DAS_ATMS_EXAM_TEMPLATE_ID) {
      if (this.tomografiaDasAtmsForm.valid) {
        this.toast.success('Laudo preenchido', 'Sucesso');
        formGroup.tomografiaAtmForm.date = date;
        response = formGroup.tomografiaAtmForm;
      } else {
        this.setFormErrors("ATM");
        this.toast.error('Preencha todos os campos', 'Erro:');
        return {};
      }
    }
    if (this.exam.exam_id == this.TOMOGRAFIA_COMPUTADORIZADA_DE_FARINGE_EXAM_TEMPLATE_ID) {
      if (this.tomografiaFaringeForm.valid) {
        formGroup.tomografiaFaringeForm.date = date;
        this.toast.success('Laudo preenchido', 'Sucesso');
        response = formGroup.tomografiaFaringeForm;
      } else {
        this.setFormErrors("Faringe");
        this.toast.error('Preencha todos os campos', 'Erro:');
        return {};
      }
    }
    if (this.exam.exam_id == this.TOMOGRAFIA_COMPUTADORIZADA_DE_MANDIBULA_EXAM_TEMPLATE_ID) {
      if (this.tomografiaMandibulaForm.valid) {
        formGroup.tomografiaMandibulaForm.date = date;
        this.toast.success('Laudo preenchido', 'Sucesso');
        response = formGroup.tomografiaMandibulaForm;
      } else {
        this.setFormErrors("Mandibula");
        this.toast.error('Preencha todos os campos', 'Erro:');
        return {};
      }
    }
    if (this.exam.exam_id == this.TOMOGRAFIA_COMPUTADORIZADA_DE_MAXILA_EXAM_TEMPLATE_ID) {
      if (this.tomografiaMaxilarForm.valid) {
        formGroup.tomografiaMaxilarForm.date = date;
        this.toast.success('Laudo preenchido', 'Sucesso');
        response = formGroup.tomografiaMaxilarForm;
      } else {
        this.setFormErrors("Maxilar");
        this.toast.error('Preencha todos os campos', 'Erro:');
        return {};
      }
    }
    if (this.exam.exam_id == this.TOMOGRAFIA_COMPUTADORIZADA_DOS_SEIOS_DA_FACE_EXAM_TEMPLATE_ID) {
      if (this.tomografiaSeiosDaFace != undefined) {
        if (this.tomografiaSeiosDaFace.formGroup.valid) {
          formGroup.tomografiaSeiosDaFace.date = date;
          this.toast.success('Laudo preenchido', 'Sucesso');
          response = formGroup.tomografiaSeiosDaFace;
          this.formSeiosDaFace = formGroup.tomografiaSeiosDaFace;
        } else {
          this.tomografiaSeiosDaFace.setErrors();
          this.toast.error('Preencha todos os campos', 'Erro:');
          return {};
        }
      } else {
        response = this.formSeiosDaFace;
      }
    }
    if (edit)
      return response;
    this.activeModal.close(response);
    return {};
  }

  buildString(stringArray: any[]) {
    stringArray = stringArray.filter(item => {
      return item.checked == true;
    });
    stringArray = stringArray.map(item => item.id);
    if (stringArray.length == 0) return '';
    if (stringArray.length == 1) {
      let stringResponseDefault = stringArray.toString();
      return stringResponseDefault;
    }
    let popped = stringArray.pop();
    let stringResponse = stringArray.join(', ');
    stringResponse += ' e ' + popped!;
    return stringResponse;
  }

  buildArray(value: any) {
    let strArray = value.split(', ');
    let arrayResponse = value.split(', ')[strArray.length - 1].split(' e ');
    strArray.pop();
    arrayResponse.push(...strArray)
    return arrayResponse;
  }

  setFormErrors(form: string) {
    let errNamesAtm = [
      "atm_direita_espaco_articular", "atm_direita_remodelacao", "atm_direita_grau_da_remodelacao",
      "atm_direita_localizacao_da_remodelacao", "atm_direita_formacoes_cisticas",
      "atm_direita_localizacao_da_formacao_cistica", "atm_direita_fossa_mandibular", "atm_direita_erosoes",
      "atm_direita_grau_da_erosao", "atm_direita_localizacao_da_erosao",
      "atm_esquerda_espaco_articular", "atm_esquerda_remodelacao", "atm_esquerda_grau_da_remodelacao",
      "atm_esquerda_localizacao_da_remodelacao", "atm_esquerda_formacoes_cisticas",
      "atm_esquerda_localizacao_da_formacao_cistica", "atm_esquerda_fossa_mandibular", "atm_esquerda_erosoes",
      "atm_esquerda_grau_da_erosao", "atm_esquerda_localizacao_da_erosao"
    ];

    if (form == 'ATM') {
      errNamesAtm.forEach(name => {
        if (this.tomografiaDasAtmsForm.get(name)?.errors)
          this.tomografiaDasAtmsForm.get(name)?.setErrors({ response: '* Este campo é obrigatório', modal: true });
      });
    }

    let errNamesFaringe = [
      "grau_do_estreitamento_sagital", "localizacao_do_estreitamento_sagital",
      "espaco_aereo_entre_palato_e_lingua", "grau_do_estreitamento_coronal",
      "localizacao_do_estreitamento_coronal", "estreitamento_em_regiao_amigdaliana",
      "volumetria_da_coluna_aerea", "menor_seccao_axial", "localizacao_da_menor_seccao_axial"
    ];

    if (form == 'Faringe') {
      errNamesFaringe.forEach(name => {
        if (this.tomografiaFaringeForm.get(name)?.errors)
          this.tomografiaFaringeForm.get(name)?.setErrors({ response: '* Este campo é obrigatório', modal: true });
      });
    }

    let errNamesDentario = [
      "dentes_presentes", "tratamento_endodontico", "assimetria_processo_alveolar",
      "desproporcao_processo_alveolar", "assimetria_corpo_mandibular",
      "distancia_transversal_anterior", "nivelamento_coronal_do_plano_oclusal",
      "implantes_osseointegraveis", "insercao_ossea_dentaria", "apinhamento_dentario",
      "insercao_ossea_implantes", "distancia_transversal_posterior", "lesoes_periapicais",
      "terceiros_molares_inclusos", "posicao_18", "posicao_28", "posicao_38", "posicao_48", "material_restaurador"
    ];

    if (form == 'Mandibula') {
      errNamesDentario.forEach(name => {
        if (this.tomografiaMandibulaForm.get(name)?.errors)
          this.tomografiaMandibulaForm.get(name)?.setErrors({ response: '* Este campo é obrigatório', modal: true });
      });
    }

    if (form == 'Maxilar') {
      errNamesDentario.forEach(name => {
        if (this.tomografiaMaxilarForm.get(name)?.errors)
          this.tomografiaMaxilarForm.get(name)?.setErrors({ response: '* Este campo é obrigatório', modal: true });
      });
    }
  }

  editReport() {
    let variables = this.save(true);
    let data = { 'exam_id': this.exam.exam_id, variables };
    this.registerService.getMedicalReportText(data).subscribe(response => {
      this.laudo = response['laudo'];
      let reportLines: string[] = this.laudo.split('\n');
      let index = 0;
      reportLines.forEach(line => {
        if (line.includes('BOLD|')) {
          this.reportTitles.push(line.replace('BOLD|', ''));
          index = this.reportTitles.length - 1;
        } else {
          if (!this.reportTextArea[index])
            this.reportTextArea[index] = line;
          else
            this.reportTextArea[index] = `${this.reportTextArea[index]}\n${line}`;
        }
      });
    });
  }

  remountReport() {
    let laudo = '';
    this.reportTitles.forEach((title, index) => {
      laudo += `BOLD|${title}\n`;
      laudo += `${this.reportTextArea[index]}\n`;
      laudo += '\n';
    });
    return laudo;
  }

  saveReport() {
    let data = this.save(true);
    data['laudo'] = this.remountReport();
    this.activeModal.close(data);
  }
}
