import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FinancialStatusListComponent } from './financial-status-list/financial-status-list.component';
import { FinancialStatusFormComponent } from './financial-status-form/financial-status-form.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';



@NgModule({
  declarations: [
    FinancialStatusListComponent,
    FinancialStatusFormComponent
  ],
  imports: [
    CommonModule,
    RenderersModule,
    SharedModule,
    ReactiveFormsModule,
    NgSelectModule
  ]
})
export class FinancialStatusModule { }
