<div class="confirm-modal-wrapper">
    <div class="confirm-modal">
        <div class="confirm-modal__header">
            <h1>Selecione uma opção</h1>
        </div>

        <div class="confirm-modal__content">
            <p>{{text}}</p>

            <div *ngIf="options.length > 0" [formGroup]="optionsForm" class="col-5 mb-4 px-0">
                <div class="selector d-flex align-content-center mb-1" *ngFor="let option of options">
                    <input type="radio" [id]="option.id" [value]="option.id" class="mr-2" formControlName="option">
                    <label [for]="option.id">{{option.name}}</label>
                </div>
            </div>

            <div class="row justify-content-end">
                <button class="btn btn-link col-sm-12 col-md-3 col-lg-3 mt-5 mb-5"
                    (click)="activeModal.close(false)">Cancelar</button>
                <button class="btn btn-primary col-sm-12 col-md-3 col-lg-3 mt-5 mb-5" (click)="okButton()">Ok</button>
            </div>
        </div>
    </div>
</div>