import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SchedulesListComponent } from './schedules-list/schedules-list.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgbTimepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarWeekModule } from "angular-calendar";
import { NgSelectModule } from '@ng-select/ng-select';
import { SchedulesFormComponent } from './schedules-form/schedules-form.component';

@NgModule({
  declarations: [
    SchedulesListComponent,
    SchedulesFormComponent
  ],
  imports: [
    CommonModule,
    RenderersModule,
    FormsModule,
    ReactiveFormsModule,
    NgbTooltipModule,
    CalendarWeekModule,
    NgbTimepickerModule,
    NgSelectModule,
    SharedModule
  ]
})
export class SchedulesModule { }
