<div *ngIf="!loadingPage else loadingTemplate">
  <div class="row mb-5">
    <div class="col card">
      <div class="col px-0">
        <h3 class="mb-4">Exames</h3>
      </div>

      <div class="row px-0" [formGroup]="formGroup">
        <div class="col-12 p-0">
          <app-basic-input id="name" label="Nome" controlName="name" [formGroup]="formGroup"></app-basic-input>
        </div>

        <div class="col-12 p-0 mt-4">
          <app-basic-input id="drive_id" label="Drive ID" controlName="drive_id" [formGroup]="formGroup"></app-basic-input>
        </div>

        <div class="col-12 p-0 py-4">
          <label class="field__title">Tipo de anexo permitido</label>
          <div class="checkbox-option" *ngFor="let attachment of attachmentList">
            <input type="checkbox" [value]="attachment.value" [id]="attachment.value" [checked]="isAttachmentSelected(attachment.value)" (click)="toggleAttachment(attachment.value)">
            <label [for]="attachment.value">{{ attachment.label }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row justify-content-end mt-5 mb-5">
    <div class="col-12 col-md-3 mb-3">
      <button (click)="cancelHandler()" class="btn btn-link btn-lg" type="button">
        Cancelar
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3" *ngIf="button">
      <button (click)="removeHandler()" [disabled]="submittingRemove || !canRemove" class="btn btn-secondary btn-lg"
        type="button">
        {{removeButton}}
        <span class="spinner-border" *ngIf="submittingRemove"></span>
      </button>
    </div>

    <div class="col-12 col-md-3 mb-3">
      <button (click)="submitHandler()" [disabled]="submitting || !canSave" class="btn btn-primary btn-lg"
        type="button">
        {{submitButton}}
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>
</div>

<ng-template #loadingTemplate>
  <div class="col card full-loading text-center">
    <div class="spinner-border spinner-border-xl" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
