import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ExamModel } from 'src/app/core/models/examModel.model';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';
import {AttachmentTypeModel} from "../../../../../core/models/attachmentType.model";

@Component({
  selector: 'app-exams-template-form',
  templateUrl: './exams-template-form.component.html',
  styleUrls: ['./exams-template-form.component.scss']
})
export class ExamsTemplateFormComponent implements OnInit {

  formGroup = this.fb.group({
    id: [],
    name: [],
    attachment: [],
    drive_id: []
  });

  id!: number;
  loadingPage: boolean = false;
  submitting: boolean = false;
  submittingRemove: boolean = false;

  isRegister: boolean = true;
  removeButton: String = "Desativar";
  submitButton: String = "Salvar";

  button: boolean = false;

  fetchedExamData!: ExamModel;

  attachmentList: AttachmentTypeModel[] = [];
  selectedAttachment : any[] = [];

  constructor(private fb: FormBuilder, private registersService: RegistersService, private activatedRoute: ActivatedRoute, private sessionManager: SessionManagerService, private toast: ToastrService, private router: Router, config: NgbModalConfig, private modalService: NgbModal) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      if (this.id) {
        this.isRegister = false;
        this.loadingPage = true;
        this.fetchExamExistingData();
      }
    });

    this.registersService.attachmentTypesList().subscribe(response => {
      this.attachmentList = response;
    })
  }

  get canSave() {
    if (this.id) {
      return this.sessionManager.getUserPermission(PermissionConst.add_document);
    } else {
      return this.sessionManager.getUserPermission(PermissionConst.change_document);
    }
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_document);
  }

  cancelHandler() {
    this.router.navigate(['/dashboard/registers/exams'])
  }

  submitHandler() {
    this.submitting = true;
    this.formGroup.markAllAsTouched();
    let examsData = this.formGroup.getRawValue() as ExamModel;
    if (this.isRegister) {
      if (this.formGroup.valid) {
        this.examsRegister(examsData);
      } else this.submitting = false;
    } else {
      this.examsEdit(examsData);
    }
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar este exame?";
    modalRef.result.then((result: boolean) => {
      if (result == true) {
        this.examsRemove();
      } else this.submittingRemove = false;
    })
  }

  examsRegister(examData: ExamModel) {
    examData.id = undefined;
    this.registersService.examsRegister(examData).subscribe(response => {
      this.toast.success("Exame criado com sucesso", "Sucesso");
      this.router.navigate(['/dashboard/registers/exams']);
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  examsEdit(examData: ExamModel) {
    examData.is_active = true;
    this.registersService.examsEdit(examData).subscribe(response => {
      this.toast.success("Exame alterado com sucesso", "Sucesso");
      this.router.navigate(['/dashboard/registers/exams']);
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
      this.submitting = false;
    });
  }

  examsRemove() {
    this.registersService.examsRemove(this.id).subscribe(response => {
      this.toast.success("Exame removido com sucesso", "Sucesso");
      this.router.navigate(['/dashboard/registers/exams']);
    }, (error: HttpErrorResponse) => {
      this.submittingRemove = false;
      this.mapErrorResponse(error);
    })
  }

  fetchExamExistingData() {
    this.registersService.examGet(this.id).subscribe(response => {
      this.fetchedExamData = response;
      if (this.fetchedExamData.is_active) {
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
        this.button = true;
      }
      else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
      }
      if(this.fetchedExamData.attachment) {
        this.selectedAttachment = this.fetchedExamData.attachment;
      }
      this.formGroup.patchValue(this.fetchedExamData);
      this.loadingPage = false;
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    })
  }

  isAttachmentSelected(item: any) {
    return this.selectedAttachment.some(value => item == value);
  }

  toggleAttachment(item: any) {
    if (this.isAttachmentSelected(item)) {
      this.selectedAttachment = this.selectedAttachment.filter(value => value != item);
    } else {
      this.selectedAttachment.push(item);
    }
    let valueMap = this.selectedAttachment.map((item) => {
      return item;
    });
    this.formGroup.get('attachment')?.setValue(valueMap);
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    }
    else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["name"]) this.formGroup.get("name")?.setErrors({ response: errorResponse.error['name'] });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  }

}
