import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatientTagsListComponent } from './patient-tags-list/patient-tags-list.component';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { PatientTagsFormComponent } from './patient-tags-form/patient-tags-form.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    PatientTagsListComponent,
    PatientTagsFormComponent
  ],
  imports: [
    CommonModule,
    RenderersModule,
    SharedModule,
    ReactiveFormsModule
  ]
})
export class PatientTagsModule { }
