import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ScheduleService } from 'src/app/core/services/schedules.service';

@Component({
  selector: 'app-registers',
  templateUrl: './registers.component.html',
  styleUrls: ['./registers.component.scss']
})
export class RegistersComponent implements OnInit {


  titleMap: string[][] = [
    ["clinics", "Clínicas"],
    ["professionals", "Profissionais"],
    ["systemUsers", "Usuários do Sistema"],
    ["redefine_password", "Redefinir Senha"],
    ["schedules", "Agendas"],
    ["healthInsurances", "Convênios"],
    ["professions", "Profissões"],
    ["patientTags", "Tags de Pacientes"],
    ["clinicTags", "Tags de Clínicas"],
    ["professionalTags", "Tags de Profissionais"],
    ["specialties", "Áreas de Atuação"],
    ["procedures", "Procedimentos"],
    ["documents", "Documentos"],
    ["exams", "Exames"],
    ["schedulingStatus", "Status do Agendamento"],
    ["attendanceType", "Tipo de Atendimento"],
    ["leads", "Leads"],
    ["triggers", "Gatilhos chatbot"],
    ["welcomeMessage", "Configurações do chatbot"],
    ["manualTrigger", "Disparo manual chatbot"],
    ["statusReminder", "Status do Lembrete"],
    ["endConversation", "Encerrar conversa chatbot"],
    ["financialStatus", "Status do Financeiro"],
    ["stageStatus", "Estágio do Status Financeiro"]
  ];

  headerTitle: string = "";
  headerSubTitle: string = "";
  showMenuSidebar: boolean = false;

  constructor(private router: Router, private scheduleService: ScheduleService) {
    if (!this.router.url.split("/")[3]) this.headerSubTitle = this.titleMap[0][1];
    else this.headerSubTitle = (this.titleMap.filter((route) => route[0] == router.url.split("/")[3])[0][1]);
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.router.url.split("/")[2] == 'registers') {
          this.headerTitle = "Cadastros";
          if (this.router.url.split("/")[3] != undefined) {
            this.headerSubTitle = (this.titleMap.filter((route) => route[0] == this.router.url.split("/")[3])[0][1]);
            if (this.router.url.split("/")[3] == 'schedules') {
              this.headerTitle = "Agenda";
              if (this.router.url.split("/")[4] == 'edit') {
                this.headerSubTitle = "Carregando...";
                this.scheduleService.retrieve(Number(this.router.url.split("/")[5])).subscribe((response) => {
                  this.headerSubTitle = response.name ?? '';
                })
              }
              else if (this.router.url.split("/")[4] != undefined) {
                this.headerTitle = "Cadastros";
                this.headerSubTitle = "Nova agenda";
              }
              else {
                this.headerTitle = "Cadastros";
              }
            }
          }
          else {
            this.headerSubTitle = this.titleMap[0][1];
          }
        }
      }
    })
  }

  menuButtonHandler() {
    this.showMenuSidebar = !this.showMenuSidebar;
  }

  ngOnInit(): void {
  }

}
