import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { SchedulingStatusModel } from 'src/app/core/models/scheduling-status.model';
import { RegistersService } from 'src/app/core/services/registers.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';
import { DefaultModalComponent } from 'src/app/shared/renderers/components/default-modal/default-modal.component';

@Component({
  selector: 'app-scheduling-status-form',
  templateUrl: './scheduling-status-form.component.html',
  styleUrls: ['./scheduling-status-form.component.scss']
})
export class SchedulingStatusFormComponent implements OnInit {

  id!: number;
  loadingPage: boolean = false;
  backgroundColorValue?: String = "#FFFFFF";
  fontColorValue?: String = "#000000";

  formGroup = new FormGroup({
    name: new FormControl('', [Validators.required]),
    can_overschedule: new FormControl(false, [Validators.required]),
    background_color: new FormControl(this.backgroundColorValue, [Validators.required]),
    font_color: new FormControl(this.fontColorValue, [Validators.required]),
    default: new FormControl(false, [])
  });

  fetchedSchedulingStatusData!: SchedulingStatusModel;

  isRegister: boolean = true;

  disabledSalvar: boolean = false;
  disabledRemover: boolean = false;

  submitting: boolean = false;
  removeButton?: String = "Desativar";
  submitButton?: String = "Salvar";

  button: boolean = false;
  registerSuccess: boolean = false;

  constructor(private registersService: RegistersService, private router: Router, private activatedRoute: ActivatedRoute, config: NgbModalConfig, private modalService: NgbModal, private sessionManager: SessionManagerService, private toast: ToastrService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.id = params['id'];
      if (this.id) {
        this.isRegister = false;
        this.initializeForm();
      }
    })
  }

  async initializeForm() {
    await this.fetchSchedulingStatusExistingData();
    if (!this.sessionManager.getUserPermission(PermissionConst.change_scheduling) || !this.sessionManager.getUserPermission(PermissionConst.add_scheduling)) {
      this.disabledSalvar = true;
      this.disabledRemover = true;
      this.formGroup.disable();
    }
    if (this.sessionManager.getUserPermission(PermissionConst.remove_scheduling) && this.sessionManager.getUserPermission(PermissionConst.add_scheduling)) {
      this.disabledRemover = false;
    }
    if (!this.sessionManager.getUserPermission(PermissionConst.remove_scheduling)) {
      this.disabledRemover = true;
    }
  }

  cancelHandler() {
    this.router.navigate(['/dashboard/registers/schedulingStatus'])
  }

  submitHandler() {
    this.submitting = true;
    const schedulingStatusData = this.formGroup.getRawValue();
    schedulingStatusData.is_active = true;
    if(this.isRegister) this.schedulingStatusRegister(schedulingStatusData);
    else this.schedulingStatusEdit(schedulingStatusData);
  }

  removeHandler() {
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar este status do agendamento?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.submitting = true;
        this.schedulingStatusRemove();
      }
    })
  }

  async schedulingStatusRegister(schedulingStatusData: any) {
    try {
      await this.registersService.schedulingStatusRegister(schedulingStatusData).toPromise();
      this.registerSuccess = true;
      this.router.navigate(['/dashboard/registers/schedulingStatus'])
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    }
  }

  async schedulingStatusEdit(schedulingStatusData: SchedulingStatusModel) {
    try {
      schedulingStatusData.id = this.id;
      await this.registersService.schedulingStatusEdit(schedulingStatusData).toPromise();
      this.registerSuccess = true;
      this.router.navigate(['/dashboard/registers/schedulingStatus'])
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    }
  }

  async schedulingStatusRemove() {
    try {
      await this.registersService.schedulingStatusRemove(this.id).toPromise();
      this.router.navigate(['/dashboard/registers/schedulingStatus'])
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
      this.submitting = false;
    }
  }

  async fetchSchedulingStatusExistingData() {
    try {
      this.loadingPage = true;
      this.fetchedSchedulingStatusData = await this.registersService.schedulingStatusGet(this.id).toPromise();
      if (this.fetchedSchedulingStatusData.is_active) {
        this.button = true;
        this.removeButton = "Desativar";
        this.submitButton = "Salvar";
      }
      else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
      }
      this.setFormData();
    }
    catch (error) {
      const response = error as HttpErrorResponse;
      this.mapErrorResponse(response);
    }
  }

  setFormData() {
    this.formGroup.patchValue(this.fetchedSchedulingStatusData);
    this.backgroundColorValue = this.fetchedSchedulingStatusData.background_color;
    this.fontColorValue = this.fetchedSchedulingStatusData.font_color;
    this.loadingPage = false;
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro", {
        closeButton: true,
      });
    }
    else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["name"]) this.formGroup.get("name")?.setErrors({ response: errorResponse.error['name'] });
    if (errorResponse.error["code"]) this.formGroup.get("code")?.setErrors({ response: errorResponse.error['code'] });
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if ((this.formGroup.dirty || this.submitting) && !this.registerSuccess && this.isRegister) {
      const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
      modalRef.componentInstance.text = "As alterações no formulário não foram salvas e serão descartadas, deseja prosseguir?";
      return modalRef.result
    }
    else
      return true;
  };
}
