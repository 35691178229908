import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountModel } from 'src/app/core/models/account.model';
import { AccountsService } from 'src/app/core/services/accounts.service';

@Component({
  selector: 'app-password-set',
  templateUrl: './password-set.component.html',
  styleUrls: ['./password-set.component.scss']
})
export class PasswordSetComponent implements OnInit {
  loading: boolean = false;
  passwordSetForm!: FormGroup;
  code!: string;

  constructor(private accountsService: AccountsService, private router: Router, private activatedRoute: ActivatedRoute, private toast: ToastrService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.code = params['code'];

      this.passwordSetForm = new FormGroup({
        "password": new FormControl(null, [Validators.required, Validators.email]),
      });
    })
  }

  onSubmit(e: Event): void {
    this.loading = true;
    this.passwordRecoveryRequest();
    e.preventDefault();
  }

  passwordRecoveryRequest() {
    var accountData = {
      password: this.passwordSetForm.get('password')!.value,
      code: this.code
    } as AccountModel;

    this.accountsService.passwordSet(accountData).subscribe((response: AccountModel) => {
      this.toast.success("Senha alterada com sucesso.", "", {
        closeButton: true,
      });
      this.router.navigate(['login']);
    }, (response: HttpErrorResponse) => {
      this.toast.error(response.error["detail"], "Erro", {
        closeButton: true,
      });
    });
  }
}
