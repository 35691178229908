<div class="birdid-modal-wrapper">
  <div class="row p-0 justify-content-end">
    <button class="btn btn-closed btn-link col-1" (click)="activeModal.close(false)">
      <i-bs name="x-lg" width="2rem" height="2rem"></i-bs>
    </button>
  </div>
  <div class="birdid-modal" *ngIf="modalItems else loadingTemplate" [formGroup]="formGroup">
    <img src="../../../../assets/birdID.svg" alt="birdID">
    <h1 class="my-4">Assinar Digitalmente</h1>
    <p class="mb-4 mt-2">Acesse o BirdID e informe seu nome de usuário e o token de 6 digitos gerado.</p>
    <ng-select [clearable]="false" [searchable]="false" class="custom" id="lifetime" formControlName="lifetime"
      [items]="lifeTimeOptions" bindValue="lifetime" bindLabel="label">
    </ng-select>
    <app-basic-input placeholder="Usuário" id="username" controlName="username" [formGroup]="formGroup"
      [maskOptions]="{mask: '000.000.000-00'}"></app-basic-input>
    <app-basic-input placeholder="Insira o token que aparece em seu app BirdID" id="password" controlName="password"
      [formGroup]="formGroup">
    </app-basic-input>
    <div class="row justify-content-end mt-3">
      <button class="btn btn-primary col-6" [disabled]="submitting" (click)="birdidAuth()">
        Autenticar
        <span class="spinner-border" *ngIf="submitting"></span>
      </button>
    </div>
  </div>
  <ng-template #loadingTemplate>
    <div class="col card full-loading text-center">
      <div class="spinner-border spinner-border-xl" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-template>
</div>
