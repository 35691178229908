import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RegistersService } from 'src/app/core/services/registers.service';
import { MenuItem } from '../../navigation/models/menu-item';

@Component({
  selector: 'app-patient-edit',
  templateUrl: './patient-edit.component.html',
  styleUrls: ['./patient-edit.component.scss']
})
export class PatientEditComponent implements OnInit {

  patientName!: string;
  showMenuSidebar: boolean = false;

  constructor(private registerService: RegistersService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.patientName = "Carregando...";

    this.activatedRoute.params.subscribe(params => {
      this.registerService.patientGet(params['id']).subscribe((patient) => {
        this.patientName = patient.name!;
      })
    })
  }

  menuButtonHandler() {
    this.showMenuSidebar = !this.showMenuSidebar;
  }
}
