import { Component } from '@angular/core';
import { Toast, ToastrService, ToastPackage} from 'ngx-toastr';

@Component({
  selector: 'app-toast-button',
  templateUrl: './toast-button.component.html',
  styleUrls: ['./toast-button.component.scss']
})

export class ToastButtonComponent extends Toast {

  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerAction();
    console.log(event);
    return false;
  }
}
