import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScheduleService } from 'src/app/core/services/schedules.service';
import * as moment from 'moment';
import { RemindersModel } from 'src/app/core/models/reminders.model';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ReminderStatusModel } from "../../../core/models/reminder-status.model";
import { RegistersService } from 'src/app/core/services/registers.service';
import { PatientModel } from 'src/app/core/models/patient.model';
import { phoneNumberStringFormatter } from 'src/app/core/utils/string-formatters';
import { OptionModel } from '../../renderers/models/option';
import { ChatbotService } from 'src/app/core/services/chatbot.service';

@Component({
  selector: 'app-create-reminder-modal',
  templateUrl: './create-reminder-modal.component.html',
  styleUrls: ['./create-reminder-modal.component.scss']
})
export class CreateReminderModalComponent implements OnInit {

  formGroup: FormGroup = this.fb.group({
    id: [''],
    patient_id: [''],
    patient__name: [''],
    chatbot_message: ['', [Validators.required]],
    notes: [''],
    date: [moment(), [Validators.required]],
    time: [moment(), [Validators.required]],
    status_id: [null, [Validators.required]]
  });

  action = 'Criar';
  modalItems!: any;
  scheduleReminder!: boolean;
  reminder: RemindersModel = {};
  reminderStatus: ReminderStatusModel[] = [];
  reminderStatusName?: string;
  submitting: boolean = false;
  loadingPage: boolean = true;
  patientOptions: OptionModel[] = [];
  patients: PatientModel[] = [];

  constructor(private fb: FormBuilder, public activeModal: NgbActiveModal, private scheduleService: ScheduleService, private toast: ToastrService, private registersService: RegistersService, private chatbotService: ChatbotService) {
  }

  ngOnInit(): void {
    if (Object.keys(this.reminderStatus).length == 0) {
      let status = JSON.parse(localStorage.getItem('reminderStatus')!);
      Object.keys(status).forEach(value => {
        this.reminderStatus.push(status[value]);
      });
      if (this.modalItems && this.modalItems['status_id']) {
        this.reminderStatusName = this.reminderStatus.find(status => status.id == this.modalItems['status_id'])?.name;
        let reminderStatusId = this.reminderStatus.find(status => status.id == this.modalItems['status_id'])?.id;
        this.formGroup.get('status_id')?.setValue(reminderStatusId);
      } else {
        this.reminderStatusName = this.reminderStatus[0].name || this.reminderStatus.find(status => status.default)?.name;
        let reminderStatusId = this.reminderStatus[0].id || this.reminderStatus.find(status => status.default)?.id;
        this.formGroup.get('status_id')?.setValue(reminderStatusId);
      }
    }
    this.registersService.listAllPatients().subscribe(response => {
      this.patients = response;
      this.patientOptions = response.map((patient) => {
        let cpf = patient.document_number;
        let phone = patient.phone_numbers?.map((phone) => {
          return `${phone.phone_number}`
        });
        let number = phoneNumberStringFormatter(phone![0]);
        cpf = (cpf == '' || cpf == null) ? "Não cadastrado" : cpf;
        let options = ({
          name: patient.id,
          label: `${patient.name} - CPF ${cpf} - Telefone ${number}`
        }) as OptionModel;

        return options;
      });
      this.loadingPage = false;
    });

    this.initialize();
  }

  initialize() {
    if (this.modalItems) this.formGroup.patchValue(this.modalItems);
    if (this.action == 'create') {
      this.action = 'Criar';
      if (!this.scheduleReminder) {
        this.formGroup.patchValue({
          time: '10:00',
          notes: '',
          date: moment().format('YYYY-MM-DD')
        });
        if (this.modalItems) {
          let patient_id = this.modalItems['item']['patient']['id'];
          this.formGroup.patchValue({
            patient_id: patient_id
          });
        }
      } else {
        let date = moment().format('YYYY-MM-DD');
        if (this.modalItems.event.item.date) {
          date = moment(this.modalItems.event.item.date).format('YYYY-MM-DD');
        } else if (this.modalItems.event.item) {
          date = moment(this.modalItems.event.item).format('YYYY-MM-DD');
        } else {
          date = moment(this.modalItems.item.date).format('YYYY-MM-DD');
        }
        this.formGroup.patchValue({
          time: '10:00',
          notes: '',
          date: date
        });
        this.formGroup.get('id')?.setValue('');
      }
    } else {
      this.action = 'Editar';
      let date = moment(this.modalItems.date).format('YYYY-MM-DD');
      let hour = moment(this.modalItems.date).format('HH:mm');
      this.formGroup.patchValue({
        date: date,
        time: hour
      });
      this.formGroup.get('patient_id')?.disable();
    }
  }

  submitHandler() {
    this.submitting = true;
    let date = moment(this.formGroup.get('date')?.value);
    let hour = moment(this.formGroup.get('time')?.value, 'HH:mm');
    let date_time = date.set({
      hour: hour.get('hour'),
      minute: hour.get('minute'),
      second: hour.get('second')
    });
    let reminderData = this.formGroup.getRawValue();
    reminderData.date = date_time;

    if (this.action == 'create' || this.action == 'Criar') {
      this.scheduleService.createReminder(reminderData).subscribe((response) => {
        this.submitting = false;
        this.toast.success("Lembrete criado", "Sucesso");
        this.activeModal.close(response);
      }, (error) => {
        const response = error as HttpErrorResponse;
        this.mapErrorResponse(response);
        this.submitting = false;
      });
    } else {
      this.scheduleService.editReminder(reminderData).subscribe(response => {
        this.submitting = false;
        this.toast.success('Lembrete editado', 'Sucesso');
        this.activeModal.close(response);
      }, (error) => {
        const response = error as HttpErrorResponse;
        this.mapErrorResponse(response);
        this.submitting = false;
      })
    }
  }

  shootMessage() {
    let patient = this.formGroup.get('patient_id')?.value;
    let phone_number = this.patients.find(value => value.id == patient)?.phone_numbers;
    let phone = phone_number?.map((phone) => {
      if (phone.type == 'WHATSAPP')
        return `${phone.country_code} + ${phone.phone_number}`;
      else return '';
    });
    let number = phone![0].replace(/\D/g, "");
    let shootMessageData = {
      phone_number: number,
      message: this.formGroup.get('chatbot_message')?.value
    };
    this.chatbotService.manualTriggersRegister(shootMessageData).subscribe(response => {
      this.toast.success('Mensagem enviada com sucesso', 'Sucesso');
    }, (error: HttpErrorResponse) => {
      this.mapErrorResponse(error);
    });
  }

  getReminderStatusBackgroundColor() {
    let statusId = this.formGroup.get('status_id')?.value;
    let background_color = this.reminderStatus.find(status => status.id == statusId)?.background_color;
    if (background_color)
      return background_color;
    return '';
  }

  getReminderStatusColor() {
    let statusId = this.formGroup.get('status_id')?.value;
    let font_color = this.reminderStatus.find(status => status.id == statusId)?.font_color;
    if (font_color)
      return font_color;
    return '';
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    } else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "attendance_id", "schedule_id", "patient_id", "chatbot_message",
      "notes", "date"
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
    });
  }
}
