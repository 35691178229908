import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ScheduleService } from 'src/app/core/services/schedules.service';
import { AttendanceTypeModel } from "../../../core/models/attendance-type.model";
import { RegistersService } from "../../../core/services/registers.service";
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-include-scheduling-modal',
  templateUrl: './include-scheduling-modal.component.html',
  styleUrls: ['./include-scheduling-modal.component.scss']
})
export class IncludeSchedulingModalComponent implements OnInit {

  @Input() modalItems: any;
  @Output() includeScheduling = new EventEmitter();
  attendanceTypesList?: AttendanceTypeModel[];

  showError = false;
  showErrorAttendanceType = false;
  item!: any;
  error: any;

  formGroup = this.fb.group({
    date: [moment(), [Validators.required]],
    hour: ['', [Validators.max(23)]],
    minutes: ['', [Validators.max(59)]],
    duration: ['10', [Validators.min(10)]],
    allowed_attendance_types: [[], [Validators.required]]
  });

  constructor(private scheduleService: ScheduleService, private registerService: RegistersService,
    private fb: FormBuilder, public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.registerService.attendancesTypeAll().subscribe(response => this.attendanceTypesList = response);
    this.item = this.modalItems.item;
    this.formGroup.get('date')?.setValue(moment(this.item).format('YYYY-MM-DD'));
    this.formGroup.get('hour')?.setValue(moment(this.item).format('HH'));
    this.formGroup.get('minutes')?.setValue(moment(this.item).format('mm'));
  }

  onceSchedule(ev: any) {
    let duration = this.formGroup.get('duration')?.value == "" ? "10" : this.formGroup.get('duration')?.value;
    if (this.formGroup.valid){
      let date = this.formGroup.get('date')?.value;
      let hour = this.formGroup.get('hour')?.value;
      let minutes = this.formGroup.get('minutes')?.value;
      let start_time = moment(moment(date).format('YYYY-MM-DD')).hour(hour).minute(minutes);
      if (this.formGroup.get('allowed_attendance_types')?.value){
        let attendance_type = (this.formGroup.get('allowed_attendance_types')?.value).map((type: any) => {
          return {
            id: type
          }
        });
        this.showErrorAttendanceType = false;
        let data = {
          start_time: moment(start_time).format(),
          end_time: moment(start_time.add(duration, 'minutes')).format(),
          allowed_attendance_types: attendance_type,
          is_available: true
        };
        this.scheduleService.onceSchedule(this.modalItems?.scheduleId, data).subscribe((response => {
          this.activeModal.close(response);
        }), (error) => {
          const response = error as HttpErrorResponse;
          this.mapErrorResponse(response);
          this.showError = true;
        }
        );
      }
    }
    else{
      this.formGroup.markAllAsTouched();
    }
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.error = errorResponse.error;
    }
  }

  onSelectAll() {
    const selected = this.attendanceTypesList?.map(item => item.id);
    this.formGroup.get('allowed_attendance_types')?.patchValue(selected);
  }

}
