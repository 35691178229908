import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ListModel } from '../models/list.model';
import { OximeterModel } from '../models/oximeter.model';
import { PolysomnographyExamModel } from '../models/polysomnography-exam.model';
import { PolysomnographyModel } from '../models/polysomnography.model';

@Injectable({
  providedIn: 'root'
})
export class OximeterService {
  constructor(private httpClient: HttpClient) { }

  oximetersList(searchString: string = "", orderingParam: string = "", status: string = '', isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/oximeters/?search=${searchString}&ordering=${orderingParam}&status=${status}&is_active=${isActive}&page=${page}`);
  }

  oximetersAll(): Observable<OximeterModel[]> {
    return this.httpClient.get<OximeterModel[]>(`${environment.API_URL}/oximeters/all/`);
  }

  oximeterGet(oximeterId: number): Observable<OximeterModel> {
    return this.httpClient.get<OximeterModel>(`${environment.API_URL}/oximeters/${oximeterId}/`);
  }

  oximeterRegister(oximeterData: OximeterModel): Observable<OximeterModel> {
    return this.httpClient.post<OximeterModel>(`${environment.API_URL}/oximeters/`, JSON.stringify(oximeterData));
  }

  oximeterEdit(oximeterData: OximeterModel): Observable<OximeterModel> {
    return this.httpClient.patch<OximeterModel>(`${environment.API_URL}/oximeters/${oximeterData.id}/`, JSON.stringify(oximeterData));
  }

  polysomnographyList(searchString: string = "", orderingParam: string = "", status: string = '', isActive: boolean = true, dateSince: string = '', dateUntil: string = '', page = 1): Observable<ListModel> {
      return this.httpClient.get<ListModel>(`${environment.API_URL}/polysomnography/?search=${searchString}&ordering=${orderingParam}&status=${status}&is_active=${isActive}&since=${dateSince}&until=${dateUntil}&page=${page}`);
  }

  polysomnographyGet(polysomnographyId: number): Observable<PolysomnographyModel> {
      return this.httpClient.get<PolysomnographyModel>(`${environment.API_URL}/polysomnography/${polysomnographyId}/`);
  }

  polysomnographyEdit(polysomnographyData: PolysomnographyModel): Observable<PolysomnographyModel> {
      return this.httpClient.patch<PolysomnographyModel>(`${environment.API_URL}/polysomnography/${polysomnographyData.id}/`, JSON.stringify(polysomnographyData));
  }

  patientExamsList(patientId: number): Observable<ListModel> {
      return this.httpClient.get<ListModel>(`${environment.API_URL}/patients/${patientId}/polysomnography_exams/`);
  }

  polysomnographyExamsList(polysomnographyId: number, is_active = true): Observable<PolysomnographyExamModel[]> {
      return this.httpClient.get<PolysomnographyExamModel[]>(`${environment.API_URL}/polysomnography/${polysomnographyId}/exams/all/?is_active=${is_active}`);
  }

  examRegister(polysomnographyId: number, examData: PolysomnographyExamModel): Observable<PolysomnographyExamModel> {
      return this.httpClient.post<PolysomnographyExamModel>(`${environment.API_URL}/polysomnography/${polysomnographyId}/exams/`, JSON.stringify(examData));
  }

  examEdit(polysomnographyId: number, examData: PolysomnographyExamModel): Observable<PolysomnographyExamModel> {
      return this.httpClient.patch<PolysomnographyExamModel>(`${environment.API_URL}/polysomnography/${polysomnographyId}/exams/${examData.id}/`, JSON.stringify(examData));
  }

  examDelete(polysomnographyId: number, examId: PolysomnographyExamModel): Observable<any> {
      return this.httpClient.delete(`${environment.API_URL}/polysomnography/${polysomnographyId}/exams/${examId}/`);
  }

  uploadTemporaryFile(formData: FormData) {
      return this.httpClient.post(`${environment.API_URL}/temporary_files/`, formData, {
        reportProgress: true,
        observe: 'events'
    });
  }
}
