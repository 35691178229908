import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManualTriggerFormComponent } from './manual-trigger-form/manual-trigger-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  declarations: [
    ManualTriggerFormComponent
  ],
  imports: [
    CommonModule,
    RenderersModule,
    SharedModule,
    NgSelectModule,
    NgxMaskModule,
    ReactiveFormsModule
  ]
})
export class ManualTriggerModule { }
