import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';
import { Dictionary } from '../../models/form-input';

@Component({
  selector: 'app-export-modal',
  templateUrl: './export-modal.component.html',
  styleUrls: ['./export-modal.component.scss']
})
export class ExportModalComponent implements OnInit {
  fields: Dictionary = {};
  url: string = 'export';
  fakeFormGroup: FormGroup = new FormGroup({checkbox: new FormControl()});
  showError: boolean = false;

  queryParamsFields: string[] = [];

  constructor(public activeModal: NgbActiveModal, private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.queryParamsFields = Object.keys(this.fields);
  }

  get keys() {
    return Object.keys(this.fields);
  }

  get queryParams() {
    var fields = this.queryParamsFields.join(',');
    return `fields=${fields}`;
  }

  hasField(field: string): boolean {
    return this.queryParamsFields.indexOf(field) != -1;
  }

  toggleField(field: string) {
    if (this.hasField(field)) {
      this.queryParamsFields = this.queryParamsFields.filter((value) => value != field);
    } else {
      this.queryParamsFields.push(field);
    }
  }

  export() {
    this.showError = false;

    if (this.queryParamsFields.length > 0) {
      var request = this.httpClient.get(`${environment.API_URL}${this.url}/?${this.queryParams}`, { responseType: 'text' });
      request.subscribe((response) => this.downloadFile(response), (error) => console.error(error));
    } else
      this.showError = true;
  }

  downloadFile(data: string) {
    var now = new Date();
    var model = this.url.replace('/', '').replace('/export', '');
    var file = new Blob([data], {type: 'text/csv'});
    var element = document.createElement('a');
    document.body.appendChild(element);
    element.href = window.URL.createObjectURL(file);
    element.download = `${model}_${now.toISOString()}.csv`;
    element.click();
    element.remove();
  }
}
