import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistersComponent } from './registers.component';
import { RouterModule } from '@angular/router';
import { NavigationModule } from '../navigation/navigation.module';
import { ClinicsModule } from './clinics/clinics.module';
import { ProfessionalsModule } from './professionals/professionals.module';
import { PatientTagsModule } from './patient-tags/patient-tags.module';
import { ProfessionsModule } from './professions/professions.module';
import { SystemUsersModule } from './system-users/system-users.module';
import { UserGroupsModule } from './user-groups/user-groups.module';
import { SchedulesModule } from './schedules/schedules.module';
import { HealthInsurancesModule } from './health-insurances/health-insurances.module';
import { ClinicTagsModule } from './clinic-tags/clinic-tags.module';
import { ProfessionalTagsModule } from './professional-tags/professional-tags.module';
import { SpecialtiesModule } from './specialties/specialties.module';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { ProceduresModule } from './procedures/procedures.module';
import { DocumentsModule } from './documents/documents.module';
import { SchedulingStatusModule } from './scheduling-status/scheduling-status.module';
import { AttendanceTypeModule } from './attendance-type/attendance-type.module';
import { LeadsModule } from './leads/leads.module';
import { TriggersModule } from './triggers/triggers.module';
import { WelcomeMessageModule } from './welcome-message/welcome-message.module';
import { ExamsTemplateModule } from './exams-template/exams-template.module';
import { ManualTriggerModule } from './manual-trigger/manual-trigger.module';
import { StatusReminderModule } from './status-reminder/status-reminder.module';
import { EndConversationModule } from './end-conversation/end-conversation.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FinancialStatusModule } from './financial-status/financial-status.module';
import { FinancialSettingsModule } from './financial-settings/financial-settings.module';
import { StagesStatusModule } from './stages-status/stages-status.module';

@NgModule({
  declarations: [
    RegistersComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    RenderersModule,
    NavigationModule,
    ClinicsModule,
    ProfessionalsModule,
    SystemUsersModule,
    UserGroupsModule,
    SchedulesModule,
    HealthInsurancesModule,
    ProfessionsModule,
    PatientTagsModule,
    ClinicTagsModule,
    ProfessionalTagsModule,
    SpecialtiesModule,
    ProceduresModule,
    DocumentsModule,
    SchedulingStatusModule,
    AttendanceTypeModule,
    LeadsModule,
    TriggersModule,
    WelcomeMessageModule,
    ExamsTemplateModule,
    ManualTriggerModule,
    StatusReminderModule,
    EndConversationModule,
    NgSelectModule,
    FinancialStatusModule,
    FinancialSettingsModule,
    StagesStatusModule
  ]
})
export class RegistersModule { }
