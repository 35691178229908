import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { PermissionConst } from 'src/app/core/models/permission-const.model';
import { TriggerModel } from 'src/app/core/models/triggers.model';
import { ChatbotService } from 'src/app/core/services/chatbot.service';
import { SessionManagerService } from 'src/app/core/services/session-manager.service';
import { OptionsSelectModel } from 'src/app/shared/models/option';

@Component({
  selector: 'app-manual-trigger-form',
  templateUrl: './manual-trigger-form.component.html',
  styleUrls: ['./manual-trigger-form.component.scss']
})
export class ManualTriggerFormComponent implements OnInit {

  phone_number = this.fb.group({
    country_code: ['+55'],
    phone_number: ['', [Validators.required]]
  });

  formGroup = this.fb.group({
    phone_number: [''],
    trigger: ['', [Validators.required]],
  });

  loadingPage: boolean = true;

  triggersOptions: OptionsSelectModel[] = [];

  submitting: boolean = false;
  removeButton?: String = "Desativar";
  submitButton?: String = "Salvar";

  button: boolean = false;

  constructor(private chatbotService: ChatbotService, private router: Router, private fb: FormBuilder,
    private sessionManager: SessionManagerService, private toast: ToastrService) { }

  ngOnInit(): void {
    this.initializeForm();
  }

  get canSave() {
    return this.sessionManager.getUserPermission(PermissionConst.add_chatbotmessage);
  }

  get canRemove() {
    return this.sessionManager.getUserPermission(PermissionConst.remove_chatbotmessage);
  }

  initializeForm() {
    this.fetchTriggers();
  }

  fetchTriggers() {
    this.chatbotService.triggersGetAll().subscribe(response => {
      this.triggersOptions = response.map(item => {
        return {
          label: item.trigger,
          value: item.id?.toString()
        } as OptionsSelectModel;
      });
      this.loadingPage = false;
    });
  }

  submitHandler() {
    this.submitting = true;
    this.phone_number.markAllAsTouched();
    this.formGroup.markAllAsTouched();
    let phone_number = this.phone_number.get('country_code')?.value + this.phone_number.get('phone_number')?.value;
    phone_number = phone_number.replace(/\D/g, "");
    this.formGroup.get('phone_number')?.setValue(phone_number);
    let manualTriggerData = this.formGroup.getRawValue();
    manualTriggerData.trigger = manualTriggerData.trigger?.toString();
    console.log(manualTriggerData.phone_number)
    if (manualTriggerData.trigger && manualTriggerData.phone_number != '55') {
      this.chatbotService.manualTrigger(manualTriggerData).subscribe(response => {
        this.submitting = false;
        this.formGroup.reset();
        this.phone_number.reset();
        this.toast.success("Fluxo iniciado com sucesso", "Sucesso");
      }, (error: HttpErrorResponse) => {
        this.mapErrorResponse(error);
        this.submitting = false;
      });
    } else {
      this.submitting = false;
    }
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro");
    } else {
      this.setFormErrors(errorResponse);
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "phone_number", "trigger"
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
      if (errorResponse.error[name] == 'phone_number')
        this.phone_number.get('phone_number')?.setErrors({ response: errorResponse.error[name] });
    });
  }

}
