import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavigationModule } from '../navigation/navigation.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AttendanceListComponent } from './attendance-list/attendance-list.component';
import { MedicalRecordsComponent } from './medical-records/medical-records.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AttendanceListComponent,
    MedicalRecordsComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NavigationModule,
    SharedModule,
    NgSelectModule,
    ReactiveFormsModule
  ]
})
export class ChartsModule { }
