import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Data } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AttendanceTimeModel } from '../models/attendance-time.model';
import { AttendanceModel } from '../models/attendance.model';
import { ListModel } from '../models/list.model';
import { ReminderStatusModel } from '../models/reminder-status.model';
import { RemindersModel } from '../models/reminders.model';
import { Schedule } from '../models/schedule.model';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {
  constructor(private httpClient: HttpClient) { }

  create(schedule: Schedule): Observable<Schedule> {
    return this.httpClient.post<Schedule>(`${environment.API_URL}/schedules/`, JSON.stringify(schedule));
  }

  list(searchString: string, orderingItem: string, isActive: boolean = true, page = 1): Observable<ListModel> {
    return this.httpClient.get<ListModel>(`${environment.API_URL}/schedules/?search=${searchString}&ordering=${orderingItem}&is_active=${isActive}&page=${page}`);
  }

  all(): Observable<Schedule[]> {
    return this.httpClient.get<Schedule[]>(`${environment.API_URL}/schedules/all/?&is_active=true&ordening=name`);
  }

  availableSchedulesForUser(): Observable<Schedule[]> {
    return this.httpClient.get<Schedule[]>(`${environment.API_URL}/schedules/available_schedules/`);
  }

  retrieve(id: number): Observable<Schedule> {
    return this.httpClient.get<Schedule>(`${environment.API_URL}/schedules/${id}/`);
  }

  update(id: number, schedule: Schedule): Observable<Schedule> {
    return this.httpClient.patch(`${environment.API_URL}/schedules/${id}/`, schedule);
  }

  remove(id: number): Observable<any> {
    return this.httpClient.delete(`${environment.API_URL}/schedules/${id}`);
  }

  getAvailableTime(id: number, date_time: string): Observable<Data> {
    return this.httpClient.get<Data>(`${environment.API_URL}/schedules/${id}/available_times/?date=${date_time}`);
  }

  attendanceCreate(id: number, attendance: AttendanceModel): Observable<any> {
    return this.httpClient.post<AttendanceModel>(`${environment.API_URL}/schedules/${id}/attendances/`, JSON.stringify(attendance));
  }

  removeAttendance(idSchedule: number, idAttendance: number): Observable<any> {
    return this.httpClient.delete(`${environment.API_URL}/schedules/${idSchedule}/attendances/${idAttendance}`);
  }

  getAttendance(idSchedule: number, idAttendance: number): Observable<AttendanceModel> {
    return this.httpClient.get<AttendanceModel>(`${environment.API_URL}/schedules/${idSchedule}/attendances/${idAttendance}/`);
  }

  updateAttendance(idSchedule: number, idAttendance: number, attendance: AttendanceModel): Observable<AttendanceModel> {
    return this.httpClient.patch(`${environment.API_URL}/schedules/${idSchedule}/attendances/${idAttendance}/`, attendance);
  }

  getWeek(id: number, date: string): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/schedules/${id}/week/?date=${date}`)
  }

  removeAttendances(id: number, attendances: any, date: moment.Moment): Observable<any> {
    let url = `${environment.API_URL}/schedules/${id}/remove/?date=${date.format('YYYY-MM-DD')}`;
    return this.httpClient.patch(url, attendances);
  }

  splitAttendance(id: number, body: any, date: moment.Moment): Observable<any> {
    let url = `${environment.API_URL}/schedules/${id}/split/?date=${date.format('YYYY-MM-DD')}`;
    return this.httpClient.patch(url, body);
  }

  mergeAttendances(id: number, body: any, date: moment.Moment): Observable<any> {
    let url = `${environment.API_URL}/schedules/${id}/merge/?date=${date.format('YYYY-MM-DD')}`;
    return this.httpClient.patch(url, body);
  }

  duplicateAttendance(schedulesId: number, attendanceId: number, data: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.API_URL}/schedules/${schedulesId}/attendances/${attendanceId}/duplicate/`, JSON.stringify(data));
  }

  onceSchedule(schedulesId: number, data: any): Observable<any> {
    return this.httpClient.post<any>(`${environment.API_URL}/schedules/${schedulesId}/once/`, JSON.stringify(data));
  }

  onceAttendanceType(attendanceId: number, data: any): Observable<any> {
    return this.httpClient.patch<any>(`${environment.API_URL}/attendance_times/${attendanceId}/`, JSON.stringify(data));
  }

  getAttendanceTypes(attendanceId: number): Observable<AttendanceTimeModel> {
    return this.httpClient.get<AttendanceTimeModel>(`${environment.API_URL}/attendance_times/${attendanceId}/`);
  }

  createReminder(reminderData: RemindersModel): Observable<RemindersModel> {
    return this.httpClient.post<RemindersModel>(`${environment.API_URL}/reminders/`, JSON.stringify(reminderData));
  }

  editReminder(reminder: RemindersModel): Observable<RemindersModel> {
    return this.httpClient.patch<RemindersModel>(`${environment.API_URL}/reminders/${reminder.id}/`, JSON.stringify(reminder))
  }

  getReminder(date: string): Observable<any> {
    return this.httpClient.get(`${environment.API_URL}/reminders/week/?date=${date}`);
  }

  listReminderStatuses(): Observable<ReminderStatusModel[]> {
    return this.httpClient.get<ReminderStatusModel[]>(`${environment.API_URL}/reminders_status/all/`);
  }
}
