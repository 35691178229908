import { DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ClinicModel } from 'src/app/core/models/clinic.model';
import { PhoneModel } from 'src/app/core/models/phone.model';
import { ProfessionalModel } from 'src/app/core/models/professional.model';
import { BrapiService } from 'src/app/core/services/brapi.service';
import { ClinicsService } from 'src/app/core/services/clinics.service';
import { RegistersService } from 'src/app/core/services/registers.service';
import { ConfirmationModalComponent } from 'src/app/shared/renderers/components/confirmation-modal/confirmation-modal.component';
import { FieldValidator } from 'src/app/shared/renderers/utils/field-validator/field-validator';

@Component({
  selector: 'app-clinic-form',
  templateUrl: './clinic-form.component.html',
  styleUrls: ['./clinic-form.component.scss']
})
export class ClinicFormComponent implements OnInit {
  addressFormGroup: FormGroup = this.fb.group({
    zipcode: [],
    address: [],
    number: [],
    complement: [],
    neighborhood: [],
    city: [],
    state: []
  });

  searchZipcode: boolean = false;

  formGroup: FormGroup = this.fb.group({
    id: [],
    name: [null],
    display_name: [],
    type: ["COMPANY"],
    email: [],
    document_number: [''],
    cro: [],
    tags: [],
    phone_numbers: this.fb.array([]),
    specialties: [],
    address: this.addressFormGroup,
    professionals: []
  });

  constructor(@Inject(DOCUMENT) private document: Document, private activatedRoute: ActivatedRoute, private fb: FormBuilder, private router: Router, config: NgbModalConfig,
    private modalService: NgbModal, private clinicsService: ClinicsService,
    private registersService: RegistersService, private brApiService: BrapiService, private toast: ToastrService) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  imageFile!: File | null;
  changedImage: boolean = false;

  loadingPage: boolean = true;
  isRegister: boolean = true;

  saveButtonDisabled: boolean = false;
  removeButtonDisabled: boolean = false;
  submitting: boolean = false;
  submittingRemove: boolean = false;
  removeButton: String = "Desativar";
  submitButton: String = "Salvar";
  button: boolean = false;

  phonesList!: PhoneModel[];
  professionalListAll!: ProfessionalModel[];
  professionalList: ProfessionalModel[] = [];
  clinicTagsList!: any[];
  specialtiesList!: any[];

  id!: number;
  existingClinicData!: ClinicModel;

  labelCpfCnpj: string = "CNPJ";
  labelName: string = "Razão social";
  labelDisplay: string = "Nome fantasia";
  mask: string = "00.000.000/0000-00";

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params["id"];
      if (this.id) {
        this.isRegister = false;
      }
    });
    this.initializeForm();
  }

  onSubtypeSelected(value?: string) {
    if (value == "COMPANY") {
      this.labelCpfCnpj = "CNPJ";
      this.mask = "00.000.000/0000-00";
      this.labelName = "Razão social";
      this.labelDisplay = "Nome fantasia";
      this.formGroup.get('document_number')?.clearValidators();
      this.formGroup.get('document_number')?.addValidators([FieldValidator.document3Validator()]);
    }
    else {
      this.labelCpfCnpj = "CPF";
      this.mask = "000.000.000-00";
      this.labelName = "Nome completo";
      this.labelDisplay = "Nome de tratamento";
      this.formGroup.get('document_number')?.clearValidators();
      this.formGroup.get('document_number')?.addValidators([FieldValidator.documentValidator()]);

    }

    if (this.formGroup.get("document_number")?.value) {
      this.formGroup.get("document_number")?.reset();
    }
  }

  initializeForm() {
    this.addPhoneNumber();
    this.fetchClinicTags();
    this.fetchSpecialties();
    this.fetchProfessional();
    if (this.id) setTimeout(() => this.fetchClinicsExistingData(), 1000);
    else this.loadingPage = false;
  }

  cancelHandler() {
    this.router.navigate(['dashboard/registers/clinics']);
  }

  removeHandler() {
    this.submittingRemove = true;
    const modalRef = this.modalService.open(ConfirmationModalComponent, { centered: true });
    modalRef.componentInstance.text = "Deseja desativar esta clínica?";
    modalRef.result.then((result) => {
      if (result == true) {
        this.clinicsService.clinicRemove(this.id).subscribe(() => {
          this.router.navigate(['dashboard/registers/clinics']);
          this.toast.success('Clínica desativada com sucesso', 'Sucesso');
        }, (errorResponse: HttpErrorResponse) => {
          this.mapErrorResponse(errorResponse);
        })
      } else {
        this.loadingPage = false;
      }
      this.submittingRemove = false;
    })
  }

  submitHandler() {
    this.formGroup.markAllAsTouched();
    this.submitting = true;
    (this.phoneNumberForms?.value as PhoneModel[]).forEach((phone, index) => {
      if (phone.id == null) this.phoneNumberForms.at(index)?.get('id')?.setValue(undefined);
    });
    if (this.formGroup.get('id')?.value == null) {
      this.formGroup.get('id')?.setValue(undefined);
    }
    if (this.formGroup.get('tags')?.value == null) {
      this.formGroup.get('tags')?.setValue([]);
    }
    if (this.formGroup.get('specialties')?.value == null) {
      this.formGroup.get('specialties')?.setValue([]);
    }
    let clinicData = this.formGroup.getRawValue() as ClinicModel;
    clinicData.professionals = this.professionalList.map(item => item!.id!);
    if (this.isRegister) this.clinicRegister(clinicData);
    else this.clinicEdit(clinicData);
  }

  fetchClinicTags() {
    this.registersService.tagGetAll("company").subscribe((response) => {
      if (response) this.clinicTagsList = response;
    });
  }

  fetchSpecialties() {
    this.registersService.listAllSpecialties().subscribe(response => this.specialtiesList = response);
  }

  fetchProfessional() {
    this.clinicsService.professionalsList().subscribe(response => this.professionalListAll = response.results!);
  }

  fetchClinicsExistingData() {
    this.clinicsService.clinicGet(this.id).subscribe((response) => {
      this.existingClinicData = response;
      this.phoneNumberForms.clear();
      response.phone_numbers.forEach(() => this.addPhoneNumber());
      this.onSubtypeSelected(this.existingClinicData!.type)
      let professional = this.existingClinicData!.professionals!;
      for (let i = 0; i < professional.length; i++) {
        this.setProfessional(professional[i]);
      }
      this.existingClinicData.professionals = [];
      this.formGroup.patchValue(this.existingClinicData);
      if (this.existingClinicData.is_active) {
        this.button = true;
        this.removeButton = 'Desativar';
        this.submitButton = 'Salvar';
      } else {
        this.button = false;
        this.submitButton = "Reativar";
        this.formGroup.disable();
        this.phoneNumberForms.disable();
      }
      this.loadingPage = false;
    })
  }

  setProfessional(professionalId?: any) {
    let professional = this.formGroup.get('professionals')?.value || professionalId || '';
    let professionals = this.professionalListAll.filter(item => item.id == professional);
    if (professionals[0] != undefined) this.professionalList.push(professionals[0]);
    this.professionalListAll = this.professionalListAll.filter((item) => {
      return this.professionalList?.find((item2) => item.id == item2.id) ? false : true;
    });
    this.formGroup.get('professionals')?.reset();
  }

  onImageSelect(file: any) {
    this.imageFile = file;
    this.changedImage = true
  }

  clinicRegister(clinicData: ClinicModel) {
    this.clinicsService.clinicRegister(clinicData).subscribe((response) => {
      if (this.changedImage) {
        this.clinicsService.uploadClinicImage(this.imageFile!, response.id!).subscribe(() => {
        }, (errorResponse: HttpErrorResponse) => {
          this.mapErrorResponse(errorResponse);
          this.submitting = false;
        });
      }
      this.isRegister = false;
      this.submitting = false;
      this.toast.success('Clínica criada com sucesso', 'Sucesso');
      this.router.navigate(['dashboard/registers/clinics']);
    }, (errorResponse: HttpErrorResponse) => {
      this.mapErrorResponse(errorResponse);
      this.submitting = false;
    });
  }

  clinicEdit(clinicData: ClinicModel) {
    clinicData.is_active = true;
    this.clinicsService.clinicEdit(clinicData).subscribe(() => {
      if (this.changedImage) {
        this.clinicsService.uploadClinicImage(this.imageFile!, clinicData.id!).subscribe(() => {
        }, (errorResponse: HttpErrorResponse) => {
          this.mapErrorResponse(errorResponse);
          this.submitting = false;
        });
      }
      this.isRegister = false;
      this.submitting = false;
      this.toast.success('Clínica alterada com sucesso', 'Sucesso');
      this.router.navigate(['dashboard/registers/clinics']);
    }, (errorResponse: HttpErrorResponse) => {
      this.mapErrorResponse(errorResponse);
      this.submitting = false;
    });
  }

  get phoneNumberForms() {
    return this.formGroup.get('phone_numbers') as FormArray;
  }

  getPhoneNumberForm(index: number) {
    return this.phoneNumberForms.controls[index] as FormGroup;
  }

  addPhoneNumber() {
    let phoneNumberFormGroup = this.fb.group({
      id: [],
      country_code: ['+55'],
      phone_number: [],
      type: ['WHATSAPP'],
      is_active: [true]
    });
    this.phoneNumberForms.push(phoneNumberFormGroup);
  }

  removePhoneNumber(at: number) {
    let formGroup = this.phoneNumberForms.controls[at] as FormGroup;
    if (formGroup.get('id')?.value) {
      formGroup.patchValue({ is_active: false });
    } else {
      this.phoneNumberForms.removeAt(at);
    }
  }

  phoneNumberIsActive(index: number) {
    let formGroup = this.phoneNumberForms.controls[index] as FormGroup;
    return formGroup.get('is_active')?.value;
  }

  handleZipCodeChange(ev: any) {
    this.searchZipcode = true;
    let zipcode = this.addressFormGroup.get('zipcode')?.value;
    this.addressFormGroup.disable();
    this.brApiService.getAddressByZipCode(zipcode).subscribe(response => {
      this.addressFormGroup.patchValue({
        address: response.street,
        neighborhood: response.neighborhood,
        city: response.city,
        state: response.state
      });
      this.searchZipcode = false;
      this.addressFormGroup.enable();
    }, (error: HttpErrorResponse) => {
      this.addressFormGroup.get('zipcode')?.setErrors({response: 'Cep não encontrado'});
      this.searchZipcode = false;
      this.addressFormGroup.enable();
    });
  }

  mapErrorResponse(errorResponse: HttpErrorResponse) {
    if (errorResponse.error["detail"]) {
      this.toast.error(errorResponse.error["detail"], "Erro", {
        closeButton: true,
      });
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    } else {
      this.setFormErrors(errorResponse);
      this.document.getElementById('main-container')?.scroll({ top: 0 });
    }
  }

  setFormErrors(errorResponse: HttpErrorResponse) {
    let errNames = [
      "name", "display_name", "gender", "birthdate",
      "email", "document_number", "cro", "clinics",
      "tags", "schedules", "phone_numbers", "specialties"
    ];
    errNames.forEach(name => {
      if (errorResponse.error[name])
        this.formGroup.get(name)?.setErrors({ response: errorResponse.error[name] });
    });

    if (errorResponse.error.phone_numbers) {
      let phone_numbers: [] = errorResponse.error.phone_numbers;
      phone_numbers.map((res, index) => {
        let formGroupPhone = this.phoneNumberForms.controls[index] as FormGroup;
        if (res["phone_number"])
          formGroupPhone.get('phone_number')?.setErrors({ response: res["phone_number"] });
        else {
          if (res["country_code"])
            formGroupPhone.get('phone_number')?.setErrors({ response: res["country_code"] });
          else {
            if (res["type"])
              formGroupPhone.get('phone_number')?.setErrors({ response: res["type"] });
          }
        }
      });
    }
  }

  canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    return true;
  };

}
