<div class="include-modal-wrapper">
  <div class="row p-0 justify-content-end">
    <button class="btn btn-link col-1" (click)="activeModal.close(modalItems.connected)">
      <i-bs name="x-lg" width="2rem" height="2rem"></i-bs>
    </button>
  </div>
  <div class="include-modal" *ngIf="modalItems else loadingTemplate">
    <div>
      <h5>1. Abra o WhatsApp no seu celular.</h5>
      <h5>
        2. Toque em <strong>Mais opções </strong>
         <i-bs name="three-dots-vertical" width="1.5rem" height="1.5rem"></i-bs>
        ou <strong>Configurações </strong>
         <i-bs name="gear-wide-connected" width="1.5rem" height="1.5rem"></i-bs> e
         selecione <strong>Aparelhos conectados</strong>.
      </h5>
      <h5>3. Aponte seu celular para o QR Code.</h5>
    </div>
    <ngx-qrcode [value]="value" cssClass="qrcode">
    </ngx-qrcode>
  </div>
  <ng-template #loadingTemplate>
    <div class="col card full-loading text-center">
      <div class="spinner-border spinner-border-xl" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-template>
</div>
