import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavigationModule } from './navigation/navigation.module';
import { SchedulesModule } from './schedules/schedules.module';
import { PatientsModule } from './patients/patients.module';
import { RegistersModule } from './registers/registers.module';
import { RenderersModule } from 'src/app/shared/renderers/renderers.module';
import { ChartsModule } from './charts/charts.module';
import { FinancialModule } from './financial/financial.module';
import { OximeterModule } from './oximeter/oximeter.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    NavigationModule,
    SchedulesModule,
    PatientsModule,
    RegistersModule,
    RenderersModule,
    ChartsModule,
    FinancialModule,
    OximeterModule
  ]
})
export class DashboardModule { }
