<div *ngIf="formGroup && formGroup.get(controlName)"
  [ngClass]="['field', removeVerticalSpacing ? 'remove-vertical-space' : '', labelPosition == 'left' ? 'horizontal' : '']"
  [formGroup]="formGroup">
  <label class="field__title" [for]="id"
         [ngClass]="{'field__title--left': labelPosition == 'left'}">{{label ? label : ""}}</label>

  <textarea [id]="id" [placeholder]="placeholder ? placeholder : ''" [formControlName]="controlName"
         [rows]="rows" [disabled]="disabled"
         [ngClass]="[(formGroup.get(controlName)!.errors && formGroup.get(controlName)!.touched) || (formGroup.get(controlName)!.errors && formGroup.get(controlName)!.errors['response']) ? 'error' : '', capitalize ? 'capitalize' : '']"
         ></textarea>
  <app-form-error [id]="id" [formGroup]="formGroup" *ngIf="showError" [controlName]="controlName"></app-form-error>
</div>
